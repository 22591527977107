<template>
  <v-app-bar app clipped-left color="#00a7e5">
    <div class="d-flex align-center">
      <v-img
        class="mr-6"
        alt="logo-wizeline"
        :src="require('../../assets/wordmark_white.svg')"
        width="185"
        max-width="300"
        max-height="64"
        contain
        aspect-ratio="1"
        @click="goToMain"
      />
      <v-toolbar-title class="white--text text-subtitle-1">{{ title }}</v-toolbar-title>
    </div>
    <v-spacer></v-spacer>

    <div v-if="userInformation.name" class="d-flex mr-1 mr-sm-5">
      <div v-if="!$vuetify.breakpoint.xsOnly" class="d-flex align-center">
        <v-avatar size="38px" class="mr-5">
          <v-img data-test-id="user-image" :src="userInformation.picture" alt="user-icon" />
        </v-avatar>
        <div data-test-id="user-name" class="mr-1 font-weight-medium white--text">
          {{ userInformation.name }}
        </div>
        <v-tooltip v-if="scopes ? scopes.includes('interviewer') : false" bottom>
          <template #activator="{ on }">
            <v-btn icon color="white" to="/interviewer-settings" v-on="on">
              <v-icon>mdi-account-cog</v-icon>
            </v-btn>
          </template>
          <span>
            Interviewer Settings
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn icon color="white" :to="cognitoUrl" @click="logout" v-on="on">
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </template>
          <span>
            Log Out
          </span>
        </v-tooltip>
      </div>
      <!-- Mobile -->
      <v-menu v-else max-width="325">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon color="white">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list :close-on-content-click="false">
          <v-list-item>
            <v-list-item-avatar size="60px" class="mr-3">
              <img data-test-id="user-image" :src="userInformation.picture" alt="user-icon" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title data-test-id="user-name">
                {{ userInformation.name }}
              </v-list-item-title>
              <v-list-item-subtitle data-test-id="user-email">
                {{ userInformation.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <template v-if="scopes ? scopes.includes('interviewer') : false">
            <v-divider></v-divider>
            <v-list-item to="/interviewer-settings">
              <v-list-item-icon>
                <v-icon>mdi-account-cog</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Interviewer Settings</v-list-item-title>
            </v-list-item>
          </template>
          <v-divider></v-divider>
          <v-list-item :to="cognitoUrl" @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      cognitoUrl: process.env.VUE_APP_COGNITO_URL_LOGOUT,
      title: 'InDiTool',
    };
  },
  computed: {
    ...mapGetters('user', { userInformation: 'getUserInformation' }),
    ...mapGetters('auth', { scopes: 'getScopes' }),
  },
  methods: {
    ...mapActions('auth', { userLogout: 'userLogout' }),
    logout() {
      window.location.replace(this.cognitoUrl);
      this.userLogout();
    },
    goToMain() {
      if (this.$router.history.current.path !== '/') {
        this.$router.push('/');
      }
    },
  },
};
</script>
