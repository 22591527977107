const menteeRegistrationErrors = {
  EmailIsAlreadyRegisteredException: 'Email is already registered',
  MenteeDoesNotHaveEnoughAvailableRecurringSlotsInTheNextSevenDaysException:
    'Mentee does not have enough recurring slots available in the next seven days',
};

const taRegistrationErrors = {
  EmailIsAlreadyRegisteredException: 'Email is already registered',
};

const scheduleInterviewErrors = {
  EventCouldNotBeCreatedException: 'Event could not be created using the Google API',
  UserIsNotPartOfOrganizationDomainException:
    "You are not part of Wizeline's organization and cannot schedule interviews",
  UserDoesNotHavePermissionToCreateEventsOnCalendarException:
    // eslint-disable-next-line max-len
    "You cannot schedule interviews on this calendar, contact a talent acquisition partner to get writer access to this discipline's interviews calendar.",
};

export { menteeRegistrationErrors, taRegistrationErrors, scheduleInterviewErrors };
