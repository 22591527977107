<template>
  <v-data-table
    :headers="headers"
    :items="candidates"
    :loading="loadingApplications"
    loading-text="Loading pending applications... Please wait"
    fixed-header
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-for="(_, slot) in $slots" v-slot:[slot]>
      <!-- @slot Slots passed to vuetify component. -->
      <slot :name="slot" />
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <span>{{ item.name }}</span>
    </template>
    <template v-slot:[`item.jobName`]="{ item }">
      <span>{{ formattedJobName(item.jobName) }}</span>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn small data-test-id="schedule-interview-btn" @click="schedule(item)">
        <v-icon left>mdi-calendar</v-icon>
        Schedule
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    candidates: {
      type: Array,
      required: true,
    },
    loadingApplications: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Email', value: 'email' },
      { text: 'Discipline', value: 'discipline.name' },
      { text: 'Job Position', value: 'jobName' },
      { text: 'Actions', value: 'actions', width: '20%' },
    ],
  }),
  methods: {
    formattedJobName(jobName) {
      return jobName && jobName.replace(';', ' - ');
    },
    schedule(candidate) {
      /**
       * Emit the schedule event to the parent component with the candidate's id.
       */
      this.$emit('schedule', candidate);
    },
  },
};
</script>

<style scoped>
::v-deep .v-data-footer {
  margin-right: 0px !important;
}

::v-deep tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
