<template>
  <country-flag
    :country="countryCode"
    v-bind="$attrs"
    data-test-id="country-flag"
    :class="{ 'no-flag': countryCode === 'XXX' }"
  />
</template>

<script>
import CountryFlag from 'vue-country-flag';
import countries from './countries';

export default {
  components: {
    CountryFlag,
  },
  inheritAttrs: false,
  props: {
    /**
     * Name of the country
     */
    country: {
      type: String,
      required: true,
    },
  },
  computed: {
    countryCode() {
      return countries[this.country.toLowerCase()] || 'XXX';
    },
  },
};
</script>

<style scoped>
.no-flag {
  background: url('../../../assets/IconFlag/web.png') no-repeat center, #63e0e4;
}
</style>
