<template>
  <div data-test-id="my-interviews-section">
    <v-tabs v-model="tab">
      <v-tab data-test-id="my-interviews-table-scheduled-tab" @click="changeStatus('scheduled')">
        Sheduled
        <label
          v-if="tab === 0 && !isLoading && interviews"
          data-test-id="my-interviews-table-scheduled-tab-count"
          class="tab-amount"
        >
          {{ interviews.scheduled.length }}
        </label>
      </v-tab>
      <v-tab data-test-id="my-interviews-table-completed-tab" @click="changeStatus('completed')">
        Completed
        <label
          v-if="tab === 1 && !isLoading && interviews"
          data-test-id="my-interviews-table-completed-tab-count"
          class="tab-amount"
        >
          {{ interviews.completed.length }}
        </label>
      </v-tab>
      <v-tab data-test-id="my-interviews-table-canceled-tab" @click="changeStatus('canceled')">
        Canceled
        <label
          v-if="tab === 2 && !isLoading && interviews"
          data-test-id="my-interviews-table-canceled-tab-count"
          class="tab-amount"
        >
          {{ interviews.canceled.length }}
        </label>
      </v-tab>
    </v-tabs>
    <MyInterviewsTable :interviews="filteredInterviews" height="290px" />
  </div>
</template>

<script>
import MyInterviewsTable from './MyInterviewsTable/MyInterviewsTable.vue';

export default {
  components: { MyInterviewsTable },
  props: {
    interviews: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      tab: 0,
      filter: {
        status: 'scheduled',
      },
    };
  },
  computed: {
    filteredInterviews() {
      return this.interviews[this.filter.status];
    },
  },
  methods: {
    changeStatus(status) {
      this.filter.status = status;
    },
  },
};
</script>

<style scoped>
.tab-amount {
  background-color: #f5f5f5;
  border-radius: 20%;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  height: 20px;
  line-height: 20px;
  margin-left: 5px;
  text-align: center;
  width: 20px;
}
</style>
