<template>
  <div>
    <v-row align="center">
      <v-col>
        <div class="text-h4">Applications</div>
      </v-col>
      <v-col cols="12" sm="5">
        <v-text-field
          v-model="searchApplications"
          label="Search"
          prepend-inner-icon="mdi-magnify"
          single-line
          hide-details
          clearable
          solo
          data-test-id="search-candidates"
        />
      </v-col>
    </v-row>
    <v-divider class="mt-3"></v-divider>
    <v-row>
      <v-col>
        <loading-dialog
          v-model="dialogLoader.show"
          :loading-text="dialogLoader.text"
          :icon="dialogLoader.icon"
          :color="dialogLoader.iconColor"
          :show-loader-bar="dialogLoader.showLoaderBar"
        ></loading-dialog>
        <v-dialog v-model="dialogEdit" max-width="800px">
          <ModalEditInterview
            data-test-id="modal-edit-interview"
            @confirmEditAction="() => confirmEditAction()"
            @close="dialogEdit = false"
          />
        </v-dialog>
        <cancel-dialog v-model="dialogCancelInterview" :dialog-cancel-interview-action="dialogCancelInterviewAction" />
        <add-mentee-dialog v-model="dialogAddMentee" :mentees="menteesForInterview" :action="dialogAddMenteeAction" />
        <v-tabs v-model="tab" show-arrows @change="searchApplications = null">
          <v-tab data-test-id="pending-tab" @click="statusChange('pending')">
            Pending <label v-if="tab === 0" class="tab-amount">{{ applications.length }}</label>
          </v-tab>
          <v-tab data-test-id="scheduled-tab" @click="statusChange('scheduled')">
            Scheduled <label v-if="tab === 1" class="tab-amount">{{ applications.length }}</label>
          </v-tab>
          <v-tab data-test-id="completed-tab" @click="statusChange('completed')">
            Completed <label v-if="tab === 2" class="tab-amount">{{ applications.length }}</label>
          </v-tab>
          <v-spacer></v-spacer>
          <div style="align-self: center;">
            <base-dropdown
              v-model="filteredDisciplines"
              :items="disciplines"
              multiple
              label="Discipline"
              data-test-id="discipline-filter"
              @on-change="disciplinesChange"
            />
          </div>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item data-test-id="pending-table">
            <pending-table
              :candidates="searchApplications ? flexSearch : applications"
              :loading-applications="isLoading"
              height="500px"
              @schedule="(candidate) => scheduleInterviews(candidate)"
            />
          </v-tab-item>
          <v-tab-item>
            <scheduled-table
              :candidates="searchApplications ? flexSearch : applications"
              :loading-applications="isLoading"
              :items-per-page="5"
              height="500px"
              @schedule="(candidate) => scheduleInterviews(candidate)"
              @edit="(id) => editInterviews(id)"
              @cancel="(id) => cancelInterview(id)"
              @addMentee="(interview, disciplineId) => addMenteeToInterview(interview, disciplineId)"
            />
          </v-tab-item>
          <v-tab-item>
            <completed-table
              :candidates="searchApplications ? flexSearch : applications"
              :loading-applications="isLoading"
              height="500px"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FlexSearch from 'flexsearch';
import Vue from 'vue';
import { mapActions } from 'vuex';
import { INTERVIEW_STATUS } from '../../../constants/index';
import PendingTable from '../PendingTable/PendingTable.vue';
import ScheduledTable from '../ScheduledTable/ScheduledTable.vue';
import CompletedTable from '../CompletedTable/CompletedTable.vue';
import BaseDropdown from '../../BaseDropdown/BaseDropdown.vue';
import CancelDialog from '../Dialog/CancelDialog.vue';
import AddMenteeDialog from '../Dialog/AddMenteeDialog.vue';
import ModalEditInterview from '../../Dashboard/ModalEditInterview.vue';
import LoadingDialog from '../Dialog/LoadingDialog.vue';
import {
  getDisciplines,
  getApplications,
  cancelInterviewRequest,
  getMenteesForInterview,
  putAddMenteeToInterview,
} from '../../../services/dashboardNext';

export default {
  components: {
    PendingTable,
    ScheduledTable,
    CompletedTable,
    BaseDropdown,
    ModalEditInterview,
    CancelDialog,
    AddMenteeDialog,
    LoadingDialog,
  },
  inheritAttrs: false,
  data() {
    return {
      searchApplications: null,
      tab: 0,
      filteredDisciplines: [],
      disciplines: [],
      dialogEdit: false,
      dialogCancelInterviewAction: null,
      dialogCancelInterview: false,
      dialogAddMenteeAction: null,
      dialogAddMentee: false,
      menteesForInterview: [],
      applications: [],
      infoSelectedToFilter: {
        status: 'pending',
        disciplines: [],
      },
      isLoading: false,
      dialogLoader: {
        icon: 'mdi-information-slab-circle',
        iconColor: 'primary',
        showLoaderBar: true,
        show: false,
        text: '',
      },
    };
  },
  computed: {
    flexSearch() {
      const index = new FlexSearch.Document({
        tokenize: 'full',
        optimize: true,
        resolution: 9,
        document: {
          id: 'id',
          store: true,
          index: ['searchContent'],
        },
      });

      this.applications.forEach((application) => {
        index.add(application);
      });
      const searchList = index.search(this.searchApplications, {
        enrich: true,
      });
      const results = [];
      searchList.forEach((item) => {
        item.result.forEach((application) => {
          if (!results.some((result) => result.id === application.doc.id)) {
            results.push(application.doc);
          }
        });
      });
      return results;
    },
  },
  mounted() {
    getDisciplines().then((response) => {
      this.disciplines = response.map((discipline) => ({
        value: discipline.id,
        text: discipline.name,
      }));
    });
    this.fetchApplications();
  },
  methods: {
    ...mapActions('editInterview', {
      initInterviewData: 'initializeInterviewData',
      updateInterview: 'updateInterviewData',
    }),
    ...mapActions('scheduleInterview', {
      setFormData: 'setScheduleInterviewData',
    }),
    async scheduleInterviews(candidate) {
      await this.setFormData({
        // eslint-disable-next-line max-len
        greenhouseProfileLink: `https://wizeline.greenhouse.io/people/${candidate.greenhouseCandidateId}?application_id=${candidate.greenhouseApplicationId}`,
      });
      this.$router.push('/schedule-interview');
    },
    async setInitialStateDialogLoading() {
      Vue.set(this.dialogLoader, 'icon', 'mdi-information-slab-circle');
      Vue.set(this.dialogLoader, 'iconColor', 'primary');
      Vue.set(this.dialogLoader, 'showLoaderBar', true);
      Vue.set(this.dialogLoader, 'text', '');
    },
    errorModalInfo() {
      this.dialogLoader = {
        ...this.dialogLoader,
        icon: 'mdi-alert',
        iconColor: 'amber accent-3',
        showLoaderBar: false,
        text: "We've run into a problem. Please try again later.",
      };
    },
    async editInterviews(interviewId) {
      this.setInitialStateDialogLoading();
      this.initInterviewData(interviewId);
      this.dialogEdit = true;
    },
    async confirmEditAction() {
      this.dialogEdit = false;
      this.dialogLoader.show = true;
      this.dialogLoader.text = 'Editing Interview...';
      try {
        const response = await this.updateInterview();
        if (response.status === 204) {
          this.dialogLoader.icon = 'mdi-check-circle';
          this.dialogLoader.iconColor = 'success';
          this.dialogLoader.text = 'Interview updated successfully';
          this.dialogLoader.showLoaderBar = false;
          setTimeout(() => {
            this.dialogLoader.show = false;
            this.statusChange('scheduled');
          }, '2000');
        }
      } catch (error) {
        this.errorModalInfo();
        setTimeout(() => {
          this.dialogLoader.show = false;
        }, '2000');
      }
    },
    addMenteeToInterview(interview, disciplineId) {
      this.dialogAddMentee = true;
      this.setInitialStateDialogLoading();

      getMenteesForInterview(interview.type_id, disciplineId).then((resp) => {
        this.menteesForInterview = resp;
      });
      this.dialogAddMenteeAction = async (menteeSelected) => {
        this.dialogAddMentee = false;
        this.dialogLoader.show = true;
        this.dialogLoader.text = 'Adding Mentee...';

        try {
          await putAddMenteeToInterview(interview.interview_id, menteeSelected);
          this.statusChange('scheduled');
          this.dialogLoader.show = false;
        } catch (error) {
          setTimeout(() => {
            this.dialogLoader.show = false;
          }, '2000');
        }
      };
    },

    async cancelInterview(interviewId) {
      this.dialogCancelInterview = true;
      this.setInitialStateDialogLoading();

      this.dialogCancelInterviewAction = async () => {
        this.dialogCancelInterview = false;
        this.dialogLoader.show = true;
        this.dialogLoader.text = 'Canceling Interview...';
        try {
          await cancelInterviewRequest(interviewId);
          this.statusChange('scheduled');
          this.dialogLoader.show = false;
        } catch (error) {
          this.errorModalInfo();
          setTimeout(() => {
            this.dialogLoader.show = false;
          }, '2000');
        }
      };
    },
    disciplinesChange(items) {
      this.infoSelectedToFilter.disciplines = items;
      this.fetchApplications();
    },
    fetchApplications() {
      this.isLoading = true;
      this.applications = [];
      getApplications(this.infoSelectedToFilter).then((response) => {
        this.isLoading = false;
        this.applications = this.infoSelectedToFilter.status !== 'pending' ? this.sortApplications(response) : response;
      });
    },
    statusChange(status) {
      this.infoSelectedToFilter.status = status;
      this.fetchApplications();
    },
    sortApplications(applications) {
      function compareScheduledApplications(a, b) {
        const aHasScheduled = a.interviews.some((interview) => interview?.status === INTERVIEW_STATUS.scheduled);
        const bHasScheduled = b.interviews.some((interview) => interview?.status === INTERVIEW_STATUS.scheduled);

        if (aHasScheduled && !bHasScheduled) {
          return -1; // a has scheduled interviews, put it before b
        }
        if (!aHasScheduled && bHasScheduled) {
          return 1; // b has scheduled interviews, put it before a
        }
        if (aHasScheduled && bHasScheduled) {
          // Both have scheduled interviews or neither have, sort by the most recent date
          const aClosestScheduledDate = Math.min(
            ...a.interviews
              .filter((interview) => interview.status === INTERVIEW_STATUS.scheduled)
              .map((interview) => new Date(interview.interview_date?.start))
          );
          const bClosestScheduledDate = Math.min(
            ...b.interviews
              .filter((interview) => interview.status === INTERVIEW_STATUS.scheduled)
              .map((interview) => new Date(interview.interview_date?.start))
          );

          return aClosestScheduledDate - bClosestScheduledDate;
        }
        const aMostRecentCompletedOrCanceled = Math.max(
          ...a.interviews
            .filter((interview) => interview.status !== INTERVIEW_STATUS.scheduled)
            .map((interview) => new Date(interview.interview_date?.start))
        );
        const bMostRecentCompletedOrCanceled = Math.max(
          ...b.interviews
            .filter((interview) => interview.status !== INTERVIEW_STATUS.scheduled)
            .map((interview) => new Date(interview.interview_date?.start))
        );
        return bMostRecentCompletedOrCanceled - aMostRecentCompletedOrCanceled;
      }
      // Custom comparator function for interviews with 'scheduled' status
      function compareScheduledInterviews(a, b) {
        if (a.status === INTERVIEW_STATUS.scheduled && b.status !== INTERVIEW_STATUS.scheduled) {
          return -1; // a has 'scheduled' status, put it before b
        }
        if (a.status !== INTERVIEW_STATUS.scheduled && b.status === INTERVIEW_STATUS.scheduled) {
          return 1; // b has 'scheduled' status, put it before a
        }
        return new Date(a.interview_date?.start) - new Date(b.interview_date?.start);
      }

      applications.sort(compareScheduledApplications);

      applications.forEach((item) => {
        if (item.interviews.some((interview) => interview.status === INTERVIEW_STATUS.scheduled)) {
          item.interviews.sort(compareScheduledInterviews);
        }
      });
      return applications;
    },
  },
};
</script>

<style>
.tab-amount {
  background-color: #f5f5f5;
  border-radius: 20%;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  height: 20px;
  line-height: 20px;
  margin-left: 5px;
  text-align: center;
  width: 20px;
}

.v-window.v-tabs-items {
  border: thin solid rgba(0, 0, 0, 0.12);
}
</style>
