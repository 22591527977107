import { createLocalVue, mount, RouterLinkStub } from '@vue/test-utils';
import Vuetify from 'vuetify';
import { required } from 'vee-validate/dist/rules.umd';
import { extend } from 'vee-validate';
import HttpStatusCodes from '@/services/HttpStatusCodes';
import UserSettings from '@/pages/UserSettings.vue';
import {
  DISCIPLINE_SWE,
  DISCIPLINE_SRE,
  getTechnologiesSuccessResponse,
  getLevelsSuccessResponse,
} from '@/constants/tests/shared.mock';
import { setupServer } from 'msw/node';
import { rest } from 'msw';
import TestingUtils from '../../../tests/TestingUtils';
import {
  interviewerTechnologiesMock,
  putTechnologiesRequest,
  getInterviewerSettingsServiceResponse,
} from './mocks/interviewers.mock';

extend('required', required);

describe('UserSettings', () => {
  /**
   * @param {function} select
   * @param {Vue} localVue
   */
  const openSelectList = async (select, localVue) => {
    select().trigger('click');
    await localVue.nextTick();
  };

  /**
   * @param {function} openLists
   * @param {Number} listIndex
   * @param {Number} elementIndex
   * @param {Vue} localVue
   */
  const selectElementFromOpenedLists = async (openLists, elementIndex, localVue) => {
    openLists().at(elementIndex).trigger('click');
    await localVue.nextTick();
  };

  const localVue = createLocalVue();
  const vuetify = new Vuetify();
  const mockRouter = {
    push: jest.fn(),
  };
  document.body.setAttribute('data-app', true);

  const server = setupServer();

  const makeGetTechnologiesRequestInterceptor = () => {
    server.use(
      rest.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/technologies`, (req, res, ctx) => {
        return res(ctx.status(HttpStatusCodes.OK), ctx.json(getTechnologiesSuccessResponse));
      })
    );
  };

  const makeGetInterviewerSettingsRequestInterceptor = (technologies, discipline) => {
    const apiResponseData = {
      ...getInterviewerSettingsServiceResponse,
      technologies,
      discipline,
    };
    server.use(
      rest.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/interviewers/me`, (req, res, ctx) => {
        return res(ctx.status(HttpStatusCodes.OK), ctx.json(apiResponseData));
      })
    );
  };

  const makeGetLevelsRequestInterceptor = () => {
    server.use(
      rest.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/levels`, (req, res, ctx) => {
        return res(ctx.status(HttpStatusCodes.OK), ctx.json(getLevelsSuccessResponse));
      })
    );
  };

  const makePutInterviewerSettingsRequestInterceptor = (apiRequestData) => {
    server.use(
      rest.put(`${process.env.VUE_APP_BACKEND_ENDPOINT}/interviewers/me`, (req, res, ctx) => {
        req.json(apiRequestData);
        return res(ctx.status(HttpStatusCodes.NO_CONTENT), ctx.json({}));
      })
    );
  };

  const waitForGetInterviewerSettingsAPIRequest = async () => {
    await TestingUtils.waitForAPIRequestNext();
    await TestingUtils.waitForAPIRequestNext();
  };
  const waitForPutInterviewerSettingsAPIRequest = async () => {
    await TestingUtils.waitForAPIRequestNext();
    await TestingUtils.waitForAPIRequestNext();
  };
  const waitForGetTechnologiesAPIRequest = async () => {
    await TestingUtils.waitForAPIRequestNext();
  };
  const waitForGetLevelsAPIRequest = async () => {
    await TestingUtils.waitForAPIRequestNext();
  };

  const build = () => {
    const wrapper = mount(UserSettings, {
      localVue,
      vuetify,
      stubs: {
        'router-link': RouterLinkStub,
      },
      mocks: {
        $router: mockRouter,
      },
    });

    return {
      email: () => wrapper.find('[data-test-id="email"]'),
      interviewerLevel: () => wrapper.find('[data-test-id="interviewer-level"]'),
      statisticsCard: () => wrapper.find('[data-test-id="statistics-card"'),
      graduatedCard: () => wrapper.find('[data-test-id="graduated-card"'),
      leadingCard: () => wrapper.find('[data-test-id="leading-card"'),
      shadowingCard: () => wrapper.find('[data-test-id="shadowing-card"'),
      lowOption: () => wrapper.find('[data-test-id="low-option"]'),
      normalOption: () => wrapper.find('[data-test-id="normal-option"]'),
      interviewerTechnologiesSelect: () => wrapper.find('[data-test-id="interviewer-technologies"]'),
      javaScriptChipCloseButton: () => wrapper.find('[data-test-id="tech-chip-JavaScript"]').find('.v-chip__close'),
      technologiesChipsList: () => wrapper.findAll('span.v-chip'),
      openLists: () => wrapper.find('.menuable__content__active').findAll('.v-list-item'),
      saveBtn: () => wrapper.find('[data-test-id="save-button"]'),
      snackbar: () => wrapper.find('[data-test-id="snackbar"]'),
      myInterviewSection: () => wrapper.find('[data-test-id="my-interviews-section"]'),
      myInterviewTableScheduledTab: () => wrapper.find('[data-test-id="my-interviews-table-scheduled-tab"]'),
      myInterviewTableCompletedTab: () => wrapper.find('[data-test-id="my-interviews-table-completed-tab"]'),
      myInterviewTableCancelledTab: () => wrapper.find('[data-test-id="my-interviews-table-canceled-tab"]'),
      myInterviewTableScheduledTabCount: () => wrapper.find('[data-test-id="my-interviews-table-scheduled-tab-count"]'),
      myInterviewTableCompletedTabCount: () => wrapper.find('[data-test-id="my-interviews-table-completed-tab-count"]'),
      myInterviewTableCancelledTabCount: () => wrapper.find('[data-test-id="my-interviews-table-canceled-tab-count"]'),
      myInteriewsTable: () => wrapper.find('[data-test-id="my-interviews-table"]'),
      wrapper,
    };
  };

  it('Interviewer settings loads', async () => {
    const unhandledRequests = [];
    makeGetInterviewerSettingsRequestInterceptor(interviewerTechnologiesMock, DISCIPLINE_SWE);
    makeGetTechnologiesRequestInterceptor();
    makeGetLevelsRequestInterceptor();

    server.listen({
      onUnhandledRequest(req) {
        unhandledRequests.push({
          method: req.method,
          url: req.url.href,
        });
        console.error(unhandledRequests.slice(-1));
      },
    });

    const {
      email,
      normalOption,
      interviewerTechnologiesSelect,
      technologiesChipsList,
      interviewerLevel,
      statisticsCard,
      graduatedCard,
      leadingCard,
      shadowingCard,
      myInterviewSection,
      myInterviewTableScheduledTab,
      myInterviewTableCompletedTab,
      myInterviewTableCancelledTab,
      myInterviewTableScheduledTabCount,
      myInteriewsTable,
    } = build();

    await waitForGetInterviewerSettingsAPIRequest();
    await waitForGetTechnologiesAPIRequest();
    await waitForGetLevelsAPIRequest();

    expect(email().element.value).toBe(getInterviewerSettingsServiceResponse.email);
    expect(interviewerLevel().element.closest('div').textContent).toBe(
      getInterviewerSettingsServiceResponse.level.name
    );

    const statisticsInterviews = statisticsCard().findAll('.v-list-item__subtitle');
    expect(statisticsInterviews.at(0).html()).toContain(
      getInterviewerSettingsServiceResponse.current_year_completed_interviews
    );
    expect(statisticsInterviews.at(1).html()).toContain(
      getInterviewerSettingsServiceResponse.last_year_completed_interviews
    );

    const gratuadetInterviewTypes = graduatedCard().findAll('.v-list-item__title');

    for (let index = 0; index < getInterviewerSettingsServiceResponse.graduated_interview_types.length; index += 1) {
      expect(gratuadetInterviewTypes.at(index).html()).toContain(
        getInterviewerSettingsServiceResponse.graduated_interview_types[index]
      );
    }

    const leadingInterviewTypes = leadingCard().findAll('.v-list-item__title');
    const leadingNumberOfInterviews = leadingCard().findAll('.v-list-item__subtitle');

    for (let index = 0; index < getInterviewerSettingsServiceResponse.leading_interview_types.length; index += 1) {
      expect(leadingInterviewTypes.at(index).text()).toContain(
        getInterviewerSettingsServiceResponse.leading_interview_types[index].interview_type_name
      );
      expect(leadingNumberOfInterviews.at(index).text()).toContain(
        getInterviewerSettingsServiceResponse.leading_interview_types[index].number_of_interviews
      );
    }

    const notShadowingInterviewTypes = shadowingCard().find('.v-card__text');

    expect(notShadowingInterviewTypes.text()).toContain(`Currently you're not shadowing interviews as a mentee yet`);
    expect(unhandledRequests).toHaveLength(0);

    expect(normalOption().element.checked).toBe(true);
    expect(interviewerTechnologiesSelect().exists()).toBe(true);
    expect(technologiesChipsList()).toHaveLength(interviewerTechnologiesMock.length);
    expect(myInterviewSection().exists()).toBe(true);
    expect(myInterviewTableScheduledTab().exists()).toBe(true);
    expect(myInterviewTableCompletedTab().exists()).toBe(true);
    expect(myInterviewTableCancelledTab().exists()).toBe(true);
    expect(myInterviewTableScheduledTabCount().exists()).toBe(true);
    expect(myInteriewsTable().exists()).toBe(true);
  });

  it('my interviews section sheduled tab should show record count', async () => {
    const unhandledRequests = [];
    makeGetInterviewerSettingsRequestInterceptor(interviewerTechnologiesMock, DISCIPLINE_SWE);
    makeGetTechnologiesRequestInterceptor();
    makeGetLevelsRequestInterceptor();

    server.listen({
      onUnhandledRequest(req) {
        unhandledRequests.push({
          method: req.method,
          url: req.url.href,
        });
        console.error(unhandledRequests.slice(-1));
      },
    });

    const { myInterviewTableScheduledTabCount } = build();

    await waitForGetInterviewerSettingsAPIRequest();
    await waitForGetTechnologiesAPIRequest();
    await waitForGetLevelsAPIRequest();

    const sheduledCount = getInterviewerSettingsServiceResponse.interviews.filter(
      (interview) => interview.status.name.toLocaleLowerCase() === 'scheduled'
    ).length;

    expect(myInterviewTableScheduledTabCount().text()).toContain(sheduledCount);
  });

  it('my interviews section completed tab should show record count', async () => {
    const unhandledRequests = [];
    makeGetInterviewerSettingsRequestInterceptor(interviewerTechnologiesMock, DISCIPLINE_SWE);
    makeGetTechnologiesRequestInterceptor();
    makeGetLevelsRequestInterceptor();

    server.listen({
      onUnhandledRequest(req) {
        unhandledRequests.push({
          method: req.method,
          url: req.url.href,
        });
        console.error(unhandledRequests.slice(-1));
      },
    });

    const { myInterviewTableCompletedTabCount, myInterviewTableCompletedTab } = build();

    await waitForGetInterviewerSettingsAPIRequest();
    await waitForGetTechnologiesAPIRequest();
    await waitForGetLevelsAPIRequest();

    const completedCount = getInterviewerSettingsServiceResponse.interviews.filter(
      (interview) => interview.status.name.toLocaleLowerCase() === 'completed'
    ).length;

    await myInterviewTableCompletedTab().trigger('click');

    expect(myInterviewTableCompletedTabCount().exists()).toBe(true);
    expect(myInterviewTableCompletedTabCount().text()).toContain(completedCount);
  });

  it('my interviews section cancelled tab should show record count', async () => {
    const unhandledRequests = [];
    makeGetInterviewerSettingsRequestInterceptor(interviewerTechnologiesMock, DISCIPLINE_SWE);
    makeGetTechnologiesRequestInterceptor();
    makeGetLevelsRequestInterceptor();

    server.listen({
      onUnhandledRequest(req) {
        unhandledRequests.push({
          method: req.method,
          url: req.url.href,
        });
        console.error(unhandledRequests.slice(-1));
      },
    });

    const { myInterviewTableCancelledTabCount, myInterviewTableCancelledTab } = build();

    await waitForGetInterviewerSettingsAPIRequest();
    await waitForGetTechnologiesAPIRequest();
    await waitForGetLevelsAPIRequest();

    const cancelledCount = getInterviewerSettingsServiceResponse.interviews.filter(
      (interview) => interview.status.name.toLocaleLowerCase() === 'canceled'
    ).length;

    await myInterviewTableCancelledTab().trigger('click');

    expect(myInterviewTableCancelledTabCount().exists()).toBe(true);
    expect(myInterviewTableCancelledTabCount().text()).toContain(cancelledCount);
  });

  it('update interviewer settings (do not include level)', async () => {
    const unhandledRequests = [];
    const putInterviewerSettingsAPIRequest = {
      interviews_desired_per_week: 'low',
      technologies: putTechnologiesRequest,
      level_id: getInterviewerSettingsServiceResponse.level.id,
    };
    makeGetInterviewerSettingsRequestInterceptor(interviewerTechnologiesMock, DISCIPLINE_SWE);
    makeGetTechnologiesRequestInterceptor();
    makeGetLevelsRequestInterceptor();

    makePutInterviewerSettingsRequestInterceptor(putInterviewerSettingsAPIRequest);

    server.listen({
      onUnhandledRequest(req) {
        unhandledRequests.push({
          method: req.method,
          url: req.url.href,
        });
        console.error(unhandledRequests.slice(-1));
      },
    });

    const {
      lowOption,
      normalOption,
      saveBtn,
      snackbar,
      javaScriptChipCloseButton,
      technologiesChipsList,
      interviewerLevel,
    } = build();
    await waitForGetInterviewerSettingsAPIRequest();
    await waitForGetTechnologiesAPIRequest();
    await waitForGetLevelsAPIRequest();

    expect(interviewerLevel().element.closest('div').textContent).toBe(
      getInterviewerSettingsServiceResponse.level.name
    );

    lowOption().trigger('click');
    await localVue.nextTick();
    expect(lowOption().element.checked).toBe(true);
    expect(normalOption().element.checked).toBe(false);

    javaScriptChipCloseButton().trigger('click');
    await localVue.nextTick();

    await saveBtn().trigger('click');
    await localVue.nextTick();
    await waitForPutInterviewerSettingsAPIRequest();

    expect(unhandledRequests).toHaveLength(0);
    await localVue.nextTick();

    expect(snackbar().isVisible()).toBe(true);
    expect(snackbar().html()).toContain('Settings saved successfully');
    expect(technologiesChipsList()).toHaveLength(putTechnologiesRequest.length);
    expect(interviewerLevel().element.closest('div').textContent).toBe(
      getInterviewerSettingsServiceResponse.level.name
    );
  });

  it('update only interviewer level', async () => {
    const unhandledRequests = [];
    const levelIndex = 2;
    const putInterviewerSettingsAPIRequest = {
      interviews_desired_per_week: getInterviewerSettingsServiceResponse.availability,
      technologies: interviewerTechnologiesMock,
      level_id: getLevelsSuccessResponse[levelIndex].id,
    };
    makeGetInterviewerSettingsRequestInterceptor(interviewerTechnologiesMock, DISCIPLINE_SWE);
    makeGetTechnologiesRequestInterceptor();
    makeGetLevelsRequestInterceptor();

    makePutInterviewerSettingsRequestInterceptor(putInterviewerSettingsAPIRequest);

    server.listen({
      onUnhandledRequest(req) {
        unhandledRequests.push({
          method: req.method,
          url: req.url.href,
        });
        console.error(unhandledRequests.slice(-1));
      },
    });
    const { saveBtn, snackbar, technologiesChipsList, interviewerLevel, openLists } = build();
    await waitForGetInterviewerSettingsAPIRequest();
    await waitForGetTechnologiesAPIRequest();
    await waitForGetLevelsAPIRequest();

    expect(interviewerLevel().element.closest('div').textContent).toBe(
      getInterviewerSettingsServiceResponse.level.name
    );

    await openSelectList(interviewerLevel, localVue);
    await selectElementFromOpenedLists(openLists, levelIndex, localVue);

    await saveBtn().trigger('click');
    await localVue.nextTick();
    await waitForPutInterviewerSettingsAPIRequest();

    expect(unhandledRequests).toHaveLength(0);
    await localVue.nextTick();

    expect(snackbar().isVisible()).toBe(true);
    expect(snackbar().html()).toContain('Settings saved successfully');
    expect(technologiesChipsList()).toHaveLength(interviewerTechnologiesMock.length);
    expect(interviewerLevel().element.closest('div').textContent).toBe(getLevelsSuccessResponse[levelIndex].name);
  });

  it('should not render technologies section', async () => {
    const unhandledRequests = [];
    makeGetInterviewerSettingsRequestInterceptor(interviewerTechnologiesMock, DISCIPLINE_SRE);
    makeGetTechnologiesRequestInterceptor();
    makeGetLevelsRequestInterceptor();

    server.listen({
      onUnhandledRequest(req) {
        unhandledRequests.push({
          method: req.method,
          url: req.url.href,
        });
        console.error(unhandledRequests.slice(-1));
      },
    });

    const { email, normalOption, interviewerTechnologiesSelect } = build();
    await waitForGetInterviewerSettingsAPIRequest();
    await waitForGetTechnologiesAPIRequest();
    await waitForGetLevelsAPIRequest();

    expect(unhandledRequests).toHaveLength(0);

    expect(email().element.value).toBe(getInterviewerSettingsServiceResponse.email);
    expect(normalOption().element.checked).toBe(true);
    expect(interviewerTechnologiesSelect().exists()).toBe(false);
  });
  server.resetHandlers();
  server.close();
});
