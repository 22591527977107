import storeResourceFactory from '@store/libs/storeResourceFactory';
import moment from 'moment';
import dashboardActions from './actions';
import getters from './getters';
import dashboardMutations from './mutations';

const tableHeaders = [
  {
    text: 'Candidate',
    align: 'start',
    sortable: true,
    value: 'candidateData.name',
  },
  {
    text: 'Discipline',
    align: 'start',
    sortable: true,
    value: 'discipline',
  },
  {
    text: 'Date',
    align: 'start',
    sortable: true,
    value: 'interviewDate',
    sort: (interview1, interview2) => moment(interview1.start).diff(interview2.start),
  },
  {
    text: 'Technology',
    align: 'start',
    sortable: true,
    value: 'technology',
  },
  {
    text: 'Type',
    align: 'start',
    sortable: true,
    value: 'type',
  },
  {
    text: 'Interviewer',
    align: 'start',
    sortable: true,
    value: 'interviewerData.email',
  },
  {
    text: 'Status',
    align: 'center',
    sortable: false,
    value: 'status',
  },
  {
    text: 'Actions',
    align: 'center',
    sortable: false,
    value: 'actions',
  },
];

const candidateTableConfigStore = storeResourceFactory('candidatesTableConfig', {
  headers: tableHeaders,
  loadingMessage: 'Loading data...',
  paginator: 10,
});

const interviewsStore = storeResourceFactory('interviews', []);

const statusesStore = storeResourceFactory('statuses', {
  filters: [],
  selected: [1],
});

const snackbarStore = storeResourceFactory('snackbar', {
  show: false,
  text: '',
});

const state = {
  ...candidateTableConfigStore.state,
  ...interviewsStore.state,
  ...statusesStore.state,
  ...snackbarStore.state,
};

const actions = {
  initializeCandidatesTableConfig: candidateTableConfigStore.request(dashboardActions.initCandidateTableConfig),
  initializeInterviewsData: interviewsStore.request(dashboardActions.initInterviewsData),
  cancelInterview: dashboardActions.cancelInterview,
  completeInterview: dashboardActions.completeInterview,
  fetchInterviewStatuses: statusesStore.request(dashboardActions.fetchInterviewStatuses),
  selectStatuses: dashboardActions.selectStatuses,
  showSnackbar: dashboardActions.showSnackbar,
};

const mutations = {
  ...dashboardMutations,
  ...candidateTableConfigStore.mutations,
  ...interviewsStore.mutations,
  ...statusesStore.mutations,
  ...snackbarStore.mutations,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
