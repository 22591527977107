import get from 'lodash.get';

const getInterviewers = (state) => {
  const interviewers = get(state, 'interviewers');
  return interviewers;
};

const getAvailableDisciplines = (state) => {
  const disciplines = get(state, 'disciplines');
  return disciplines.disciplines;
};

const getSelectedDisciplines = (state) => {
  const disciplines = get(state, 'disciplines');
  return disciplines.selectedDisciplines;
};

const getInterviewersLoading = (state) => {
  const interviewersLoading = get(state, 'interviewersLoading');
  return interviewersLoading;
};

export default {
  getInterviewers,
  getAvailableDisciplines,
  getSelectedDisciplines,
  getInterviewersLoading,
};
