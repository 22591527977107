// eslint-disable-next-line max-classes-per-file
import APIService from './API';
import axios from './axiosConfig';

/* eslint-disable camelcase */
const GetInterviewersServiceRequest = function constructor({
  regions,
  disciplines,
  technologies,
  interviewTypes,
  graduated,
  leading,
  shadowing,
  interviewTypesGraduated,
  interviewTypesLeading,
  interviewTypesShadowing,
}) {
  this.region_id = regions;
  this.discipline_id = disciplines;
  this.technology_id = technologies;
  this.interview_type_id = interviewTypes;
  this.interviewers_graduated = graduated;
  this.interviewers_leading = leading;
  this.interviewers_shadowing = shadowing;
  this.graduated_interview_type_id = interviewTypesGraduated;
  this.leading_interview_type_id = interviewTypesLeading;
  this.shadowing_interview_type_id = interviewTypesShadowing;
};

/* eslint-disable camelcase */
const GetInterviewersResponse = function constructor({
  id,
  full_name,
  email,
  is_active,
  discipline,
  technologies,
  leading_interview_types,
  shadowing_interview_types,
  graduated_interview_types,
  region,
  availability,
  current_week_interviews_scheduled_or_done,
  last_completed_interview,
  last_7_days_completed_interviews,
  last_30_days_completed_interviews,
  last_180_days_completed_interviews,
}) {
  this.id = id;
  this.fullName = full_name;
  this.email = email;
  this.isActive = is_active;
  this.discipline = discipline;
  this.technologies = technologies;
  this.leadingInterviewTypes = leading_interview_types;
  this.shadowingInterviewTypes = shadowing_interview_types;
  this.graduatedInterviewTypes = graduated_interview_types;
  this.region = region;
  this.availability = availability;
  this.currentWeekInterviewsScheduledOrDone = current_week_interviews_scheduled_or_done;
  this.lastCompletedInterview = last_completed_interview;
  this.last7DaysCompletedInterviews = last_7_days_completed_interviews;
  this.last30DaysCompletedInterviews = last_30_days_completed_interviews;
  this.last180DaysCompletedInterviews = last_180_days_completed_interviews;
  this.loading = false;
  this.searchContent = [
    full_name,
    email,
    discipline,
    technologies,
    region,
    shadowing_interview_types.map((value) => value.interview_type_name),
    leading_interview_types.map((value) => value.interview_type_name),
    graduated_interview_types,
  ];
};
/* eslint-disable camelcase */

class GetInterviewersService extends APIService {
  constructor(getInterviewersRequest) {
    super();
    this.serviceURL = '/interviewers';
    this.request = getInterviewersRequest;
  }

  async do() {
    this.setAuthorizationHeaders();
    this.setScopesHeaders();
    try {
      const response = await this.createHttpRequest().get(this.serviceURL, { params: this.request });
      return response.data.map((responseData) => new GetInterviewersResponse(responseData));
    } catch (response) {
      throw new Error(`${response}`);
    }
  }
}

//
const getInterviews = () =>
  axios.axiosInstance.get('/interviews', {}).catch(({ response }) => {
    throw new Error(`${response.status} | ${response.data.message}`);
  });

const GetInterviewsServiceRequest = function constructor(status) {
  this.status = status;
};
/* eslint-disable camelcase */
const GetInterviewsServiceResponse = function constructor({
  interview_id,
  greenhouse_link,
  interviewer_data,
  candidate_data,
  status_description,
  status,
  status_id,
  type,
  type_id,
  technologies,
  interview_date,
  discipline,
}) {
  this.candidateData = candidate_data;
  this.id = interview_id;
  this.greenhouseLink = greenhouse_link;
  this.interviewerData = interviewer_data;
  this.statusDescription = status_description;
  this.status = status;
  this.statusId = status_id;
  this.type = type;
  this.typeId = type_id;
  this.technology = technologies;
  this.interviewDate = interview_date;
  this.discipline = discipline;
};
/* eslint-enable camelcase */

class GetInterviewsService extends APIService {
  constructor(getInterviewsServiceRequest) {
    super();
    this.serviceURL = '/interviews';
    this.request = getInterviewsServiceRequest;
  }

  async do() {
    this.setAuthorizationHeaders();
    this.setScopesHeaders();
    try {
      const response = await this.createHttpRequest().get(this.serviceURL, { params: this.request });
      return response.data.map((responseData) => new GetInterviewsServiceResponse(responseData));
    } catch (response) {
      throw new Error(`Response | ${response}`);
    }
  }
}

const CancelInterviewServiceRequest = function constructor(interviewId) {
  this.interview_id = interviewId;
};

/* eslint-disable camelcase */
const CancelInterviewServiceResponse = function constructor() {};

/* eslint-enable camelcase */
class CancelInterviewService extends APIService {
  constructor(scheduleInterviewServiceRequest) {
    super();
    this.request = scheduleInterviewServiceRequest;
    this.serviceURL = `/interviews/${this.request.interview_id}/cancel`;
  }

  async do() {
    this.setAuthorizationHeaders();
    this.setScopesHeaders();
    try {
      await this.createHttpRequest().put(this.serviceURL, this.request);
      return new CancelInterviewServiceResponse();
    } catch (response) {
      throw new Error(`Response | ${response}`);
    }
  }
}
const CompleteInterviewServiceRequest = function constructor(interviewId) {
  this.interview_id = interviewId;
};

/* eslint-disable camelcase */
const CompleteInterviewServiceResponse = function constructor({ interview_id }) {
  this.interviewId = interview_id;
  this.interviewStatusId = 2;
};

/* eslint-enable camelcase */
class CompleteInterviewService extends APIService {
  constructor(scheduleInterviewServiceRequest) {
    super();
    this.request = scheduleInterviewServiceRequest;
    this.serviceURL = `/interviews/${this.request.interview_id}/complete`;
  }

  async do() {
    this.setAuthorizationHeaders();
    this.setScopesHeaders();
    try {
      const response = await this.createHttpRequest().put(this.serviceURL, this.request);
      return new CompleteInterviewServiceResponse(response.data);
    } catch (response) {
      throw new Error(`Response | ${response}`);
    }
  }
}

const GetInterviewsStatusesServiceRequest = function constructor() {};
const GetInterviewsStatusesServiceResponse = function constructor({ id, name, slug }) {
  this.id = id;
  this.name = name;
  this.description = slug;
};

class GetInterviewsStatusesService extends APIService {
  constructor(interviewStatusesServiceReq) {
    super();
    this.request = interviewStatusesServiceReq;
    this.serviceURL = '/interview-statuses';
  }

  async do() {
    this.setAuthorizationHeaders();
    this.setScopesHeaders();
    try {
      const response = await this.createHttpRequest().get(this.serviceURL, this.request);
      return response.data.map((data) => new GetInterviewsStatusesServiceResponse(data));
    } catch (response) {
      throw new Error(`Response | ${response}`);
    }
  }
}

export default {
  getInterviews,
  GetInterviewsServiceRequest,
  GetInterviewsService,
  CancelInterviewServiceRequest,
  CancelInterviewService,
  CompleteInterviewServiceRequest,
  CompleteInterviewService,
  GetInterviewsStatusesServiceRequest,
  GetInterviewsStatusesServiceResponse,
  GetInterviewsStatusesService,
  GetInterviewersService,
  GetInterviewersResponse,
  GetInterviewersServiceRequest,
};
