<template>
  <v-navigation-drawer app clipped :permanent="!$vuetify.breakpoint.xsOnly" width="200" fixed>
    <v-list dense nav class="mt-4">
      <v-list-item v-for="option in navSections" :key="option.name" link :to="changePath(option.link)" class="my-5">
        <v-list-item-icon>
          <v-icon size="30px">{{ option.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="text-subtitle-1 text-wrap">{{ option.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('navigation', { navSections: 'getNavigationContent' }),
  },
  async mounted() {
    this.initNavigationContent();
    this.initUserInformation();
  },
  methods: {
    ...mapActions('user', { initUserInformation: 'initializeUserInformation' }),
    ...mapActions('navigation', {
      initNavigationContent: 'initializeNavigationContent',
    }),
    changePath(link) {
      return this.$route.name === 'Home' && link === '/dashboard' ? '/' : link;
    },
  },
};
</script>
