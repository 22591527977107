import get from 'lodash.get';

const getInterviewsLoading = (state) => {
  const loadingInterviews = get(state, 'isRequestingInterviews');
  return loadingInterviews;
};

const getCandidateTableConfig = (state) => {
  const headers = get(state, 'candidatesTableConfig');
  return headers;
};

const getInterviews = (state) => {
  const interviews = get(state, 'interviews');
  return interviews;
};

const getInterviewStatusesFilters = (state) => {
  const statuses = get(state, 'statuses');
  return statuses.filters;
};

const getSelectedStatuses = (state) => {
  const statuses = get(state, 'statuses');
  return statuses.selected;
};

const getSnackbar = (state) => {
  const snackbar = get(state, 'snackbar');
  return snackbar;
};

export default {
  getInterviewsLoading,
  getCandidateTableConfig,
  getInterviews,
  getInterviewStatusesFilters,
  getSelectedStatuses,
  getSnackbar,
};
