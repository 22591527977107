<template>
  <validation-provider v-slot="{ errors }" :name="$attrs.name || $attrs.label" :rules="rules" v-bind="validatorProps">
    <v-textarea
      v-model="innerValue"
      :class="{ 'label-required': required }"
      :error-messages="errors"
      :counter="counter"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-for="(_, slot) in $slots" v-slot:[slot]>
        <!-- @slot Slots passed to vuetify component. -->
        <slot :name="slot" />
      </template>
    </v-textarea>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    ValidationProvider,
  },
  inheritAttrs: false,
  props: {
    /**
     * Value set by the parent component.
     * @ignore
     */
    value: {
      type: null,
      default: null,
    },
    /**
     * Rules for the input from vee-validate.
     * @see https://vee-validate.logaretm.com/v2/guide/rules.html
     */
    rules: {
      type: [String, Object],
      default: '',
    },
    /**
     * Props for the validation provider.
     * @see https://vee-validate.logaretm.com/v2/guide/components/validation-provider.html#props
     */
    validatorProps: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(value) {
        /**
         * Emit the input event to the parent component.
         */
        this.$emit('input', value);
      },
    },
    counter() {
      if (this.rules) {
        if (typeof this.rules === 'string') {
          return this.rules.includes('max') ? this.rules.match(/max:(\d+)/)[1] : false;
        }
        return this.rules.max;
      }
      return false;
    },
    required() {
      if (this.rules) {
        if (typeof this.rules === 'string') {
          return this.rules.includes('required');
        }
        return this.rules.required;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.label-required::v-deep label::after {
  content: ' *';
  color: #d32f2f;
}
</style>
