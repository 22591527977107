import storeResourceFactory from '@store/libs/storeResourceFactory';
import { currentDate, currentDatePlusSevenDays } from '@/helpers';
import scheduleInterviewActions from './actions';
import scheduleGetters from './getters';
import scheduleMutations from './mutations';

const disciplinesStore = storeResourceFactory('disciplines', []);
const levelsStore = storeResourceFactory('levels', []);

const interviewTypesStore = storeResourceFactory('interviewTypes', []);
const availableInterviewersStore = storeResourceFactory('availableInterviewers', []);
const availabilityStore = storeResourceFactory('interviewerAvailability', []);
const additionalInfoStore = storeResourceFactory('additionalInfo', []);
const requiresTechnologyStore = storeResourceFactory('requiresTechnologyFlag', []);
const menteeStore = storeResourceFactory('mentees', []);

const scheduleInterviewFormStore = storeResourceFactory('scheduleInterviewForm', {
  candidateName: null,
  candidateLastName: null,
  candidateEmail: null,
  candidateJobName: null,
  candidateId: null,
  dateRange: [currentDate, currentDatePlusSevenDays],
  applicationId: null,
  selectedInterviewers: [],
  calendar: {
    start: null,
    end: null,
  },
  interviewer: null,
  interviewType: null,
  discipline: null,
  requiresTechnology: false,
  technology: null,
  level: null,
  greenhouseLink: null,
  includeInterviewersWithoutAvailableSlots: false,
  includeMenteesWithoutAvailableSlots: false,
  mentee: null,
  additionalInfo: null,
  meetingType: 'zoom',
  greenhouseProfileLink: null,
  greenhouseData: {
    candidateId: null,
    applicationId: null,
  },
});

const interviewTechnologiesStore = storeResourceFactory('technologies', []);

const calendarStore = storeResourceFactory('calendar', {
  selectedSlot: {
    color: null,
    end: null,
    isSelected: null,
    name: null,
    start: null,
    email: null,
  },
  focus: '',
});

const alertStore = storeResourceFactory('alert', {
  type: null,
  message: null,
  show: false,
});

const state = {
  ...disciplinesStore.state,
  ...interviewTypesStore.state,
  ...scheduleInterviewFormStore.state,
  ...availabilityStore.state,
  ...availableInterviewersStore.state,
  ...interviewTechnologiesStore.state,
  ...calendarStore.state,
  ...levelsStore.state,
  ...additionalInfoStore.state,
  ...requiresTechnologyStore.state,
  ...menteeStore.state,
  ...alertStore.state,
};

const actions = {
  initializeDisciplinesData: disciplinesStore.request(scheduleInterviewActions.initDisciplinesData),
  initializeLevelsData: levelsStore.request(scheduleInterviewActions.initLevelsData),
  initializeInterviewTypesData: interviewTypesStore.request(scheduleInterviewActions.initInterviewTypesData),
  getInterviewerAvailability: availabilityStore.request(scheduleInterviewActions.getInterviewerAvailability),
  setSelectedSlot: scheduleInterviewActions.setSelectedSlot,
  setSelectedEmptySlot: scheduleInterviewActions.setSelectedEmptySlot,
  setCalendarForm: scheduleInterviewActions.setCalendarForm,
  setScheduleInterviewData: scheduleInterviewFormStore.request(scheduleInterviewActions.setScheduleInterviewData),
  saveCandidate: scheduleInterviewActions.saveCandidate,
  initializeAvailableInterviewers: availableInterviewersStore.request(
    scheduleInterviewActions.initAvailableInterviewers
  ),
  addEmptyCalendarSlot: scheduleInterviewActions.addEmptyCalendarSlot,
  searchAvailableMentee: scheduleInterviewActions.searchAvailableMentee,
  postInterview: scheduleInterviewActions.scheduleInterview,
  validateForm: scheduleInterviewActions.validateForm,
  clearForm: scheduleInterviewFormStore.request(scheduleInterviewActions.clearForm),
  clearCalendar: calendarStore.request(scheduleInterviewActions.clearCalendar),
  setCalendarFocus: scheduleInterviewActions.setCalendarFocus,
  setDiscipline: scheduleInterviewActions.setDiscipline,
  setInterviewTypes: scheduleInterviewActions.setInterviewTypes,
  setTechnologies: scheduleInterviewActions.setTechnologies,
  hasDiscTechnologies: scheduleInterviewActions.hasDiscTechnologies,
  clearInterviewValue: scheduleInterviewActions.clearInterviewValue,
  updateSetCalendarForm: scheduleInterviewActions.updateSetCalendarForm,
  getCandidateInfoFromGreenhouse: scheduleInterviewActions.getCandidateInfoFromGreenhouse,
  setCandidateInfoFromGreenhouse: scheduleInterviewActions.setCandidateInfoFromGreenhouse,
  getGreenhouseURLInfo: scheduleInterviewActions.getGreenhouseURLInfo,
  clearInfoWhenGreenhouseEndpoint: scheduleInterviewActions.clearInfoWhenGreenhouseEndpoint,
};

const mutations = {
  ...disciplinesStore.mutations,
  ...interviewTypesStore.mutations,
  ...availabilityStore.mutations,
  ...calendarStore.mutations,
  ...scheduleMutations,
  ...scheduleInterviewFormStore.mutations,
  ...availableInterviewersStore.mutations,
  ...interviewTechnologiesStore.mutations,
  ...additionalInfoStore.mutations,
  ...levelsStore.mutations,
  ...requiresTechnologyStore.mutations,
  ...menteeStore.mutations,
  ...alertStore.mutations,
};

const getters = {
  ...scheduleGetters,
  ...availableInterviewersStore.getters,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
