<template>
  <v-container fluid class="ma-auto pa-8 dashboard-table__container">
    <v-row class="dashboard-table__dashboard">
      <v-col>
        <h1>Dashboard</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <TableCandidates
          :page-size="candidateTableConfig.paginator"
          :headers="candidateTableConfig.headers"
          :items="interviews"
          :loading-text="candidateTableConfig.loadingMessage"
          :statuses="statuses"
          data-test-id="interviews-table"
        />
        <v-snackbar v-model="snackbar.show" :timeout="3000" data-test-id="snackbar">
          {{ snackbar.text }}
          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar.show = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TableCandidates from '../components/Dashboard/TableCandidates.vue';

export default {
  components: { TableCandidates },
  computed: {
    ...mapGetters('dashboard', {
      interviews: 'getInterviews',
      candidateTableConfig: 'getCandidateTableConfig',
      statuses: 'getInterviewStatusesFilters',
      snackbar: 'getSnackbar',
    }),
  },
  async mounted() {
    this.initCandidateTableConfig();
    await this.fetchInterviewStatuses();
    await this.initInterviewsData();
  },
  methods: {
    ...mapActions('dashboard', {
      initCandidateTableConfig: 'initializeCandidatesTableConfig',
      initInterviewsData: 'initializeInterviewsData',
      fetchInterviewStatuses: 'fetchInterviewStatuses',
    }),
  },
};
</script>
