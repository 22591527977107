<template>
  <v-card elevation="5" outlined class="overflow-auto">
    <v-card-title>{{ candidate.name }}</v-card-title>
    <v-card-subtitle>{{ candidate.email }}</v-card-subtitle>
    <v-container class="px-6">
      <v-row>
        <v-col cols="2">
          <p class="font-weight-medium">Discipline</p>
        </v-col>
        <v-col>
          <p class="font-weight-regular" data-test-id="discipline-text">
            {{ candidate.discipline }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <p class="font-weight-medium">Type</p>
        </v-col>
        <v-col>
          <p data-test-id="type-text">
            {{ candidate.type }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <strong>Technology</strong>
        </v-col>
        <v-col>
          <p data-test-id="technology-text">
            {{ candidate.technology || 'Unspecified' }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="d-flex align-center">
          <strong>Date</strong>
        </v-col>
        <v-col cols="4">
          <base-date-picker
            v-model="date"
            date-format="MMMM Do YYYY"
            rules="required"
            dense
            prepend-icon="mdi-calendar"
            data-test-id="date-input"
          />
        </v-col>
        <v-col cols="3">
          <base-time-picker
            v-model="startTime"
            format="ampm"
            digital
            rules="required"
            dense
            prepend-icon="mdi-clock-time-four-outline"
            data-test-id="start-time-input"
          />
        </v-col>
        <v-col cols="3">
          <base-time-picker
            v-model="endTime"
            format="ampm"
            digital
            rules="required"
            dense
            prepend-icon="mdi-arrow-right"
            data-test-id="end-time-input"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="d-flex align-center">
          <strong>Interviewer</strong>
        </v-col>
        <v-col cols="10">
          <ValidationProvider v-slot="{ errors }" name="Interviewer " rules="required">
            <v-select
              v-model="formData.interviewer"
              :error-messages="errors"
              placeholder="Select interviewer"
              dense
              data-test-id="interviewer-select"
              :items="sortedInterviewers"
              item-text="interviewerText"
              return-object
            ></v-select>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="d-flex align-center">
          <span class="font-weight-medium">
            Mentee
          </span>
        </v-col>
        <v-col cols="3">
          <v-switch
            v-model="formData.includeMenteesWithoutAvailableSlots"
            label="Include mentees without available slots"
            data-test-id="mentees-switch"
            @input="(value) => setFormData({ includeMenteesWithoutAvailableSlots: value })"
            @change="searchAvailableMentee"
          ></v-switch>
        </v-col>
        <v-col cols="7">
          <AutoComplete
            v-model="formData.mentee"
            placeholder="Select a mentee"
            dense
            :items="mentees"
            item-text="email"
            clearable
            data-test-id="mentee-select"
            return-object
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="d-flex align-center">
          <strong>Greenhouse Link</strong>
        </v-col>
        <v-col>
          <a
            :href="candidate.greenhouseLink"
            target="_blank"
            class="text-decoration-none"
            rel="noreferrer noopener"
            data-test-id="greenhouse-link"
          >
            {{ candidate.greenhouseLink }}
          </a>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="d-flex align-center">
          <span class="font-weight-medium">Video Call Options </span>
        </v-col>
        <v-col>
          <base-toggle-buttons
            v-model="formData.meetingType"
            color="primary"
            :buttons="[
              { value: 'google-meet', text: 'Google Meet', icon: 'mdi-google' },
              { value: 'zoom', text: 'Zoom', icon: 'mdi-video' },
            ]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <strong>Special notes for the interviewer</strong>
          <v-btn icon data-test-id="special-notes-tooltip">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" dark v-bind="attrs" v-on="on">
                  mdi-help-circle
                </v-icon>
              </template>
              <span>
                These notes will be sent to the interviewer and mentee in a separate email
              </span>
            </v-tooltip>
          </v-btn>
        </v-col>
        <v-col>
          <v-textarea
            v-model="formData.additionalInfo"
            outlined
            dense
            placeholder="Here you can add special notes you would want the interviewer to have"
            data-test-id="additional-info-textarea"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-container>
    <slot name="cardActions"></slot>
  </v-card>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { ValidationProvider } from 'vee-validate';
import AutoComplete from './AutoComplete.vue';
import BaseTimePicker from '../BaseTimePicker/BaseTimePicker.vue';
import BaseDatePicker from '../BaseDatePicker/BaseDatePicker.vue';
import BaseToggleButtons from '../BaseToggleButtons/BaseToggleButtons.vue';

export default {
  components: { ValidationProvider, AutoComplete, BaseTimePicker, BaseDatePicker, BaseToggleButtons },
  props: {
    candidate: {
      type: Object,
      default: () => ({
        name: '',
        discipline: '',
        type: '',
        email: '',
      }),
    },
  },
  computed: {
    ...mapGetters('scheduleInterview', {
      formData: 'getFormData',
      availableInterviewers: 'getAvailableInterviewers',
      mentees: 'getMentees',
    }),
    date: {
      get() {
        return this.formData.calendar.start ? moment(this.formData.calendar.start).format('YYYY-MM-DD') : null;
      },
      set(value) {
        const interviewDuration = this.formData.interviewType.duration;

        if (!this.formData.calendar.start && !this.formData.calendar.end) {
          this.formData.calendar.start = new Date().setTime(0);
          this.formData.calendar.end = new Date().setTime(0);
        }
        if (!this.startTime) {
          this.formData.calendar.start = moment(this.formData.calendar.start).set('hour', 9).toDate();
          this.formData.calendar.end = moment(this.formData.calendar.end)
            .set('hour', 9)
            .add(interviewDuration, 'minutes')
            .toDate();
        }
        this.formData.calendar.start = moment(this.formData.calendar.start)
          .year(moment(value).year())
          .month(moment(value).month())
          .date(moment(value).date())
          .toDate();
        this.formData.calendar.end = moment(this.formData.calendar.end)
          .year(moment(value).year())
          .month(moment(value).month())
          .date(moment(value).date())
          .toDate();
        this.searchAvailableMentee();
      },
    },
    startTime: {
      get() {
        return this.formData.calendar.start ? moment(this.formData.calendar.start).format('HH:mm') : null;
      },
      set(value) {
        if (!this.formData.calendar.start && !this.formData.calendar.end) {
          this.formData.calendar.start = new Date();
          this.formData.calendar.end = new Date();
        }
        this.formData.calendar.start = moment(this.formData.calendar.start)
          .hour(value.split(':')[0])
          .minute(value.split(':')[1])
          .toDate();
        this.formData.calendar.end = moment(this.formData.calendar.start)
          .add(this.formData.interviewType.duration, 'minutes')
          .toDate();

        this.searchAvailableMentee();
      },
    },
    endTime: {
      get() {
        return this.formData.calendar.end ? moment(this.formData.calendar.end).format('HH:mm') : null;
      },
      set(value) {
        if (!this.formData.calendar.start && !this.formData.calendar.end) {
          this.formData.calendar.start = new Date();
          this.formData.calendar.end = new Date();
        }
        this.formData.calendar.end = moment(this.formData.calendar.end)
          .hour(value.split(':')[0])
          .minute(value.split(':')[1])
          .toDate();
        this.formData.calendar.start = moment(this.formData.calendar.end)
          .subtract(this.formData.interviewType.duration, 'minutes')
          .toDate();

        this.searchAvailableMentee();
      },
    },
    sortedInterviewers() {
      const interviewersArr = [...this.formData.selectedInterviewers];
      return interviewersArr.map((interviewerObj) => ({
        ...interviewerObj,
        interviewerText: `${interviewerObj.fullName} (${interviewerObj.currentWeekInterviews})`,
      }));
    },
  },
  methods: {
    ...mapActions('scheduleInterview', {
      setFormData: 'setScheduleInterviewData',
      searchAvailableMentee: 'searchAvailableMentee',
    }),
  },
};
</script>
