import { COMPLETED_STATUS, SCHEDULED_STATUS, CANCELED_STATUS } from '@/constants/tests/shared.mock';

const getInterviewsResponse = [
  {
    interview_id: 1,
    greenhouse_link: 'google.com',
    interviewer_data: { id: 1, name: 'dummy', email: 'dummy@example.com' },
    candidate_data: { id: 1, name: 'dummy', email: 'dummy@a.com' },
    status_description: 'Scheduled',
    status: 'Scheduled',
    status_id: SCHEDULED_STATUS,
    type: 'Tech Assessment',
    type_id: 1,
    discipline: 'Site Reliability Engineering',
    discipline_id: 2,
    technologies: [
      { id: 1, name: 'JavaScript' },
      { id: 5, name: 'Java' },
    ],
    interview_date: {
      start: '2022-02-03T18:00:00',
      end: '2022-02-03T19:00:00',
    },
  },
  {
    interview_id: 2,
    greenhouse_link: 'google.com',
    interviewer_data: { id: 1, name: 'dummy', email: 'dummy@example.com' },
    candidate_data: { id: 1, name: 'dummy', email: 'dummy@a.com' },
    status_description: 'Completed',
    status: 'Completed',
    status_id: COMPLETED_STATUS,
    type: 'Tech Assessment',
    type_id: 1,
    discipline: 'Site Reliability Engineering',
    discipline_id: 2,
    technologies: [],
    interview_date: {
      start: '2022-02-03T18:00:00',
      end: '2022-02-03T19:00:00',
    },
  },
  {
    interview_id: 3,
    interviewer_data: { id: 1, name: 'dummy', email: 'dummy@example.com' },
    candidate_data: { id: 1, name: 'dummy', email: 'dummy@a.com' },
    status_description: 'Canceled',
    status: 'Canceled',
    status_id: CANCELED_STATUS,
    type: 'Tech Assessment',
    type_id: 1,
    discipline: 'Site Reliability Engineering',
    discipline_id: 2,
    technologies: [],
    interview_date: {
      start: '2022-02-03T18:00:00',
      end: '2022-02-03T19:00:00',
    },
  },
];

export { getInterviewsResponse };
