<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>Is this ok?</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <ValidationObserver ref="observer">
          <CardCandidateReview :candidate="candidate" />
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import CardCandidateReview from '../shared/CardCandidateReview.vue';

export default {
  components: { ValidationObserver, CardCandidateReview },
  computed: {
    ...mapGetters('scheduleInterview', {
      formData: 'getFormData',
      selected: 'getSelectedSlot',
    }),
    candidate() {
      return {
        name: `${this.formData.candidateName} ${this.formData.candidateLastName}`,
        discipline: this.discipline,
        type: this.type,
        date: this.formatDate,
        email: this.formData.candidateEmail,
        technology: this.technology,
        greenhouseLink: this.greenhouseLink,
        additionalInfo: this.additionalInfo,
      };
    },
    type() {
      return this.formData.interviewType ? this.formData.interviewType.name : '';
    },
    discipline() {
      return this.formData.discipline ? this.formData.discipline.name : '';
    },
    formatDate() {
      return this.selected ? { start: this.selected.start, end: this.selected.end } : null;
    },
    technology() {
      return this.formData.technology ? this.formData.technology.name : '';
    },
    greenhouseLink() {
      return this.formData.greenhouseLink ? this.formData.greenhouseLink : '';
    },
    additionalInfo() {
      return this.formData.additionalInfo ? this.formData.additionalInfo : '';
    },
  },
};
</script>
