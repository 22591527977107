import Vue from 'vue';
import { serializeError } from 'serialize-error';
import retry from 'async-retry';
import { constant, pascal } from 'case';

/**
 * @param  {string} resourceName
 * @param  {any} resourceInitialValue
 */
export default function storeResourceFactory(resourceName, resourceInitialValue) {
  const requestResourceRequestMutationName = `REQUEST_${constant(resourceName)}_REQUEST`;
  const requestResourceSuccessMutationName = `REQUEST_${constant(resourceName)}_SUCCESS`;
  const requestResourceFailureMutationName = `REQUEST_${constant(resourceName)}_FAILURE`;
  const isRequestingResourceName = `isRequesting${pascal(resourceName)}`;
  const requestResourceFailureName = `request${pascal(resourceName)}Failure`;
  const requestResourceSuccessName = `request${pascal(resourceName)}Success`;

  return {
    state: {
      [resourceName]: resourceInitialValue,
      [isRequestingResourceName]: false,
      [requestResourceSuccessName]: false,
      [requestResourceFailureName]: null,
    },
    mutations: {
      [requestResourceRequestMutationName](state) {
        Vue.set(state, isRequestingResourceName, true);
        Vue.set(state, requestResourceSuccessName, false);
        Vue.set(state, requestResourceFailureName, null);
      },
      [requestResourceSuccessMutationName](state, result) {
        Vue.set(state, isRequestingResourceName, false);
        Vue.set(state, requestResourceSuccessName, true);
        Vue.set(state, requestResourceFailureName, false);
        Vue.set(state, resourceName, result);
      },
      [requestResourceFailureMutationName](state, error = null) {
        Vue.set(state, isRequestingResourceName, false);
        Vue.set(state, requestResourceSuccessName, false);
        Vue.set(state, requestResourceFailureName, serializeError(error));
      },
    },
    getters: {
      [isRequestingResourceName](state) {
        return state[isRequestingResourceName];
      },
      [requestResourceFailureName](state) {
        return state[requestResourceFailureName];
      },
      [requestResourceSuccessName](state) {
        return state[requestResourceSuccessName];
      },
    },
    request(service) {
      return async (store, state) => {
        store.commit(requestResourceRequestMutationName);

        try {
          let result;
          if (process.env.NODE_ENV === 'test') {
            result = await service(store, state);
          } else {
            result = await retry(async () => service(store, state), {
              retries: 2,
            });
          }

          store.commit(requestResourceSuccessMutationName, result);
        } catch (error) {
          store.commit(requestResourceFailureMutationName, error);
          throw error;
        }
      };
    },
    mutationTypes: {
      request: requestResourceRequestMutationName,
      requestSuccess: requestResourceSuccessMutationName,
      requestFailure: requestResourceFailureMutationName,
    },
  };
}
