<template>
  <v-dialog v-model="addMenteeDialogVisible" max-width="500px" @input="updateDialogVisible">
    <template>
      <v-card data-test-id="modal-add-mentee">
        <v-card-title>
          Select Mentee
        </v-card-title>
        <v-card-text>
          <ValidationObserver ref="observer">
            <ValidationProvider
              v-slot="{ errors }"
              :custom-messages="{ required: 'You need to select a mentee.' }"
              name="Mentee"
              rules="required"
            >
              <v-autocomplete
                v-model="menteeSelected"
                label="Mentee"
                :error-messages="errors"
                :items="mentees"
                item-text="email"
                item-value="id"
                outlined
                dense
                data-test-id="mentees-list-select"
              />
            </ValidationProvider>
          </ValidationObserver>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="submit(menteeSelected)">
            Confirm
          </v-btn>
          <v-btn color="primary" text @click="closeAction">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  components: { ValidationObserver, ValidationProvider },
  props: {
    value: Boolean,
    mentees: {
      type: Array,
      default: () => [],
    },
    action: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      menteeSelected: '',
      addMenteeDialogVisible: this.value,
    };
  },
  watch: {
    value(newVal) {
      this.addMenteeDialogVisible = newVal;
    },
  },
  methods: {
    closeAction() {
      this.$refs.observer.reset();
      this.menteeSelected = '';
      this.$emit('input', false); // Close the dialog
    },
    async submit(menteeSelected) {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        await this.action(menteeSelected);
        this.resetInput();
      }
    },
    updateDialogVisible() {
      this.resetInput();
    },
    resetInput() {
      this.$refs.observer.reset();
      this.menteeSelected = '';
      this.$emit('input', this.addMenteeDialogVisible);
    },
  },
};
</script>

<style scoped>
::v-deep .v-dialog > .v-card > .v-card__text {
  padding: 20px 24px 0;
}
</style>
