/* eslint-disable no-unused-vars */
import _clone from 'lodash.clonedeep';
import { getErrorMessage } from '@/helpers';
import EditInterview from '@/services/editInterview';
import SharedService from '@/services/shared';
import SharedActions from '@store/modules/shared/actions';
import moment from 'moment-timezone';

const clearForm = () => ({
  candidateData: {
    id: null,
    name: null,
    email: null,
  },
  interviewerData: {
    id: null,
    name: null,
    email: null,
  },
  menteeData: {
    id: null,
    name: null,
    email: null,
  },
  interviewDate: {
    start: null,
    end: null,
  },
  discipline: null,
  disciplineHasTechnologies: false,
  interviewType: null,
  technology: null,
  level: null,
  greenhouseLink: null,
  meetingType: null,
  additionalInformation: null,
});

const parseDates = (interview) => {
  const interviewDate = {
    start: moment.utc(interview.interviewDate.start).local().toDate(),
    end: moment.utc(interview.interviewDate.end).local().toDate(),
  };
  return { ...interview, interviewDate };
};

const initInterviewData = async ({ state, commit, dispatch }, interviewId) => {
  try {
    commit('START_LOADING');
    dispatch('initializeDisciplinesData');
    dispatch('initializeLevelsData');
    const request = new EditInterview.GetInterviewServiceRequest(interviewId);
    const service = new EditInterview.GetInterviewService(request);
    const response = await service.do();
    dispatch('initializeInterviewTypes', response.discipline);
    dispatch('initializeAvailableInterviewers', response);
    dispatch('initializeAvailableMentees', response);
    dispatch('initializeGreenhouseData', response.greenhouseLink);
    const parsedResponse = parseDates(response);
    commit('SET_ORIGINAL_INTERVIEW_DATA', _clone(parsedResponse));
    return parsedResponse;
  } catch (e) {
    console.error(e);
    return [...state.interview];
  } finally {
    commit('STOP_LOADING');
  }
};

const updateInterview = async ({ state, commit }) => {
  const form = state.interview;
  const userTimeZone = moment.tz.guess();
  const startDate = moment.tz(form.interviewDate.start, userTimeZone).format();
  const endDate = moment.tz(form.interviewDate.end, userTimeZone).format();

  try {
    commit('START_LOADING');
    const request = new EditInterview.PutInterviewServiceRequest({
      interviewId: form.id,
      interviewerId: typeof form.interviewerData === 'object' ? form.interviewerData?.id : form.interviewerData,
      menteeId: typeof form.menteeData === 'object' ? form.menteeData?.id : form.menteeData,
      startDatetime: startDate,
      endDatetime: endDate,
      disciplineId: form.discipline,
      interviewTypeId: form.interviewType,
      technologyId: form.technology,
      expectedLevelId: form.level,
      greenhouseLink: form.greenhouseLink,
      meetingType: form.meetingType,
      additionalInformation: form.additionalInformation,
    });
    const service = new EditInterview.PutInterviewService(request);
    const response = await service.do();
    commit('STOP_LOADING');
    return response;
  } catch (error) {
    commit('STOP_LOADING');
    console.error(error);
  }
};

const initInterviewTypes = async ({ commit, dispatch }, disciplineId) => {
  commit('SET_INTERVIEW_FORM_VALUE', { key: 'interviewType', value: null });
  dispatch('disciplineHasTechnologies', disciplineId);
  return SharedActions.fetchInterviewTypes(disciplineId);
};

const disciplineHasTechnologies = ({ commit, dispatch, state }, disciplineId) => {
  commit('SET_INTERVIEW_FORM_VALUE', { key: 'technology', value: null });
  let technologies = false;
  if (disciplineId === 1) {
    technologies = true;
    dispatch('initializeTechnologies');
  } else commit('SET_TECHNOLOGIES', []);
  if (state.interview.discipline !== null) {
    commit('SET_INTERVIEW_FORM_VALUE', {
      key: 'disciplineHasTechnologies',
      value: technologies,
    });
  }
};

const initAvailableInterviewers = async ({ _state }, interview) => {
  try {
    const searchInterviewersServiceRequest = new EditInterview.SearchInterviewersServiceRequest({
      interviewTypeId: interview.interviewType,
      startDate: new Date(interview.interviewDate.start).toISOString(),
      endDate: null,
      technologyId: interview.technology,
      includeInterviewersWithoutAvailableSlots: true,
      disciplineId: interview.discipline,
    });
    const searchInterviewersService = new EditInterview.SearchInterviewersService(searchInterviewersServiceRequest);
    return await searchInterviewersService.do();
  } catch (e) {
    console.error(e);
    return [];
  }
};

const reloadAvailableInterviewers = async ({ commit, state }) => {
  const { interview } = state;
  try {
    const searchInterviewersServiceRequest = new EditInterview.SearchInterviewersServiceRequest({
      interviewTypeId: interview.interviewType,
      startDate: new Date(interview.interviewDate.start).toISOString(),
      endDate: null,
      technologyId: interview.technology,
      includeInterviewersWithoutAvailableSlots: true,
      disciplineId: interview.discipline,
    });
    const searchInterviewersService = new EditInterview.SearchInterviewersService(searchInterviewersServiceRequest);
    const response = await searchInterviewersService.do();
    if (!response.find((i) => i.id === interview.interviewerData?.id)) {
      commit('SET_INTERVIEW_FORM_VALUE', { key: 'interviewerData', value: null });
    }
    return response;
  } catch (e) {
    console.error(e);
    return [];
  }
};

const initAvailableMentees = async ({ _state }, interview) => {
  try {
    const startDate = interview.interviewDate.start;
    const endDate = null;
    const searchMenteesServiceRequest = new EditInterview.SearchMenteesServiceRequest({
      interviewTypeId: interview.interviewType,
      startDate,
      endDate,
      disciplineId: interview.discipline,
    });
    const searchMenteesService = new EditInterview.SearchMenteesService(searchMenteesServiceRequest);
    return await searchMenteesService.do();
  } catch (e) {
    console.error(e);
    return [];
  }
};

const reloadAvailableMentees = async ({ commit, state }) => {
  const { interview } = state;
  try {
    const startDate = interview.interviewDate.start;
    const endDate = null;
    const searchMenteesServiceRequest = new EditInterview.SearchMenteesServiceRequest({
      interviewTypeId: interview.interviewType,
      startDate,
      endDate,
      disciplineId: interview.discipline,
    });
    const searchMenteesService = new EditInterview.SearchMenteesService(searchMenteesServiceRequest);
    const response = await searchMenteesService.do();
    if (!response.find((i) => i.id === interview.menteeData?.id)) {
      commit('SET_INTERVIEW_FORM_VALUE', { key: 'menteeData', value: null });
    }
    return response;
  } catch (e) {
    console.error(e);
    return [];
  }
};

const initGreenhouseData = async ({ _commit }, greenhouseLink) => {
  try {
    return {
      candidateId: greenhouseLink.split('/people/')[1].split('/')[0],
      applicationId: new URL(greenhouseLink).searchParams.get('application_id'),
    };
  } catch (e) {
    return {
      candidateId: null,
      applicationId: null,
    };
  }
};

const getGreenhouseURLInfo = async ({ state, commit }) => {
  const form = state.interview;
  const { candidateId, applicationId } = state.greenhouseData;
  const interviewType = state.interviewTypes.find((i) => i.id === form.interviewType);
  try {
    const request = new SharedService.GetGreenhouseURLInfoServiceRequest({
      candidateId,
      applicationId,
      interviewType: interviewType.name,
    });
    const service = new SharedService.GetGreenhouseURLInfoService(request);
    const response = await service.do();
    commit('SET_INTERVIEW_FORM_VALUE', { key: 'greenhouseLink', value: response.url });
    return response;
  } catch (error) {
    commit('SET_INTERVIEW_FORM_VALUE', { key: 'greenhouseLink', value: null });
    const exception = error.data.message.split('(')[0];
    return getErrorMessage(exception);
  }
};

export default {
  ...SharedActions,
  initInterviewData,
  updateInterview,
  initInterviewTypes,
  disciplineHasTechnologies,
  initAvailableInterviewers,
  reloadAvailableInterviewers,
  initAvailableMentees,
  reloadAvailableMentees,
  clearForm,
  initGreenhouseData,
  getGreenhouseURLInfo,
};
