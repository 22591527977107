<template>
  <v-footer app padless tile color="#ebebeb">
    <v-col class="text-center" cols="12">
      &copy; {{ content.year }}
      <strong>{{ content.text }}</strong>
      - v{{ content.version }}
      <template v-if="this.$route.name === 'Role Selection'">
        - <a href="https://storyset.com/job">Job illustrations by Storyset</a>
      </template>
    </v-col>
  </v-footer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters('footer', { content: 'getContent' }),
  },
  mounted() {
    this.initializeFooterContent();
  },
  methods: {
    ...mapActions('footer', { initializeFooterContent: 'initializeFooterContent' }),
  },
};
</script>
