import Dashboard from '@/services/dashboard';
import moment from 'moment-timezone';
import { cancelledInterviewStatusId, INTERVIEW_STATUS_COLORS } from '@store/modules/dashboard/constants';

const initCandidateTableConfig = ({ state }) => {
  const { headers } = state.candidatesTableConfig;
  const { loadingMessage } = state.candidatesTableConfig;
  const { paginator } = state.candidatesTableConfig;
  return { headers, loadingMessage, paginator };
};

const formatInterviewData = (interviewsData) => {
  return interviewsData.map((interview) => {
    const interviewDate = {
      end: moment.utc(interview.interviewDate.end).local().format('YYYY-MM-DD HH:mm:ss'),
      start: moment.utc(interview.interviewDate.start).local().format('YYYY-MM-DD HH:mm:ss'),
    };
    return {
      ...interview,
      interviewDate,
      statusColor: INTERVIEW_STATUS_COLORS[interview.statusId],
    };
  });
};

const initInterviewsData = async ({ state }) => {
  try {
    const request = new Dashboard.GetInterviewsServiceRequest(state.statuses.selected);
    const service = new Dashboard.GetInterviewsService(request);
    const interviewsData = await service.do();
    return formatInterviewData(interviewsData);
  } catch (e) {
    console.error(e);
    return [...state.interviews];
  }
};

const cancelInterview = async ({ commit }, interview) => {
  try {
    const request = new Dashboard.CancelInterviewServiceRequest(interview.id);
    const service = new Dashboard.CancelInterviewService(request);
    const response = await service.do();
    if (response) {
      commit('SET_INTERVIEW_STATUS', { id: interview.id, status: cancelledInterviewStatusId });
    }
    return response;
  } catch (error) {
    console.error(error);
  }
};

const completeInterview = async ({ commit }, interview) => {
  try {
    const request = new Dashboard.CompleteInterviewServiceRequest(interview.id);
    const service = new Dashboard.CompleteInterviewService(request);
    const response = await service.do();
    if (response) {
      commit('SET_INTERVIEW_STATUS', { id: response.interviewId, status: response.interviewStatusId });
    }
    return response;
  } catch (error) {
    console.error(error);
  }
};

const fetchInterviewStatuses = async ({ state }) => {
  try {
    const request = new Dashboard.GetInterviewsStatusesServiceRequest();
    const service = new Dashboard.GetInterviewsStatusesService(request);
    const statusesData = await service.do();
    return { ...state.statuses, filters: statusesData };
  } catch (e) {
    console.error(e);
    return state.statuses;
  }
};

const selectStatuses = ({ commit }, statuses) => {
  commit('SET_SELECTED_STATUSES', { statuses });
};

const showSnackbar = ({ commit }, message) => {
  commit('SHOW_SNACKBAR', message);
};

export default {
  initCandidateTableConfig,
  initInterviewsData,
  cancelInterview,
  completeInterview,
  fetchInterviewStatuses,
  selectStatuses,
  showSnackbar,
};
