<template>
  <v-app>
    <Navigation v-if="isNavBarVisible" />
    <Header />
    <v-main color="#ebebeb">
      <router-view></router-view>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Header from './components/Header/Header.vue';
import Footer from './components/Footer.vue';
import Navigation from './components/Navigation.vue';

export default {
  components: {
    Header,
    Footer,
    Navigation,
  },
  computed: {
    isNavBarVisible() {
      return ![
        'Login',
        'Role Selection',
        'Mentee Interviewers Registration',
        'Talent Acquisition Registration',
      ].includes(this.$route.name);
    },
  },
};
</script>
