import { getPagesConfig } from './pages';

const getRoutes = () =>
  getPagesConfig().map((page) => ({
    name: page.name,
    path: page.path,
    component: require(`@/pages/${page.componentName}`).default,
    meta: { ...page.meta },
    redirect: page.redirect,
  }));

export default { getRoutes };
