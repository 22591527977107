var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"data-test-id":"container-main"}},[_c('v-row',[_c('v-col',[_c('div',[_c('h2',[_vm._v("General Information")]),_c('h3',{staticClass:"mb-3",attrs:{"data-test-id":"candidate-job-name"}},[_vm._v(_vm._s(_vm.formData.candidateJobName))])])])],1),_c('ValidationObserver',{ref:"observer"},[_c('div',[_c('v-row',{staticClass:"justify-space-around px-5 px-sm-0"},[_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('ValidationProvider',{staticClass:"flex",attrs:{"name":"Greenhouse Link","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var touched = ref.touched;
var required = ref.required;
return [_c('v-text-field',{class:required ? 'label-required' : '',attrs:{"label":"Greenhouse Link","error":touched && !valid,"error-messages":errors[0],"data-test-id":"greenhouse-profile-link","required":""},on:{"paste":function($event){return _vm.onGreenhouseLinkChange($event, true)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();},"input":function($event){return _vm.onGreenhouseLinkChange($event, false)}},model:{value:(_vm.formData.greenhouseProfileLink),callback:function ($$v) {_vm.$set(_vm.formData, "greenhouseProfileLink", $$v)},expression:"formData.greenhouseProfileLink"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-menu',{attrs:{"transition":"scale-transition","offset-y":"","min-width":"290px","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"name":"Date range","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var touched = ref.touched;
var required = ref.required;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"calendar-field",class:required ? 'label-required' : '',attrs:{"label":"Select a date range","prepend-inner-icon":"mdi-calendar","readonly":"","data-test-id":"date-range","error":touched && !valid,"error-messages":errors[0]},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1)],1)],1)],1),_c('v-sheet',{staticClass:"mx-auto mt-3 mb-10 px-5 px-sm-0",attrs:{"rounded":"","elevation":"4","height":"auto","width":"100%"}},[_c('v-row',{staticClass:"justify-space-around"},[_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('ValidationProvider',{staticClass:"flex",attrs:{"name":"Candidate's Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var touched = ref.touched;
var required = ref.required;
return [_c('v-text-field',{class:required ? 'label-required' : '',attrs:{"label":"Candidate's Name","error":touched && !valid,"error-messages":errors[0],"data-test-id":"candidate-name","disabled":_vm.fieldsDisabled},on:{"input":function (value) { return _vm.setFormData({ candidateName: value }); }},model:{value:(_vm.formData.candidateName),callback:function ($$v) {_vm.$set(_vm.formData, "candidateName", $$v)},expression:"formData.candidateName"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('ValidationProvider',{staticClass:"flex",attrs:{"name":"Candidate's Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var touched = ref.touched;
var required = ref.required;
return [_c('v-text-field',{class:required ? 'label-required' : '',attrs:{"label":"Candidate's Last Name","error":touched && !valid,"error-messages":errors[0],"data-test-id":"candidate-lastName","disabled":_vm.fieldsDisabled},on:{"input":function (value) { return _vm.setFormData({ candidateLastName: value }); }},model:{value:(_vm.formData.candidateLastName),callback:function ($$v) {_vm.$set(_vm.formData, "candidateLastName", $$v)},expression:"formData.candidateLastName"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('ValidationProvider',{staticClass:"flex",attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var touched = ref.touched;
var required = ref.required;
return [_c('v-text-field',{class:required ? 'label-required' : '',attrs:{"label":"Candidate Email","error":touched && !valid,"error-messages":errors[0],"data-test-id":"candidate-email","required":"","disabled":_vm.fieldsDisabled},on:{"input":function (value) { return _vm.setFormData({ candidateEmail: value }); },"paste":_vm.onEmailChange,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();}},model:{value:(_vm.formData.candidateEmail),callback:function ($$v) {_vm.$set(_vm.formData, "candidateEmail", $$v)},expression:"formData.candidateEmail"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('ValidationProvider',{attrs:{"name":"Levels","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var touched = ref.touched;
var required = ref.required;
return [_c('v-select',{class:required ? 'label-required' : '',attrs:{"items":_vm.levels,"item-text":"name","item-value":"id","label":"Candidate's expected level","return-object":"","error":touched && !valid,"error-messages":errors[0],"data-test-id":"levels-select"},on:{"input":function (value) { return _vm.setFormData({ level: value }); }},model:{value:(_vm.formData.level),callback:function ($$v) {_vm.$set(_vm.formData, "level", $$v)},expression:"formData.level"}})]}}])})],1)],1)],1),_c('v-sheet',{staticClass:"mx-auto mb-3 mt-10 px-5 px-sm-0",attrs:{"rounded":"","elevation":"4","height":"auto","width":"100%"}},[_c('v-row',{staticClass:"justify-space-around"},[_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('ValidationProvider',{attrs:{"name":"Discipline","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var touched = ref.touched;
var required = ref.required;
return [_c('v-select',{class:required ? 'label-required' : '',attrs:{"items":_vm.disciplines,"item-text":"name","item-value":"id","label":"Discipline","return-object":"","disabled":_vm.disciplineDisabled,"error":touched && !valid,"error-messages":errors[0],"data-test-id":"disciplines-select"},on:{"input":function (value) { return _vm.setDisciplineData(value); },"change":function () { return _vm.setFormData({ requiresTechnology: false }); }},model:{value:(_vm.formData.discipline),callback:function ($$v) {_vm.$set(_vm.formData, "discipline", $$v)},expression:"formData.discipline"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('ValidationProvider',{attrs:{"name":"Interview Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var touched = ref.touched;
var required = ref.required;
return [_c('v-select',{class:required ? 'label-required' : '',attrs:{"items":_vm.interviewTypes,"item-text":"name","item-value":"id","label":"Interview Type","return-object":"","error":touched && !valid,"error-messages":errors[0],"data-test-id":"interview-types-select"},on:{"input":function (value) { return _vm.setFormData({ interviewType: value }); },"change":function($event){return _vm.resetData()}},model:{value:(_vm.formData.interviewType),callback:function ($$v) {_vm.$set(_vm.formData, "interviewType", $$v)},expression:"formData.interviewType"}})]}}])})],1),_c('v-col',{staticClass:"d-md-flex justify-md-space-center p-0",attrs:{"cols":"12","sm":"5"}},[_c('div',{staticClass:"mr-2"},[_c('ValidationProvider',{attrs:{"name":"Requires Technology","vid":"requiresTechnology"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var touched = ref.touched;
return [_c('v-switch',{attrs:{"label":"Requires a specific technology","error":touched && !valid,"error-messages":errors[0],"data-test-id":"technology-switch"},on:{"input":function (value) { return _vm.setFormData({ requiresTechnology: value }); },"change":_vm.onSwitchChange},model:{value:(_vm.formData.requiresTechnology),callback:function ($$v) {_vm.$set(_vm.formData, "requiresTechnology", $$v)},expression:"formData.requiresTechnology"}})]}}])}),_c('v-snackbar',{attrs:{"timeout":3000},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1),_c('div',[_c('ValidationProvider',{attrs:{"name":"Technology","rules":"required_if:requiresTechnology,true"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var touched = ref.touched;
return [_c('AutoComplete',{attrs:{"error":touched && !valid && !_vm.isTechDisabled,"error-messages":errors[0],"items":_vm.technologies,"label":"Technology","item-text":"name","item-value":"id","data-test-id":"technology-select","is-disabled":!_vm.formData.requiresTechnology},on:{"input":function (value) { return _vm.setTechnologyData(value); }},model:{value:(_vm.formData.technology),callback:function ($$v) {_vm.$set(_vm.formData, "technology", $$v)},expression:"formData.technology"}})]}}])})],1)]),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('ValidationProvider',{attrs:{"name":"Include Unavailable"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var touched = ref.touched;
return [_c('v-switch',{attrs:{"label":"Include interviewers without available slots","error":touched && !valid,"error-messages":errors[0],"data-test-id":"interviewers-switch"},on:{"input":function (value) { return _vm.setFormData({ includeInterviewersWithoutAvailableSlots: value }); },"change":function($event){return _vm.resetData()}},model:{value:(_vm.formData.includeInterviewersWithoutAvailableSlots),callback:function ($$v) {_vm.$set(_vm.formData, "includeInterviewersWithoutAvailableSlots", $$v)},expression:"formData.includeInterviewersWithoutAvailableSlots"}})]}}])})],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.loadingData}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }