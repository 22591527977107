import storeResourceFactory from '@store/libs/storeResourceFactory';
import { LOCAL_STORAGE_AUTH_KEYS } from '@/constants';
import authActions from './actions';
import getters from './getters';
import authMutations from './mutations';

const authStore = storeResourceFactory('authData', {
  accessToken: localStorage.getItem(LOCAL_STORAGE_AUTH_KEYS.accessToken) || undefined,
  refreshToken: localStorage.getItem(LOCAL_STORAGE_AUTH_KEYS.refreshToken) || undefined,
  expiresIn: localStorage.getItem(LOCAL_STORAGE_AUTH_KEYS.expiresIn) || undefined,
  scopesToken: localStorage.getItem(LOCAL_STORAGE_AUTH_KEYS.scopesToken) || undefined,
});

const state = { ...authStore.state };

const actions = {
  initializeAuthentication: authStore.request(authActions.initAuth),
  doRefreshToken: authStore.request(authActions.doRefreshToken),
  userLogout: authStore.request(authActions.userLogout),
};

const mutations = { ...authMutations, ...authStore.mutations };

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
