<template>
  <v-container fluid class="ma-auto pa-7">
    <v-row justify="center">
      <v-col sm="12" md="8" lg="6">
        <v-card>
          <v-card-title>
            <h2>Mentee Interviewer Registration</h2>
          </v-card-title>
          <v-card-text>
            Thanks for your interest in helping Wizeline to grow by being an interviewer. This form will help us
            understand your background, find the right mentors and schedule the best time for you to start shadowing.
            Please be patient while you are assigned since it's may not be immediate. Remember that you can begin your
            journey anytime (what about starting now?) by consuming the current learning content.
          </v-card-text>
          <v-divider />
          <v-card-text>
            Important notes:
            <ul>
              <li>
                You must have at least 2 free recurrent slots on the next 7 days in your calendar to be able to be a
                mentee, 4 recurrent slots are recommended to increase your chances to get shadow interviews. A guide for
                that can be found
                <a href="https://github.com/wizeline/inditool-docs/wiki/interviewers-calendar">
                  here.
                </a>
              </li>
              <li>
                Creating these slots doesn't mean that you will be having interviews all of those days, it's simply a
                way for HR to know your availability.
              </li>
              <li>
                You may have requests outside your slots if there's no availability from anyone else, but you will be
                contacted in case a different time is needed.
              </li>
            </ul>
          </v-card-text>
          <v-divider />
          <v-card-text>
            The following email will be used to register:
            <strong data-test-id="mentee-email">{{ userInformation.email }}</strong>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col sm="12" md="8" lg="6">
        <v-card>
          <v-overlay absolute :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-text>
            <ValidationObserver ref="observer" v-slot="{ invalid }">
              <form @submit.prevent="postMentee">
                <v-row>
                  <v-col cols="12" sm="3">
                    <base-text-field v-model="formData.name" label="Name" rules="required" data-test-id="field-name" />
                  </v-col>
                  <v-col cols="12" sm="3">
                    <base-text-field
                      v-model="formData.last_name"
                      label="Last Name"
                      rules="required"
                      data-test-id="field-last-name"
                    />
                  </v-col>
                  <v-col cols="12" sm="3">
                    <base-text-field
                      v-model="formData.bamboo_employee_id"
                      label="BambooHR ID"
                      rules="required"
                      type="number"
                      min="0"
                      hide-spin-buttons
                      data-test-id="field-bamboo-id"
                    />
                  </v-col>
                  <v-col cols="12" sm="3">
                    <base-select
                      v-model="formData.level_id"
                      label="Level"
                      rules="required"
                      :items="levels"
                      item-text="name"
                      item-value="id"
                      data-test-id="level-select"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <base-date-picker
                      v-model="formData.join_date"
                      label="Hire Date"
                      rules="required"
                      date-format="MMMM DD, YYYY"
                      :date-picker-props="{ max: new Date().toJSON() }"
                      append-icon="mdi-calendar"
                      data-test-id="field-hire-date"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-select
                      v-model="formData.city_id"
                      label="City"
                      rules="required"
                      :items="cities"
                      :item-text="getCitiesText"
                      item-value="id"
                      data-test-id="city-select"
                    >
                      <template v-slot:append>
                        <v-tooltip bottom>
                          <template #activator="{ on }">
                            <v-icon color="gray" v-on="on">mdi-help-circle-outline</v-icon>
                          </template>
                          <span>If your city is not listed, choose remote</span>
                        </v-tooltip>
                        <v-icon color="gray">mdi-menu-down</v-icon>
                      </template>
                    </base-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <base-select
                      v-model="formData.has_past_experience"
                      label="Experience interviewing"
                      rules="required"
                      :items="[
                        { id: true, name: 'Yes' },
                        { id: false, name: 'No' },
                      ]"
                      item-text="name"
                      item-value="id"
                      data-test-id="past-experience-select"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-select
                      v-model="formData.discipline_id"
                      label="Discipline"
                      rules="required"
                      :items="disciplines"
                      item-text="name"
                      item-value="id"
                      data-test-id="disciplines-select"
                      @change="(value) => setDisciplineData(value)"
                    />
                  </v-col>
                </v-row>
                <v-row v-if="interviewTypes.length > 0" data-test-id="interview-types-container">
                  <v-col cols="12"><strong>Select your level in each interview type</strong></v-col>
                  <template v-for="(interviewType, idx) in interviewTypes">
                    <v-col
                      :key="interviewType.id"
                      cols="12"
                      sm="6"
                      :data-test-id="`interview-type-${interviewType.slug}`"
                    >
                      <label>{{ interviewType.name }}</label>
                      <base-select
                        v-model="formData.interviewTypes[idx].value"
                        :items="interviewTypesLevels"
                        item-text="name"
                        item-value="id"
                        return-object
                        required
                        :data-test-id="`select-interview-type-level-${interviewType.slug}`"
                        @change="(value) => setInterviewTypeLevel(value, interviewType.id)"
                      >
                      </base-select>
                    </v-col>
                  </template>
                </v-row>
                <v-row v-if="formData.discipline_id === 1" data-test-id="technologies-container">
                  <v-col cols="12" sm="6">
                    <base-select
                      v-model="formData.technologies_ids"
                      label="Technologies"
                      rules="required"
                      placeholder="Medium to advanced experience"
                      :items="technologies"
                      item-text="name"
                      item-value="id"
                      multiple
                      clearable
                      data-test-id="technologies-select"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-text-field
                      v-model="formData.other_technologies"
                      label="Other Technologies"
                      rules="max:50"
                      :validator-props="{ customMessages: { max: 'Can not exceed 50 characters in length' } }"
                      hint="Please separate by comma, e.g. Vue, React, Angular"
                    />
                  </v-col>
                </v-row>
                <v-row class="text-center">
                  <v-col>
                    <v-btn data-test-id="btn-submit" color="primary" type="submit" :disabled="invalid">
                      Submit
                    </v-btn>
                    <v-snackbar v-model="snackbar" :timeout="5000">
                      {{ snackbarText }}
                      <template v-slot:action="{ attrs }">
                        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
                          Close
                        </v-btn>
                      </template>
                    </v-snackbar>
                    <v-dialog v-model="dialog" persistent max-width="500px">
                      <v-card>
                        <v-card-title>
                          Already Registered
                        </v-card-title>
                        <v-card-text>
                          It appears you're already registered with another role, click continue to proceed to the
                          dashboard with your current role. If you wanted to register for a new role, please contact the
                          InDiTool team for assistance.
                        </v-card-text>
                        <v-card-text>
                          <a href="mailto:inditool-team@wizeline.com">
                            inditool-team@wizeline.com
                          </a>
                        </v-card-text>
                        <v-divider />
                        <v-card-actions>
                          <v-spacer />
                          <v-btn color="primary" text @click="dialog = false">
                            Cancel
                          </v-btn>
                          <v-btn color="primary" text @click="refreshAndContinue()">
                            Continue
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
              </form>
            </ValidationObserver>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import BaseTextField from '@/components/BaseTextField/BaseTextField.vue';
import BaseSelect from '@/components/BaseSelect/BaseSelect.vue';
import BaseDatePicker from '@/components/BaseDatePicker/BaseDatePicker.vue';
import { menteeRegistrationErrors } from '@/constants/errors';
import { LOCAL_STORAGE_AUTH_KEYS } from '@/constants';
import menteeRegistrationService from '../services/menteeRegistration';

export default {
  components: { ValidationObserver, BaseTextField, BaseSelect, BaseDatePicker },
  data() {
    return {
      loading: false,
      snackbar: false,
      snackbarText: '',
      dialog: false,
      levels: [],
      technologies: [],
      cities: [],
      disciplines: [],
      interviewTypes: [],
      formData: {
        name: null,
        last_name: null,
        bamboo_employee_id: null,
        technologies_ids: [],
        discipline_id: null,
        level_id: null,
        city_id: null,
        join_date: null,
        other_technologies: null,
        has_past_experience: null,
        interviewTypes: [],
      },
      interviewTypesLevels: [
        {
          name: 'Mentee: Shadow',
          id: 'shadow',
        },
        {
          name: 'Mentee: Leading',
          id: 'lead',
        },
        {
          name: 'Graduated',
          id: 'graduated',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('user', { userInformation: 'getUserInformation' }),
  },
  async mounted() {
    this.initUserInformation();

    [this.levels, this.technologies, this.cities, this.disciplines] = await Promise.all([
      menteeRegistrationService.getLevelsData(),
      menteeRegistrationService.getTechnologiesData(),
      menteeRegistrationService.getCitiesData(),
      menteeRegistrationService.getDisciplinesData(),
    ]);
  },
  methods: {
    ...mapActions('user', { initUserInformation: 'initializeUserInformation' }),
    ...mapActions('auth', { refreshTokens: 'doRefreshToken' }),
    ...mapMutations('auth', { setScope: 'SET_SCOPES_TOKEN' }),
    getCitiesText(item) {
      return `${item.country_name} - ${item.name}`;
    },
    postMentee() {
      this.loading = true;
      menteeRegistrationService
        .postMentee(this.formData)
        .then((response) => {
          this.snackbarText = 'Registration successful';
          this.snackbar = true;
          this.clearForm();
          localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.scopesToken, response.scopes_token);
          this.setScope(response.scopes_token);
          this.$router.push('/dashboard');
        })
        .catch((error) => {
          if (error.data.message) {
            const exception = error.data.message.split('(')[0];
            if (exception === 'EmailIsRegisteredWithAnotherRoleException') {
              this.dialog = true;
            } else {
              this.snackbarText = menteeRegistrationErrors[exception];
              this.snackbar = true;
            }
          } else {
            this.snackbarText = `Error: ${error}`;
            this.snackbar = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async setDisciplineData(disciplineId) {
      await menteeRegistrationService.getInterviewTypes(disciplineId).then((data) => {
        this.formData.interviewTypes = data.map((interviewType) => ({
          id: interviewType.id,
          value: 'shadow',
        }));
        this.interviewTypes = data;
        this.formData.technologies_ids = [];
      });
    },

    async setInterviewTypeLevel(value, interviewTypeId) {
      const interviewTypeFound = this.formData.interviewTypes.findIndex((obj) => obj.id === interviewTypeId);
      this.formData.interviewTypes[interviewTypeFound].value = value.id;
    },
    async refreshAndContinue() {
      await this.refreshTokens();
      this.$router.push('/dashboard');
    },
    clearForm() {
      this.formData = {
        name: null,
        last_name: null,
        bamboo_employee_id: null,
        technologies_ids: [],
        discipline_id: null,
        level_id: null,
        city_id: null,
        join_date: null,
        other_technologies: null,
        has_past_experience: null,
        interviewTypes: [],
      };
      this.$refs.observer.reset();
    },
  },
};
</script>
