<template>
  <v-container fluid class="mt-10">
    <v-row class="text-center my-10">
      <v-col>
        <h1 class="display-2 font-weight-bold mb-3">Welcome to InDiTool</h1>
      </v-col>
    </v-row>
    <v-row class="text-center my-10">
      <v-col>
        <BtnPrimary :text="btnText" :href="cognitoUrl" color="blue darken-2" class="white--text" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BtnPrimary from '../components/shared/BtnPrimary.vue';

export default {
  components: { BtnPrimary },
  data() {
    return {
      btnText: 'Login with your Wizeline Account',
      cognitoUrl: process.env.VUE_APP_COGNITO_URL_LOGIN,
    };
  },
};
</script>
