<template>
  <v-container>
    <v-row>
      <v-col>
        <h2 data-test-id="title">Select Interviewers</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" md="6">
        <ValidationObserver ref="observer">
          <ValidationProvider
            v-slot="{ errors }"
            :custom-messages="{ required: 'You need to select at least one interviewer.' }"
            name="Interviewers"
            rules="required"
          >
            <v-autocomplete
              v-model="interviewers"
              auto-select-first
              :search-input.sync="search"
              :error-messages="errors"
              :items="sortedInterviewers"
              placeholder="Select interviewers"
              item-text="interviewerText"
              data-test-id="interviewers-select"
              return-object
              multiple
              :filter="customFilter"
              :loading="isLoading"
              @blur="search = null"
              @input="search = null"
            >
              <template slot="item" slot-scope="{ item }">
                <v-row class="justify-space-between" no-gutters>
                  <div>{{ item.interviewerText }}</div>
                  <span data-test-id="interviewer-level">{{ item.levelName }}</span>
                </v-row>
              </template>
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-if="search">
                      No results matching "<strong>{{ search }}</strong
                      >".
                    </v-list-item-title>
                    <v-list-item-title v-else>
                      No interviewers found.
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </ValidationProvider>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  components: { ValidationObserver, ValidationProvider },
  props: {
    page: { type: Number, default: 0 },
  },
  data: () => ({
    search: null,
  }),
  computed: {
    ...mapGetters('scheduleInterview', {
      isLoading: 'isRequestingAvailableInterviewers',
      availableInterviewers: 'getAvailableInterviewers',
      formData: 'getFormData',
    }),
    interviewers: {
      get() {
        return this.formData.selectedInterviewers;
      },
      set(value) {
        this.setFormData({ selectedInterviewers: value });
      },
    },
    sortedInterviewers() {
      const interviewersArr = [...this.availableInterviewers];
      return interviewersArr.map((interviewerObj) => ({
        ...interviewerObj,
        interviewerText: `${interviewerObj.fullName} (${interviewerObj.currentWeekInterviews})`,
        levelName: interviewerObj.level?.name,
      }));
    },
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal === 2 && oldVal === 1) {
        requestAnimationFrame(() => {
          this.$refs.observer.reset();
          this.setFormData({
            selectedInterviewers: [],
            interviewer: {},
            mentee: {},
            calendar: {
              end: null,
              start: null,
            },
          });
        });
        this.initAvailableInterviewers();
      }
    },
  },
  methods: {
    ...mapActions('scheduleInterview', {
      initAvailableInterviewers: 'initializeAvailableInterviewers',
      setFormData: 'setScheduleInterviewData',
    }),
    customFilter(_item, queryText, itemText) {
      queryText = queryText.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      itemText = itemText.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
    },
  },
};
</script>
