const countries = {
  mexico: 'MEX',
  spain: 'ESP',
  colombia: 'COL',
  usa: 'USA',
  emea: 'XXX',
  apac: 'XXX',
};

export default countries;
