<template>
  <v-card class="v-digital-time-picker d-flex align-center">
    <v-select
      id="hour"
      v-model="hour"
      append-icon=""
      class="v-digital-time-picker-select pt-0 mt-0 align-center"
      hide-details
      :menu-props="{ nudgeTop: 22, tile: true }"
      :items="hours"
    />
    <span class="v-digital-time-picker-colon d-flex align-center">
      :
    </span>
    <v-select
      v-model="minute"
      append-icon=""
      class="v-digital-time-picker-select pt-0 mt-0 align-center"
      hide-details
      :menu-props="{ nudgeTop: 22, tile: true }"
      :items="minutes"
    />
    <template v-if="format == 'ampm'">
      <v-divider vertical />
      <v-select
        v-model="period"
        append-icon=""
        class="v-digital-time-picker-select pt-0 mt-0 align-center"
        hide-details
        :menu-props="{ nudgeTop: 22, tile: true }"
        :items="periods"
      />
    </template>
  </v-card>
</template>

<script>
export default {
  props: {
    /**
     * Value set by the parent component.
     * @ignore
     */
    value: {
      type: null,
      default: null,
    },
    /**
     * Date format used by the time picker.
     */
    format: {
      type: String,
      default: '24hr',
      validator(value) {
        return ['ampm', '24hr'].includes(value);
      },
    },
    /**
     * Used for the digital clock, sets the interval between minutes options.
     */
    minuteStep: {
      type: Number,
      default: 1,
      validator: (value) => {
        return value >= 1 && value <= 60;
      },
    },
  },
  data: () => ({
    hours: [],
    minutes: [],
    periods: ['AM', 'PM'],
    defaultHour: '12',
    defaultPeriod: 'PM',
  }),
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(value) {
        /**
         * Emit the input event to the parent component.
         */
        this.$emit('input', value);
      },
    },
    hour: {
      get() {
        if (this.format === 'ampm') {
          return this.innerValue ? this.convert24to12(this.innerValue.split(':')[0]) : '12';
        }
        return this.innerValue ? this.innerValue.split(':')[0] : '12';
      },
      set(value) {
        value = this.format === 'ampm' ? this.convert12to24(value, this.period) : value;
        this.innerValue = `${value}:${this.minute}`;
      },
    },
    minute: {
      get() {
        return this.innerValue ? this.innerValue.split(':')[1] : '00';
      },
      set(value) {
        const hour = this.format === 'ampm' ? this.convert12to24(this.hour, this.period) : this.hour;
        this.innerValue = `${hour}:${value}`;
      },
    },
    period: {
      get() {
        if (this.innerValue) {
          return this.innerValue.split(':')[0] >= 12 ? 'PM' : 'AM';
        }
        return this.defaultPeriod;
      },
      async set(value) {
        if (!this.innerValue) {
          const hour = this.convert12to24(this.defaultHour, this.defaultPeriod);
          value = (parseInt(hour, 10) + (value === 'AM' ? -12 : 12)).toString().padStart(2, '0');
        } else {
          value = (parseInt(this.innerValue.split(':')[0], 10) + (value === 'AM' ? -12 : 12))
            .toString()
            .padStart(2, '0');
        }

        this.innerValue = `${value}:${this.minute}`;
      },
    },
  },
  mounted() {
    this.hours =
      this.format === '24hr'
        ? this.generateNumberStringSteppedArray(0, 23, 1)
        : this.generateNumberStringSteppedArray(1, 12, 1);
    this.minutes = this.generateNumberStringSteppedArray(0, 59, this.minuteStep);
  },
  methods: {
    generateNumberStringSteppedArray(start, stop, step) {
      return Array.from({ length: (stop - start) / step + 1 }, (_, i) =>
        (start + i * step).toString().padStart(2, '0')
      );
    },
    convert24to12(hour) {
      if (hour === '00') {
        return '12';
      }
      return (((hour - 1) % 12) + 1).toString().padStart(2, '0');
    },
    convert12to24(hour, period) {
      if (hour === 12 && period === 'AM') {
        return '12';
      }
      return ((hour % 12) + (period === 'PM' ? 12 : 0)).toString().padStart(2, '0');
    },
  },
};
</script>

<style scoped>
::v-deep .v-list-item__title {
  display: flex;
  justify-content: center;
  font-size: 25px;
}

.v-card {
  min-width: unset !important;
  max-width: 300px;
}

.v-card ::v-deep .v-select__selections {
  width: 100px;
  font-size: 25px;
  line-height: 25px;
}

.v-card ::v-deep .v-select__selections > .v-select__selection.v-select__selection--comma {
  margin: 0 auto;
}

.v-card ::v-deep .v-select__selections > input {
  display: none;
}

.v-digital-time-picker-select {
  height: 80px;
}

.v-digital-time-picker-select ::v-deep .v-input__slot::before {
  border-style: none !important;
}

.v-digital-time-picker-select ::v-deep .v-input__slot::after {
  border-style: none !important;
}

.v-digital-time-picker-colon {
  font-size: 25px;
}
</style>
