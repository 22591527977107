import { ROLES } from '@/permissions/permissions';

const ROLES_HOMEPAGES = { [ROLES.talentAcquisition]: '/dashboard', [ROLES.interviewer]: '/interviewer-settings' };

const getUserRoleHomepage = (userRoles) => {
  for (let i = 0; i < userRoles.length; i += 1) {
    if (ROLES_HOMEPAGES[userRoles[i]]) {
      return ROLES_HOMEPAGES[userRoles[i]];
    }
  }

  return '/role-select';
};

const getPagesConfig = () => [
  {
    name: 'Home',
    path: '/',
    componentName: 'DashboardNext.vue',
    icon: 'mdi-view-dashboard',
    showInNavigation: false,
    meta: {
      requiresAuth: true,
      allowedRoles: [ROLES.talentAcquisition],
    },
  },
  {
    name: 'Login',
    path: '/login',
    componentName: 'Login.vue',
    icon: '',
    showInNavigation: false,
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: 'Interviewer Settings',
    path: '/interviewer-settings',
    componentName: 'UserSettings.vue',
    icon: 'mdi-account-cog',
    showInNavigation: true,
    meta: {
      requiresAuth: true,
      allowedRoles: [ROLES.interviewer],
    },
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    componentName: 'DashboardNext.vue',
    icon: 'mdi-view-dashboard',
    showInNavigation: true,
    meta: {
      requiresAuth: true,
      allowedRoles: [ROLES.talentAcquisition],
    },
  },
  {
    name: 'Dashboard Next',
    path: '/dashboard-next',
    componentName: 'DashboardNext.vue',
    icon: 'mdi-view-dashboard',
    showInNavigation: false,
    meta: {
      requiresAuth: true,
      allowedRoles: [ROLES.talentAcquisition],
    },
    redirect: '/dashboard',
  },
  {
    name: 'Schedule',
    path: '/schedule-interview',
    componentName: 'RequestInterview.vue',
    icon: 'mdi-calendar',
    showInNavigation: true,
    meta: {
      requiresAuth: true,
      allowedRoles: [ROLES.talentAcquisition],
    },
  },
  {
    name: 'Mentee Interviewers Registration',
    path: '/mentee-registration',
    componentName: 'MenteeRegistration.vue',
    icon: 'mdi-account-plus',
    showInNavigation: false,
    meta: {
      requiresAuth: true,
      userRegistration: true,
    },
  },
  {
    name: 'Talent Acquisition Registration',
    path: '/ta-registration',
    componentName: 'TalentRegistration.vue',
    icon: 'mdi-account-plus',
    showInNavigation: false,
    meta: {
      requiresAuth: true,
      userRegistration: true,
    },
  },
  {
    name: 'Interviewers',
    path: '/interviewers',
    componentName: 'Interviewers.vue',
    icon: 'mdi-view-list',
    showInNavigation: true,
    meta: {
      requiresAuth: true,
      allowedRoles: [ROLES.talentAcquisition],
    },
  },
  {
    name: 'Mentee Interviewers Evaluation',
    path: '/interview/:id/mentee-evaluation',
    componentName: 'MenteeEvaluation.vue',
    icon: 'mdi-account-check',
    showInNavigation: false,
    meta: {
      requiresAuth: true,
      allowedRoles: [ROLES.interviewer],
    },
  },
  {
    name: 'Role Selection',
    path: '/role-select',
    componentName: 'RoleSelect.vue',
    icon: 'mdi-account',
    showInNavigation: false,
    meta: {
      requiresAuth: true,
      userRegistration: true,
    },
  },
];

export { getPagesConfig, getUserRoleHomepage };
