var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',{attrs:{"color":"transparent"}},_vm._l((_vm.items),function(interview){return _c('v-list-item',{key:interview.interview_id,staticClass:"my-n1 px-0 py-1"},[_c('v-list-item',{staticClass:"pl-0 pr-3"},[_c('v-icon',{class:'circle-tag two-color--' + interview.status},[_vm._v(" "+_vm._s(_vm.getInterviewStatusIcon(interview.status))+" ")])],1),_c('v-list-item-content',{staticClass:"py-0 label-content mr-2"},[_c('span',{staticClass:"subtitle-2"},[_vm._v(_vm._s(interview.type))])]),(interview.interview_date)?_c('v-list-item',{staticClass:"px-0 caption"},[_vm._v(" "+_vm._s(_vm.formattedDate(interview.interview_date))+" ")]):_vm._e(),(interview.status === 'Scheduled')?_c('v-list-item',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.edit(interview.interview_id)}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(" Edit interview ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":"","small":"","data-test-id":"btn-cancel-interview"},on:{"click":function($event){return _vm.cancel(interview.interview_id)}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-cancel")])],1)]}}],null,true)},[_c('span',[_vm._v(" Cancel interview ")])]),(interview.mentee_data === null)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":"","small":"","data-test-id":"btn-add-mentee"},on:{"click":function($event){return _vm.addMentee(interview)}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-account-school")])],1)]}}],null,true)},[_c('span',[_vm._v(" Add Mentee ")])]):_vm._e()],1):_vm._e()],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }