<template>
  <v-container data-test-id="container-main">
    <v-row>
      <v-col>
        <div>
          <h2>General Information</h2>
          <h3 class="mb-3" data-test-id="candidate-job-name">{{ formData.candidateJobName }}</h3>
        </div>
      </v-col>
    </v-row>
    <ValidationObserver ref="observer">
      <!-- Section 1 -->
      <div>
        <v-row class="justify-space-around px-5 px-sm-0">
          <v-col cols="12" sm="5">
            <ValidationProvider
              v-slot="{ valid, errors, touched, required }"
              name="Greenhouse Link"
              rules="required|url"
              class="flex"
            >
              <v-text-field
                v-model="formData.greenhouseProfileLink"
                label="Greenhouse Link"
                :class="required ? 'label-required' : ''"
                :error="touched && !valid"
                :error-messages="errors[0]"
                data-test-id="greenhouse-profile-link"
                required
                @paste="onGreenhouseLinkChange($event, true)"
                @keydown.space.prevent
                @input="onGreenhouseLinkChange($event, false)"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="5">
            <v-menu
              v-model="menu"
              transition="scale-transition"
              offset-y
              min-width="290px"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <ValidationProvider v-slot="{ valid, errors, touched, required }" name="Date range" rules="required">
                  <v-text-field
                    v-model="dateRange"
                    label="Select a date range"
                    class="calendar-field"
                    :class="required ? 'label-required' : ''"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    data-test-id="date-range"
                    v-bind="attrs"
                    :error="touched && !valid"
                    :error-messages="errors[0]"
                    v-on="on"
                  ></v-text-field>
                </ValidationProvider>
              </template>
              <v-date-picker v-model="dates" range />
            </v-menu>
          </v-col>
        </v-row>
      </div>
      <!-- End Section 1 -->

      <!-- Section 2 -->
      <v-sheet rounded class="mx-auto mt-3 mb-10 px-5 px-sm-0" elevation="4" height="auto" width="100%">
        <v-row class="justify-space-around">
          <v-col cols="12" sm="5">
            <ValidationProvider
              v-slot="{ valid, errors, touched, required }"
              name="Candidate's Name"
              rules="required"
              class="flex"
            >
              <v-text-field
                v-model="formData.candidateName"
                label="Candidate's Name"
                :class="required ? 'label-required' : ''"
                :error="touched && !valid"
                :error-messages="errors[0]"
                data-test-id="candidate-name"
                :disabled="fieldsDisabled"
                @input="(value) => setFormData({ candidateName: value })"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="5">
            <ValidationProvider
              v-slot="{ valid, errors, touched, required }"
              name="Candidate's Last Name"
              rules="required"
              class="flex"
            >
              <v-text-field
                v-model="formData.candidateLastName"
                label="Candidate's Last Name"
                :class="required ? 'label-required' : ''"
                :error="touched && !valid"
                :error-messages="errors[0]"
                data-test-id="candidate-lastName"
                :disabled="fieldsDisabled"
                @input="(value) => setFormData({ candidateLastName: value })"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="5">
            <ValidationProvider
              v-slot="{ valid, errors, touched, required }"
              name="Email"
              rules="required|email"
              class="flex"
            >
              <v-text-field
                v-model="formData.candidateEmail"
                label="Candidate Email"
                :error="touched && !valid"
                :class="required ? 'label-required' : ''"
                :error-messages="errors[0]"
                data-test-id="candidate-email"
                required
                :disabled="fieldsDisabled"
                @input="(value) => setFormData({ candidateEmail: value })"
                @paste="onEmailChange"
                @keydown.space.prevent
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="5">
            <ValidationProvider v-slot="{ valid, errors, touched, required }" name="Levels" rules="required">
              <v-select
                v-model="formData.level"
                :items="levels"
                item-text="name"
                item-value="id"
                label="Candidate's expected level"
                :class="required ? 'label-required' : ''"
                return-object
                :error="touched && !valid"
                :error-messages="errors[0]"
                data-test-id="levels-select"
                @input="(value) => setFormData({ level: value })"
              ></v-select>
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-sheet>
      <!-- End Section 2 -->

      <!-- Section 3 -->
      <v-sheet rounded class="mx-auto mb-3 mt-10 px-5 px-sm-0" elevation="4" height="auto" width="100%">
        <v-row class="justify-space-around">
          <v-col cols="12" sm="5">
            <ValidationProvider v-slot="{ valid, errors, touched, required }" name="Discipline" rules="required">
              <v-select
                v-model="formData.discipline"
                :items="disciplines"
                item-text="name"
                item-value="id"
                label="Discipline"
                :class="required ? 'label-required' : ''"
                return-object
                :disabled="disciplineDisabled"
                :error="touched && !valid"
                :error-messages="errors[0]"
                data-test-id="disciplines-select"
                @input="(value) => setDisciplineData(value)"
                @change="() => setFormData({ requiresTechnology: false })"
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="5">
            <ValidationProvider v-slot="{ valid, errors, touched, required }" name="Interview Type" rules="required">
              <v-select
                v-model="formData.interviewType"
                :items="interviewTypes"
                item-text="name"
                item-value="id"
                label="Interview Type"
                :class="required ? 'label-required' : ''"
                return-object
                :error="touched && !valid"
                :error-messages="errors[0]"
                data-test-id="interview-types-select"
                @input="(value) => setFormData({ interviewType: value })"
                @change="resetData()"
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="5" class="d-md-flex justify-md-space-center p-0">
            <div class="mr-2">
              <ValidationProvider
                v-slot="{ valid, errors, touched }"
                name="Requires Technology"
                vid="requiresTechnology"
              >
                <v-switch
                  v-model="formData.requiresTechnology"
                  label="Requires a specific technology"
                  :error="touched && !valid"
                  :error-messages="errors[0]"
                  data-test-id="technology-switch"
                  @input="(value) => setFormData({ requiresTechnology: value })"
                  @change="onSwitchChange"
                ></v-switch>
              </ValidationProvider>
              <v-snackbar v-model="snackbar" :timeout="3000">
                {{ snackbarText }}
                <template v-slot:action="{ attrs }">
                  <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close</v-btn>
                </template>
              </v-snackbar>
            </div>
            <div>
              <ValidationProvider
                v-slot="{ valid, errors, touched }"
                name="Technology"
                rules="required_if:requiresTechnology,true"
              >
                <AutoComplete
                  v-model="formData.technology"
                  :error="touched && !valid && !isTechDisabled"
                  :error-messages="errors[0]"
                  :items="technologies"
                  label="Technology"
                  item-text="name"
                  item-value="id"
                  data-test-id="technology-select"
                  :is-disabled="!formData.requiresTechnology"
                  @input="(value) => setTechnologyData(value)"
                />
              </ValidationProvider>
            </div>
          </v-col>
          <v-col cols="12" sm="5">
            <ValidationProvider v-slot="{ valid, errors, touched }" name="Include Unavailable">
              <v-switch
                v-model="formData.includeInterviewersWithoutAvailableSlots"
                label="Include interviewers without available slots"
                :error="touched && !valid"
                :error-messages="errors[0]"
                data-test-id="interviewers-switch"
                @input="(value) => setFormData({ includeInterviewersWithoutAvailableSlots: value })"
                @change="resetData()"
              ></v-switch>
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-sheet>
      <v-overlay :value="loadingData">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </ValidationObserver>
  </v-container>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { mapActions, mapGetters } from 'vuex';
import AutoComplete from '../shared/AutoComplete.vue';

extend('url', {
  validate(value) {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' +
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
        '((\\d{1,3}\\.){3}\\d{1,3}))' +
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
        '(\\?[;&a-z\\d%_.~+=-]*)?' +
        '(\\#[-a-z\\d_]*)?$',
      'i'
    );
    return pattern.test(value);
  },
});
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    AutoComplete,
  },
  data() {
    return {
      menu: false,
      snackbar: false,
      snackbarText: '',
      loadingData: false,
      disciplineDisabled: false,
      fieldsDisabled: true,
    };
  },
  computed: {
    ...mapGetters('scheduleInterview', {
      disciplines: 'getDisciplines',
      levels: 'getLevels',
      interviewTypes: 'getInterviewTypes',
      formData: 'getFormData',
      rangeDate: 'getFormDataRange',
      technologies: 'getTechnologies',
      discipline: 'getSelectedDiscipline',
      hasTechnologies: 'getIsDiscTechnologies',
      alert: 'getAlert',
    }),
    dates: {
      get() {
        return this.rangeDate;
      },
      set(value) {
        this.setFormData({ dateRange: value });
        if (value[1]) {
          const date = this.validateRange(value);

          this.setFormData({ dateRange: date });
          this.setCalendarFocus(date[0]);
          this.menu = false;
        }
      },
    },
    dateRange() {
      return this.dates.join(' ~ ');
    },
  },
  mounted() {
    this.initDisciplinesData();
    this.initLevelsData();
    this.validateFields();
    if (this.formData.greenhouseProfileLink) {
      this.fetchCandidateInfo();
    }
  },
  methods: {
    ...mapActions('scheduleInterview', {
      initDisciplinesData: 'initializeDisciplinesData',
      initLevelsData: 'initializeLevelsData',
      setFormData: 'setScheduleInterviewData',
      setCalendarFocus: 'setCalendarFocus',
      setDiscipline: 'setDiscipline',
      loadTechnologies: 'setTechnologies',
      getCandidateInfoFromGreenhouse: 'getCandidateInfoFromGreenhouse',
    }),
    onEmailChange(event) {
      event.preventDefault();
      const paste = (event.clipboardData || window.clipboardData).getData('text').trim();
      this.formData.candidateEmail = paste;
    },
    validateRange(value) {
      return value[0] < value[1] ? value : [value[1], value[0]];
    },
    validateFields() {
      if (this.formData.candidateName || this.formData.candidateEmail || this.formData.candidateLastName) {
        this.fieldsDisabled = false;
      }
      if (this.formData.discipline) {
        this.disciplineDisabled = true;
      }
    },
    async resetData() {
      this.formData.calendar = { end: null, start: null };
      this.setFormData({ selectedInterviewers: [] });
      this.formData.selectedInterviewers = [];
      this.formData.interviewer = null;
    },
    async setDisciplineData(value) {
      this.setDiscipline(value);
      this.resetData();
    },
    async setTechnologyData(value) {
      this.setFormData({ technology: value });
      this.resetData();
    },
    onSwitchChange(value) {
      this.resetData();
      if (value) {
        this.formData.technology = null;
        if (!this.hasTechnologies) {
          this.snackbar = true;
          this.snackbarText = 'No technologies available for this discipline';
          this.setFormData({ requiresTechnology: false });
        } else {
          this.loadTechnologies();
          this.setFormData({ requiresTechnology: true });
        }
      } else {
        this.formData.technology = null;
        this.setFormData({ requiresTechnology: false });
      }
    },
    async fetchCandidateInfo() {
      try {
        this.snackbar = false;
        this.disciplineDisabled = true;
        this.fieldsDisabled = true;
        this.loadingData = true;
        await this.getCandidateInfoFromGreenhouse();
        if (!this.formData.discipline) {
          this.disciplineDisabled = false;
        }
        this.fieldsDisabled = false;
        this.loadingData = false;
      } catch (error) {
        this.loadingData = false;
        this.snackbar = this.alert.show;
        this.snackbarText = this.alert.message;
        this.$refs.observer.reset();
        console.error(error);
      }
    },
    async updateInput() {
      this.fetchCandidateInfo();
    },
    async onGreenhouseLinkChange(event, pasted) {
      if (pasted) {
        event.preventDefault();
        const paste = (event.clipboardData || window.clipboardData).getData('text').trim();
        this.formData.greenhouseProfileLink = paste;
        this.fetchCandidateInfo();
      } else {
        this.updateInput();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.calendar-field::v-deep label {
  left: -28px !important;
}
.label-required::v-deep label::after {
  content: ' *';
  color: #d83838;
}
</style>
