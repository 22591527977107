var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 nowrap-sortable-icon",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.pageSize,"loading":_vm.interviewsLoading,"loading-text":_vm.loadingText,"sort-by":"interviewDate","no-data-text":"No interviews found, try another filter?","mobile-breakpoint":"600"},scopedSlots:_vm._u([{key:"top",fn:function(){return [[_c('v-toolbar',{staticClass:"mb-n2",attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Candidates")]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-sm-none",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Filter By Status ")])]}}])},[_c('v-list',{staticClass:"d-sm-none"},[_c('v-list-item-group',{attrs:{"multiple":""},on:{"change":function($event){return _vm.fetchInterviews()}},model:{value:(_vm.selectedStatuses),callback:function ($$v) {_vm.selectedStatuses=$$v},expression:"selectedStatuses"}},_vm._l((_vm.statuses),function(status){return _c('v-list-item',{key:status.id,attrs:{"value":status.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(status.name))])],1)]}}],null,true)})}),1)],1)],1),_c('v-toolbar-title',{staticClass:"d-none d-sm-flex"},[_vm._v("Filter by status:")])],1)],(_vm.statuses.length > 0)?[_c('v-layout',{staticClass:"d-flex flex-wrap justify-xs-center justify-sm-end"},_vm._l((_vm.statuses),function(status){return _c('v-checkbox',{key:status.id,staticClass:"pr-4 mt-n2 d-none d-sm-flex",attrs:{"label":status.name,"value":status.id,"data-test-id":"status-checkbox","aria-test-value":status.name},on:{"change":function($event){return _vm.fetchInterviews()}},model:{value:(_vm.selectedStatuses),callback:function ($$v) {_vm.selectedStatuses=$$v},expression:"selectedStatuses"}})}),1)]:_vm._e(),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.dialogEdit),callback:function ($$v) {_vm.dialogEdit=$$v},expression:"dialogEdit"}},[_c('ModalEditInterview',{attrs:{"data-test-id":"modal-edit-interview"},on:{"close":function($event){_vm.dialogEdit = false}}})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogActions),callback:function ($$v) {_vm.dialogActions=$$v},expression:"dialogActions"}},[[_c('v-card',[_c('v-card-title',[_vm._v(" Confirm Action ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.dialogActionsText)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.dialogActionsFunction}},[_vm._v(" Yes ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialogActions = false}}},[_vm._v(" No ")])],1)],1)]],2)]},proxy:true},{key:"item.interviewDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.setInterviewDateFormat(item.interviewDate))+" ")])]}},{key:"item.technology",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.technology.map(function (tech) { return tech.name; }).join(', ')))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.statusId === 1)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"black","icon":"","data-test-id":"interview-action"}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('span',[_vm._v("Interview Actions")])])]}}],null,true)},[_c('v-list',_vm._l((_vm.actions_list),function(action,index){return _c('v-list-item',{key:index,attrs:{"data-test-id":"candidate-row"},on:{"click":function($event){return _vm.callAction(action.action, item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-"+_vm._s(action.icon)+" ")])],1),_c('v-list-item-title',[_vm._v(_vm._s(action.title))])],1)}),1)],1):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.statusColor,"dark":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }