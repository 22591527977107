<template>
  <v-container fluid class="ma-auto pa-8 dashboard-table__container">
    <v-row class="dashboard-table__dashboard">
      <v-col>
        <h1>Dashboard</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <CandidatesContainer />
        <v-snackbar v-model="snackbar.show" :timeout="3000" data-test-id="snackbar">
          {{ snackbar.text }}
          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar.show = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import CandidatesContainer from '../components/Candidates/CandidatesContainer/CandidatesContainer.vue';

export default {
  components: { CandidatesContainer },
  computed: {
    ...mapGetters('dashboard', {
      snackbar: 'getSnackbar',
    }),
  },
};
</script>
