/* eslint-disable max-classes-per-file */
import APIService from '@/services/API';
import Shared from '@/services/shared';

const GetUserSettingsServiceRequest = function constructor() {};

/* eslint-disable camelcase */
const GetUserSettingsServiceResponse = function constructor({
  email,
  availability,
  discipline,
  technologies,
  level,
  leading_interview_types,
  shadowing_interview_types,
  graduated_interview_types,
  current_year_completed_interviews,
  last_year_completed_interviews,
  interviews,
}) {
  this.email = email;
  this.availability = availability;
  this.discipline = discipline;
  this.technologies = technologies;
  this.level = level;
  this.leadingInterviewTypes = leading_interview_types;
  this.shadowingInterviewTypes = shadowing_interview_types;
  this.graduatedInterviewTypes = graduated_interview_types;
  this.currentYearCompletedInterviews = current_year_completed_interviews;
  this.lastYearCompletedInterviews = last_year_completed_interviews;
  this.interviews = interviews;
};

class GetUserSettingsService extends APIService {
  constructor(getUserSettingsServiceRequest) {
    super();
    this.serviceURL = '/interviewers/me';
    this.request = getUserSettingsServiceRequest;
  }

  async do() {
    this.setAuthorizationHeaders();
    this.setScopesHeaders();
    try {
      const response = await this.createHttpRequest().get(this.serviceURL, { params: this.request });
      return new GetUserSettingsServiceResponse(response.data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

const getUserSettings = async () => {
  try {
    const getUserSettingsServiceRequest = new GetUserSettingsServiceRequest();
    const getUserSettingsService = new GetUserSettingsService(getUserSettingsServiceRequest);
    return getUserSettingsService.do();
  } catch (error) {
    console.error(error);
    throw error;
  }
};

/* eslint-disable camelcase */
const PutUserSettingsServiceRequest = function constructor({ interviewsDesiredPerWeek, technologies, levelId }) {
  this.interviews_desired_per_week = interviewsDesiredPerWeek;
  this.technologies = technologies;
  this.level_id = levelId;
};
/* eslint-enable camelcase */

class PutUserSettingsService extends APIService {
  constructor(putUserSettingsRequest) {
    super();
    this.serviceURL = '/interviewers/me';
    this.request = putUserSettingsRequest;
  }

  async do() {
    this.setAuthorizationHeaders();
    this.setScopesHeaders();
    try {
      return await this.createHttpRequest().put(this.serviceURL, this.request);
    } catch (response) {
      throw new Error(response);
    }
  }
}

const putUserSettings = async (formData) => {
  try {
    const request = new PutUserSettingsServiceRequest({
      interviewsDesiredPerWeek: formData.availability,
      technologies: formData.technologies,
      levelId: formData.level?.id,
    });
    const service = new PutUserSettingsService(request);
    return await service.do();
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getTechnologies = async () => {
  try {
    const request = new Shared.GetTechnologiesServiceRequest();
    const service = new Shared.GetTechnologiesService(request);
    return await service.do();
  } catch (e) {
    console.error(e);
    return [];
  }
};

const getLevelsData = async () => {
  try {
    const request = new Shared.GetLevelsServiceRequest();
    const service = new Shared.GetLevelsService(request);
    return await service.do();
  } catch (e) {
    console.error(e);
    return [];
  }
};

export { getTechnologies, getUserSettings, putUserSettings, getLevelsData };
