import Shared from '@/services/shared';

const initDisciplinesData = async ({ state }) => {
  try {
    const request = new Shared.GetDisciplinesServiceRequest();
    const service = new Shared.GetDisciplinesService(request);
    return await service.do();
  } catch (e) {
    console.error(e);
    return [...state.disciplines];
  }
};

const initLevelsData = async ({ state }) => {
  try {
    const request = new Shared.GetLevelsServiceRequest();
    const service = new Shared.GetLevelsService(request);
    return await service.do();
  } catch (e) {
    console.error(e);
    return [...state.levels];
  }
};

const fetchInterviewTypes = async (id) => {
  try {
    const request = new Shared.GetInterviewTypesServiceRequest({ disciplineId: id });
    const service = new Shared.GetInterviewTypesService(request);
    return await service.do();
  } catch (e) {
    console.error(e);
    return [];
  }
};

const fetchTechnologies = async () => {
  try {
    const request = new Shared.GetTechnologiesServiceRequest();
    const service = new Shared.GetTechnologiesService(request);
    return await service.do();
  } catch (error) {
    console.error(error);
    return [];
  }
};

export default {
  initDisciplinesData,
  initLevelsData,
  fetchInterviewTypes,
  fetchTechnologies,
};
