import { getPagesConfig } from '@/router/pages';
import { isUserAllowed } from '@/permissions/permissions';

const getUserMenu = ({ userRoles, pages }) => {
  return pages.reduce((navs, page) => {
    if (page.showInNavigation && isUserAllowed({ allowedRoles: page.meta.allowedRoles, userRoles })) {
      navs.push({
        name: page.name,
        icon: page.icon,
        link: page.path,
      });
    }
    return navs;
  }, []);
};

const initNavigationContent = ({ rootGetters }) => {
  const pages = getPagesConfig();
  const userRoles = rootGetters['auth/getScopes'];
  return getUserMenu({ userRoles, pages });
};

export { initNavigationContent, getUserMenu };
