/* eslint-disable max-classes-per-file */
import APIService from './API';

const GetDisciplinesServiceRequest = function constructor() {};

const GetDisciplinesServiceResponse = function constructor({ id, name, description, slug }) {
  this.id = id;
  this.name = name;
  this.description = description;
  this.slug = slug;
};

class GetDisciplinesService extends APIService {
  constructor(getDisciplinesServiceRequest) {
    super();
    this.serviceURL = 'disciplines';
    this.request = getDisciplinesServiceRequest;
  }

  async do() {
    this.setAuthorizationHeaders();
    try {
      const response = await this.createHttpRequest().get(this.serviceURL, { params: this.request });
      return response.data.map((responseData) => new GetDisciplinesServiceResponse(responseData));
    } catch ({ response }) {
      throw new Error(`Response | ${response}`);
    }
  }
}

const GetInterviewTypesServiceRequest = function constructor({ disciplineId }) {
  this.discipline_id = disciplineId;
};

const GetInterviewTypesServiceResponse = function constructor({ id, name, description, duration, slug }) {
  this.id = id;
  this.name = name;
  this.description = description;
  this.duration = duration;
  this.slug = slug;
};

class GetInterviewTypesService extends APIService {
  constructor(getDisciplinesServiceRequest) {
    super();
    this.request = getDisciplinesServiceRequest;
    this.serviceURL = `disciplines/${this.request.discipline_id}/interview-types`;
  }

  async do() {
    this.setAuthorizationHeaders();
    this.setScopesHeaders();
    try {
      const response = await this.createHttpRequest().get(this.serviceURL);
      return response.data.map((responseData) => new GetInterviewTypesServiceResponse(responseData));
    } catch (response) {
      throw new Error(`Response | ${response}`);
    }
  }
}

const GetLevelsServiceRequest = function constructor() {};

const GetLevelsServiceResponse = function constructor({ id, name }) {
  this.id = id;
  this.name = name;
};

class GetLevelsService extends APIService {
  constructor(getLevelsServiceRequest) {
    super();
    this.serviceURL = '/levels';
    this.request = getLevelsServiceRequest;
  }

  async do() {
    this.setAuthorizationHeaders();
    try {
      const response = await this.createHttpRequest().get(this.serviceURL, { params: this.request });
      return response.data.map((responseData) => new GetLevelsServiceResponse(responseData));
    } catch (response) {
      throw new Error(response);
    }
  }
}

const GetTechnologiesServiceRequest = function constructor() {};

const GetTechnologiesServiceResponse = function constructor({ id, name }) {
  this.id = id;
  this.name = name;
};

class GetTechnologiesService extends APIService {
  constructor(getTechnologiesServiceRequest) {
    super();
    this.request = getTechnologiesServiceRequest;
    this.serviceURL = '/technologies';
  }

  async do() {
    this.setAuthorizationHeaders();
    try {
      const response = await this.createHttpRequest().get(this.serviceURL, { params: this.request });
      return response.data.map((responseData) => new GetTechnologiesServiceResponse(responseData));
    } catch (response) {
      throw new Error(`Response | ${response}`);
    }
  }
}

const GetCitiesServiceRequest = function constructor() {};

/* eslint-disable camelcase */
const GetCitiesServiceResponse = function constructor({ id, name, country_name }) {
  this.id = id;
  this.name = name;
  this.country_name = country_name;
};
/* eslint-enable camelcase */

class GetCitiesService extends APIService {
  constructor(getCitiesServiceRequest) {
    super();
    this.serviceURL = '/cities';
    this.request = getCitiesServiceRequest;
  }

  async do() {
    this.setAuthorizationHeaders();
    try {
      const response = await this.createHttpRequest().get(this.serviceURL, { params: this.request });
      return response.data.map((responseData) => new GetCitiesServiceResponse(responseData));
    } catch (response) {
      throw new Error(`Response | ${response}`);
    }
  }
}

const SearchInterviewersServiceRequest = function constructor({
  interviewTypeId,
  technologyId,
  startDate,
  endDate,
  includeInterviewersWithoutAvailableSlots,
  disciplineId,
}) {
  this.start_datetime = startDate;
  this.end_datetime = endDate;
  this.interview_type_id = interviewTypeId;
  this.technology_id = technologyId;
  this.include_interviewers_without_available_slots = includeInterviewersWithoutAvailableSlots;
  this.discipline_id = disciplineId;
};

/* eslint-disable camelcase */
const SearchInterviewersServiceResponse = function constructor({
  id,
  full_name,
  email,
  current_week_interviews,
  leading_interview_types,
  shadowing_interview_types,
  main_discipline,
  region,
  technologies,
  level,
}) {
  this.id = id;
  this.fullName = full_name;
  this.email = email;
  this.currentWeekInterviews = current_week_interviews;
  this.leadingInterviewTypes = leading_interview_types;
  this.shadowingInterviewTypes = shadowing_interview_types;
  this.mainDiscipline = main_discipline;
  this.region = region;
  this.technologies = technologies;
  this.level = level;
};
/* eslint-enable camelcase */

class SearchInterviewersService extends APIService {
  constructor(searchInterviewersRequest) {
    super();
    this.serviceURL = 'search-interviewers';
    this.request = searchInterviewersRequest;
  }

  async do() {
    this.setAuthorizationHeaders();
    this.setScopesHeaders();
    try {
      const response = await this.createHttpRequest().get(this.serviceURL, { params: this.request });
      return response.data.map((responseData) => new SearchInterviewersServiceResponse(responseData));
    } catch (response) {
      throw new Error(`Error: ${response}`);
    }
  }
}

const SearchMenteesServiceRequest = function constructor({
  interviewTypeId,
  startDate,
  endDate,
  includeInterviewersWithoutAvailableSlots,
  disciplineId,
}) {
  this.start_datetime = startDate;
  this.end_datetime = endDate;
  this.interview_type_id = interviewTypeId;
  this.include_interviewers_without_available_slots = includeInterviewersWithoutAvailableSlots;
  this.discipline_id = disciplineId;
};
/* eslint-disable camelcase */
const SearchMenteesServiceResponse = function constructor({ id, email }) {
  this.id = id;
  this.email = email;
};
/* eslint-enable camelcase */
class SearchMenteesService extends APIService {
  constructor(searchMenteeRequest) {
    super();
    this.serviceURL = 'search-mentees';
    this.request = searchMenteeRequest;
  }

  async do() {
    this.setAuthorizationHeaders();
    this.setScopesHeaders();
    try {
      const response = await this.createHttpRequest().get(this.serviceURL, { params: this.request });

      if (response.status === 204) {
        return [];
      }

      return response.data.map((responseData) => new SearchMenteesServiceResponse(responseData));
    } catch (response) {
      throw new Error(`Response | ${response}`);
    }
  }
}

const GetInterviewServiceRequest = function constructor(interviewId) {
  this.interview_id = interviewId;
};

/* eslint-disable camelcase */
const GetInterviewServiceResponse = function constructor({
  interview_id,
  candidate_data,
  interviewer_data,
  mentee_data,
  interview_date,
  discipline_id,
  type,
  type_id,
  expected_level_id,
  technology_id,
  greenhouse_link,
  meeting_type,
  additional_information,
}) {
  this.id = interview_id;
  this.candidateData = candidate_data;
  this.interviewerData = interviewer_data;
  this.menteeData = mentee_data;
  this.interviewDate = interview_date;
  this.discipline = discipline_id;
  this.disciplineHasTechnologies = discipline_id === 1;
  this.interviewTypeName = type;
  this.interviewType = type_id;
  this.level = expected_level_id;
  this.technology = technology_id;
  this.greenhouseLink = greenhouse_link;
  this.meetingType = meeting_type;
  this.additionalInformation = additional_information;
};
/* eslint-enable camelcase */

class GetInterviewService extends APIService {
  constructor(getInterviewServiceRequest) {
    super();
    this.request = getInterviewServiceRequest;
    this.serviceURL = `/interviews/${this.request.interview_id}`;
  }

  async do() {
    this.setAuthorizationHeaders();
    this.setScopesHeaders();
    try {
      const response = await this.createHttpRequest().get(this.serviceURL);
      return new GetInterviewServiceResponse(response.data);
    } catch (response) {
      throw new Error(response.message);
    }
  }
}

/* eslint-disable camelcase */
const GetGreenhouseURLInfoServiceRequest = function constructor({ candidateId, applicationId, interviewType }) {
  this.greenhouse_candidate_id = candidateId;
  this.application_id = applicationId;
  this.interview_type = interviewType;
};
/* eslint-disable camelcase */
const GetGreenhouseURLInfoServiceResponse = function constructor({ url }) {
  this.url = url;
};
class GetGreenhouseURLInfoService extends APIService {
  constructor(getGreenhouseInfoServiceRequest) {
    super();
    this.request = getGreenhouseInfoServiceRequest;
    // eslint-disable-next-line max-len
    this.serviceURL = `/greenhouse-candidates/${this.request.greenhouse_candidate_id}/applications/${this.request.application_id}/interview_types/${this.request.interview_type}/interview_url`;
  }

  async do() {
    this.setAuthorizationHeaders();
    this.setScopesHeaders();
    try {
      const response = await this.createHttpRequest().get(this.serviceURL, this.request);
      return new GetGreenhouseURLInfoServiceResponse(response.data);
    } catch (error) {
      throw error.response;
    }
  }
}

export default {
  GetDisciplinesServiceRequest,
  GetDisciplinesService,
  GetInterviewTypesServiceRequest,
  GetInterviewTypesService,
  GetLevelsServiceRequest,
  GetLevelsService,
  GetTechnologiesServiceRequest,
  GetTechnologiesService,
  GetCitiesServiceRequest,
  GetCitiesService,
  SearchInterviewersServiceRequest,
  SearchInterviewersService,
  SearchMenteesServiceRequest,
  SearchMenteesService,
  GetInterviewServiceRequest,
  GetInterviewService,
  GetGreenhouseURLInfoServiceRequest,
  GetGreenhouseURLInfoService,
};
