import APIService from '@/services/API';

const RefreshAccessTokenServiceRequest = function constructor({ refreshToken }) {
  this.refresh_token = refreshToken;
};
/* eslint-disable camelcase */
const RefreshAccessTokenServiceResponse = function constructor({ access_token, expires_in, scopes_token }) {
  this.accessToken = access_token;
  this.expiresIn = expires_in;
  this.scopesToken = scopes_token;
};
/* eslint-enable camelcase */

class RefreshAccessTokenService extends APIService {
  constructor(refreshAccessTokenRequest) {
    super();
    this.serviceURL = 'refresh_token';
    this.request = refreshAccessTokenRequest;
  }

  async do() {
    try {
      const response = await this.createHttpRequest().post(this.serviceURL, this.request);
      return new RefreshAccessTokenServiceResponse(response.data);
    } catch ({ response }) {
      throw new Error(`${response.status} | ${response.data.message}`);
    }
  }
}

export { RefreshAccessTokenService };
export { RefreshAccessTokenServiceResponse };
export { RefreshAccessTokenServiceRequest };
