<template>
  <v-row>
    <v-col v-for="card in cards" :key="card.slug" cols="12" sm="6" lg="3">
      <template>
        <v-card variant="tonal" height="358" :data-test-id="card.slug">
          <v-card-title class="justify-center flex-column">
            <v-icon color="blue" size="40px">{{ card.icon }}</v-icon>
            <span>{{ card.title }}</span>
          </v-card-title>
          <v-card-subtitle>{{ card.subtitle }}</v-card-subtitle>
          <v-card-text>
            <v-list v-if="card.interviewTypes.length > 0">
              <v-list-item
                v-for="(interviewType, key) in card.interviewTypes"
                :key="'item-' + key"
                class="px-0 d-block"
              >
                <v-list-item-title class="custom-title">
                  {{ interviewType.interview_type_name ? interviewType.interview_type_name : interviewType }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="interviewType.number_of_interviews != null"
                  ><span>{{ interviewType.number_of_interviews }} interviews </span>
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
            <div v-else class="mt-2">{{ card.notInterviewTypes }}</div>
          </v-card-text>
        </v-card>
      </template>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    cards: {
      type: Array,
      default: () => ({}),
    },
  },
};
</script>
<style scoped>
.custom-title {
  font-size: 0.9rem;
}
::v-deep .v-list-item {
  min-height: 46px;
}
</style>
