import storeResourceFactory from '@store/libs/storeResourceFactory';
import InterviewersActions from './actions';
import getters from './getters';
import interviewersMutation from './mutations';

const interviewersStore = storeResourceFactory('interviewers', []);

const disciplinesStore = storeResourceFactory('disciplines', {
  disciplines: [],
  selectedDisciplines: [],
});

const state = {
  interviewersLoading: false,
  ...interviewersStore.state,
  ...disciplinesStore.state,
};

const actions = {
  initInterviewersData: interviewersStore.request(InterviewersActions.initInterviewersData),
  initDisciplinesData: disciplinesStore.request(InterviewersActions.initDisciplinesData),
  selectDisciplines: InterviewersActions.selectDisciplines,
};

const mutations = {
  ...interviewersStore.mutations,
  ...disciplinesStore.mutations,
  ...interviewersMutation,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
