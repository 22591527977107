<template>
  <div>
    <v-list width="300" color="transparent">
      <v-list-item
        v-for="(interviewType, index) in items"
        :key="interviewType.name + '-' + index"
        class="my-n1 px-0 py-1"
      >
        <v-list-item class="pl-0 pr-3">
          <span :class="'circle-tag two-color--' + interviewType.status"></span>
        </v-list-item>

        <v-list-item-content class="py-0 label-content mr-2">
          <v-list-item-title class="caption">{{ interviewType.name }}</v-list-item-title>
        </v-list-item-content>

        <v-list-item class="px-0" :data-test-id="`interview-type-${interviewType.status}`">
          <v-chip :class="'text-capitalize two-color--' + interviewType.status" x-small>
            {{ interviewType.status === 'graduated' ? interviewType.status : `Mentee: ${interviewType.status}ing` }}
            {{ interviewType.status !== 'graduated' ? '(' + interviewType.numberOfInterviews + ')' : '' }}
          </v-chip>

          <div v-if="interviewType.status !== 'graduated'" class="ml-3">
            <v-btn x-small data-test-id="graduate-mentee-btn" @click="graduateMentee($event, interviewType.id)">
              <v-icon left>mdi-school</v-icon>
              Graduate
            </v-btn>
          </div>
        </v-list-item>
      </v-list-item>
    </v-list>
    <v-dialog v-model="dialogGraduateMentee" max-width="500px">
      <template>
        <v-card data-test-id="modal-graduate-mentee">
          <v-card-title>
            Confirm Action
          </v-card-title>
          <v-card-text>
            You are about to graduate a mentee, are you sure to continue?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text data-test-id="save-button" @click="submit">
              Yes
            </v-btn>
            <v-btn color="primary" text @click="closeDialog">
              No
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * The items to be displayed in the tags.
     * Each item should have the following properties:
     * @example { name: string, status: string, numberOfInterviews: number }
     */
    items: {
      type: Array,
      default: () => [],
    },
    interviewerId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dialogGraduateMentee: false,
      interviewTypeIdToGraduate: 0,
    };
  },
  methods: {
    graduateMentee(event, interviewTypeId) {
      event.stopPropagation();
      this.dialogGraduateMentee = true;
      this.interviewTypeIdToGraduate = interviewTypeId;
    },
    submit() {
      this.$emit('graduateMenteeReq', this.interviewerId, this.interviewTypeIdToGraduate);
      this.closeDialog();
    },
    closeDialog() {
      this.dialogGraduateMentee = false;
      this.interviewTypeIdToGraduate = 0;
    },
  },
};
</script>

<style scoped>
.circle-tag {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.two-color--lead {
  box-shadow: 0 0 0 2px #f7c324;
  background: #fbe192 !important;
}
.two-color--shadow {
  box-shadow: 0 0 0 2px #f08e58;
  background: #fbceac !important;
}
.two-color--graduated {
  box-shadow: 0 0 0 2px #1bae9f;
  background: #8dd7cf !important;
}
.label-content {
  min-width: 130px;
  max-width: 130px;
}
::v-deep .v-list-item {
  flex: initial;
  min-height: 30px;
}
::v-deep .v-chip {
  padding: 0 5px;
  font-size: 12px;
}
</style>
