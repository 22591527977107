<template>
  <v-menu v-model="menu" transition="scale-transition" offset-y min-width="290px" :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <validation-provider
        v-slot="{ errors }"
        :name="$attrs.name || $attrs.label"
        :rules="rules"
        v-bind="validatorProps"
      >
        <v-text-field
          :value="formattedDate"
          :class="{ 'label-required': required }"
          :error-messages="errors"
          placeholder="Select a date"
          readonly
          v-bind="{ ...attrs, ...$attrs }"
          v-on="{ ...on, ...$listeners }"
          @click:append="on.click"
        >
          <template v-for="(_, slot) in $slots" v-slot:[slot]>
            <!-- @slot Slots passed to vuetify component. -->
            <slot :name="slot" />
          </template>
        </v-text-field>
      </validation-provider>
    </template>
    <v-date-picker v-model="innerValue" v-bind="datePickerProps" no-title @input="menu = false" />
  </v-menu>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import moment from 'moment';

export default {
  components: {
    ValidationProvider,
  },
  inheritAttrs: false,
  props: {
    /**
     * Value set by the parent component.
     * @ignore
     */
    value: {
      type: null,
      default: null,
    },
    /**
     * Rules for the input from vee-validate.
     * @see https://vee-validate.logaretm.com/v2/guide/rules.html
     */
    rules: {
      type: [String, Object],
      default: '',
    },
    /**
     * Props for the validation provider.
     * @see https://vee-validate.logaretm.com/v2/guide/components/validation-provider.html#props
     */
    validatorProps: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Date format applied to the text field using Moment.js.
     * @see https://momentjs.com/docs/#/displaying/format/
     */
    dateFormat: {
      type: String,
      default: 'MM/DD/YYYY',
    },
    /**
     * Props passed to the v-date-picker component.
     */
    datePickerProps: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(value) {
        /**
         * Emit the input event to the parent component.
         */
        this.$emit('input', value);
      },
    },
    required() {
      if (this.rules) {
        if (typeof this.rules === 'string') {
          return this.rules.includes('required');
        }
        return this.rules.required;
      }
      return false;
    },
    formattedDate() {
      return this.innerValue ? moment(this.innerValue).format(this.dateFormat) : null;
    },
  },
};
</script>

<style scoped>
.label-required::v-deep label::after {
  content: ' *';
  color: #d32f2f;
}
</style>
