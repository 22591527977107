import get from 'lodash.get';
import SharedGetters from '@store/modules/shared/getters';
import _clone from 'lodash.clonedeep';

const getAvailability = (state) => {
  const interviewerAvailability = get(state, 'interviewerAvailability');
  return interviewerAvailability;
};

const getEmptySlotAvailability = (state) => {
  /**
   * @constant
   * @type {Array} emptySlotAvailability
   */
  const emptySlotAvailability = _clone(get(state, 'interviewerAvailability'))
    .map((el, i) => ({ ...el, index: i }))
    .filter((el) => el.calendarId === null);
  return emptySlotAvailability.length > 0 ? emptySlotAvailability[0] : null;
};

const getFormData = (state) => {
  const scheduleInterviewFormData = get(state, 'scheduleInterviewForm');
  return scheduleInterviewFormData;
};

const getFormDataRange = (state) => {
  const scheduleInterviewFormData = get(state, 'scheduleInterviewForm');
  return scheduleInterviewFormData.dateRange;
};

const getSelectedSlot = (state) => {
  const { selectedSlot } = get(state, 'calendar');
  return selectedSlot;
};

const getCalendarFocus = (state) => {
  const { focus } = get(state, 'calendar');
  return focus;
};

const getSelectedDiscipline = (state) => {
  const { discipline } = get(state, 'scheduleInterviewForm');
  return discipline;
};

const getAvailableInterviewers = (state) => {
  const availableInterviewers = get(state, 'availableInterviewers');
  return availableInterviewers;
};

const getIsDiscTechnologies = (state) => {
  const { hasDiscTechnologies } = get(state, 'scheduleInterviewForm');
  return hasDiscTechnologies;
};

const getAdditionalInfo = (state) => {
  const { additionalInfo } = get(state, 'additionalInfo');
  return additionalInfo;
};

const getAlert = (state) => {
  const alert = get(state, 'alert');
  return alert;
};

export default {
  ...SharedGetters,
  getFormData,
  getAvailability,
  getFormDataRange,
  getSelectedSlot,
  getCalendarFocus,
  getSelectedDiscipline,
  getIsDiscTechnologies,
  getAdditionalInfo,
  getAlert,
  getEmptySlotAvailability,
  getAvailableInterviewers,
};
