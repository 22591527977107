<template>
  <v-container fluid class="ma-auto pa-7">
    <v-row>
      <v-col>
        <h1>Schedule Interview</h1>
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <v-col>
        <form>
          <v-stepper v-model="currentStep">
            <v-stepper-header>
              <template v-for="(step, index) in steps">
                <v-stepper-step :key="`${index}-step`" :complete="currentStep > index + 1" :step="index + 1">
                  {{ step.displayText }}
                </v-stepper-step>
                <v-divider v-if="index + 1 < steps.length" :key="index" />
              </template>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content v-for="(step, index) in steps" :key="index" :step="index + 1">
                <v-card class="mx-auto" style="overflow: scroll;" min-height="600">
                  <div class="container p-relative py-0">
                    <span class="skip-to-end-button">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <span class="d-block" v-on="on">
                            <BtnPrimary
                              v-if="currentStep == 1"
                              :href="'#'"
                              :text="'Skip to the end'"
                              :action-name="'next-step'"
                              color="success darken-1"
                              :has-tooltip="true"
                              data-test-id="last-step-button"
                              @next-step="nextStep(true)"
                            />
                          </span>
                        </template>
                        <span>
                          Go to the last step
                        </span>
                      </v-tooltip>
                    </span>
                  </div>
                  <component :is="step.view" ref="currentStep" :page="currentStep" />
                </v-card>
              </v-stepper-content>
              <div class="ma-6 text-center">
                <span class="mx-2">
                  <BtnPrimary
                    v-if="currentStep > 1"
                    :href="'#'"
                    :text="'Back'"
                    :action-name="'back-step'"
                    color="blue darken-2"
                    outlined
                    :has-tooltip="false"
                    data-test-id="back-button"
                    @back-step="prevStep()"
                  />
                </span>
                <span class="mx-2">
                  <BtnPrimary
                    v-if="currentStep < steps.length"
                    :href="'#'"
                    :text="'Continue'"
                    :action-name="'next-step'"
                    class="mx-2 white--text"
                    color="blue darken-2"
                    data-test-id="continue-button"
                    @next-step="nextStep()"
                  />
                  <BtnPrimary
                    v-else
                    :href="'#'"
                    :text="'Finish'"
                    :action-name="'finish'"
                    class="mx-2"
                    color="success white--text"
                    data-test-id="finish-button"
                    :disabled="loading"
                    @finish="finish()"
                  />
                </span>
                <v-snackbar v-model="snackbar" :timeout="4000" data-test-id="snackbar">
                  {{ snackbarText }}
                  <template v-slot:action="{ attrs }">
                    <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
                      Close
                    </v-btn>
                  </template>
                </v-snackbar>
              </div>
            </v-stepper-items>
            <v-overlay :value="loading">
              <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
          </v-stepper>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { mapActions, mapGetters } from 'vuex';
import { scheduleInterviewErrors } from '@/constants/errors';
import BtnPrimary from '../components/shared/BtnPrimary.vue';
import BtnText from '../components/shared/BtnText.vue';
import { Schedule, Review, General, Interviewers } from '../components/RequestInterview';

export default {
  components: {
    BtnPrimary,
    BtnText,
    Schedule,
    ValidationObserver,
    General,
  },
  data: () => ({
    currentStep: 1,
    steps: [
      {
        displayText: 'General Information',
        view: General,
      },
      {
        displayText: 'Select Interviewers',
        view: Interviewers,
      },
      {
        displayText: 'Schedule',
        view: Schedule,
      },
      {
        displayText: 'Confirm',
        view: Review,
      },
    ],
    snackbar: false,
    snackbarText: 'Please fill all the required fields.',
    loading: false,
  }),
  computed: {
    ...mapGetters('scheduleInterview', {
      formData: 'getFormData',
      selectedSlot: 'getSelectedSlot',
      alert: 'getAlert',
      availableInterviewers: 'getAvailableInterviewers',
    }),
  },
  methods: {
    ...mapActions('scheduleInterview', {
      saveCandidate: 'saveCandidate',
      initAvailability: 'getInterviewerAvailability',
      postInterview: 'postInterview',
      validate: 'validateForm',
      clearForm: 'clearForm',
      clearCalendar: 'clearCalendar',
      setFormData: 'setScheduleInterviewData',
      generateGreenhouseURL: 'getGreenhouseURLInfo',
      initAvailableInterviewers: 'initializeAvailableInterviewers',
    }),
    async nextStep(skipFirstSteps) {
      switch (this.currentStep) {
        case 1: {
          const isFormValid = await this.isFormValid();
          const isDateRangeValid = this.formData.dateRange.length === 2;
          if (isFormValid && isDateRangeValid) {
            this.loading = true;
            await this.firstStep(skipFirstSteps);
          } else if (!isDateRangeValid) {
            this.snackbarText = 'Please select a valid date range.';
            this.snackbar = true;
          } else {
            this.snackbarText = 'Please fill all the required fields.';
            this.snackbar = true;
          }
          break;
        }
        case 2: {
          const isFormValid = await this.isFormValid();
          if (isFormValid) {
            this.loading = true;
            await this.initAvailability();
            this.loading = false;
            this.currentStep += 1;
          } else {
            this.snackbarText = 'Please fill all the required fields.';
            this.snackbar = true;
          }
          break;
        }
        case 3: {
          this.loading = true;
          await this.setInterviewer();
          this.loading = false;
          this.currentStep += 1;
          break;
        }
        default: {
          break;
        }
      }
    },
    prevStep() {
      this.currentStep -= 1;
    },
    async finish() {
      const isFormValid = await this.isFormValid();
      if (isFormValid) {
        this.loading = true;
        try {
          const response = await this.postInterview();
          if (response?.interviewId) {
            this.loading = false;
            this.$router.push('/dashboard');
            this.clearForm();
            this.clearCalendar();
          } else {
            const exception = response.split('(')[0];
            this.loading = false;
            this.snackbarText = scheduleInterviewErrors[exception];
            this.snackbar = true;
          }
        } catch (error) {
          this.loading = false;
          console.error(error);
        }
      } else {
        this.snackbarText = 'Please fill all the required fields.';
        this.snackbar = true;
      }
    },
    async isFormValid() {
      return this.$refs.currentStep[this.currentStep - 1].$refs.observer.validate();
    },
    async firstStep(skipFirstSteps) {
      try {
        await this.saveCandidate();
        await this.generateGreenhouseURL();
        this.loading = false;
        if (skipFirstSteps) {
          this.currentStep = 4;
          await this.initAvailableInterviewers();
          this.formData.includeInterviewersWithoutAvailableSlots = true;
          this.formData.selectedInterviewers = this.availableInterviewers;
          this.setInterviewer();
        } else this.currentStep += 1;
      } catch (error) {
        this.loading = false;
        this.snackbar = this.alert.show;
        this.snackbarText = this.alert.message;
        console.error(error);
      }
    },
    setInterviewer() {
      const selectedInterviewer = this.formData.selectedInterviewers.find((x) => {
        return x.email === this.selectedSlot?.email;
      });
      if (selectedInterviewer) {
        this.setFormData({ interviewer: selectedInterviewer });
      } else if (this.formData.selectedInterviewers.length === 1) {
        this.setFormData({ interviewer: this.formData.selectedInterviewers[0] });
      } else {
        this.setFormData({ interviewer: {} });
      }
    },
  },
};
</script>
<style scoped>
.p-relative {
  position: relative;
}
.skip-to-end-button {
  position: absolute;
  right: 0;
  top: 30px;
}
</style>
