var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.headers,"items":_vm.candidates,"loading":_vm.loadingApplications,"loading-text":"Loading scheduled applications... Please wait","fixed-header":"","data-test-id":"scheduled-table"},scopedSlots:_vm._u([_vm._l((_vm.$slots),function(_,slot){return {key:slot,fn:function(){return [_vm._t(slot)]},proxy:true}}),{key:"item.jobName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formattedJobName(item.jobName)))])]}},{key:"item.interviewers",fn:function(ref){
var item = ref.item;
return [_c('v-list',{attrs:{"color":"transparent"}},_vm._l((item.interviews),function(interview){return _c('v-list-item',{key:interview.interview_id,staticClass:"px-0 my-n1 py-1",style:({ minHeight: '30px' })},[_c('span',[_vm._v(_vm._s(interview.interviewer_data.name))])])}),1)]}},{key:"item.interviews",fn:function(ref){
var item = ref.item;
return [_c('interview-tag',{attrs:{"items":item.interviews},on:{"edit":function (id) { return _vm.edit(id); },"cancel":function (id) { return _vm.cancel(id); },"addMentee":function (interview) { return _vm.addMentee(interview, item.discipline.id); }}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","data-test-id":"schedule-interview-btn"},on:{"click":function($event){return _vm.schedule(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-calendar")]),_vm._v(" Schedule ")],1)]}}],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }