<template>
  <v-dialog v-model="cancelDialogVisible" max-width="500px" @input="updateDialogVisible">
    <template>
      <v-card data-test-id="modal-cancel-interview">
        <v-card-title>
          Confirm Action
        </v-card-title>
        <v-card-text>
          Are you sure you want to cancel this interview?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogCancelInterviewAction">
            Yes
          </v-btn>
          <v-btn color="primary" text @click="closeAction">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    dialogCancelInterviewAction: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      cancelDialogVisible: this.value,
    };
  },
  watch: {
    value(newVal) {
      this.cancelDialogVisible = newVal;
    },
  },
  methods: {
    closeAction() {
      this.$emit('input', false); // Close the dialog
    },
    updateDialogVisible() {
      this.$emit('input', this.cancelDialogVisible);
    },
  },
};
</script>
