import { INTERVIEW_TYPE_TA } from '@/constants/tests/shared.mock';

const MENTOR_EMAIL = 'leslie.figueroa@wizeline.com';

const MENTEE_EMAIL = 'mentee_test@wizeline.com';

const CANDIDATE_NAME = 'Test test 2';

const getUserInfoResponse = {
  name: 'Leslie Figueroa Moreno',
  email: MENTOR_EMAIL,
  image_url: 'https://lh3.googleusercontent.com/a/AAcHTtf7fORKUx5xsDysaqXhTNc1dEgGVF0Td09PioWWoygRPw=s96-c',
};

const getInterviewResponse = {
  interview_id: 1711,
  candidate_data: {
    id: 941,
    name: CANDIDATE_NAME,
    email: 'candidate0@hotmail.com',
  },
  interviewer_data: {
    id: 84,
    name: 'Leslie Figueroa',
    email: MENTOR_EMAIL,
  },
  mentee_data: {
    id: 116,
    name: 'Mentee Name',
    email: MENTEE_EMAIL,
  },
  interview_date: {
    start: '2023-08-08T17:00:00',
    end: '2023-08-08T18:00:00',
  },
  discipline: 'Software Engineering',
  discipline_id: 1,
  type: INTERVIEW_TYPE_TA.name,
  type_id: 1,
  expected_level_id: 3,
  technology_id: 5,
  greenhouse_link: 'https://wizeline.greenhouse.io/guides/25183627/people/283863317/interview?application_id=308908246',
  additional_information: null,
  meeting_type: 'zoom',
};

const putMenteeEvaluationGraduatedRequest = {
  comments: '',
  evaluation: 3,
  has_attended: true,
  interview_id: 16,
  mentee_email: MENTEE_EMAIL,
  mentee_role: 3,
};
const putMenteeEvaluationTakeAnotherSessionRequest = {
  comments: '',
  evaluation: 2,
  has_attended: true,
  interview_id: 16,
  mentee_email: MENTEE_EMAIL,
  mentee_role: 3,
};

const putMenteeEvaluationResponse = {
  mentee_id: 116,
  evaluated_interview_type: INTERVIEW_TYPE_TA.name,
  has_graduated: true,
};

const putMenteeEvaluationTakeAnotherSessionResponse = {
  mentee_id: 116,
  evaluated_interview_type: INTERVIEW_TYPE_TA.name,
  has_graduated: false,
};

export {
  MENTOR_EMAIL,
  MENTEE_EMAIL,
  CANDIDATE_NAME,
  getUserInfoResponse,
  getInterviewResponse,
  putMenteeEvaluationGraduatedRequest,
  putMenteeEvaluationResponse,
  putMenteeEvaluationTakeAnotherSessionRequest,
  putMenteeEvaluationTakeAnotherSessionResponse,
};
