<template>
  <v-data-table
    :headers="headers"
    :items="candidates"
    :loading="loadingApplications"
    loading-text="Loading scheduled applications... Please wait"
    fixed-header
    v-bind="$attrs"
    data-test-id="scheduled-table"
    v-on="$listeners"
  >
    <template v-for="(_, slot) in $slots" v-slot:[slot]>
      <!-- @slot Slots passed to vuetify component. -->
      <slot :name="slot" />
    </template>
    <template v-slot:[`item.jobName`]="{ item }">
      <span>{{ formattedJobName(item.jobName) }}</span>
    </template>
    <template v-slot:[`item.interviewers`]="{ item }">
      <v-list color="transparent">
        <v-list-item
          v-for="interview in item.interviews"
          :key="interview.interview_id"
          class="px-0 my-n1 py-1"
          :style="{ minHeight: '30px' }"
        >
          <span>{{ interview.interviewer_data.name }}</span>
        </v-list-item>
      </v-list>
    </template>
    <template v-slot:[`item.interviews`]="{ item }">
      <interview-tag
        :items="item.interviews"
        @edit="(id) => edit(id)"
        @cancel="(id) => cancel(id)"
        @addMentee="(interview) => addMentee(interview, item.discipline.id)"
      />
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn small data-test-id="schedule-interview-btn" @click="schedule(item)">
        <v-icon left>mdi-calendar</v-icon>
        Schedule
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import InterviewTag from '../InterviewTag/InterviewTag.vue';

export default {
  components: {
    InterviewTag,
  },
  inheritAttrs: false,
  props: {
    candidates: {
      type: Array,
      required: true,
    },
    loadingApplications: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    headers: [
      { text: 'Name', value: 'name', width: '16%' },
      { text: 'Discipline', value: 'discipline.name', width: '12%' },
      { text: 'Job Position', value: 'jobName', width: '14%' },
      { text: 'Interviewer(s)', value: 'interviewers', width: '17%' },
      { text: 'Scheduled Interview(s)', value: 'interviews', width: '33%' },
      { text: 'Actions', value: 'actions', width: '8%' },
    ],
  }),
  methods: {
    formattedJobName(jobName) {
      return jobName && jobName.replace(';', ' - ');
    },
    edit(id) {
      /**
       * Emit the edit event to the parent component with the interview's id.
       */
      this.$emit('edit', id);
    },
    cancel(id) {
      /**
       * Emit the cancel event to the parent component with the interview's id.
       */
      this.$emit('cancel', id);
    },
    schedule(candidate) {
      this.$emit('schedule', candidate);
    },
    addMentee(interview, disciplineId) {
      this.$emit('addMentee', interview, disciplineId);
    },
  },
};
</script>

<style scoped>
::v-deep .v-data-footer {
  margin-right: 0px !important;
}

::v-deep tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

::v-deep tbody tr {
  height: 90px;
}
::v-deep tbody tr td {
  padding: 0 8px !important;
}
</style>
