const START_LOADING = (state) => {
  state.loading = true;
};

const STOP_LOADING = (state) => {
  state.loading = false;
};

const SET_INTERVIEW_FORM_VALUE = (state, { key, value }) => {
  state.interview[key] = value;
};

const SET_ORIGINAL_INTERVIEW_DATA = (state, interview) => {
  state.originalInterview = interview;
};

const SET_INTERVIEW_TYPES = (state, types) => {
  state.interviewTypes = types;
};

const SET_TECHNOLOGIES = (state, technologies) => {
  state.technologies = technologies;
};

const SET_AVAILABLE_INTERVIEWERS = (state, interviewers) => {
  state.availableInterviewers = interviewers;
};

const SET_MENTEES = (state, mentees) => {
  state.mentees = mentees;
};

export default {
  START_LOADING,
  STOP_LOADING,
  SET_ORIGINAL_INTERVIEW_DATA,
  SET_INTERVIEW_FORM_VALUE,
  SET_INTERVIEW_TYPES,
  SET_TECHNOLOGIES,
  SET_AVAILABLE_INTERVIEWERS,
  SET_MENTEES,
};
