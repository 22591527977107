import get from 'lodash.get';
import { parseJwt } from '@/helpers';

const getAuthData = (state) => {
  return get(state, 'authData');
};

const getScopes = (state) => {
  const { scopes } = state.authData.scopesToken ? parseJwt(state.authData.scopesToken) : { scopes: [] };
  return scopes;
};

export default { getAuthData, getScopes };
