import axios from 'axios';
import httpAdapter from 'axios/lib/adapters/http';
import { LOCAL_STORAGE_AUTH_KEYS } from '../constants/index';

export default class APIService {
  constructor() {
    this.baseURL = process.env.VUE_APP_BACKEND_ENDPOINT;
    this.headers = {
      Authorization: '',
      'Scopes-Token': '',
    };
  }

  // eslint-disable-next-line class-methods-use-this
  do() {
    throw new Error('You have to implement the method do');
  }

  setAuthorizationHeaders() {
    const jwt = localStorage.getItem(LOCAL_STORAGE_AUTH_KEYS.accessToken);
    this.headers.Authorization = `${jwt}`;
  }

  setScopesHeaders() {
    const scopesToken = localStorage.getItem(LOCAL_STORAGE_AUTH_KEYS.scopesToken);
    this.headers['Scopes-Token'] = `${scopesToken}`;
  }

  createHttpRequest() {
    return axios.create({
      baseURL: `${this.baseURL}/`,
      headers: this.headers,
      adapter: httpAdapter,
    });
  }
}
