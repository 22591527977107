import Vuex from 'vuex';
import Vue from 'vue';
import footerModule from './modules/footer';
import dashboardModule from './modules/dashboard';
import userModule from './modules/user';
import navigationModule from './modules/navigation';
import authModule from './modules/auth';
import scheduleInterviewModule from './modules/scheduleInterview';
import editInterviewModule from './modules/editInterview';
import interviewersModule from './modules/interviewers';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    footer: footerModule,
    dashboard: dashboardModule,
    user: userModule,
    navigation: navigationModule,
    auth: authModule,
    scheduleInterview: scheduleInterviewModule,
    editInterview: editInterviewModule,
    interviewers: interviewersModule,
  },
});
