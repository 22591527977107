import Shared from '@/services/shared';
import Dashboard from '@/services/dashboard';

const initInterviewersData = async ({ state, commit }) => {
  try {
    commit('START_LOADING');
    const request = new Dashboard.GetInterviewersServiceRequest({
      disciplines: state.disciplines.selectedDisciplines,
    });

    const service = new Dashboard.GetInterviewersService(request);
    commit('STOP_LOADING');
    return await service.do();
  } catch (e) {
    commit('STOP_LOADING');
    console.error(e);
    return [...state.interviewers];
  }
};

const initDisciplinesData = async ({ state }) => {
  try {
    const request = new Shared.GetDisciplinesServiceRequest();
    const service = new Shared.GetDisciplinesService(request);
    const disciplinesData = await service.do();
    return { ...state.disciplines, disciplines: disciplinesData };
  } catch (e) {
    console.error(e);
    return state.disciplines;
  }
};

const selectDisciplines = ({ commit }, disciplines) => {
  commit('SET_SELECTED_DISCIPLINES', { disciplines });
};

export default {
  initInterviewersData,
  initDisciplinesData,
  selectDisciplines,
};
