var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":"","close-on-content-click":!_vm.multiple},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:_vm.classes},'v-btn',Object.assign({}, attrs, _vm.$attrs),false),Object.assign({}, on, _vm.$listeners)),[_vm._v(" "+_vm._s(_vm.label)+" "),_c('v-icon',{attrs:{"right":"","size":"25"}},[_vm._v(" mdi-chevron-down ")])],1)]}}])},[_c('v-list',[(!_vm.multiple)?_vm._l((_vm.items),function(item){return _c('v-list-item',{key:item[_vm.itemValue],on:{"click":function($event){return _vm.itemClick(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item[_vm.itemText]))])],1)],1)}):(_vm.multiple && _vm.multiList)?_vm._l((_vm.items),function(item,i){return _c('v-list-item',{key:item[_vm.itemValue] + '-' + i,class:item['itemChildren'] ? 'd-block px-0 w-100' : '',attrs:{"value":item[_vm.itemValue]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [(item['itemChildren'])?_c('v-list-group',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-checkbox',{attrs:{"input-value":active,"value":item[_vm.itemValue]},on:{"change":function($event){return _vm.sendModel(item)}},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1),_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item[_vm.itemText]))])]},proxy:true}],null,true)},_vm._l((item['itemChildren']),function(itemChild,index){return _c('v-list-item',{key:itemChild[_vm.itemValue] + '-' + index,staticClass:"ml-3",attrs:{"value":itemChild[_vm.itemValue]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-checkbox',_vm._g(_vm._b({attrs:{"value":itemChild[_vm.itemValue],"input-value":active},on:{"change":function($event){return _vm.childChange(item, itemChild[_vm.itemValue])}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"checkbox-label"},[_vm._v(_vm._s(itemChild[_vm.itemText]))])]},proxy:true}],null,true),model:{value:(_vm.modelChild),callback:function ($$v) {_vm.modelChild=$$v},expression:"modelChild"}},'v-checkbox',attrs,false),on))],1)]}}],null,true)})}),1):_vm._e()]}}],null,true)})}):[_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},_vm._l((_vm.items),function(item){return _c('v-list-item',{key:item[_vm.itemValue],attrs:{"value":item[_vm.itemValue]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-checkbox',{attrs:{"input-value":active,"true-value":item}})],1),_c('v-list-item-title',[_vm._v(_vm._s(item[_vm.itemText]))])]}}],null,true)})}),1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }