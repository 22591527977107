<template>
  <v-list color="transparent">
    <v-list-item v-for="interview in items" :key="interview.interview_id" class="my-n1 px-0 py-1">
      <v-list-item class="pl-0 pr-3">
        <v-icon :class="'circle-tag two-color--' + interview.status">
          {{ getInterviewStatusIcon(interview.status) }}
        </v-icon>
      </v-list-item>
      <v-list-item-content class="py-0 label-content mr-2">
        <span class="subtitle-2">{{ interview.type }}</span>
      </v-list-item-content>
      <v-list-item v-if="interview.interview_date" class="px-0 caption">
        {{ formattedDate(interview.interview_date) }}
      </v-list-item>
      <v-list-item v-if="interview.status === 'Scheduled'">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small @click="edit(interview.interview_id)">
              <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>
            Edit interview
          </span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small data-test-id="btn-cancel-interview" @click="cancel(interview.interview_id)">
              <v-icon v-bind="attrs" v-on="on">mdi-cancel</v-icon>
            </v-btn>
          </template>
          <span>
            Cancel interview
          </span>
        </v-tooltip>
        <v-tooltip v-if="interview.mentee_data === null" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small data-test-id="btn-add-mentee" @click="addMentee(interview)">
              <v-icon v-bind="attrs" v-on="on">mdi-account-school</v-icon>
            </v-btn>
          </template>
          <span>
            Add Mentee
          </span>
        </v-tooltip>
      </v-list-item>
    </v-list-item>
  </v-list>
</template>

<script>
import moment from 'moment-timezone';

export default {
  props: {
    /**
     * The items to be displayed in the tags.
     * Each item should have the following properties:
     * @example { name: string, date: string }
     */
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    formattedDate(date) {
      const start = moment.utc(date.start).local();
      const end = moment.utc(date.end).local();

      return `${moment(start).format('HH:mm')} - ${moment(end).format('HH:mm, dddd, MM/DD/YYYY')}`;
    },
    getInterviewStatusIcon(interviewStatus) {
      if (interviewStatus === 'Scheduled') {
        return 'mdi-calendar-clock-outline';
      }
      if (interviewStatus === 'Completed') {
        return 'mdi-clock-check-outline';
      }
      return 'mdi-clock-remove-outline';
    },
    edit(id) {
      /**
       * Emit the edit event to the parent component with the interview's id.
       */
      this.$emit('edit', id);
    },
    cancel(id) {
      /**
       * Emit the cancel event to the parent component with the interview's id.
       */
      this.$emit('cancel', id);
    },
    addMentee(interview) {
      /**
       * Emit the cancel event to the parent component with the interview's id.
       */
      this.$emit('addMentee', interview);
    },
  },
};
</script>

<style scoped>
.circle-tag {
  width: 6px;
  height: 6px;
}
.two-color--Completed {
  color: #46b36a !important;
}
.two-color--Scheduled {
  color: #fbb41b !important;
}
.two-color--Canceled {
  color: #ef7375 !important;
}
.label-content {
  min-width: 170px;
  max-width: 170px;
}
::v-deep .v-list-item {
  flex: initial;
  min-height: 30px;
}
::v-deep .v-chip {
  padding: 0 5px;
  font-size: 12px;
}
</style>
