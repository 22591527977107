<template>
  <v-data-table
    :headers="headers"
    :items="interviewers"
    :expanded.sync="expanded"
    :items-per-page="10"
    item-key="email"
    fixed-header
    show-expand
    class="elevation-1"
    v-bind="$attrs"
    data-test-id="interviewers-table"
    :loading="loadingInterviewers"
    loading-text="Loading interviewers... Please wait"
    v-on="$listeners"
    @click:row="(item, slot) => expandRow(slot)"
  >
    <template v-for="(_, slot) in $slots" v-slot:[slot]>
      <!-- @slot Slots passed to vuetify component. -->
      <slot :name="slot" />
    </template>
    <template v-slot:[`item.data-table-expand`]="{ isExpanded }">
      <v-icon>
        {{ isExpanded ? 'mdi-minus-circle' : 'mdi-plus-circle' }}
      </v-icon>
    </template>
    <template v-slot:[`item.region`]="{ item }">
      <icon-flag :country="item.region.split('-')[1]" rounded />
    </template>
    <template v-slot:[`item.fullName`]="{ item }">
      <div>
        <div>{{ item.fullName }}</div>
        <div class="caption">{{ item.email }}</div>
      </div>
    </template>
    <template v-slot:[`item.discipline`]="{ item }">
      <span>{{ item.discipline }}</span>
    </template>
    <template v-slot:[`item.last30DaysCompletedInterviews`]="{ item }">
      <span class="col">
        <p class="pt-2 mb-0">
          Last 7 Days:
          <span data-test-id="last-7-days-completed-inerviews">
            {{ item.last7DaysCompletedInterviews }} interviews
          </span>
        </p>
        <p class="pt-2 mb-0">
          Last 30 Days:
          <span data-test-id="last-30-days-completed-inerviews">
            {{ item.last30DaysCompletedInterviews }} interviews
          </span>
        </p>
        <p class="pt-2 mb-0">
          Last 180 Days:
          <span data-test-id="last-180-days-completed-inerviews">
            {{ item.last180DaysCompletedInterviews }} interviews
          </span>
        </p>
      </span>
    </template>
    <template v-slot:[`item.types`]="{ item }">
      <div data-test-id="interview-type-tag">
        <interview-type-tag
          :items="
            combineInterviewTypes(
              item.shadowingInterviewTypes,
              item.leadingInterviewTypes,
              item.graduatedInterviewTypes
            )
          "
          :interviewer-id="item.id"
          @graduateMenteeReq="graduateMenteeReq"
        />
      </div>
    </template>
    <template v-slot:expanded-item="{ item }">
      <td colspan="5" class="pa-4">
        <v-container class="ma-0">
          <v-row>
            <v-col>
              <v-row class="d-flex">
                <span class="col px-0" data-test-id="last-completed-interview">
                  <span class="font-weight-bold">Last Interview: </span>
                  {{ formattedDate(item.lastCompletedInterview) }}
                </span>
                <span class="col px-0">
                  <span class="font-weight-bold"> Slots hours</span>
                  <p class="pt-2 mb-0" data-test-id="current-week-slots-hours">
                    Current week: {{ item.currentWeekSlots }} hours
                  </p>
                  <p class="pt-2 mb-0" data-test-id="next-week-slots-hours">
                    Next week: {{ item.nextWeekInterviews }} hours
                  </p>
                </span>
              </v-row>
              <v-row>
                <div>
                  <tech-chips-container title="Technologies" :items="item.technologies" />
                </div>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </td>
      <td colspan="1" class="pa-4 grey lighten-4" style="box-shadow: inherit; border: inherit;">
        <v-container class="align-content-center pt-0" style="row-gap: 25%; display: grid;">
          <v-row class="mt-0 align-center" no-gutters> <v-icon left> mdi-email </v-icon> {{ item.email }} </v-row>
          <v-row class="mt-0 align-center" no-gutters>
            <v-icon left> mdi-bell </v-icon>
            <span data-test-id="current-week-interviews"
              >{{ item.currentWeekInterviewsScheduledOrDone }} interview(s) this week</span
            >
          </v-row>
          <v-row class="mt-0 align-center" no-gutters>
            <div>
              <v-icon left> mdi-calendar-check </v-icon>
              <span data-test-id="availability-per-week">
                Availability:
                {{ availabilityCode(item.availability, item.currentWeekInterviewsScheduledOrDone) }}</span
              >
            </div>
          </v-row>
        </v-container>
      </td>

      <v-overlay absolute :value="item.loading" opacity="0.75" color="white">
        <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
      </v-overlay>
    </template>
  </v-data-table>
</template>

<script>
import moment from 'moment';
import { getInterviewerSlotsHours } from '@/services/interviewers';
import InterviewTypeTag from '../InterviewTypeTag/InterviewTypeTag.vue';
import IconFlag from '../IconFlag/IconFlag.vue';
import TechChipsContainer from '../TechChips/TechChipsContainer.vue';

export default {
  components: { InterviewTypeTag, IconFlag, TechChipsContainer },
  inheritAttrs: false,
  props: {
    interviewers: {
      type: Array,
      required: true,
    },
    loadingInterviewers: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    headers: [
      { text: '', value: 'data-table-expand' },
      { text: 'Region', value: 'region' },
      { text: 'Name', value: 'fullName' },
      { text: 'Discipline', value: 'discipline' },
      { text: 'Completed Interviews', value: 'last30DaysCompletedInterviews' },
      { text: 'Interview Types', value: 'types', width: '435px', class: 'mr-2' },
    ],
    expanded: [],
  }),
  methods: {
    formattedDate(date) {
      return date ? moment(date).format('MMM DD, YYYY') : 'Has not done any interview yet';
    },
    availabilityCode(availability, interviewsThisWeek) {
      let numberOfInterviewsPerWeek = 0;
      const calculateInterviewsThisWeek = (currentAvailability, currentInterviews) => {
        const interviews = currentAvailability - currentInterviews;
        if (interviews === 0) return 'No more interviews this week';
        return interviews > 0
          ? ` ${interviews} more interviews this week`
          : ` Overbooked capacity with ${Math.abs(interviews)} interviews this week`;
      };
      switch (availability) {
        case 'low':
          numberOfInterviewsPerWeek = calculateInterviewsThisWeek(1, interviewsThisWeek);
          break;
        case 'normal':
          numberOfInterviewsPerWeek = calculateInterviewsThisWeek(2, interviewsThisWeek);
          break;
        case 'high':
          numberOfInterviewsPerWeek = calculateInterviewsThisWeek(4, interviewsThisWeek);
          break;
        default:
          numberOfInterviewsPerWeek = 'No limit interviews per week';
      }
      return numberOfInterviewsPerWeek;
    },
    expandRow(slot) {
      slot.expand(!slot.isExpanded);
      !slot.isExpanded && this.loadSlots(slot);
    },
    async loadSlots({ item }) {
      item.loading = true;
      await getInterviewerSlotsHours(item.id).then((response) => {
        item.nextWeekInterviews = response.nextWeekSlotsHours;
        item.currentWeekSlots = response.currentWeekSlotsHours;
        item.loading = false;
      });
    },
    combineInterviewTypes(shadowingTypes, leadingTypes, graduatedTypes) {
      const interviewTypesObjectArray = [];

      shadowingTypes.forEach((type) => {
        const interviewTypeObject = {
          id: type.id,
          name: type.interview_type_name ? type.interview_type_name : type,
          status: 'shadow',
          numberOfInterviews: type.number_of_interviews ? type.number_of_interviews : 0,
        };
        interviewTypesObjectArray.push(interviewTypeObject);
      });

      leadingTypes.forEach((type) => {
        const interviewTypeObject = {
          id: type.id,
          name: type.interview_type_name ? type.interview_type_name : type,
          status: 'lead',
          numberOfInterviews: type.number_of_interviews ? type.number_of_interviews : 0,
        };
        interviewTypesObjectArray.push(interviewTypeObject);
      });

      graduatedTypes.forEach((type) => {
        const interviewTypeObject = {
          name: type.interview_type_name ? type.interview_type_name : type,
          status: 'graduated',
          numberOfInterviews: type.number_of_interviews ? type.number_of_interviews : 0,
        };
        interviewTypesObjectArray.push(interviewTypeObject);
      });
      interviewTypesObjectArray.sort((interviewTypeA, interviewTypeB) =>
        interviewTypeA.name.localeCompare(interviewTypeB.name)
      );
      return interviewTypesObjectArray;
    },
    async graduateMenteeReq(interviewerId, interviewTypeId) {
      this.$emit('graduateMenteeReq', interviewerId, interviewTypeId);
    },
  },
};
</script>

<style scoped>
::v-deep .v-data-footer {
  margin-right: 0px !important;
}
::v-deep .v-data-table__expanded__content {
  position: relative;
}
::v-deep tbody tr :hover {
  cursor: pointer;
}
</style>
