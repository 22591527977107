import { render, staticRenderFns } from "./CompletedTable.vue?vue&type=template&id=c8784f62&scoped=true&"
import script from "./CompletedTable.vue?vue&type=script&lang=js&"
export * from "./CompletedTable.vue?vue&type=script&lang=js&"
import style0 from "./CompletedTable.vue?vue&type=style&index=0&id=c8784f62&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8784f62",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
installComponents(component, {VChip,VDataTable,VList,VListItem})
