import ScheduleInterview from '@/services/scheduleInterview';
import _clone from 'lodash.clonedeep';
import moment from 'moment-timezone';
import Moment from 'moment';
import SharedActions from '@store/modules/shared/actions';
import { GREENHOUSE_PARTIAL_URL, EMPTY_SLOT_NAME } from '@/constants';
import {
  getRandomColors,
  hasNullValues,
  currentDate,
  currentDatePlusSevenDays,
  getErrorMessage,
  getStartDatetimeInIsoFormat,
  getEndDatetimeInIsoFormat,
} from '@/helpers';

const setScheduleInterviewData = ({ state }, payload) => ({
  ...state.scheduleInterviewForm,
  ...payload,
});

const clearInterviewValue = ({ commit }, { key, value }) => {
  commit('SET_SCHEDULE_INTERVIEW_VALUE', {
    key,
    value,
  });
};

const setInterviewTypes = async ({ commit, dispatch }, id) => {
  dispatch('clearInterviewValue', { key: 'interviewType', value: null });
  const interviewTypes = await SharedActions.fetchInterviewTypes(id);
  commit('SET_INTERVIEW_TYPES', interviewTypes);
};

const setTechnologies = async ({ commit }) => {
  const technologies = await SharedActions.fetchTechnologies();
  commit('SET_TECHNOLOGIES', technologies);
};

const hasDiscTechnologies = ({ commit, dispatch }, { id }) => {
  dispatch('clearInterviewValue', { key: 'technology', value: null });
  let technologies = false;
  if (id === 1) {
    technologies = true;
  } else commit('SET_TECHNOLOGIES', []);
  commit('SET_SCHEDULE_INTERVIEW_VALUE', {
    key: 'hasDiscTechnologies',
    value: technologies,
  });
};

const setDiscipline = async ({ commit, dispatch }, discipline) => {
  commit('SET_SCHEDULE_INTERVIEW_VALUE', {
    key: 'discipline',
    value: discipline,
  });
  dispatch('setInterviewTypes', discipline.id);
  dispatch('hasDiscTechnologies', discipline);
};

const saveCandidate = async ({ state, commit }) => {
  try {
    const {
      candidateName: name,
      candidateLastName: lastName,
      candidateEmail: email,
      candidateJobName: jobName,
      greenhouseData,
      discipline,
    } = state.scheduleInterviewForm;

    const request = new ScheduleInterview.CreateCandidateApplicationServiceRequest({
      name,
      lastName,
      email,
      jobName,
      greenhouseApplicationId: greenhouseData.applicationId,
      greenhouseCandidateId: greenhouseData.candidateId,
      disciplineId: discipline.id,
    });
    const service = new ScheduleInterview.CreateCandidateApplicationService(request);
    const response = await service.do();
    commit('SET_CANDIDATE_ID', response.candidateId);
    commit('SET_APPLICATION_ID', response.applicationId);
  } catch (error) {
    console.error(error);
  }
};

const cloneAvailability = (el, index, eventParsed, commit, keyToSet) => {
  const element = el;
  if (index === eventParsed.index) {
    element.isSelected = true;
    commit('SET_CALENDAR_VALUE', { key: keyToSet, value: element });
  } else element.isSelected = false;
  return element;
};

const setCalendarForm = (el, index, eventParsed, state) => {
  if (index === eventParsed.index) {
    const { scheduleInterviewForm } = state;
    const { start } = el;
    const interviewDuration = scheduleInterviewForm.interviewType.duration;

    const end = Moment(start).add(interviewDuration, 'minutes').toDate();
    scheduleInterviewForm.calendar = {
      start,
      end,
    };
  }
};

const setSelectedSlot = ({ commit, state }, { eventParsed }) => {
  const { interviewerAvailability } = state;

  const availabilityClone = _clone(interviewerAvailability).filter((el) => el.calendarId !== null);
  const events = availabilityClone.map((el, index) => {
    setCalendarForm(el, index, eventParsed, state);
    return cloneAvailability(el, index, eventParsed, commit, 'selectedSlot');
  });
  commit('SET_CALENDAR_SLOTS', events);
};

/**
 * Set an empty slot as selected
 * @param {import('vuex').ActionContext} ActionContext
 */
const setSelectedEmptySlot = ({ commit, state }) => {
  const { interviewerAvailability } = state;
  const availabilityClone = _clone(interviewerAvailability);
  const indexSlot = availabilityClone.findIndex((el) => el.calendarId === null);
  const events = availabilityClone.map((el, index) => {
    setCalendarForm(el, index, { index: indexSlot }, state);
    return cloneAvailability(el, index, { index: indexSlot }, commit, 'selectedSlot');
  });
  commit('SET_CALENDAR_SLOTS', events);
};

const updateSetCalendarForm = ({ commit }, { key, value }) => {
  commit('SET_FORM_CALENDAR', { key, value });
};
const setSelectedReviewSlot = ({ commit, state }, { eventParsed }) => {
  const { interviewerAvailability } = state;
  const availabilityReviewClone = _clone(interviewerAvailability);
  availabilityReviewClone.map((el, index) => cloneAvailability(el, index, eventParsed, commit, 'selectedSlotReview'));
};

const CalendarEvent = function constructor({ name, email, start, end, isSelected, timed, color, calendarId }) {
  this.name = name;
  this.email = email;
  this.start = start;
  this.end = end;
  this.isSelected = isSelected;
  this.timed = timed;
  this.color = color;
  this.calendarId = calendarId;
};

/**
 * @param {import('vuex').ActionContext} ActionContext
 * @param {{
 * eventMoment: moment.Moment
 * }} eventTime
 */
const addEmptyCalendarSlot = ({ dispatch, commit, state }, eventTime) => {
  const { eventMoment } = eventTime;
  const colors = getRandomColors(1);
  const interviewDuration = state.scheduleInterviewForm.interviewType.duration;

  const emptyCalendarEvent = new CalendarEvent({
    name: EMPTY_SLOT_NAME,
    email: null,
    start: eventMoment.startOf('hour').toDate(),
    end: eventMoment.startOf('hour').add(interviewDuration, 'minutes').toDate(),
    isSelected: false,
    timed: true,
    color: colors[0],
    calendarId: null,
  });
  const copyCalendarEvents = _clone(state.interviewerAvailability).filter((el) => el.calendarId !== null);
  commit('SET_CALENDAR_SLOTS', [...copyCalendarEvents, emptyCalendarEvent]);
  dispatch('setSelectedEmptySlot');
};

const getInterviewerAvailability = async ({ state }) => {
  try {
    const { selectedInterviewers, dateRange } = state.scheduleInterviewForm;
    const startDatetime = getStartDatetimeInIsoFormat(dateRange[0]);
    const endDatetime = getEndDatetimeInIsoFormat(dateRange[1]);
    const interviewersEmails = selectedInterviewers.map((interviewer) => interviewer.email);

    const request = new ScheduleInterview.GetInterviewersInterviewSlotsServiceRequest({
      interviewersEmails,
      startDatetime,
      endDatetime,
    });

    const service = new ScheduleInterview.GetInterviewersInterviewSlotsService(request);
    const interviewersInterviewsSlots = await service.do();

    const colors = getRandomColors(interviewersInterviewsSlots.length);
    const calendarEvents = [];
    for (let i = 0; i < interviewersInterviewsSlots.length; i += 1) {
      const email = interviewersInterviewsSlots[i].interviewerEmail;
      const name = email.split('@')[0];

      for (let e = 0; e < interviewersInterviewsSlots[i].slots.length; e += 1) {
        const slot = interviewersInterviewsSlots[i].slots[e];
        calendarEvents.push(
          new CalendarEvent({
            name,
            email,
            start: new Date(slot.startDatetime),
            end: new Date(slot.endDatetime),
            isSelected: false,
            timed: true,
            color: colors[i],
            calendarId: email,
          })
        );
      }
    }

    return calendarEvents;
  } catch (e) {
    return [...state.interviewerAvailability];
  }
};

const initAvailableInterviewers = async ({ state }) => {
  try {
    const userTimeZone = moment.tz.guess();
    const {
      interviewType,
      dateRange,
      technology,
      includeInterviewersWithoutAvailableSlots,
      discipline,
    } = state.scheduleInterviewForm;

    const searchInterviewersServiceRequest = new ScheduleInterview.SearchInterviewersServiceRequest({
      interviewTypeId: interviewType.id,
      startDate: moment.tz(dateRange[0], userTimeZone).set('hour', 0).format(),
      endDate: dateRange[1] ? moment.tz(dateRange[1], userTimeZone).set('hour', 23).set('minute', 59).format() : null,
      technologyId: technology?.id,
      includeInterviewersWithoutAvailableSlots,
      disciplineId: discipline.id,
    });
    const searchInterviewersService = new ScheduleInterview.SearchInterviewersService(searchInterviewersServiceRequest);

    return await searchInterviewersService.do();
  } catch (e) {
    console.error(e);
    return [];
  }
};

const searchAvailableMentee = async ({ state, commit }) => {
  try {
    const { calendar } = state.scheduleInterviewForm;
    const userTimeZone = moment.tz.guess();
    const startDate = moment.tz(calendar.start, userTimeZone).format();
    const endDate = moment.tz(calendar.end, userTimeZone).format();
    commit('SET_MENTEE', null);

    const searchMenteesServiceRequest = new ScheduleInterview.SearchMenteesServiceRequest({
      interviewTypeId: state.scheduleInterviewForm.interviewType?.id,
      startDate,
      endDate,
      includeInterviewersWithoutAvailableSlots: state.scheduleInterviewForm.includeMenteesWithoutAvailableSlots,
      disciplineId: state.scheduleInterviewForm.discipline.id,
    });

    const searchMenteesService = new ScheduleInterview.SearchMenteesService(searchMenteesServiceRequest);

    const menteeData = await searchMenteesService.do();
    commit('SET_MENTEES', menteeData);
    commit('SET_MENTEE', menteeData[0]);
  } catch (e) {
    console.error(e);
    return [];
  }
};

const clearForm = () => ({
  candidateName: null,
  candidateLastName: null,
  candidateEmail: null,
  candidateJobName: null,
  dateRange: [currentDate, currentDatePlusSevenDays],
  candidateId: null,
  applicationId: null,
  selectedInterviewers: [],
  calendar: {
    end: null,
    start: null,
  },
  interviewer: null,
  interviewType: null,
  discipline: null,
  requiresTechnology: false,
  technology: null,
  level: null,
  greenhouseLink: null,
  includeInterviewersWithoutAvailableSlots: false,
  includeMenteesWithoutAvailableSlots: false,
  mentee: null,
  additionalInfo: null,
  greenhouseProfileLink: null,
  meetingType: 'zoom',
  greenhouseData: {
    candidateId: null,
    applicationId: null,
  },
});

const clearCalendar = () => ({
  selectedSlot: {
    color: null,
    end: null,
    isSelected: null,
    name: null,
    start: null,
    email: null,
  },
  focus: null,
});

const scheduleInterview = async ({ state }) => {
  try {
    const form = state.scheduleInterviewForm;

    const { calendar } = form;
    const userTimeZone = moment.tz.guess();
    const startDate = moment.tz(calendar.start, userTimeZone).format();
    const endDate = moment.tz(calendar.end, userTimeZone).format();

    const request = new ScheduleInterview.ScheduleInterviewServiceRequest({
      greenhouseLink: form.greenhouseLink,
      startDatetime: startDate,
      endDatetime: endDate,
      interviewTypeId: form.interviewType.id,
      candidateId: form.candidateId,
      interviewerId: form.interviewer.id,
      disciplineId: form.discipline.id,
      menteeId: form.mentee?.id,
      technologyId: form.technology?.id,
      expectedLevelId: form.level.id,
      additionalInformation: form.additionalInfo,
      meetingType: form.meetingType,
      applicationId: form.applicationId,
    });
    const service = new ScheduleInterview.ScheduleInterviewService(request);
    return await service.do();
  } catch (error) {
    return console.error(error);
  }
};

const validateForm = ({ state }, page) => {
  const form = state.scheduleInterviewForm;
  let isValid;
  switch (page) {
    case 1:
      isValid =
        !hasNullValues({
          candidateName: form.candidateName,
          candidateLastName: form.candidateLastName,
          candidateEmail: form.candidateEmail,
          dateRange: form.dateRange,
          discipline: form.discipline,
          interviewType: form.interviewType,
          expectedLevel: form.level,
          greenhouseLink: form.greenhouseLink,
          technology: !form.requiresTechnology || form.technology,
        }) && form.dateRange.length === 2;
      break;
    case 2:
      isValid = form.selectedInterviewers.length > 0;
      break;
    case 3:
      isValid = true;
      break;
    case 4:
      isValid = true;
      break;
    default:
      isValid = false;
      break;
  }
  return isValid;
};

const setCalendarFocus = ({ commit }, date) => {
  commit('SET_CALENDAR_VALUE', { key: 'focus', value: date });
};

const setCandidateInfoFromGreenhouse = ({ commit }, { firstName, lastName, email, jobName }) => {
  commit('SET_CANDIDATE_INFO', { key: 'candidateName', value: firstName });
  commit('SET_CANDIDATE_INFO', { key: 'candidateLastName', value: lastName });
  commit('SET_CANDIDATE_INFO', { key: 'candidateEmail', value: email });
  commit('SET_CANDIDATE_INFO', { key: 'candidateJobName', value: jobName });
};
const handleGreenhouseErrorResponse = (error) => {
  const alertInfo = {
    type: 'error',
    message: '',
    show: true,
  };
  let errorCode = error;
  if (error.data) {
    errorCode = error.data.message?.split('(')[0];
  }
  alertInfo.message = getErrorMessage(errorCode);
  return alertInfo;
};
const validateGreenhouseLink = (url) => {
  let error = 'generalError';
  const greenhouseUrl = new URL(GREENHOUSE_PARTIAL_URL);

  if (url && url.includes(greenhouseUrl)) {
    let greenhouseData;
    const params = new URL(url).searchParams;
    if (params.has('application_id')) {
      greenhouseData = {
        applicationId: params.get('application_id'),
        candidateId: url.split(GREENHOUSE_PARTIAL_URL)[1].split('?')[0],
      };
    } else {
      const remainingUrl = url.slice(greenhouseUrl.origin.length);
      const candidateIdMatch = remainingUrl.match(/people\/(\d+)/);
      const applicationIdMatch = remainingUrl.match(/applications\/(\d+)/);
      greenhouseData = {
        candidateId: candidateIdMatch ? candidateIdMatch[1] : null,
        applicationId: applicationIdMatch ? applicationIdMatch[1] : null,
      };
    }
    if (greenhouseData) {
      if (!/^\d+$/.test(greenhouseData.applicationId) || !/^\d+$/.test(greenhouseData.candidateId)) {
        error = 'invalidURL';
      } else {
        return greenhouseData;
      }
    }
  } else {
    error = 'invalidURL';
  }
  throw error;
};
const clearInfoWhenGreenhouseEndpoint = ({ commit }) => {
  commit('SET_CANDIDATE_INFO', { key: 'candidateName', value: null });
  commit('SET_CANDIDATE_INFO', { key: 'candidateLastName', value: null });
  commit('SET_CANDIDATE_INFO', { key: 'candidateEmail', value: null });
  commit('SET_CANDIDATE_INFO', { key: 'candidateJobName', value: null });
  commit('SET_CANDIDATE_INFO', { key: 'interviewType', value: null });
  commit('SET_CANDIDATE_INFO', { key: 'discipline', value: null });
  commit('SET_CANDIDATE_INFO', { key: 'level', value: null });
  commit('SET_CANDIDATE_INFO', { key: 'greenhouseLink', value: null });
  commit('SET_CANDIDATE_INFO', { key: 'greenhouseLink', value: false });
  commit('SET_GREENHOUSE_DATA', { key: 'candidateId', value: null });
  commit('SET_GREENHOUSE_DATA', { key: 'applicationId', value: null });
  commit('SET_INTERVIEW_TYPES', []);
};

const getCandidateInfoFromGreenhouse = async ({ state, dispatch, commit }) => {
  try {
    dispatch('clearInfoWhenGreenhouseEndpoint');
    const { greenhouseProfileLink } = state.scheduleInterviewForm;
    const { applicationId, candidateId } = validateGreenhouseLink(greenhouseProfileLink);
    if (applicationId && candidateId) {
      commit('SET_GREENHOUSE_DATA', { key: 'candidateId', value: candidateId });
      commit('SET_GREENHOUSE_DATA', { key: 'applicationId', value: applicationId });
    }

    const request = new ScheduleInterview.GetCandidateInfoServiceRequest({
      candidateId: state.scheduleInterviewForm.greenhouseData?.candidateId,
      applicationId: state.scheduleInterviewForm.greenhouseData?.applicationId,
    });
    const service = new ScheduleInterview.GetCandidateInfoService(request);
    const response = await service.do();
    const { firstName, lastName, email, discipline, jobName } = response;
    dispatch('setCandidateInfoFromGreenhouse', { firstName, lastName, email, jobName });
    if (discipline) {
      const { disciplines } = state;
      const founded = disciplines.find((item) => item.id === discipline.discipline_id);
      if (founded) {
        dispatch('setDiscipline', founded);
      }
    }
    return response;
  } catch (error) {
    const alertInfo = handleGreenhouseErrorResponse(error);
    commit('SET_ALERT', { ...alertInfo });
    throw new Error(error);
  }
};
const getGreenhouseURLInfo = async ({ state, commit }) => {
  try {
    const form = state.scheduleInterviewForm;
    const request = new ScheduleInterview.GetGreenhouseURLInfoServiceRequest({
      candidateId: form.greenhouseData.candidateId,
      applicationId: form.greenhouseData.applicationId,
      interviewType: form.interviewType.name,
    });
    const service = new ScheduleInterview.GetGreenhouseURLInfoService(request);
    const response = await service.do();

    commit('SET_SCHEDULE_INTERVIEW_VALUE', { key: 'greenhouseLink', value: response.url });
    return response;
  } catch (error) {
    const alertInfo = handleGreenhouseErrorResponse(error);
    commit('SET_ALERT', { ...alertInfo });
    throw new Error(error);
  }
};

export default {
  ...SharedActions,
  getInterviewerAvailability,
  setSelectedSlot,
  addEmptyCalendarSlot,
  setSelectedEmptySlot,
  setSelectedReviewSlot,
  setScheduleInterviewData,
  saveCandidate,
  initAvailableInterviewers,
  searchAvailableMentee,
  scheduleInterview,
  setTechnologies,
  validateForm,
  clearForm,
  clearCalendar,
  clearInfoWhenGreenhouseEndpoint,
  setCalendarFocus,
  setDiscipline,
  setInterviewTypes,
  hasDiscTechnologies,
  clearInterviewValue,
  setCalendarForm,
  updateSetCalendarForm,
  getCandidateInfoFromGreenhouse,
  setCandidateInfoFromGreenhouse,
  getGreenhouseURLInfo,
};
