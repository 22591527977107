<template>
  <v-dialog v-model="dialogLoading" :scrim="false" persistent max-width="500px" data-test-id="loading-dialog">
    <v-card min-height="200" height="auto" class="d-flex align-center">
      <v-card-text class="text-center pt-5">
        <v-icon :color="color" size="60px">{{ icon }}</v-icon>
        <p class="modal-text">{{ loadingText }}</p>
        <v-progress-circular v-if="showLoaderBar" indeterminate size="44" color="primary"></v-progress-circular>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: Boolean,
    loadingText: {
      type: String,
      default: '',
    },
    showLoaderBar: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: 'mdi-information-slab-circle',
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      dialogLoading: this.value,
    };
  },
  watch: {
    value(newVal) {
      this.dialogLoading = newVal;
    },
  },
};
</script>
<style scoped>
.modal-text {
  margin-top: 16px;
  font-size: 1.2rem;
}
</style>
