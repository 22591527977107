const SET_CALENDAR_SLOTS = (state, events) => {
  state.interviewerAvailability = events;
};

const SET_CANDIDATE_INFO = (state, { key, value }) => {
  state.scheduleInterviewForm[key] = value;
};

const SET_CANDIDATE_ID = (state, id) => {
  state.scheduleInterviewForm.candidateId = id;
};
const SET_APPLICATION_ID = (state, id) => {
  state.scheduleInterviewForm.applicationId = id;
};

const SET_CALENDAR_VALUE = (state, { key, value }) => {
  state.calendar[key] = value;
};

const SET_SCHEDULE_INTERVIEW_VALUE = (state, { key, value }) => {
  state.scheduleInterviewForm[key] = value;
};

const SET_INTERVIEW_TYPES = (state, types) => {
  state.interviewTypes = types;
};

const SET_TECHNOLOGIES = (state, technologies) => {
  state.technologies = technologies;
};

const SET_MENTEE = (state, menteeInfo) => {
  state.scheduleInterviewForm.mentee = menteeInfo;
};

const SET_MENTEES = (state, mentees) => {
  state.mentees = mentees;
};

const SET_FORM_CALENDAR = (state, { key, value }) => {
  state.scheduleInterviewForm.calendar[key] = value;
};

const SET_GREENHOUSE_DATA = (state, { key, value }) => {
  state.scheduleInterviewForm.greenhouseData[key] = value;
};

const SET_ALERT = (state, alert) => {
  state.alert = alert;
};

export default {
  SET_CALENDAR_SLOTS,
  SET_CANDIDATE_INFO,
  SET_CANDIDATE_ID,
  SET_APPLICATION_ID,
  SET_CALENDAR_VALUE,
  SET_SCHEDULE_INTERVIEW_VALUE,
  SET_INTERVIEW_TYPES,
  SET_TECHNOLOGIES,
  SET_MENTEE,
  SET_MENTEES,
  SET_FORM_CALENDAR,
  SET_GREENHOUSE_DATA,
  SET_ALERT,
};
