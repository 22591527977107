<template>
  <v-btn-toggle v-model="innerValue" v-bind="$attrs" class="d-flex flex-row" v-on="$listeners">
    <v-btn
      v-for="(btn, index) in buttons"
      :key="index"
      :value="btn.value"
      :height="$attrs.dense !== undefined ? '40' : null"
      :class="{ col: $attrs.block !== undefined }"
      :data-test-id="`${btn.value}-option`"
    >
      <v-icon v-if="btn.icon" style="color: inherit;" left>{{ btn.icon }}</v-icon>
      {{ btn.text }}
    </v-btn>
    <!-- @slot Slot to manually insert buttons -->
    <slot></slot>
  </v-btn-toggle>
</template>

<script>
export default {
  props: {
    /**
     * Value set by the parent component.
     * @ignore
     */
    value: {
      type: null,
      default: null,
    },
    /**
     * The button's data required to display them, icons are optional.
     * @example <caption>With icons</caption>
     * [{ value: 'a', text: 'A', icon: 'mdi-a' }, { value: 'b', text: 'B', icon: 'mdi-b' }]
     */
    buttons: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(value) {
        /**
         * Emit the input event to the parent component.
         */
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style></style>
