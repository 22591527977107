import chroma from 'chroma-js';
import { CALENDAR_PALETTE, ERRORS_DICTIONARY } from '@/constants';

const getRandomColors = (total) => chroma.scale(CALENDAR_PALETTE).mode('lch').colors(total);

const currentDate = new Date().toISOString().substring(0, 10);
const tempCurrentDatePlusSevenDays = new Date();
tempCurrentDatePlusSevenDays.setDate(tempCurrentDatePlusSevenDays.getDate() + 7);
const currentDatePlusSevenDays = tempCurrentDatePlusSevenDays.toISOString().substring(0, 10);

const hasNullValues = (obj) => {
  const nullValues = Object.values(obj).filter((e) => !e);
  return nullValues.length > 0;
};

const getErrorMessage = (errorCode) => {
  return ERRORS_DICTIONARY[errorCode];
};

const parseJwt = (token) => {
  try {
    return JSON.parse(Buffer.from(token.split('.')[1], 'base64'));
  } catch (e) {
    return null;
  }
};

const getStartDatetimeInIsoFormat = (date) => {
  const [year, month, day] = date.split('-');
  const dateTime = new Date(year, month - 1, day, 0, 0, 0, 0);
  return dateTime.toISOString();
};

const getEndDatetimeInIsoFormat = (date) => {
  const [year, month, day] = date.split('-');
  const dateTime = new Date(year, month - 1, day, 23, 59, 59, 999);
  return dateTime.toISOString();
};

function formatTwoDatesAsTimeRange(isoDateStart, isoDateEnd) {
  const dateStart = new Date(isoDateStart);
  const dateEnd = new Date(isoDateEnd);
  const hhStart = String(dateStart.getUTCHours()).padStart(2, '0');
  const mmStart = String(dateStart.getUTCMinutes()).padStart(2, '0');
  const hhEnd = String(dateEnd.getUTCHours()).padStart(2, '0');
  const mmEnd = String(dateEnd.getUTCMinutes()).padStart(2, '0');
  const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const day = dayNames[dateStart.getUTCDay()];
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const month = monthNames[dateStart.getUTCMonth()];
  const dd = String(dateStart.getUTCDate()).padStart(2, '0');
  const yyyy = dateStart.getUTCFullYear();
  return `${hhStart}:${mmStart} - ${hhEnd}:${mmEnd}, ${day}, ${month} ${dd}, ${yyyy}`;
}

const createQueryString = (requetsParams) => {
  const queryString = Object.keys(requetsParams)
    .map((key) => {
      const paramsToArray = [];
      if (Array.isArray(requetsParams[key])) {
        const paramsArray = requetsParams[key];
        const paramsArrayToQuery = [];
        paramsArray.forEach((item) => {
          paramsArrayToQuery.push(`${encodeURIComponent(key)}[]=${encodeURIComponent(item)}`);
        });
        paramsToArray.push(paramsArrayToQuery.join('&'));
      } else {
        paramsToArray.push(`${encodeURIComponent(key)}=${requetsParams[key]}`);
      }
      return paramsToArray;
    })
    .join('&');
  return queryString;
};

export {
  getRandomColors,
  currentDate,
  currentDatePlusSevenDays,
  hasNullValues,
  getErrorMessage,
  parseJwt,
  getStartDatetimeInIsoFormat,
  getEndDatetimeInIsoFormat,
  createQueryString,
  formatTwoDatesAsTimeRange,
};
