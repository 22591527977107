const interviewerTechnologiesMock = [
  { id: 3, name: 'JavaScript' },
  { id: 6, name: 'TypeScript' },
  { id: 7, name: 'Angular' },
];

const putTechnologiesRequest = [
  { id: 6, name: 'TypeScript' },
  { id: 7, name: 'Angular' },
];

const getInterviewersRequest = {
  interviewers_graduated: false,
  interviewers_leading: false,
  interviewers_shadowing: false,
};

const getInterviewersResponse = [
  {
    id: 1,
    full_name: 'Enrique De La Fuente',
    email: 'enrique.delafuente@wizeline.com',
    is_active: true,
    discipline: 'Software Engineering',
    technologies: ['JavaScript', 'TypeScript', 'Vue.js', 'React', 'Node', 'Java'],
    shadowing_interview_types: [{ interview_type_name: 'Tech Assessment', number_of_interviews: 12, id: 1 }],
    leading_interview_types: [{ interview_type_name: 'Design', number_of_interviews: 8, id: 2 }],
    graduated_interview_types: ['Pair Programming'],
    region: 'Remote-Mexico',
    availability: 'high',
    current_week_interviews_scheduled_or_done: 4,
    last_completed_interview: '2022-11-07T18:00:00',
    last_7_days_completed_interviews: 5,
    last_30_days_completed_interviews: 15,
    last_180_days_completed_interviews: 30,
  },
  {
    id: 2,
    full_name: 'Leslie Figueroa',
    email: 'leslie.figueroa@wizeline.com',
    is_active: true,
    discipline: 'Software Engineering',
    technologies: ['JavaScript', 'Vue.js', 'React'],
    shadowing_interview_types: [{ interview_type_name: 'Design', number_of_interviews: 2, id: 2 }],
    leading_interview_types: [{ interview_type_name: 'Tech Assessment', number_of_interviews: 1, id: 1 }],
    graduated_interview_types: ['Pair Programming'],
    region: 'Remote-Mexico',
    availability: 'gt-turbo',
    current_week_interviews_scheduled_or_done: 4,
    last_interview_completed: '2022-11-06T18:00:00',
    last_7_days_completed_interviews: 0,
    last_30_days_completed_interviews: 0,
    last_180_days_completed_interviews: 0,
  },
  {
    id: 3,
    full_name: 'Felipe Ruíz García',
    email: 'felipe.ruiz@wizeline.com',
    is_active: true,
    discipline: 'Site Reliability Engineering',
    technologies: [],
    leading_interview_types: [],
    shadowing_interview_types: [
      {
        interview_type_name: 'Design',
        number_of_interviews: 0,
        id: 2,
      },
      {
        interview_type_name: 'DevOps Mentorship',
        number_of_interviews: 0,
        id: 5,
      },
    ],
    graduated_interview_types: [],
    region: 'Barcelona-Spain',
    availability: 'normal',
    current_week_interviews_scheduled_or_done: 0,
    last_completed_interview: null,
    last_7_days_completed_interviews: 0,
    last_30_days_completed_interviews: 0,
    last_180_days_completed_interviews: 0,
  },
];

const getInterviewersSlotsHousResponse = {
  current_week_slots_hours: 4.0,
  next_week_slots_hours: 0,
};

const interviews = [
  {
    interview_id: 7,
    candidate_name: 'Omar Emmanuel Zaragoza Figueroa',
    status: {
      id: 2,
      name: 'Completed',
    },
    type: {
      id: 3,
      name: 'Pair Programming',
    },
    interviewer: {
      id: 234,
      full_name: 'Jaime Zamora',
      email: 'jaime.zamora@wizeline.com',
    },
    mentee: {
      id: 69,
      full_name: 'Ricardo Escobedo Solares',
      email: 'ricardo.escobedo@wizeline.com',
    },
    interview_date: {
      start: '2022-01-28T21:00:00',
      end: '2022-01-28T22:00:00',
    },
  },
  {
    interview_id: 28,
    candidate_name: 'Miryam Quezada Aguirre',
    status: {
      id: 2,
      name: 'Completed',
    },
    type: {
      id: 3,
      name: 'Pair Programming',
    },
    interviewer: {
      id: 48,
      full_name: 'Noé López Hernández',
      email: 'noe.lopez@wizeline.com',
    },
    mentee: {
      id: 234,
      full_name: 'Jaime Zamora',
      email: 'jaime.zamora@wizeline.com',
    },
    interview_date: {
      start: '2022-02-04T22:00:00',
      end: '2022-02-04T23:00:00',
    },
  },
  {
    interview_id: 32,
    candidate_name: 'Alejandro Ortiz',
    status: {
      id: 2,
      name: 'Completed',
    },
    type: {
      id: 2,
      name: 'Design',
    },
    interviewer: {
      id: 234,
      full_name: 'Jaime Zamora',
      email: 'jaime.zamora@wizeline.com',
    },
    mentee: {
      id: 95,
      full_name: 'Hiram Torres',
      email: 'hiram.torres@wizeline.com',
    },
    interview_date: {
      start: '2022-02-08T23:00:00',
      end: '2022-02-09T00:00:00',
    },
  },
];

const getInterviewerSettingsServiceResponse = {
  email: 'enrique.delafuente@wizeline.com',
  availability: 'normal',
  level: {
    id: 1,
    name: 'Level I',
  },
  graduated_interview_types: ['Pair Programming'],
  shadowing_interview_types: [],
  leading_interview_types: [
    { interview_type_name: 'Tech Assessment', number_of_interviews: 1 },
    { interview_type_name: 'Design', number_of_interviews: 2 },
  ],
  current_year_completed_interviews: 18,
  last_year_completed_interviews: 56,
  interviews,
};

export {
  interviewerTechnologiesMock,
  putTechnologiesRequest,
  getInterviewersRequest,
  getInterviewersResponse,
  getInterviewersSlotsHousResponse,
  getInterviewerSettingsServiceResponse,
};
