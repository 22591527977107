import Interviewers from '@/pages/Interviewers.vue';
import HttpStatusCodes from '@/services/HttpStatusCodes';
import { createLocalVue, mount, RouterLinkStub } from '@vue/test-utils';
import { required } from 'vee-validate/dist/rules.umd';
import { extend } from 'vee-validate';
import moment from 'moment';
import Vuetify from 'vuetify';
import { setupServer } from 'msw/node';
import { rest } from 'msw';
import {
  getDisciplinesSuccessResponse,
  getRegionsSuccessResponse,
  getTechnologiesSuccessResponse,
  getInterviewTypesSuccessResponse,
  getCountries,
} from '@/constants/tests/shared.mock';
import TestingUtils from '../../../tests/TestingUtils';
import {
  getInterviewersResponse,
  getInterviewersSlotsHousResponse,
  getInterviewersRequest,
} from './mocks/interviewers.mock';

extend('required', required);

describe('Interviewers', () => {
  /**
   * @param {function} select
   * @param {Vue} localVue
   */
  const openSelectList = async (select, localVue) => {
    select().trigger('click');
    await localVue.nextTick();
  };

  /**
   * @param {function} openLists
   * @param {Number} listIndex
   * @param {Number} elementIndex
   * @param {Vue} localVue
   */
  const selectElementFromOpenedLists = async (openLists, listIndex, elementIndex, localVue) => {
    openLists(listIndex).at(elementIndex).trigger('click');
    await localVue.nextTick();
  };

  const localVue = createLocalVue();
  const vuetify = new Vuetify();
  document.body.setAttribute('data-app', true);
  const mockRouter = {
    push: jest.fn(),
  };
  const server = setupServer();

  const makeGetDisciplinesRequestInterceptor = () => {
    server.use(
      rest.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/disciplines`, (req, res, ctx) => {
        return res(ctx.status(HttpStatusCodes.OK), ctx.json(getDisciplinesSuccessResponse));
      })
    );
  };

  const makeGetInterviewTypesRequestInterceptor = (selectedDisciplineId) => {
    server.use(
      rest.get(
        `${process.env.VUE_APP_BACKEND_ENDPOINT}/disciplines/${selectedDisciplineId}/interview-types`,
        (req, res, ctx) => {
          return res(ctx.status(HttpStatusCodes.OK), ctx.json(getInterviewTypesSuccessResponse));
        }
      )
    );
  };

  const makeGetRegionsRequestInterceptor = () => {
    server.use(
      rest.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/cities`, (req, res, ctx) => {
        return res(ctx.status(HttpStatusCodes.OK), ctx.json(getRegionsSuccessResponse));
      })
    );
  };

  const makeGetTechnologiesRequestInterceptor = () => {
    server.use(
      rest.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/technologies`, (req, res, ctx) => {
        return res(ctx.status(HttpStatusCodes.OK), ctx.json(getTechnologiesSuccessResponse));
      })
    );
  };

  const makeGetInterviewersRequestInterceptor = (apiRequestData, apiResponseData) => {
    server.use(
      rest.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/interviewers`, (req, res, ctx) => {
        req.url.searchParams.set('interviewers_graduated', apiRequestData.interviewers_graduated);
        req.url.searchParams.append('interviewers_leading', apiRequestData.interviewers_leading);
        req.url.searchParams.append('interviewers_shadowing', apiRequestData.interviewers_shadowing);
        req.url.searchParams.append('region_id', apiRequestData.region_id);
        req.url.searchParams.append('discipline_id', apiRequestData.discipline_id);
        req.url.searchParams.append('technology_id', apiRequestData.technology_id);
        req.url.searchParams.append('interview_type_id', apiRequestData.interview_type_id);
        return res(ctx.status(HttpStatusCodes.OK), ctx.json(apiResponseData));
      })
    );
  };

  const makeGetInterviewersSlotsHoursInterceptor = (interviewerId) => {
    server.use(
      rest.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/interviewers/${interviewerId}/slots-hours`, (req, res, ctx) => {
        return res(ctx.status(HttpStatusCodes.OK), ctx.json(getInterviewersSlotsHousResponse));
      })
    );
  };

  const makePutGraduateInterviewerInterceptor = (apiRequestData, apiResponseData) => {
    server.use(
      rest.put(`${process.env.VUE_APP_BACKEND_ENDPOINT}/graduate-mentee`, (req, res, ctx) => {
        req.json(apiRequestData);
        return res(ctx.status(HttpStatusCodes.OK), ctx.json(apiResponseData));
      })
    );
  };
  const waitForGetDisciplinesAPIRequest = async () => {
    await TestingUtils.waitForAPIRequestNext();
  };

  const waitForGetInterviewTypesAPIRequest = async () => {
    await TestingUtils.waitForAPIRequestNext();
    await TestingUtils.waitForAPIRequestNext();
  };

  const waitForGetRegionsAPIRequest = async () => {
    await TestingUtils.waitForAPIRequestNext();
  };

  const waitForGetTechnologiesAPIRequest = async () => {
    await TestingUtils.waitForAPIRequestNext();
  };

  const waitForGetInterviewersAPIRequest = async () => {
    await TestingUtils.waitForAPIRequestNext();
    await TestingUtils.waitForAPIRequestNext();
  };
  const waitForGetInterviewersSlotHoursAPIRequest = async () => {
    await TestingUtils.waitForAPIRequestNext();
  };
  const waitForPutGraduateMenteeAPIRequest = async () => {
    await TestingUtils.waitForAPIRequestNext();
  };
  const build = () => {
    const wrapper = mount(Interviewers, {
      localVue,
      vuetify,
      stubs: {
        'router-link': RouterLinkStub,
      },
      mocks: {
        $router: mockRouter,
      },
    });

    return {
      interviewersRows: () => wrapper.findAll('[data-test-id="interviewers-table"] tbody tr'),
      interviewerRowExpanded: () =>
        wrapper.find('[data-test-id="interviewers-table"] tbody tr.v-data-table__expanded__content'),
      currentWeekSlotsHours: () => wrapper.find('[data-test-id="current-week-slots-hours"]'),
      nextWeekSlotsHours: () => wrapper.find('[data-test-id="next-week-slots-hours"]'),
      interviewType: () => wrapper.findAll('[data-test-id="interview-type-tag"]'),
      lastInterviewContainer: () => wrapper.find('[data-test-id="last-completed-interview"]'),
      last7DaysInterviewsContainer: () => wrapper.find('[data-test-id="last-7-days-completed-inerviews"]'),
      last30DaysInterviewsContainer: () => wrapper.find('[data-test-id="last-30-days-completed-inerviews"]'),
      last180DaysInterviewsContainer: () => wrapper.find('[data-test-id="last-180-days-completed-inerviews"]'),
      currentWeekInterviewsContianer: () => wrapper.find('[data-test-id="current-week-interviews"]'),
      availabilityContainer: () => wrapper.find('[data-test-id="availability-per-week"]'),
      openLists: (index) => wrapper.findAll('.menuable__content__active').at(index).findAll('.v-list-item'),
      multipleMenuOpenLists: () => wrapper.find('.menuable__content__active').findAll('.v-list-item--link'),
      sumbmenuMultipleLists: () => wrapper.find('.menuable__content__active').find('.v-list-group--active'),
      regionsDropdown: () => wrapper.find('[data-test-id="regions-dropdown"]'),
      statusDropdown: () => wrapper.find('[data-test-id="status-dropdown"]'),
      disciplinesDropdown: () => wrapper.find('[data-test-id="disciplines-dropdown"]'),
      technologiesDropdown: () => wrapper.find('[data-test-id="technologies-dropdown"]'),
      searchInterviewers: () => wrapper.find('[data-test-id="search-interviewers-input"]'),
      technologyChips: () => wrapper.find('[data-test-id="tech-chips-container"]').findAll('span.v-chip'),
      clearFiltersButton: () => wrapper.find('[data-test-id="clear-filters-button"]'),
      modalGraduateMentee: () => wrapper.find('[data-test-id="modal-graduate-mentee"]'),
      snackbar: () => wrapper.find('[data-test-id="snackbar"]'),
      saveButton: () => wrapper.find('[data-test-id="save-button"]'),
      wrapper,
    };
  };

  const initialEndpointsCall = async () => {
    makeGetInterviewersRequestInterceptor(getInterviewersRequest, getInterviewersResponse);
    makeGetDisciplinesRequestInterceptor();
    getDisciplinesSuccessResponse.forEach(async (discipline) => {
      makeGetInterviewTypesRequestInterceptor(discipline.id);
      await waitForGetInterviewTypesAPIRequest();
    });
    makeGetRegionsRequestInterceptor();
    makeGetTechnologiesRequestInterceptor();
  };

  const waitTimeForInitialEndpoinsCall = async () => {
    await waitForGetInterviewersAPIRequest();
    await waitForGetDisciplinesAPIRequest();
    await waitForGetRegionsAPIRequest();
    await waitForGetTechnologiesAPIRequest();
  };

  it('renders interviewers, and filters endpoints in Interviewers page', async () => {
    const unhandledRequests = [];
    await initialEndpointsCall();
    server.listen({
      onUnhandledRequest(req) {
        unhandledRequests.push({
          method: req.method,
          url: req.url.href,
        });
        console.error(unhandledRequests.slice(-1));
      },
    });
    const { interviewersRows } = build();
    await waitTimeForInitialEndpoinsCall();

    await localVue.nextTick();
    expect(unhandledRequests).toHaveLength(0);
    expect(interviewersRows()).toHaveLength(getInterviewersResponse.length);
    getInterviewersResponse.forEach((interviewer, interviewerIndex) => {
      const expectedInterviewRow = interviewersRows().at(interviewerIndex);
      const expectedInterviewRowRegion = interviewer.region.split('-')[1].toLowerCase();
      expect(expectedInterviewRow.html()).toContain(getCountries[expectedInterviewRowRegion]);
      expect(expectedInterviewRow.text()).toContain(interviewer.full_name);
      expect(expectedInterviewRow.text()).toContain(interviewer.email);
      expect(expectedInterviewRow.text()).toContain(interviewer.discipline);
    });
  });

  it('Shows shadow, leading, and graduated types correctly', async () => {
    const unhandledRequests = [];
    await initialEndpointsCall();
    server.listen({
      onUnhandledRequest(req) {
        unhandledRequests.push({
          method: req.method,
          url: req.url.href,
        });
        console.error(unhandledRequests.slice(-1));
      },
    });
    const { interviewType } = build();
    await waitTimeForInitialEndpoinsCall();

    await localVue.nextTick();

    expect(unhandledRequests).toHaveLength(0);
    getInterviewersResponse.forEach((interviewer, interviewerIndex) => {
      const expectedInterviewType = interviewType().at(interviewerIndex).text();

      interviewer.shadowing_interview_types.forEach((shadowInterview) => {
        expect(expectedInterviewType).toContain(shadowInterview.interview_type_name);
        expect(expectedInterviewType).toContain('Mentee: shadowing');
        expect(expectedInterviewType).toContain(`(${shadowInterview.number_of_interviews})`);
      });
      interviewer.leading_interview_types.forEach((leadInterview) => {
        expect(expectedInterviewType).toContain(leadInterview.interview_type_name);
        expect(expectedInterviewType).toContain('Mentee: leading');
        expect(expectedInterviewType).toContain(`(${leadInterview.number_of_interviews})`);
      });
      interviewer.graduated_interview_types.forEach((graduatedInterview) => {
        expect(expectedInterviewType).toContain(graduatedInterview);
        expect(expectedInterviewType).toContain('graduated');
      });
    });
  });

  it('render /interviewers/id/slots-hours endpoint', async () => {
    await initialEndpointsCall();
    makeGetInterviewersSlotsHoursInterceptor(getInterviewersResponse[0].id);

    const unhandledRequests = [];
    server.listen({
      onUnhandledRequest(req) {
        unhandledRequests.push({
          method: req.method,
          url: req.url.href,
        });
        console.error(unhandledRequests.slice(-1));
      },
    });

    const {
      interviewersRows,
      currentWeekSlotsHours,
      nextWeekSlotsHours,
      lastInterviewContainer,
      last7DaysInterviewsContainer,
      last30DaysInterviewsContainer,
      last180DaysInterviewsContainer,
      currentWeekInterviewsContianer,
      availabilityContainer,
    } = build();

    await waitTimeForInitialEndpoinsCall();

    await localVue.nextTick();
    const firstRow = interviewersRows().at(0);
    expect(firstRow.classes('')).not.toContain('v-data-table__expanded__row');

    await interviewersRows().at(0).trigger('click');
    await localVue.nextTick();
    await waitForGetInterviewersSlotHoursAPIRequest();

    const lastCompletedInterview = getInterviewersResponse[0].last_completed_interview;
    const last7DaysInterviews = getInterviewersResponse[0].last_7_days_completed_interviews;
    const last30DaysInterviews = getInterviewersResponse[0].last_30_days_completed_interviews;
    const last180DaysInterviews = getInterviewersResponse[0].last_180_days_completed_interviews;
    const currentWeekInterviews = getInterviewersResponse[0].current_week_interviews_scheduled_or_done;
    const currentHours = getInterviewersSlotsHousResponse.current_week_slots_hours;
    const nextWeekHours = getInterviewersSlotsHousResponse.next_week_slots_hours;

    expect(unhandledRequests).toHaveLength(0);
    expect(firstRow.classes()).toContain('v-data-table__expanded__row');

    expect(lastInterviewContainer().text()).toContain(moment(lastCompletedInterview).format('MMM DD, YYYY'));
    expect(last7DaysInterviewsContainer().text()).toContain(`${last7DaysInterviews} interviews`);
    expect(last30DaysInterviewsContainer().text()).toContain(`${last30DaysInterviews} interviews`);
    expect(last180DaysInterviewsContainer().text()).toContain(`${last180DaysInterviews} interviews`);
    expect(currentWeekInterviewsContianer().text()).toContain(`${currentWeekInterviews} interview(s) this week`);
    expect(availabilityContainer().text()).toContain('No more interviews this week');

    expect(currentWeekSlotsHours().text()).toContain(`${currentHours} hours`);
    expect(nextWeekSlotsHours().text()).toContain(`${nextWeekHours} hours`);
  }, 5000);

  it('filters by region and renders get interviewers endpoint then clears filters', async () => {
    await initialEndpointsCall();

    let indexOfTheLastOpenedList = -1;
    const unhandledRequests = [];
    server.listen({
      onUnhandledRequest(req) {
        unhandledRequests.push({
          method: req.method,
          url: req.url.href,
        });
        console.error(unhandledRequests.slice(-1));
      },
    });

    const {
      interviewersRows,
      regionsDropdown,
      clearFiltersButton,
      multipleMenuOpenLists,
      sumbmenuMultipleLists,
    } = build();
    await waitTimeForInitialEndpoinsCall();

    const regionSelected = {
      name: 'Mexico',
      index: 0,
    };
    const regionsFiltered = getRegionsSuccessResponse.filter((region) => region.country_name === regionSelected.name);

    expect(unhandledRequests).toHaveLength(0);
    expect(interviewersRows()).toHaveLength(getInterviewersResponse.length);
    expect(clearFiltersButton().element.disabled).toBe(true);

    await openSelectList(regionsDropdown, localVue);
    await localVue.nextTick();

    indexOfTheLastOpenedList += 1;

    await selectElementFromOpenedLists(multipleMenuOpenLists, indexOfTheLastOpenedList, regionSelected.index, localVue);
    await localVue.nextTick();

    const submenuList = sumbmenuMultipleLists().find('.v-list-group__items').findAll('.v-list-item__action');
    const countryCheckbox = multipleMenuOpenLists().at(regionSelected.index).find('input[type="checkbox"]');

    expect(submenuList).toHaveLength(regionsFiltered.length);
    for (let index = 0; index < regionsFiltered.length; index += 1) {
      expect(submenuList.at(index).html()).toContain(regionsFiltered[index].name);
    }

    server.resetHandlers();
    const newGetInterviewersResponse = getInterviewersResponse.filter((interviewer) =>
      interviewer.region.includes(regionSelected.name)
    );
    const regionsToQuery = regionsFiltered.map((region) => `region_id[]=${region.id}`).join('&');
    const newQueryParams = { ...getInterviewersRequest, region_id: regionsToQuery };
    makeGetInterviewersRequestInterceptor(newQueryParams, newGetInterviewersResponse);

    countryCheckbox.trigger('click');
    await localVue.nextTick();
    await waitForGetInterviewersAPIRequest();

    expect(interviewersRows()).toHaveLength(newGetInterviewersResponse.length);

    server.resetHandlers();
    makeGetInterviewersRequestInterceptor(getInterviewersRequest, getInterviewersResponse);

    await clearFiltersButton().trigger('click');
    await localVue.nextTick();

    await waitForGetInterviewersAPIRequest();

    expect(unhandledRequests).toHaveLength(0);
    expect(interviewersRows()).toHaveLength(getInterviewersResponse.length);
  });

  it('filters by status and renders get interviewers endpoint then clears filters', async () => {
    await initialEndpointsCall();

    let indexOfTheLastOpenedList = -1;
    const unhandledRequests = [];
    server.listen({
      onUnhandledRequest(req) {
        unhandledRequests.push({
          method: req.method,
          url: req.url.href,
        });
        console.error(unhandledRequests.slice(-1));
      },
    });

    const { interviewersRows, statusDropdown, openLists, clearFiltersButton } = build();
    await waitTimeForInitialEndpoinsCall();
    await localVue.nextTick();

    expect(unhandledRequests).toHaveLength(0);
    expect(interviewersRows()).toHaveLength(getInterviewersResponse.length);
    expect(clearFiltersButton().element.disabled).toBe(true);

    const newGetInterviewersResponse = getInterviewersResponse.filter(
      (interviewer) => interviewer.graduated_interview_types.length > 0
    );

    const searchGraduatedParams = {
      interviewers_graduated: true,
      interviewers_leading: false,
      interviewers_shadowing: false,
    };

    server.resetHandlers();
    makeGetInterviewersRequestInterceptor(searchGraduatedParams, newGetInterviewersResponse);

    await openSelectList(statusDropdown, localVue);
    indexOfTheLastOpenedList += 1;

    await selectElementFromOpenedLists(openLists, indexOfTheLastOpenedList, 0, localVue);
    await waitForGetInterviewersAPIRequest();

    expect(openLists(indexOfTheLastOpenedList).at(0).find('.v-list-item__title').html()).toContain('Graduated');
    expect(openLists(indexOfTheLastOpenedList).at(0).attributes()['aria-selected']).toBe('true');
    expect(interviewersRows()).toHaveLength(newGetInterviewersResponse.length);

    server.resetHandlers();
    makeGetInterviewersRequestInterceptor(getInterviewersRequest, getInterviewersResponse);

    await clearFiltersButton().trigger('click');
    await localVue.nextTick();

    await waitForGetInterviewersAPIRequest();
    expect(unhandledRequests).toHaveLength(0);
    expect(interviewersRows()).toHaveLength(getInterviewersResponse.length);
  });

  // eslint-disable-next-line max-len
  it('filters by discipline, interviewType, technology, renders get interviewers endpoint then clears filters', async () => {
    await initialEndpointsCall();

    let indexOfTheLastOpenedList = -1;
    const unhandledRequests = [];
    server.listen({
      onUnhandledRequest(req) {
        unhandledRequests.push({
          method: req.method,
          url: req.url.href,
        });
        console.error(unhandledRequests.slice(-1));
      },
    });

    const {
      openLists,
      interviewersRows,
      disciplinesDropdown,
      multipleMenuOpenLists,
      sumbmenuMultipleLists,
      technologiesDropdown,
      clearFiltersButton,
    } = build();
    await waitTimeForInitialEndpoinsCall();

    const indexDisciplineSelected = 0;
    const disciplineSelected = getDisciplinesSuccessResponse[indexDisciplineSelected];

    expect(unhandledRequests).toHaveLength(0);
    expect(interviewersRows()).toHaveLength(getInterviewersResponse.length);
    expect(technologiesDropdown().element.disabled).toBe(true);
    expect(clearFiltersButton().element.disabled).toBe(true);

    await openSelectList(disciplinesDropdown, localVue);
    await localVue.nextTick();

    indexOfTheLastOpenedList += 1;
    await selectElementFromOpenedLists(
      multipleMenuOpenLists,
      indexOfTheLastOpenedList,
      indexDisciplineSelected,
      localVue
    );
    await localVue.nextTick();

    const submenuList = sumbmenuMultipleLists().find('.v-list-group__items').findAll('.v-list-item__action');
    const disciplineCheckbox = multipleMenuOpenLists().at(indexDisciplineSelected).find('input[type="checkbox"]');

    for (let index = 0; index < getDisciplinesSuccessResponse.length; index += 1) {
      expect(multipleMenuOpenLists().at(index).html()).toContain(getDisciplinesSuccessResponse[index].name);
    }
    expect(submenuList).toHaveLength(getInterviewTypesSuccessResponse.length);
    for (let index = 0; index < getInterviewTypesSuccessResponse.length; index += 1) {
      expect(submenuList.at(index).html()).toContain(getInterviewTypesSuccessResponse[index].name);
    }

    server.resetHandlers();
    const newGetInterviewersResponse = getInterviewersResponse.filter(
      (interviewer) => interviewer.discipline === disciplineSelected.name
    );
    const interviewTypeParams = getInterviewTypesSuccessResponse.map((it) => `interview_type_id[]=${it.id}`).join('&');
    let newQueryParams = {
      ...getInterviewersRequest,
      discipline_id: disciplineSelected.id,
      interview_type_id: interviewTypeParams,
    };
    makeGetInterviewersRequestInterceptor(newQueryParams, newGetInterviewersResponse);

    // Select and filter by discipline
    disciplineCheckbox.trigger('click');
    await localVue.nextTick();
    await waitForGetInterviewersAPIRequest();

    for (let index = 0; index < getInterviewTypesSuccessResponse.length; index += 1) {
      expect(submenuList.at(index).find('input[type="checkbox"]').attributes()['aria-checked']).toBe('true');
    }
    expect(interviewersRows()).toHaveLength(newGetInterviewersResponse.length);
    expect(technologiesDropdown().element.disabled).toBe(false);

    await openSelectList(technologiesDropdown, localVue);
    await localVue.nextTick();

    indexOfTheLastOpenedList += 1;

    expect(openLists(indexOfTheLastOpenedList)).toHaveLength(getTechnologiesSuccessResponse.length);

    for (let index = 0; index < getTechnologiesSuccessResponse.length; index += 1) {
      expect(openLists(indexOfTheLastOpenedList).at(index).html()).toContain(
        getTechnologiesSuccessResponse[index].name
      );
    }
    server.resetHandlers();
    newQueryParams = {
      ...getInterviewersRequest,
      discipline_id: disciplineSelected.id,
      interview_type_id: interviewTypeParams,
      technology_id: getTechnologiesSuccessResponse[0].id,
    };
    makeGetInterviewersRequestInterceptor(newQueryParams, newGetInterviewersResponse);

    // select and filter by technology
    await selectElementFromOpenedLists(openLists, indexOfTheLastOpenedList, 0, localVue);
    await localVue.nextTick();
    await waitForGetInterviewersAPIRequest();

    server.resetHandlers();
    makeGetInterviewersRequestInterceptor(getInterviewersRequest, getInterviewersResponse);

    await clearFiltersButton().trigger('click');
    await localVue.nextTick();

    await waitForGetInterviewersAPIRequest();
    await waitForGetInterviewersAPIRequest();

    expect(unhandledRequests).toHaveLength(0);
    expect(interviewersRows()).toHaveLength(getInterviewersResponse.length);
  });

  it('searches interviewers, find matches and no matches', async () => {
    await initialEndpointsCall();
    const index = 0;
    const selectedInterviewer = getInterviewersResponse[index];
    makeGetInterviewersSlotsHoursInterceptor(selectedInterviewer.id);
    const unhandledRequests = [];
    server.listen({
      onUnhandledRequest(req) {
        unhandledRequests.push({
          method: req.method,
          url: req.url.href,
        });
        console.error(unhandledRequests.slice(-1));
      },
    });

    const { interviewersRows, searchInterviewers, technologyChips } = build();
    await waitTimeForInitialEndpoinsCall();

    await localVue.nextTick();
    expect(interviewersRows()).toHaveLength(getInterviewersResponse.length);

    await searchInterviewers().setValue(`${selectedInterviewer.full_name} ${selectedInterviewer.technologies[index]}`);
    await localVue.nextTick();
    expect(interviewersRows()).toHaveLength(1);
    expect(interviewersRows().at(index).text()).toContain(selectedInterviewer.full_name);

    await interviewersRows().at(index).trigger('click');
    await localVue.nextTick();

    expect(unhandledRequests).toHaveLength(0);
    expect(technologyChips()).toHaveLength(selectedInterviewer.technologies.length);
    expect(technologyChips().at(index).text()).toContain(selectedInterviewer.technologies[index]);

    await interviewersRows().at(index).trigger('click');
    await localVue.nextTick();

    await searchInterviewers().setValue('');
    await localVue.nextTick();
    expect(interviewersRows()).toHaveLength(getInterviewersResponse.length);

    await searchInterviewers().setValue('Mexico Design');
    await localVue.nextTick();
    expect(interviewersRows()).toHaveLength(2);

    await searchInterviewers().setValue('no interviewer match');
    await localVue.nextTick();
    expect(interviewersRows().at(0).text()).toContain('No data available');
  });

  it('Checks if interview types are ordered alphabetically', async () => {
    await initialEndpointsCall();
    const unhandledRequests = [];
    server.listen({
      onUnhandledRequest(req) {
        unhandledRequests.push({
          method: req.method,
          url: req.url.href,
        });
        console.error(unhandledRequests.slice(-1));
      },
    });

    const { interviewType } = build();
    await waitTimeForInitialEndpoinsCall();

    await localVue.nextTick();
    const expectedInterviewTypeTitleList = [];

    for (let n = 0; n < getInterviewersResponse.length; n += 1) {
      expectedInterviewTypeTitleList.push(interviewType().at(n).findAll('.v-list-item__title'));
    }
    const resultingInterviewTypeList = [];
    for (let i = 0; i < expectedInterviewTypeTitleList.length; i += 1) {
      const interviewerInterviewTypesTitles = [];
      for (let j = 0; j < expectedInterviewTypeTitleList[i].length; j += 1) {
        interviewerInterviewTypesTitles.push(expectedInterviewTypeTitleList[i].at(j).text());
      }
      resultingInterviewTypeList.push(interviewerInterviewTypesTitles);
    }
    const sortedInterviewTypeList = [];
    for (let k = 0; k < resultingInterviewTypeList.length; k += 1) {
      const arrayToSort = resultingInterviewTypeList[k];
      sortedInterviewTypeList.push(arrayToSort.sort());
    }
    expect(resultingInterviewTypeList).toEqual(sortedInterviewTypeList);
    expect(unhandledRequests).toHaveLength(0);
  });
  it('renders interviewers, graduate mentees button', async () => {
    await initialEndpointsCall();
    const unhandledRequests = [];
    server.listen({
      onUnhandledRequest(req) {
        unhandledRequests.push({
          method: req.method,
          url: req.url.href,
        });
        console.error(unhandledRequests.slice(-1));
      },
    });

    const { interviewersRows, interviewType, saveButton, snackbar } = build();
    await waitTimeForInitialEndpoinsCall();
    await localVue.nextTick();

    const firstInterviewer = getInterviewersResponse[0];
    const putGraduateMenteeResponse = {
      interviewer_id: firstInterviewer.id,
      interview_type_name: firstInterviewer.leading_interview_types[0].name,
      has_graduated: true,
    };
    const putGraduateMenteRequest = {
      interviewer_id: firstInterviewer.id,
      interview_type_id: firstInterviewer.leading_interview_types[0].id,
    };

    const expectedInterviewType = interviewType().at(0);
    const leadingInterviews = expectedInterviewType.findAll('[data-test-id="interview-type-lead"]');
    const graduateBtn = leadingInterviews.at(0).find('[data-test-id="graduate-mentee-btn"]');

    expect(unhandledRequests).toHaveLength(0);
    expect(interviewersRows()).toHaveLength(getInterviewersResponse.length);

    await graduateBtn.trigger('click');
    await localVue.nextTick();

    server.resetHandlers();

    makePutGraduateInterviewerInterceptor(putGraduateMenteRequest, putGraduateMenteeResponse);
    makeGetInterviewersRequestInterceptor(getInterviewersRequest, getInterviewersResponse);

    await saveButton().trigger('click');
    await waitForGetInterviewersAPIRequest();
    await waitForPutGraduateMenteeAPIRequest();

    expect(snackbar().isVisible()).toBe(true);
    expect(snackbar().html()).toContain('Mentee graduated successfully');
    expect(unhandledRequests).toHaveLength(0);
  });
  server.resetHandlers();
  server.close();
});
