import storeResourceFactory from '@store/libs/storeResourceFactory';
import EditInterviewActions from './actions';
import EditInterviewGetters from './getters';
import EditInterviewMutations from './mutations';

const availableInterviewersStore = storeResourceFactory('availableInterviewers', []);
const menteesStore = storeResourceFactory('mentees', []);
const disciplinesStore = storeResourceFactory('disciplines', []);
const interviewTypesStore = storeResourceFactory('interviewTypes', []);
const technologiesStore = storeResourceFactory('technologies', []);
const levelsStore = storeResourceFactory('levels', []);
const greenhouseDataStore = storeResourceFactory('greenhouseData', {
  applicationId: null,
  candidateId: null,
});

const originalInterviewStore = storeResourceFactory('originalInterview', {});
const interviewStore = storeResourceFactory('interview', {
  id: null,
  candidateData: {
    id: null,
    name: null,
    email: null,
  },
  interviewerData: {
    id: null,
    name: null,
    email: null,
  },
  menteeData: {
    id: null,
    name: null,
    email: null,
  },
  interviewDate: {
    start: null,
    end: null,
  },
  discipline: null,
  disciplineHasTechnologies: false,
  interviewType: null,
  technology: null,
  level: null,
  greenhouseLink: null,
  meetingType: null,
  additionalInformation: null,
});

const state = {
  ...originalInterviewStore.state,
  ...interviewStore.state,
  ...availableInterviewersStore.state,
  ...menteesStore.state,
  ...disciplinesStore.state,
  ...interviewTypesStore.state,
  ...technologiesStore.state,
  ...levelsStore.state,
  ...greenhouseDataStore.state,
};

const actions = {
  initializeInterviewData: interviewStore.request(EditInterviewActions.initInterviewData),
  initializeDisciplinesData: disciplinesStore.request(EditInterviewActions.initDisciplinesData),
  initializeInterviewTypes: interviewTypesStore.request(EditInterviewActions.initInterviewTypes),
  initializeLevelsData: levelsStore.request(EditInterviewActions.initLevelsData),
  initializeAvailableInterviewers: availableInterviewersStore.request(EditInterviewActions.initAvailableInterviewers),
  initializeAvailableMentees: menteesStore.request(EditInterviewActions.initAvailableMentees),
  initializeTechnologies: technologiesStore.request(EditInterviewActions.fetchTechnologies),
  initializeGreenhouseData: greenhouseDataStore.request(EditInterviewActions.initGreenhouseData),
  disciplineHasTechnologies: EditInterviewActions.disciplineHasTechnologies,
  reloadAvailableMentees: menteesStore.request(EditInterviewActions.reloadAvailableMentees),
  reloadAvailableInterviewers: availableInterviewersStore.request(EditInterviewActions.reloadAvailableInterviewers),
  updateInterviewData: EditInterviewActions.updateInterview,
  updateGreenhouseLink: EditInterviewActions.getGreenhouseURLInfo,
  clearForm: interviewStore.request(EditInterviewActions.clearForm),
};

const mutations = {
  ...EditInterviewMutations,
  ...availableInterviewersStore.mutations,
  ...menteesStore.mutations,
  ...disciplinesStore.mutations,
  ...interviewTypesStore.mutations,
  ...levelsStore.mutations,
  ...technologiesStore.mutations,
  ...originalInterviewStore.mutations,
  ...interviewStore.mutations,
  ...greenhouseDataStore.mutations,
};

const getters = {
  ...EditInterviewGetters,
  ...interviewStore.getters,
  ...availableInterviewersStore.getters,
  ...menteesStore.getters,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
