<template>
  <v-container fluid class="ma-auto pa-8 dashboard-table__container">
    <v-row>
      <v-col>
        <h1>Interviewers</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="9">
        <template>
          <base-dropdown
            ref="regionsDropdown"
            data-test-id="regions-dropdown"
            label="Regions"
            :multiple="true"
            :items="regions"
            :value="infoSelectedToFilter.regions"
            :multi-list="true"
            color="primary"
            outlined
            rounded
            classes="mr-2"
            @on-change="regionsChange"
          ></base-dropdown>
        </template>
        <template>
          <base-dropdown
            label="Status"
            data-test-id="status-dropdown"
            :multiple="true"
            :items="status"
            :value="infoSelectedToFilter.status"
            color="primary"
            outlined
            rounded
            classes="mx-2"
            @on-change="statusChange"
          ></base-dropdown>
        </template>
        <template>
          <base-dropdown
            ref="disciplinesDropdown"
            label="Disciplines"
            data-test-id="disciplines-dropdown"
            :multiple="true"
            :items="disciplines"
            :multi-list="true"
            color="primary"
            outlined
            rounded
            classes="mx-2"
            @on-change="disciplinesChange"
          ></base-dropdown>
        </template>
        <template>
          <base-dropdown
            label="Technologies"
            data-test-id="technologies-dropdown"
            :multiple="true"
            :items="technologies"
            :value="infoSelectedToFilter.technologies"
            :disabled="technologiesDisabled"
            color="primary"
            outlined
            rounded
            classes="mx-2"
            @on-change="technologiesChange"
          ></base-dropdown>
        </template>
        <v-btn
          color="primary"
          data-test-id="clear-filters-button"
          outlined
          class="mx-2"
          :disabled="clearFiltersDisabled"
          @click="clearFilters"
        >
          Clear filters
          <v-icon right>mdi-filter-remove</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="searchInterviewers"
          append-icon="mdi-account-search"
          data-test-id="search-interviewers-input"
          height="37px"
          label="Search"
          type="text"
          outlined
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <interviewers-table
          :interviewers="searchInterviewers ? flexSearch : interviewers"
          :loading-interviewers="isLoading"
          @graduateMenteeReq="graduateMenteeReq"
        />
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="5000" data-test-id="snackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import FlexSearch from 'flexsearch';
import InterviewersTable from '../components/Interviewers/InterviewersTable/InterviewersTable.vue';
import BaseDropdown from '../components/BaseDropdown/BaseDropdown.vue';
import {
  getInterviewers,
  getRegions,
  getDisciplines,
  getInterviewTypes,
  getTechnologies,
  graduateMentee,
} from '../services/interviewers';

export default {
  components: { InterviewersTable, BaseDropdown },
  data() {
    return {
      searchInterviewers: null,
      isLoading: false,
      interviewers: [],
      disciplines: [],
      regions: [],
      disciplineChild: [],
      status: [
        {
          text: 'Graduated',
          value: 'graduated',
        },
        {
          text: 'Mentee - Leading',
          value: 'leading',
        },
        {
          text: 'Mentee - Shadowing',
          value: 'shadowing',
        },
      ],
      technologies: [],
      infoSelectedToFilter: {
        regions: [],
        status: [],
        disciplines: [],
        interviewTypes: {
          graduated: [],
          shadowing: [],
          leading: [],
          general: [],
        },
        technologies: [],
      },
      snackbar: false,
      snackbarText: '',
    };
  },
  computed: {
    flexSearch() {
      const index = new FlexSearch.Document({
        tokenize: 'full',
        optimize: true,
        resolution: 9,
        document: {
          id: 'id',
          store: true,
          index: ['searchContent'],
        },
      });

      this.interviewers.forEach((interviewer) => {
        index.add(interviewer);
      });

      const searchList = index.search(this.searchInterviewers, {
        enrich: true,
      });

      const results = [];
      searchList.forEach((item) => {
        item.result.forEach((interviewer) => {
          if (!results.some((result) => result.id === interviewer.doc.id)) {
            results.push(interviewer.doc);
          }
        });
      });
      return results;
    },
    clearFiltersDisabled() {
      return !Object.values(this.infoSelectedToFilter).some(
        (selectedItem) =>
          selectedItem.length > 0 || Object.values(selectedItem).some((subSelectedItem) => subSelectedItem.length > 0)
      );
    },
    technologiesDisabled() {
      return !(
        this.infoSelectedToFilter.disciplines.includes(1) ||
        this.$refs.disciplinesDropdown?.modelChild.find((a) => a.includes('swe'))
      );
    },
  },
  mounted() {
    this.fetchInterviewers();

    getRegions().then((response) => {
      const regionsGrupedByCountry = this.groupBy(response, 'country_name');

      this.regions = Object.keys(regionsGrupedByCountry).map((country) => ({
        text: country,
        value: country,
        itemChildren: regionsGrupedByCountry[country].map((city) => ({
          value: city.id,
          text: city.name,
        })),
      }));
    });
    getDisciplines().then(async (response) => {
      const fetchInterviewTypes = async (discipline) => {
        const x = await getInterviewTypes(discipline.id).then((data) => {
          return data.map((interviewType) => ({
            value: `${discipline.slug}-${interviewType.id}`,
            text: interviewType.name,
          }));
        });
        return x;
      };
      this.disciplines = await Promise.all(
        response.map(async (discipline) => ({
          value: discipline.id,
          text: discipline.name,
          itemChildren: await fetchInterviewTypes(discipline),
        }))
      );
    });
    getTechnologies().then((response) => {
      this.technologies = response.map((technology) => ({
        value: technology.id,
        text: technology.name,
      }));
    });
  },
  methods: {
    groupBy(array, key) {
      return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
        return result;
      }, {});
    },
    regionsChange(country, city) {
      this.infoSelectedToFilter.regions = city;
      this.fetchInterviewers();
    },
    statusChange(items) {
      this.infoSelectedToFilter.status = items;
      const interviewTypes = this.infoSelectedToFilter.interviewTypes.general;
      if (interviewTypes.length) {
        this.infoSelectedToFilter.interviewTypes = {
          ...this.infoSelectedToFilter.interviewTypes,
          graduated: [],
          shadowing: [],
          leading: [],
        };
        this.assignInterviewTypeByStatus(interviewTypes);
      }
      this.fetchInterviewers();
    },
    disciplinesChange(disciplines, interviewTypes) {
      this.infoSelectedToFilter.technologies = [];
      this.infoSelectedToFilter.disciplines = disciplines;

      const interviewTypesId = interviewTypes.map((a) => parseInt(a.split('-')[1], 10));
      this.infoSelectedToFilter.status.length && this.assignInterviewTypeByStatus(interviewTypesId);
      this.infoSelectedToFilter.interviewTypes.general = interviewTypesId;

      this.fetchInterviewers();
    },
    technologiesChange(items) {
      this.infoSelectedToFilter.technologies = items;
      this.fetchInterviewers();
    },
    clearFilters() {
      this.$refs.disciplinesDropdown.model = [];
      this.$refs.disciplinesDropdown.modelChild = [];
      this.$refs.regionsDropdown.model = [];
      this.$refs.regionsDropdown.modelChild = [];
      this.infoSelectedToFilter = {
        regions: [],
        status: [],
        disciplines: [],
        interviewTypes: {
          graduated: [],
          shadowing: [],
          leading: [],
          general: [],
        },
        technologies: [],
      };
      this.fetchInterviewers();
    },
    assignInterviewTypeByStatus(interviewTypes) {
      this.infoSelectedToFilter.status.includes('graduated') &&
        (this.infoSelectedToFilter.interviewTypes.graduated = interviewTypes);
      this.infoSelectedToFilter.status.includes('leading') &&
        (this.infoSelectedToFilter.interviewTypes.leading = interviewTypes);
      this.infoSelectedToFilter.status.includes('shadowing') &&
        (this.infoSelectedToFilter.interviewTypes.shadowing = interviewTypes);
    },
    fetchInterviewers() {
      this.isLoading = true;
      getInterviewers(this.infoSelectedToFilter).then((response) => {
        this.isLoading = false;
        this.interviewers = response;
      });
    },
    async graduateMenteeReq(interviewerId, interviewTypeId) {
      await graduateMentee(interviewerId, interviewTypeId).then((response) => {
        if (response.interviewerId) {
          this.snackbarText = 'Mentee graduated successfully';
          this.snackbar = true;
          this.fetchInterviewers();
        } else {
          this.snackbarText = response.data.message;
          this.snackbar = true;
        }
      });
    },
  },
};
</script>

<style scoped>
::v-deep.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
::v-deep .v-text-field--outlined .v-label {
  top: 6.5px;
}
::v-deep .v-text-field--outlined .v-label--active {
  top: 12.5px !important;
}
::v-deep .v-text-field--enclosed .v-input__append-inner {
  margin-top: 6.5px;
}
</style>
