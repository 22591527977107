import { version } from '../../package.json';

export const APP_VERSION = version;

export const CALENDAR_PALETTE = [
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#03A9F4',
  '#00BCD4',
  '#009688',
  '#4CAF50',
  '#8BC34A',
  '#FF9800',
];

export const LAST_PATH_KEY = 'LAST_PATH_BEFORE_LOGIN';

export const GREENHOUSE_PARTIAL_URL = 'https://wizeline.greenhouse.io/people/';

export const ERRORS_DICTIONARY = {
  GreenhouseCandidateNotInOnSiteStageException: 'The candidate is not currently on on-site stage on Greenhouse',
  GreenhouseCandidateNotFoundException: "The candidate's profile was not found on Greenhouse",
  GreenhouseInterviewTypeNotFoundException: 'The interview type was not found on Greenhouse',
  GreenhouseJobStageNotFoundException: 'The Job stage was not found on Greenhouse',
  GreenhouseApplicationNotFoundException: "The candidate's application was not found on Greenhouse",
  GreenhouseAPIException: 'There was a problem with Greenhouse API, please try later',
  generalError: 'There was a problem, please try later or contact the IT team',
  invalidURL: "The URL doesn't have the valid format, please verify it",
  invalidFormat: 'Candidate or Application ID format is incorrect',
};

export const LOCAL_STORAGE_KEY_PREFIX = 'inditool';
export const LOCAL_STORAGE_AUTH_KEYS = {
  accessToken: `${LOCAL_STORAGE_KEY_PREFIX}AccessToken`,
  refreshToken: `${LOCAL_STORAGE_KEY_PREFIX}RefreshToken`,
  expiresIn: `${LOCAL_STORAGE_KEY_PREFIX}ExpiresIn`,
  scopesToken: `${LOCAL_STORAGE_KEY_PREFIX}ScopesToken`,
};

export const EMPTY_SLOT_NAME = 'Slot Assigned';

export const INTERVIEW_STATUS = {
  scheduled: 'Scheduled',
  completed: 'Completed',
  canceled: 'Canceled',
};
