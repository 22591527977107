<template>
  <v-container fluid class="ma-auto pa-7">
    <v-row justify="center">
      <v-col sm="12" md="8" lg="6">
        <v-card>
          <v-card-title>
            <h2>Talent Acquisition Registration</h2>
          </v-card-title>
          <v-card-text>
            Thanks for registering on the Interview Distribution Tool. Please fill out the following information to
            complete your registration as a Talent Acquisition user. This will help us bring you the adequate features
            and overall a better experience for your role.
          </v-card-text>
          <v-divider />
          <v-card-text>
            The following email will be used to register:
            <strong>{{ userInformation.email }}</strong>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col sm="12" md="8" lg="6">
        <v-card>
          <v-overlay absolute :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-text>
            <ValidationObserver ref="observer" v-slot="{ invalid }">
              <form @submit.prevent="postTA">
                <v-row>
                  <v-col cols="12" sm="6" xl="3">
                    <base-text-field v-model="formData.name" label="Name" rules="required" />
                  </v-col>
                  <v-col cols="12" sm="6" xl="3">
                    <base-text-field v-model="formData.last_name" label="Last Name" rules="required" />
                  </v-col>
                  <v-col cols="12" sm="3">
                    <base-text-field
                      v-model="formData.bamboo_employee_id"
                      label="BambooHR ID"
                      rules="required"
                      type="number"
                      min="0"
                      hide-spin-buttons
                    />
                  </v-col>
                  <v-col cols="12" sm="3">
                    <base-select
                      v-model="formData.level_id"
                      label="Level"
                      rules="required"
                      :items="levels"
                      item-text="name"
                      item-value="id"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <base-select
                      v-model="formData.assigned_disciplines_ids"
                      label="Assigned Disciplines"
                      rules="required"
                      :items="disciplines"
                      item-text="name"
                      item-value="id"
                      multiple
                    />
                  </v-col>
                  <v-col cols="12" sm="6" xl="3">
                    <base-date-picker
                      v-model="formData.join_date"
                      label="Hire Date"
                      rules="required"
                      date-format="MMMM DD, YYYY"
                      :date-picker-props="{ max: new Date().toJSON() }"
                      append-icon="mdi-calendar"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" xl="3">
                    <base-select
                      v-model="formData.city_id"
                      label="City"
                      rules="required"
                      :items="cities"
                      :item-text="getCitiesText"
                      item-value="id"
                    >
                      <template v-slot:append>
                        <v-tooltip bottom>
                          <template #activator="{ on }">
                            <v-icon color="gray" v-on="on">mdi-help-circle-outline</v-icon>
                          </template>
                          <span>If your city is not listed, choose remote</span>
                        </v-tooltip>
                        <v-icon color="gray">mdi-menu-down</v-icon>
                      </template>
                    </base-select>
                  </v-col>
                </v-row>
                <v-row class="text-center">
                  <v-col>
                    <v-btn color="primary" type="submit" :disabled="invalid">
                      Submit
                    </v-btn>
                    <v-snackbar v-model="snackbar" :timeout="5000">
                      {{ snackbarText }}
                      <template v-slot:action="{ attrs }">
                        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
                          Close
                        </v-btn>
                      </template>
                    </v-snackbar>
                    <v-dialog v-model="dialog" persistent max-width="500px">
                      <v-card>
                        <v-card-title>
                          Already Registered
                        </v-card-title>
                        <v-card-text>
                          It appears you're already registered with another role, click continue to proceed to the
                          dashboard with your current role. If you wanted to register for a new role, please contact the
                          InDiTool team for assistance.
                        </v-card-text>
                        <v-card-text>
                          <a href="mailto:inditool-team@wizeline.com">
                            inditool-team@wizeline.com
                          </a>
                        </v-card-text>
                        <v-divider />
                        <v-card-actions>
                          <v-spacer />
                          <v-btn color="primary" text @click="dialog = false">
                            Cancel
                          </v-btn>
                          <v-btn color="primary" text @click="refreshAndContinue()">
                            Continue
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
              </form>
            </ValidationObserver>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import { taRegistrationErrors } from '@/constants/errors';
import BaseTextField from '@/components/BaseTextField/BaseTextField.vue';
import BaseSelect from '@/components/BaseSelect/BaseSelect.vue';
import BaseDatePicker from '@/components/BaseDatePicker/BaseDatePicker.vue';
import { LOCAL_STORAGE_AUTH_KEYS } from '@/constants';
import menteeRegistrationService from '@/services/menteeRegistration';
import talentAcquisitionRegistrationService from '../services/taRegistration';

export default {
  components: { ValidationObserver, BaseTextField, BaseSelect, BaseDatePicker },
  data() {
    return {
      loading: false,
      snackbar: false,
      snackbarText: '',
      dialog: false,
      levels: [],
      disciplines: [],
      cities: [],
      formData: {
        name: null,
        last_name: null,
        bamboo_employee_id: null,
        level_id: null,
        join_date: null,
        assigned_disciplines_ids: null,
        city_id: null,
      },
    };
  },
  computed: {
    ...mapGetters('user', { userInformation: 'getUserInformation' }),
  },
  async mounted() {
    this.initUserInformation();

    [this.levels, this.cities, this.disciplines] = await Promise.all([
      menteeRegistrationService.getLevelsData(),
      menteeRegistrationService.getCitiesData(),
      menteeRegistrationService.getDisciplinesData(),
    ]);
  },
  methods: {
    ...mapActions('user', { initUserInformation: 'initializeUserInformation' }),
    ...mapActions('auth', { refreshTokens: 'doRefreshToken' }),
    ...mapMutations('auth', { setScope: 'SET_SCOPES_TOKEN' }),
    getCitiesText(item) {
      return `${item.country_name} - ${item.name}`;
    },
    async postTA() {
      this.loading = true;
      talentAcquisitionRegistrationService
        .postTA(this.formData)
        .then((response) => {
          this.snackbarText = 'Registration successful';
          this.snackbar = true;
          this.clearForm();
          localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.scopesToken, response.scopes_token);
          this.setScope(response.scopes_token);
          this.$router.push('/dashboard');
        })
        .catch((error) => {
          if (error.data.message) {
            const exception = error.data.message.split('(')[0];
            if (exception === 'EmailIsRegisteredWithAnotherRoleException') {
              this.dialog = true;
            } else {
              this.snackbarText = taRegistrationErrors[exception];
              this.snackbar = true;
            }
          } else {
            this.snackbarText = `Error: ${error}`;
            this.snackbar = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async refreshAndContinue() {
      await this.refreshTokens();
      this.$router.push('/dashboard');
    },
    clearForm() {
      this.formData = {
        name: null,
        last_name: null,
        bamboo_employee_id: null,
        level_id: null,
        assigned_disciplines_ids: null,
        join_date: null,
        city_id: null,
      };
      this.$refs.observer.reset();
    },
  },
};
</script>
