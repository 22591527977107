<template>
  <validation-provider v-slot="{ errors }" :name="$attrs.name || $attrs.label" :rules="rules" v-bind="validatorProps">
    <v-radio-group
      v-model="innerValue"
      :class="{ 'label-required': required }"
      :error-messages="errors"
      v-bind="$attrs"
    >
      <v-list-item v-for="(option, index) in options" :key="index" two-line>
        <v-list-item-content>
          <v-list-item-title>
            <v-radio
              :label="option[optionText]"
              :value="option[optionValue]"
              :data-test-id="`${option[optionValue]}-option`"
            />
          </v-list-item-title>
          <v-list-item-subtitle class="ml-8">{{ option[optionHint] }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <template v-for="(_, slot) in $slots">
        <template :slot="slot">
          <!-- @slot Slots passed to vuetify component. -->
          <slot :name="slot"></slot>
        </template>
      </template>
    </v-radio-group>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    ValidationProvider,
  },
  inheritAttrs: false,
  props: {
    /**
     * Value set by the parent component.
     * @ignore
     */
    value: {
      type: null,
      default: null,
    },
    /**
     * Rules for the input from vee-validate.
     * @see https://vee-validate.logaretm.com/v2/guide/rules.html
     */
    rules: {
      type: [String, Object],
      default: '',
    },
    /**
     * Props for the validation provider.
     * @see https://vee-validate.logaretm.com/v2/guide/components/validation-provider.html#props
     */
    validatorProps: {
      type: Object,
      default: () => ({}),
    },
    /**
     * The list of options to show.
     */
    options: {
      type: Array,
      default: () => [],
    },
    /**
     * The inner value of the options.
     */
    optionValue: {
      type: String,
      default: 'value',
    },
    /**
     * The labels of the options.
     */
    optionText: {
      type: String,
      default: 'text',
    },
    /**
     * The hints of the options
     */
    optionHint: {
      type: String,
      default: 'hint',
    },
  },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(value) {
        /**
         * Emit the input event to the parent component.
         */
        this.$emit('input', value);
      },
    },
    required() {
      if (this.rules) {
        if (typeof this.rules === 'string') {
          return this.rules.includes('required');
        }
        return this.rules.required;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.label-required::v-deep legend::after {
  content: ' *';
  color: #d32f2f;
}
</style>
