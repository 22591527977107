import Vuex from 'vuex';
import Vuetify from 'vuetify';
import cloneDeep from 'lodash.clonedeep';
import { extend } from 'vee-validate';
// eslint-disable-next-line camelcase
import { required, required_if, email } from 'vee-validate/dist/rules.umd';
import { createLocalVue, mount, RouterLinkStub } from '@vue/test-utils';
import { setupServer } from 'msw/node';
import { rest } from 'msw';

import user from '@store/modules/user';
import auth from '@store/modules/auth';
import MenteeRegistration from '@/pages/MenteeRegistration.vue';
import HttpStatusCodes from '@/services/HttpStatusCodes';
import {
  getLevelsSuccessResponse,
  getDisciplinesSuccessResponse,
  getTechnologiesSuccessResponse,
  getInterviewTypesSuccessResponse,
  getRegionsSuccessResponse,
  getInterviewTypesSRESuccessResponse,
} from '@/constants/tests/shared.mock';
import { getUserInfoResponse } from './mocks/menteeEvaluation.mock';
import TestingUtils from '../../../tests/TestingUtils';

extend('required', required);
extend('required_if', required_if);
extend('email', email);

describe('Mentee Registration', () => {
  /**
   * @param {function} select
   * @param {Vue} localVue
   */
  const openSelectList = async (select, localVue) => {
    select().trigger('click');
    await localVue.nextTick();
  };
  /**
   * @param {function} openLists
   * @param {Number} elementIndex
   * @param {Vue} localVue
   */
  const selectElementFromOpenedLists = async (openLists, elementIndex, localVue) => {
    openLists().at(elementIndex).trigger('click');
    await localVue.nextTick();
  };
  const localVue = createLocalVue();
  const vuetify = new Vuetify();
  localVue.use(Vuetify);
  localVue.use(Vuex);
  document.body.setAttribute('data-app', true);
  const mockRouter = {
    push: jest.fn(),
  };
  const getStore = () => {
    return new Vuex.Store({
      modules: {
        user: cloneDeep(user),
        auth: cloneDeep(auth),
      },
    });
  };
  const server = setupServer();
  const getStoreWithInitialData = () => {
    const store = getStore();
    store.state.user.information = getUserInfoResponse;
    return store;
  };
  const build = ({ store = getStore() } = {}) => {
    const wrapper = mount(MenteeRegistration, {
      store,
      vuetify,
      localVue,
      stubs: {
        RouterLink: RouterLinkStub,
      },
      mocks: {
        $router: mockRouter,
      },
    });
    return {
      wrapper,
      menteeEmail: () => wrapper.find('[data-test-id="mentee-email"]'),
      fieldName: () => wrapper.find('[data-test-id="field-name"]'),
      fieldLastName: () => wrapper.find('[data-test-id="field-last-name"]'),
      filedBambooId: () => wrapper.find('[data-test-id="field-bamboo-id"]'),
      fieldHireDate: () => wrapper.find('[data-test-id="field-hire-date"]'),
      openLists: () => wrapper.find('.menuable__content__active').findAll('.v-list-item'),
      levelSelect: () => wrapper.find('[data-test-id="level-select"]'),
      citySelect: () => wrapper.find('[data-test-id="city-select"]'),
      pastExperienceSelect: () => wrapper.find('[data-test-id="past-experience-select"]'),
      disciplinesSelect: () => wrapper.find('[data-test-id="disciplines-select"]'),
      interviewTypesContainer: () => wrapper.find('[data-test-id="interview-types-container"]'),
      technologiesContainer: () => wrapper.find('[data-test-id="technologies-container"]'),
      technologiesSelect: () => wrapper.find('[data-test-id="technologies-select"]'),
      btnSubmit: () => wrapper.find('[data-test-id="btn-submit"]'),
    };
  };
  const userInfoFullName = getUserInfoResponse.name.split(' ');
  const BAMBOO_ID = 12345;
  const JOIN_DATE = '2024-03-12';
  const JOIN_DATE_FORMATTED = '2024-03-12 00:00:00';
  const createInterviewerResponse = {
    mentee_id: 1,
    scopes_token: '',
  };

  const createInterviewerRequest = (req) => {
    return {
      name: userInfoFullName[0],
      last_name: `${userInfoFullName[1]} ${userInfoFullName[2]}`,
      bamboo_employee_id: BAMBOO_ID,
      technologies_ids: req.technologies,
      discipline_id: req.discipline,
      level_id: getLevelsSuccessResponse[0].id,
      city_id: getRegionsSuccessResponse[0].id,
      other_technologies: '',
      join_date: JOIN_DATE_FORMATTED,
      has_past_experience: true,
      shadowing_interview_types: req.shadow,
      leading_interview_types: req.leading,
      graduated_interview_types: req.graduated,
    };
  };

  const makeGetUserInfoRequestInterceptor = () => {
    server.use(
      rest.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/user-info`, (req, res, ctx) => {
        return res(ctx.status(HttpStatusCodes.OK), ctx.json(getUserInfoResponse));
      })
    );
  };

  const makeGetLevelsRequestInterceptor = () => {
    server.use(
      rest.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/levels`, (req, res, ctx) => {
        return res(ctx.status(HttpStatusCodes.OK), ctx.json(getLevelsSuccessResponse));
      })
    );
  };

  const makeGetTechnologiesRequestInterceptor = () => {
    server.use(
      rest.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/technologies`, (req, res, ctx) => {
        return res(ctx.status(HttpStatusCodes.OK), ctx.json(getTechnologiesSuccessResponse));
      })
    );
  };

  const makeGetDisciplinesRequestInterceptor = () => {
    server.use(
      rest.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/disciplines`, (req, res, ctx) => {
        return res(ctx.status(HttpStatusCodes.OK), ctx.json(getDisciplinesSuccessResponse));
      })
    );
  };

  const makeGetRegionsRequestInterceptor = () => {
    server.use(
      rest.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/cities`, (req, res, ctx) => {
        return res(ctx.status(HttpStatusCodes.OK), ctx.json(getRegionsSuccessResponse));
      })
    );
  };

  const makeGetInterviewTypesRequestInterceptor = (selectedDisciplineId, response) => {
    server.use(
      rest.get(
        `${process.env.VUE_APP_BACKEND_ENDPOINT}/disciplines/${selectedDisciplineId}/interview-types`,
        (req, res, ctx) => {
          return res(ctx.status(HttpStatusCodes.OK), ctx.json(response));
        }
      )
    );
  };

  const makeCreateInterviewerRequestInterceptor = (apiRequestData) => {
    server.use(
      rest.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/candidates`, (req, res, ctx) => {
        req.json(apiRequestData);
        return res(ctx.status(HttpStatusCodes.CREATED), ctx.json(createInterviewerResponse));
      })
    );
  };

  const waitForGetUserInfoAPIRequest = async () => {
    await TestingUtils.waitForAPIRequestNext();
  };
  const waitForGetLevelsAPIRequest = async () => {
    await TestingUtils.waitForAPIRequestNext();
  };
  const waitForGetTechnologiesAPIRequest = async () => {
    await TestingUtils.waitForAPIRequestNext();
  };
  const waitForGetDisciplinesAPIRequest = async () => {
    await TestingUtils.waitForAPIRequestNext();
  };
  const waitForGetRegionsAPIRequest = async () => {
    await TestingUtils.waitForAPIRequestNext();
  };
  const waitForGetInterviewTypesAPIRequest = async () => {
    await TestingUtils.waitForAPIRequestNext();
  };
  const waitForCreateInterviewerAPIRequest = async () => {
    await TestingUtils.waitForAPIRequestNext();
  };
  const initialEndpointsCall = async () => {
    makeGetLevelsRequestInterceptor();
    makeGetTechnologiesRequestInterceptor();
    makeGetDisciplinesRequestInterceptor();
    makeGetRegionsRequestInterceptor();
  };
  const waitTimeForInitialEndpoinsCall = async () => {
    await waitForGetLevelsAPIRequest();
    await waitForGetTechnologiesAPIRequest();
    await waitForGetDisciplinesAPIRequest();
    await waitForGetRegionsAPIRequest();
  };
  it('Renders the page correctly', async () => {
    const unhandledRequests = [];
    const store = getStoreWithInitialData();
    server.listen({
      onUnhandledRequest(req) {
        unhandledRequests.push({
          method: req.method,
          url: req.url.href,
        });
        console.error(unhandledRequests.slice(-1));
      },
    });
    await makeGetUserInfoRequestInterceptor();
    await initialEndpointsCall();
    await makeGetInterviewTypesRequestInterceptor(
      getDisciplinesSuccessResponse[0].id,
      getInterviewTypesSuccessResponse
    );

    const {
      menteeEmail,
      levelSelect,
      citySelect,
      disciplinesSelect,
      openLists,
      pastExperienceSelect,
      interviewTypesContainer,
      technologiesContainer,
    } = build({ store });

    await waitForGetUserInfoAPIRequest();
    await waitTimeForInitialEndpoinsCall();

    expect(unhandledRequests).toHaveLength(0);
    expect(menteeEmail().text()).toBe(getUserInfoResponse.email);

    await openSelectList(levelSelect, localVue);
    expect(openLists()).toHaveLength(getLevelsSuccessResponse.length);
    await selectElementFromOpenedLists(openLists, 0, localVue);

    await openSelectList(citySelect, localVue);
    expect(openLists()).toHaveLength(getRegionsSuccessResponse.length);
    await selectElementFromOpenedLists(openLists, 0, localVue);

    await openSelectList(pastExperienceSelect, localVue);
    expect(openLists()).toHaveLength(2);
    await selectElementFromOpenedLists(openLists, 0, localVue);

    expect(technologiesContainer().exists()).toBe(false);
    expect(interviewTypesContainer().exists()).toBe(false);

    await openSelectList(disciplinesSelect, localVue);
    expect(openLists()).toHaveLength(getDisciplinesSuccessResponse.length);
    for (let index = 0; index < getDisciplinesSuccessResponse.length; index += 1) {
      expect(openLists().at(index).html()).toContain(getDisciplinesSuccessResponse[index].name);
    }

    await selectElementFromOpenedLists(openLists, 0, localVue);
    await waitForGetInterviewTypesAPIRequest();
    expect(technologiesContainer().exists()).toBe(true);
    expect(interviewTypesContainer().exists()).toBe(true);
    for (let index = 0; index < getInterviewTypesSuccessResponse.length; index += 1) {
      const interviewType = interviewTypesContainer().find(
        `[data-test-id="interview-type-${getInterviewTypesSuccessResponse[index].slug}"]`
      );
      expect(interviewType.text()).toContain(getInterviewTypesSuccessResponse[index].name);
    }
    expect(unhandledRequests).toHaveLength(0);
  });

  it('Submit new software Interviewer, change TA to graduated', async () => {
    const unhandledRequests = [];
    const store = getStoreWithInitialData();
    server.listen({
      onUnhandledRequest(req) {
        unhandledRequests.push({
          method: req.method,
          url: req.url.href,
        });
        console.error(unhandledRequests.slice(-1));
      },
    });
    await makeGetUserInfoRequestInterceptor();
    await initialEndpointsCall();
    await makeGetInterviewTypesRequestInterceptor(
      getDisciplinesSuccessResponse[0].id,
      getInterviewTypesSuccessResponse
    );
    const {
      fieldName,
      fieldLastName,
      filedBambooId,
      fieldHireDate,
      levelSelect,
      citySelect,
      disciplinesSelect,
      openLists,
      pastExperienceSelect,
      interviewTypesContainer,
      technologiesSelect,
      btnSubmit,
      wrapper,
    } = build({ store });

    await waitForGetUserInfoAPIRequest();
    await waitTimeForInitialEndpoinsCall();

    await fieldName().setValue(userInfoFullName[0]);
    await fieldLastName().setValue(`${userInfoFullName[1]} ${userInfoFullName[2]}`);
    await filedBambooId().setValue(BAMBOO_ID);
    await fieldHireDate().setValue(JOIN_DATE);

    // select level I
    await openSelectList(levelSelect, localVue);
    await selectElementFromOpenedLists(openLists, 0, localVue);

    // select region
    await openSelectList(citySelect, localVue);
    await selectElementFromOpenedLists(openLists, 0, localVue);

    // select experience interviewing
    await openSelectList(pastExperienceSelect, localVue);
    await selectElementFromOpenedLists(openLists, 0, localVue);

    // select discipline with id 1
    await openSelectList(disciplinesSelect, localVue);
    await selectElementFromOpenedLists(openLists, 0, localVue);
    await waitForGetInterviewTypesAPIRequest();

    // Set Tech Assessment as graduated, the rest of interview types are shadow
    const promises = getInterviewTypesSuccessResponse.map(async (item) => {
      if (item.id === 1) {
        const interviewTypeSelect = () =>
          interviewTypesContainer().find(`[data-test-id="select-interview-type-level-${item.slug}"]`);
        await openSelectList(interviewTypeSelect, localVue);
        await selectElementFromOpenedLists(openLists, 2, localVue);
      }
    });
    await Promise.all(promises);

    // if software engineering is selected, technologies are required
    expect(btnSubmit().element.disabled).toBe(true);

    // select technologies
    await openSelectList(technologiesSelect, localVue);
    await selectElementFromOpenedLists(openLists, 0, localVue);
    await selectElementFromOpenedLists(openLists, 1, localVue);

    expect(technologiesSelect().element.closest('div').textContent).toContain(getTechnologiesSuccessResponse[0].name);
    expect(technologiesSelect().element.closest('div').textContent).toContain(getTechnologiesSuccessResponse[1].name);

    // validate the form before to submit
    const isValid = await wrapper.vm.$refs.observer.validate();
    expect(isValid).toBe(true);
    expect(btnSubmit().element.disabled).toBe(false);
    const sweRequest = {
      technologies: [getTechnologiesSuccessResponse[0].id, getTechnologiesSuccessResponse[1].id],
      discipline: [getDisciplinesSuccessResponse[0].id],
      shadow: [getInterviewTypesSuccessResponse[1].id, getInterviewTypesSuccessResponse[2].id],
      leading: [],
      graduated: [getInterviewTypesSuccessResponse[0].id],
    };
    makeCreateInterviewerRequestInterceptor(createInterviewerRequest(sweRequest));
    await btnSubmit().trigger('click');
    await waitForCreateInterviewerAPIRequest();
    expect(unhandledRequests).toHaveLength(0);
  });

  it('Submit new Interviewer different than SWE (id 1), graduate 1, lead 1 interview type', async () => {
    const unhandledRequests = [];
    const store = getStoreWithInitialData();
    server.listen({
      onUnhandledRequest(req) {
        unhandledRequests.push({
          method: req.method,
          url: req.url.href,
        });
        console.error(unhandledRequests.slice(-1));
      },
    });
    await makeGetUserInfoRequestInterceptor();
    await initialEndpointsCall();
    await makeGetInterviewTypesRequestInterceptor(
      getDisciplinesSuccessResponse[1].id,
      getInterviewTypesSRESuccessResponse
    );
    const {
      fieldName,
      fieldLastName,
      filedBambooId,
      fieldHireDate,
      levelSelect,
      citySelect,
      disciplinesSelect,
      openLists,
      pastExperienceSelect,
      interviewTypesContainer,
      technologiesContainer,
      btnSubmit,
      wrapper,
    } = build({ store });

    await waitForGetUserInfoAPIRequest();
    await waitTimeForInitialEndpoinsCall();

    await fieldName().setValue(userInfoFullName[0]);
    await fieldLastName().setValue(`${userInfoFullName[1]} ${userInfoFullName[2]}`);
    await filedBambooId().setValue(BAMBOO_ID);
    await fieldHireDate().setValue(JOIN_DATE);

    // select level I
    await openSelectList(levelSelect, localVue);
    await selectElementFromOpenedLists(openLists, 0, localVue);

    // select region
    await openSelectList(citySelect, localVue);
    await selectElementFromOpenedLists(openLists, 0, localVue);

    // select experience interviewing
    await openSelectList(pastExperienceSelect, localVue);
    await selectElementFromOpenedLists(openLists, 0, localVue);

    // select discipline with id 1
    await openSelectList(disciplinesSelect, localVue);
    await selectElementFromOpenedLists(openLists, 1, localVue);
    await waitForGetInterviewTypesAPIRequest();

    // Set first it as graduated, second it as leading
    const promises = getInterviewTypesSRESuccessResponse.map(async (item, idx) => {
      const interviewTypeSelect = () =>
        interviewTypesContainer().find(`[data-test-id="select-interview-type-level-${item.slug}"]`);
      switch (idx) {
        case 0:
          await openSelectList(interviewTypeSelect, localVue);
          await selectElementFromOpenedLists(openLists, 2, localVue);
          break;
        case 1:
          await openSelectList(interviewTypeSelect, localVue);
          await selectElementFromOpenedLists(openLists, 1, localVue);
          break;
        default:
          break;
      }
    });
    await Promise.all(promises);

    // if software engineering is not selected, technologies are not required
    expect(technologiesContainer().exists()).toBe(false);

    // validate the form before to submit
    const isValid = await wrapper.vm.$refs.observer.validate();
    expect(isValid).toBe(true);
    expect(btnSubmit().element.disabled).toBe(false);
    const sreRequest = {
      technologies: [getTechnologiesSuccessResponse[0].id, getTechnologiesSuccessResponse[1].id],
      discipline: [getDisciplinesSuccessResponse[1].id],
      shadow: [],
      leading: [getInterviewTypesSRESuccessResponse[1].id],
      graduated: [getInterviewTypesSuccessResponse[0].id],
    };
    makeCreateInterviewerRequestInterceptor(createInterviewerRequest(sreRequest));
    await btnSubmit().trigger('click');
    await waitForCreateInterviewerAPIRequest();
    expect(unhandledRequests).toHaveLength(0);
  });
  it('Submit button should be disabled if the info required is not completed', async () => {
    const unhandledRequests = [];
    const store = getStoreWithInitialData();
    server.listen({
      onUnhandledRequest(req) {
        unhandledRequests.push({
          method: req.method,
          url: req.url.href,
        });
        console.error(unhandledRequests.slice(-1));
      },
    });
    await makeGetUserInfoRequestInterceptor();
    await initialEndpointsCall();
    await makeGetInterviewTypesRequestInterceptor(
      getDisciplinesSuccessResponse[0].id,
      getInterviewTypesSuccessResponse
    );
    const { btnSubmit } = build({ store });
    await waitForGetUserInfoAPIRequest();
    await waitTimeForInitialEndpoinsCall();
    expect(btnSubmit().element.disabled).toBe(true);
  });
});
