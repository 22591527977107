/* eslint-disable camelcase, max-classes-per-file */
import APIService from '@/services/API';
import Shared from '@/services/shared';

const PutInterviewServiceRequest = function constructor({
  interviewId,
  interviewerId,
  menteeId,
  startDatetime,
  endDatetime,
  disciplineId,
  interviewTypeId,
  technologyId,
  expectedLevelId,
  greenhouseLink,
  meetingType,
  additionalInformation,
}) {
  this.payload = {
    interviewer_id: interviewerId,
    mentee_id: menteeId,
    start_datetime: startDatetime,
    end_datetime: endDatetime,
    discipline_id: disciplineId,
    interview_type_id: interviewTypeId,
    technology_id: technologyId,
    expected_level_id: expectedLevelId,
    greenhouse_link: greenhouseLink,
    meeting_type: meetingType,
    additional_information: additionalInformation,
  };
  this.path = interviewId;
};

class PutInterviewService extends APIService {
  constructor(scheduleInterviewServiceRequest) {
    super();
    this.request = scheduleInterviewServiceRequest;
    this.serviceURL = `/interviews/${this.request.path}`;
  }

  async do() {
    this.setAuthorizationHeaders();
    this.setScopesHeaders();
    try {
      return await this.createHttpRequest().put(this.serviceURL, this.request.payload);
    } catch (response) {
      throw new Error(response.message);
    }
  }
}

export default {
  ...Shared,
  PutInterviewServiceRequest,
  PutInterviewService,
};
