-<template>
  <v-card>
    <v-overlay absolute :value="isInterviewLoading" opacity="0.75" color="white">
      <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
    </v-overlay>
    <v-card-title data-test-id="candidate-name">
      {{ formData.candidateData.name }}
      <v-spacer></v-spacer>
      <v-tooltip max-width="375px" bottom>
        <template #activator="{ on }">
          <v-icon v-on="on" @click="retrieveGreenhouseLink">mdi-help-circle-outline</v-icon>
        </template>
        <span>
          The candidate cannot be edited, and a new interview should be scheduled if you wish to change it.
        </span>
      </v-tooltip>
    </v-card-title>
    <v-card-subtitle data-test-id="candidate-email">
      {{ formData.candidateData.email }}
    </v-card-subtitle>
    <v-card-text class="pb-0">
      <ValidationObserver ref="observer">
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <ValidationProvider v-slot="{ errors }" name="Interviewer" rules="required">
                <v-autocomplete
                  v-model="formData.interviewerData"
                  label="Interviewer"
                  :error-messages="errors[0]"
                  :items="sortedInterviewers"
                  item-text="interviewerText"
                  item-value="id"
                  data-test-id="interviewer"
                  outlined
                  dense
                  :loading="isInterviewersLoading"
                >
                  <template v-slot:append>
                    <v-tooltip max-width="375px" bottom>
                      <template #activator="{ on }">
                        <v-icon color="gray" v-on="on">mdi-help-circle-outline</v-icon>
                      </template>
                      <span>
                        Showing all interviewers available for the selected discipline, interview type and technology
                      </span>
                    </v-tooltip>
                    <v-icon>mdi-menu-down</v-icon>
                  </template>
                </v-autocomplete>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="formData.menteeData"
                label="Mentee"
                :items="mentees"
                item-text="email"
                item-value="id"
                data-test-id="mentee"
                outlined
                dense
                :loading="isMenteesLoading"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <base-date-picker
                v-model="date"
                label="Interview Date"
                rules="required"
                append-icon="mdi-calendar"
                data-test-id="interview-date"
                date-format="MMMM DD, YYYY"
                outlined
                dense
              />
            </v-col>
            <v-col cols="6" sm="3">
              <base-time-picker
                v-model="startTime"
                label="Start Time"
                rules="required"
                append-icon="mdi-clock-outline"
                data-test-id="start-time"
                outlined
                dense
                digital
                format="ampm"
              />
            </v-col>
            <v-col cols="6" sm="3">
              <base-time-picker
                v-model="endTime"
                label="End Time"
                rules="required"
                append-icon="mdi-arrow-right"
                data-test-id="end-time"
                outlined
                dense
                digital
                format="ampm"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <base-select
                :value="formData.discipline"
                label="Discipline"
                rules="required"
                :items="disciplines"
                item-text="name"
                item-value="id"
                data-test-id="discipline"
                outlined
                dense
                filled
                readonly
              >
                <template v-slot:append>
                  <v-tooltip max-width="375px" bottom>
                    <template #activator="{ on }">
                      <v-icon v-on="on"> mdi-information-outline </v-icon>
                    </template>
                    <span>
                      This is retrieved from the Greenhouse profile.
                    </span>
                  </v-tooltip>
                </template>
              </base-select>
            </v-col>
            <v-col cols="12" sm="6">
              <base-select
                v-model="formData.interviewType"
                label="Interview Type"
                rules="required"
                :items="interviewTypes"
                item-text="name"
                item-value="id"
                data-test-id="interview-type"
                outlined
                dense
                @change="
                  reloadInterviewersAndMentees();
                  retrieveGreenhouseLink();
                "
              >
                <template v-slot:append>
                  <v-tooltip max-width="375px" bottom>
                    <template #activator="{ on }">
                      <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                    </template>
                    <span>
                      Changing this will update the Greenhouse link
                    </span>
                  </v-tooltip>
                  <v-icon>mdi-menu-down</v-icon>
                </template>
              </base-select>
            </v-col>
            <v-col cols="12" sm="6">
              <base-select
                v-model="formData.technology"
                label="Technology"
                hint="This is optional and available only for Software Engineering"
                persistent-hint
                :disabled="!disciplineHasTechnologies"
                :items="technologies"
                item-text="name"
                item-value="id"
                data-test-id="technology"
                outlined
                dense
                clearable
                @change="reloadInterviewers"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <base-select
                v-model="formData.level"
                label="Level"
                rules="required"
                :items="levels"
                item-text="name"
                item-value="id"
                data-test-id="level"
                outlined
                dense
              />
            </v-col>
            <v-col cols="12" md="6">
              <base-text-field
                :value="formData.greenhouseLink"
                label="Greenhouse Link"
                rules="required"
                data-test-id="greenhouse-link"
                :messages="greenhouseLinkUpdateMessage.message"
                outlined
                dense
                readonly
                filled
                :loading="isGreenhouseLinkLoading"
              >
                <template v-slot:prepend-inner>
                  <v-tooltip v-if="greenhouseLinkUpdateMessage.error" bottom>
                    <template #activator="{ on }">
                      <v-icon v-on="on">mdi-alert-circle-outline</v-icon>
                    </template>
                    <span>
                      {{ greenhouseLinkUpdateMessage.error }}
                    </span>
                  </v-tooltip>
                </template>
                <template v-if="formData.greenhouseLink" v-slot:append>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <a :href="formData.greenhouseLink" target="_blank" style="text-decoration: none;" v-on="on">
                        <v-icon>mdi-open-in-new</v-icon>
                      </a>
                    </template>
                    <span>Visit link</span>
                  </v-tooltip>
                </template>
                <template v-else v-slot:append-outer>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-icon v-on="on" @click="retrieveGreenhouseLink">mdi-sync</v-icon>
                    </template>
                    <span>Generate link</span>
                  </v-tooltip>
                </template>
                <template v-slot:message="{ message }">
                  <span :class="`${greenhouseLinkUpdateMessage.color}--text`"> {{ message }} </span>
                </template>
              </base-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <base-toggle-buttons
                v-model="formData.meetingType"
                color="primary"
                :buttons="[
                  { value: 'google-meet', text: 'Google Meet', icon: 'mdi-google' },
                  { value: 'zoom', text: 'Zoom', icon: 'mdi-video' },
                ]"
                dense
                block
              />
            </v-col>
            <v-col>
              <base-text-area
                v-model="formData.additionalInformation"
                label="Special notes for the interviewer"
                rules="max:5000"
                data-test-id="additional-information"
                outlined
                dense
              />
            </v-col>
          </v-row>
        </v-container>
      </ValidationObserver>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" data-test-id="save-button" outlined :loading="isGreenhouseLinkLoading" @click="save()">
        <v-icon left> mdi-content-save </v-icon>
        Save
      </v-btn>
      <v-btn color="red" data-test-id="cancel-button" outlined @click="close()">
        Cancel
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import BaseSelect from '../BaseSelect/BaseSelect.vue';
import BaseDatePicker from '../BaseDatePicker/BaseDatePicker.vue';
import BaseTimePicker from '../BaseTimePicker/BaseTimePicker.vue';
import BaseTextField from '../BaseTextField/BaseTextField.vue';
import BaseToggleButtons from '../BaseToggleButtons/BaseToggleButtons.vue';
import BaseTextArea from '../BaseTextArea/BaseTextArea.vue';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BaseSelect,
    BaseDatePicker,
    BaseTimePicker,
    BaseTextField,
    BaseToggleButtons,
    BaseTextArea,
  },
  data() {
    return {
      dataChanged: false,
      isGreenhouseLinkLoading: false,
      greenhouseLinkUpdateMessage: {
        message: '',
        color: 'gray',
        error: '',
      },
    };
  },
  computed: {
    ...mapGetters('editInterview', {
      isInterviewLoading: 'isRequestingInterview',
      initialFormData: 'getInitialFormData',
      formData: 'getFormData',
      interviewers: 'getAvailableInterviewers',
      isInterviewersLoading: 'isRequestingAvailableInterviewers',
      mentees: 'getMentees',
      isMenteesLoading: 'isRequestingMentees',
      disciplines: 'getDisciplines',
      interviewTypes: 'getInterviewTypes',
      disciplineHasTechnologies: 'getDisciplineHasTechnologies',
      technologies: 'getTechnologies',
      levels: 'getLevels',
    }),
    date: {
      get() {
        return this.formData.interviewDate.start
          ? moment(this.formData.interviewDate.start).format('YYYY-MM-DD')
          : null;
      },
      set(value) {
        this.formData.interviewDate.start = moment(this.formData.interviewDate.start)
          .year(moment(value).year())
          .month(moment(value).month())
          .date(moment(value).date())
          .toDate();
        this.formData.interviewDate.end = moment(this.formData.interviewDate.end)
          .year(moment(value).year())
          .month(moment(value).month())
          .date(moment(value).date())
          .toDate();
      },
    },
    startTime: {
      get() {
        return this.formData.interviewDate.start ? moment(this.formData.interviewDate.start).format('HH:mm') : null;
      },
      set(value) {
        this.formData.interviewDate.start = moment(this.formData.interviewDate.start)
          .hour(value.split(':')[0])
          .minute(value.split(':')[1])
          .toDate();
      },
    },
    endTime: {
      get() {
        return this.formData.interviewDate.end ? moment(this.formData.interviewDate.end).format('HH:mm') : null;
      },
      set(value) {
        this.formData.interviewDate.end = moment(this.formData.interviewDate.end)
          .hour(value.split(':')[0])
          .minute(value.split(':')[1])
          .toDate();
      },
    },
    sortedInterviewers() {
      const interviewersArr = [...this.interviewers];
      return interviewersArr.map((interviewerObj) => ({
        ...interviewerObj,
        interviewerText: `${interviewerObj.fullName} (${interviewerObj.currentWeekInterviews})`,
      }));
    },
  },
  watch: {
    formData: {
      handler() {
        if (JSON.stringify(this.initialFormData) !== JSON.stringify(this.formData)) {
          this.dataChanged = true;
        } else {
          this.dataChanged = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('editInterview', {
      reloadInterviewers: 'reloadAvailableInterviewers',
      reloadMentees: 'reloadAvailableMentees',
      updateInterview: 'updateInterviewData',
      updateGreenhouseLink: 'updateGreenhouseLink',
      clearForm: 'clearForm',
    }),
    ...mapActions('dashboard', {
      showSnackbar: 'showSnackbar',
      initInterviewsData: 'initializeInterviewsData',
    }),
    async reloadInterviewersAndMentees() {
      if (this.formData.interviewType != null && this.formData.discipline != null) {
        await this.reloadInterviewers();
        await this.reloadMentees();
      }
    },
    async retrieveGreenhouseLink() {
      this.isGreenhouseLinkLoading = true;
      this.greenhouseLinkUpdateMessage = {
        message: '',
        color: 'gray',
        error: '',
      };
      this.updateGreenhouseLink()
        .then((response) => {
          if (response.url) {
            this.greenhouseLinkUpdateMessage = {
              message: 'Greenhouse link successfully!',
              color: 'green',
            };
          } else {
            this.showSnackbar(response);
            this.greenhouseLinkUpdateMessage = {
              message: 'Greenhouse link update failed!',
              color: 'red',
              error: response,
            };
          }
        })
        .finally(() => {
          this.isGreenhouseLinkLoading = false;
        });
    },
    async save() {
      const isValid = await this.$refs.observer.validate();
      if (isValid && this.dataChanged) {
        this.$emit('confirmEditAction');
      } else if (!isValid) {
        this.showSnackbar('Please fill out all required fields');
      } else {
        this.showSnackbar('No changes were detected');
        this.close();
      }
    },
    close() {
      this.clearForm();
      this.greenhouseLinkUpdateMessage = {
        message: '',
        color: 'gray',
        error: '',
      };
      this.dataChanged = false;
      this.$refs.observer.reset();
      this.$emit('close');
    },
  },
};
</script>
