import APIService from '@/services/API';

const GetUserInfoServiceRequest = function constructor() {};

/* eslint-disable camelcase */
const GetUserInfoServiceResponse = function constructor({ name, email, image_url }) {
  this.name = name;
  this.email = email;
  this.picture = image_url;
};
/* eslint-enable camelcase */

class GetUserInfoService extends APIService {
  constructor(getUserInfoRequest) {
    super();
    this.serviceURL = 'user-info';
    this.request = getUserInfoRequest;
  }

  async do() {
    this.setAuthorizationHeaders();
    try {
      const response = await this.createHttpRequest().get(this.serviceURL, { params: this.request });
      return new GetUserInfoServiceResponse(response.data);
    } catch ({ response }) {
      throw new Error(`${response.status} | ${response.data.message}`);
    }
  }
}

export { GetUserInfoService, GetUserInfoServiceRequest };
