import Vuex from 'vuex';
import Vuetify from 'vuetify';
import cloneDeep from 'lodash.clonedeep';
// eslint-disable-next-line camelcase
import { required, required_if, email } from 'vee-validate/dist/rules.umd';
import { extend } from 'vee-validate';
import { createLocalVue, mount, RouterLinkStub } from '@vue/test-utils';
import MenteeEvaluation from '@/pages/MenteeEvaluation.vue';
import HttpStatusCodes from '@/services/HttpStatusCodes';
import user from '@store/modules/user';
import auth from '@store/modules/auth';
import { INTERVIEW_TYPE_TA } from '@/constants/tests/shared.mock';
import { setupServer } from 'msw/node';
import { rest } from 'msw';

import TestingUtils from '../../../tests/TestingUtils';
import {
  getUserInfoResponse,
  getInterviewResponse,
  putMenteeEvaluationResponse,
  putMenteeEvaluationGraduatedRequest,
  putMenteeEvaluationTakeAnotherSessionRequest,
  putMenteeEvaluationTakeAnotherSessionResponse,
  MENTOR_EMAIL,
  MENTEE_EMAIL,
  CANDIDATE_NAME,
} from './mocks/menteeEvaluation.mock';

extend('required', required);
extend('required_if', required_if);
extend('email', email);

describe('Mentee Evaluation', () => {
  /**
   * @param {function} select
   * @param {Vue} localVue
   */
  const openSelectList = async (select, localVue) => {
    select().trigger('click');
    await localVue.nextTick();
  };
  /**
   * @param {function} openLists
   * @param {Number} listIndex
   * @param {Number} elementIndex
   * @param {Vue} localVue
   */
  const selectElementFromOpenedLists = async (openLists, elementIndex, localVue) => {
    openLists().at(elementIndex).trigger('click');
    await localVue.nextTick();
  };
  const localVue = createLocalVue();
  const vuetify = new Vuetify();
  localVue.use(Vuetify);
  localVue.use(Vuex);
  document.body.setAttribute('data-app', true);
  const mockRouter = {
    push: jest.fn(),
  };

  const getStore = () => {
    return new Vuex.Store({
      modules: {
        user: cloneDeep(user),
        auth: cloneDeep(auth),
      },
    });
  };
  const server = setupServer();
  const idParam = 12;

  const $route = {
    params: {
      id: idParam,
    },
  };

  const getStoreWithInitialData = () => {
    const store = getStore();
    store.state.user.information = getUserInfoResponse;
    return store;
  };

  const build = ({ store = getStore() } = {}) => {
    const wrapper = mount(MenteeEvaluation, {
      store,
      vuetify,
      localVue,
      stubs: {
        RouterLink: RouterLinkStub,
      },
      mocks: {
        $router: mockRouter,
        $route,
      },
    });

    return {
      wrapper,
      interviewType: () => wrapper.find('[data-test-id="interview-type-evaluation"]'),
      candidateName: () => wrapper.find('[data-test-id="candidate-name-evaluation"]'),
      mentorEmail: () => wrapper.find('[data-test-id="mentor-email-evaluation"]'),
      menteeEmail: () => wrapper.find('[data-test-id="mentee-email-evaluation"]'),
      menteeAttendanceSelect: () => wrapper.find('[data-test-id="mentee-attendance-evaluation"]'),
      menteeRoleSelect: () => wrapper.find('[data-test-id="mentee-role-evaluation"]'),
      menteeEvaluationSelect: () => wrapper.find('[data-test-id="mentee-evaluation"]'),
      modalGraduateMentee: () => wrapper.find('[data-test-id="modal-graduate-mentee"]'),
      openLists: () => wrapper.find('.menuable__content__active').findAll('.v-list-item'),
      snackbar: () => wrapper.find('[data-test-id="snackbar"]'),
      saveButton: () => wrapper.find('[data-test-id="save-button"]'),
    };
  };

  const makeGetUserInfoRequestInterceptor = () => {
    server.use(
      rest.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/user-info`, (req, res, ctx) => {
        return res(ctx.status(HttpStatusCodes.OK), ctx.json(getUserInfoResponse));
      })
    );
  };

  const makeGetInterviewRequestInterceptor = ({ interviewId = 0 } = {}) => {
    server.use(
      rest.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/interviews/${interviewId}`, (req, res, ctx) => {
        return res(ctx.status(HttpStatusCodes.OK), ctx.json(getInterviewResponse));
      })
    );
  };
  const makePutMenteeEvaluationRequestInterceptor = (apiRequestData, apiResponse) => {
    server.use(
      rest.put(`${process.env.VUE_APP_BACKEND_ENDPOINT}/mentees/evaluation`, (req, res, ctx) => {
        req.json(apiRequestData);
        return res(ctx.status(HttpStatusCodes.CREATED), ctx.json(apiResponse));
      })
    );
  };
  const waitForGetInterviewAPIRequest = async () => {
    await TestingUtils.waitForAPIRequestNext();
  };

  const waitForGetUserInfoAPIRequest = async () => {
    await TestingUtils.waitForAPIRequestNext();
  };

  const waitForShowSnackBar = async () => {
    await TestingUtils.waitForAPIRequestNext();
  };

  const waitForPutMenteeEvaluationAPIRequest = async () => {
    await TestingUtils.waitForAPIRequest();
  };
  it('renders the page correctly', async () => {
    const unhandledRequests = [];
    const store = getStoreWithInitialData();
    makeGetUserInfoRequestInterceptor();
    makeGetInterviewRequestInterceptor({ interviewId: idParam });

    server.listen({
      onUnhandledRequest(req) {
        unhandledRequests.push({
          method: req.method,
          url: req.url.href,
        });
        console.error(unhandledRequests.slice(-1));
      },
    });
    const { interviewType, candidateName, mentorEmail, menteeEmail, wrapper } = build({ store });
    await waitForGetUserInfoAPIRequest();
    await waitForGetInterviewAPIRequest();

    await wrapper.vm.$nextTick();

    expect(interviewType().text()).toBe(INTERVIEW_TYPE_TA.name);
    expect(candidateName().text()).toBe(CANDIDATE_NAME);
    expect(mentorEmail().text()).toBe(MENTOR_EMAIL);
    expect(menteeEmail().element.value).toBe(MENTEE_EMAIL);
  });

  it('save button is not allowed until all required fields are filled', async () => {
    const unhandledRequests = [];
    const store = getStoreWithInitialData();
    makeGetUserInfoRequestInterceptor();
    makeGetInterviewRequestInterceptor({ interviewId: idParam });

    server.listen({
      onUnhandledRequest(req) {
        unhandledRequests.push({
          method: req.method,
          url: req.url.href,
        });
        console.error(unhandledRequests.slice(-1));
      },
    });
    const {
      saveButton,
      snackbar,
      wrapper,
      menteeAttendanceSelect,
      menteeRoleSelect,
      menteeEvaluationSelect,
      openLists,
    } = build({ store });
    await waitForGetUserInfoAPIRequest();
    await waitForGetInterviewAPIRequest();

    await wrapper.vm.$nextTick();

    await saveButton().trigger('click');
    await waitForShowSnackBar();

    expect(snackbar().isVisible()).toBe(true);
    expect(snackbar().html()).toContain('Please fill out all required fields');
    expect(menteeRoleSelect().attributes('disabled')).toBeTruthy();
    expect(menteeEvaluationSelect().attributes('disabled')).toBeTruthy();

    await openSelectList(menteeAttendanceSelect, localVue);
    await selectElementFromOpenedLists(openLists, 1, localVue);

    await saveButton().trigger('click');
    await waitForShowSnackBar();

    expect(snackbar().isVisible()).toBe(true);
    expect(snackbar().html()).toContain('Please fill out all required fields');
    expect(menteeRoleSelect().attributes('disabled')).toBeFalsy();
    expect(menteeEvaluationSelect().attributes('disabled')).toBeFalsy();

    // Select Mentees role
    await openSelectList(menteeRoleSelect, localVue);
    await selectElementFromOpenedLists(openLists, 0, localVue);
    expect(menteeEvaluationSelect().attributes('disabled')).toBeTruthy();

    await openSelectList(menteeRoleSelect, localVue);
    await selectElementFromOpenedLists(openLists, 1, localVue);
    expect(menteeEvaluationSelect().attributes('disabled')).toBeFalsy();

    //  menteeEvaluationSelect is required
    await saveButton().trigger('click');
    await waitForShowSnackBar();

    expect(snackbar().isVisible()).toBe(true);
    expect(snackbar().html()).toContain('Please fill out all required fields');
  });

  it('Warning modal should be shown when the evaluation is: Graduate Mentee', async () => {
    const unhandledRequests = [];
    const store = getStoreWithInitialData();

    makeGetUserInfoRequestInterceptor();
    makeGetInterviewRequestInterceptor({ interviewId: idParam });
    makePutMenteeEvaluationRequestInterceptor(putMenteeEvaluationGraduatedRequest, putMenteeEvaluationResponse);
    server.listen({
      onUnhandledRequest(req) {
        unhandledRequests.push({
          method: req.method,
          url: req.url.href,
        });
        console.error(unhandledRequests.slice(-1));
      },
    });

    const {
      saveButton,
      wrapper,
      snackbar,
      menteeAttendanceSelect,
      menteeRoleSelect,
      menteeEvaluationSelect,
      modalGraduateMentee,
      openLists,
    } = build({ store });
    await waitForGetUserInfoAPIRequest();
    await waitForGetInterviewAPIRequest();

    await wrapper.vm.$nextTick();

    await openSelectList(menteeAttendanceSelect, localVue);
    await selectElementFromOpenedLists(openLists, 1, localVue);

    // Select Mentees role: leading interview
    await openSelectList(menteeRoleSelect, localVue);
    await selectElementFromOpenedLists(openLists, 1, localVue);

    // Select evaluation option: Graduate Mentee
    await openSelectList(menteeEvaluationSelect, localVue);
    await selectElementFromOpenedLists(openLists, 1, localVue);

    await saveButton().trigger('click');
    await waitForShowSnackBar();
    expect(modalGraduateMentee().isVisible()).toBe(true);

    const modalButtons = modalGraduateMentee().findAll('button');
    modalButtons.at(0).trigger('click');
    await localVue.nextTick();
    await waitForPutMenteeEvaluationAPIRequest();

    expect(snackbar().isVisible()).toBe(true);
    expect(snackbar().html()).toContain('Evaluation submitted successfully');
  });

  it('Send Evaluation when the mentee should take another session', async () => {
    const unhandledRequests = [];
    const store = getStoreWithInitialData();

    makeGetUserInfoRequestInterceptor();
    makeGetInterviewRequestInterceptor({ interviewId: idParam });
    makePutMenteeEvaluationRequestInterceptor(
      putMenteeEvaluationTakeAnotherSessionRequest,
      putMenteeEvaluationTakeAnotherSessionResponse
    );
    server.listen({
      onUnhandledRequest(req) {
        unhandledRequests.push({
          method: req.method,
          url: req.url.href,
        });
        console.error(unhandledRequests.slice(-1));
      },
    });

    const {
      saveButton,
      wrapper,
      snackbar,
      menteeAttendanceSelect,
      menteeRoleSelect,
      menteeEvaluationSelect,
      openLists,
    } = build({ store });
    await waitForGetUserInfoAPIRequest();
    await waitForGetInterviewAPIRequest();

    await wrapper.vm.$nextTick();

    await openSelectList(menteeAttendanceSelect, localVue);
    await selectElementFromOpenedLists(openLists, 1, localVue);

    // Select Mentees role: leading interview
    await openSelectList(menteeRoleSelect, localVue);
    await selectElementFromOpenedLists(openLists, 1, localVue);

    // Select evaluation option: take another session
    await openSelectList(menteeEvaluationSelect, localVue);
    await selectElementFromOpenedLists(openLists, 0, localVue);

    await saveButton().trigger('click');
    await waitForPutMenteeEvaluationAPIRequest();
    await waitForShowSnackBar();

    expect(snackbar().isVisible()).toBe(true);
    expect(snackbar().html()).toContain('Evaluation submitted successfully');
  });
  server.resetHandlers();
  server.close();
});
