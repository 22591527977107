<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="pageSize"
    class="elevation-1 nowrap-sortable-icon"
    :loading="interviewsLoading"
    :loading-text="loadingText"
    sort-by="interviewDate"
    no-data-text="No interviews found, try another filter?"
    mobile-breakpoint="600"
  >
    <template v-slot:top>
      <template>
        <v-toolbar class="mb-n2" flat>
          <v-toolbar-title>Candidates</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" class="d-sm-none" v-on="on">
                Filter By Status
              </v-btn>
            </template>
            <v-list class="d-sm-none">
              <v-list-item-group v-model="selectedStatuses" multiple @change="fetchInterviews()">
                <v-list-item v-for="status in statuses" :key="status.id" :value="status.id">
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ status.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
          <v-toolbar-title class="d-none d-sm-flex">Filter by status:</v-toolbar-title>
        </v-toolbar>
      </template>
      <template v-if="statuses.length > 0">
        <v-layout class="d-flex flex-wrap justify-xs-center justify-sm-end">
          <v-checkbox
            v-for="status in statuses"
            :key="status.id"
            v-model="selectedStatuses"
            :label="status.name"
            :value="status.id"
            class="pr-4 mt-n2 d-none d-sm-flex"
            data-test-id="status-checkbox"
            :aria-test-value="status.name"
            @change="fetchInterviews()"
          ></v-checkbox>
        </v-layout>
      </template>
      <v-dialog v-model="dialogEdit" max-width="800px">
        <ModalEditInterview data-test-id="modal-edit-interview" @close="dialogEdit = false" />
      </v-dialog>
      <v-dialog v-model="dialogActions" max-width="500px">
        <template>
          <v-card>
            <v-card-title>
              Confirm Action
            </v-card-title>
            <v-card-text>
              {{ dialogActionsText }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialogActionsFunction">
                Yes
              </v-btn>
              <v-btn color="primary" text @click="dialogActions = false">
                No
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </template>
    <template v-slot:[`item.interviewDate`]="{ item }">
      <span> {{ setInterviewDateFormat(item.interviewDate) }} </span>
    </template>
    <template v-slot:[`item.technology`]="{ item }">
      <span> {{ item.technology.map((tech) => tech.name).join(', ') }}</span>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-menu v-if="item.statusId === 1" offset-y>
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn color="black" icon v-bind="attrs" data-test-id="interview-action" v-on="{ ...tooltip, ...menu }">
                <v-icon> mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <span>Interview Actions</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item
            v-for="(action, index) in actions_list"
            :key="index"
            data-test-id="candidate-row"
            @click="callAction(action.action, item)"
          >
            <v-list-item-icon>
              <v-icon> mdi-{{ action.icon }} </v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ action.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <v-chip :color="item.statusColor" dark>
        {{ item.status }}
      </v-chip>
    </template>
  </v-data-table>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import ModalEditInterview from './ModalEditInterview.vue';

export default {
  components: {
    ModalEditInterview,
  },
  props: {
    headers: {
      type: Array,
      default: () => [
        {
          text: 'ColumnName',
          align: 'start',
          sortable: false,
          value: 'nameValue',
        },
      ],
    },
    items: {
      type: Array,
      default: () => [
        {
          nameValue: 'Value',
        },
      ],
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    loadingText: { type: String, default: 'Loading..' },
    statuses: { type: Array, default: () => [] },
  },
  data: () => ({
    dialogActions: false,
    dialogActionsText: '',
    dialogActionsFunction: null,
    dialogEdit: false,
    actions_list: [
      {
        title: 'Cancel Interview',
        icon: 'close',
        action: 'cancelInterview',
      },
      {
        title: 'Mark as Completed',
        icon: 'check',
        action: 'completeInterview',
      },
      {
        title: 'Edit Interview',
        icon: 'pencil',
        action: 'editInterview',
      },
    ],
  }),
  computed: {
    ...mapGetters('dashboard', {
      selected: 'getSelectedStatuses',
      interviewsLoading: 'getInterviewsLoading',
    }),
    isLoading() {
      return !this.items.length;
    },
    selectedStatuses: {
      get() {
        return this.selected;
      },
      set(value) {
        this.updateSelectedStatuses(value);
      },
    },
  },
  methods: {
    ...mapActions('dashboard', {
      cancelInterviewAction: 'cancelInterview',
      markInterviewAsCompleted: 'completeInterview',
      updateSelectedStatuses: 'selectStatuses',
      fetchInterviews: 'initializeInterviewsData',
    }),
    ...mapActions('editInterview', {
      initInterviewData: 'initializeInterviewData',
    }),
    callAction(action, item) {
      this[action](item);
    },
    cancelInterview(item) {
      this.dialogActionsText = 'Are you sure you want to cancel this interview?';
      this.dialogActionsFunction = async () => {
        this.dialogActions = false;
        await this.cancelInterviewAction(item);
      };
      this.dialogActions = true;
    },
    completeInterview(item) {
      this.dialogActionsText = 'Are you sure you want to mark this as completed?';
      this.dialogActionsFunction = async () => {
        this.dialogActions = false;
        await this.markInterviewAsCompleted(item);
      };
      this.dialogActions = true;
    },
    editInterview(item) {
      this.initInterviewData(item.id);
      this.dialogEdit = true;
    },
    setHoursFormat(startHour, endHour) {
      const start = startHour.split(':').slice(0, 2).join(':');
      const end = endHour.split(':').slice(0, 2).join(':');
      return `${start} - ${end}`;
    },
    setInterviewDateFormat(interviewDate) {
      const [startDate, startHour] = interviewDate.start.split(' ');
      const endHour = interviewDate.end.split(' ')[1];
      const date = moment(startDate);
      const hour = this.setHoursFormat(startHour, endHour);

      return `${hour}, ${date.format('dddd, MMMM DD, YYYY')}`;
    },
  },
};
</script>
<style scoped>
.nowrap-sortable-icon::v-deep .sortable {
  white-space: nowrap;
}
</style>
