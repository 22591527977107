<template>
  <v-chip
    label
    class="mr-2 mt-2"
    :color="techColors(item).chipColor"
    :text-color="techColors(item).textColor"
    :data-test-id="`tech-chip-${item}`"
    :input-value="selected"
    :close="close"
    v-bind="attrs"
    @click:close="method(item)"
    @click="select"
  >
    <v-icon left> {{ techIcon(item) }} </v-icon>
    {{ item }}
  </v-chip>
</template>

<script>
import technologies from './technologies';

export default {
  inheritAttrs: false,
  props: {
    item: {
      type: String,
      required: true,
    },
    selected: Boolean,
    method: {
      type: Function,
      default: () => {},
    },
    select: {
      type: Function,
      default: () => {},
    },
    close: {
      type: Boolean,
      default: false,
    },
    attrs: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    techIcon(item) {
      return technologies[item] ? technologies[item].icon : 'mdi-code-tags';
    },
    techColors(item) {
      const chipColor = technologies[item] ? technologies[item].color : '#e0e0e0';
      const hex = chipColor.replace('#', '');
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);
      const brightness = (r * 299 + g * 587 + b * 114) / 1000;
      const textColor = brightness > 135 ? '#000000' : '#ffffff';
      return {
        chipColor,
        textColor,
      };
    },
  },
};
</script>
