<template>
  <v-btn text class="white--blue" @click="handleAction">{{ text }}</v-btn>
</template>

<script>
export default {
  props: {
    text: { type: String, default: 'BtnText' },
    actionName: { type: String, default: 'action-name' },
  },
  methods: {
    handleAction() {
      this.$emit(this.actionName);
    },
  },
};
</script>
