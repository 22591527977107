<template>
  <div data-test-id="tech-chips-container">
    <h3 data-test-id="tech-chips-title">{{ title }} ({{ items.length }})</h3>
    <tech-chip v-for="(item, index) in items" :key="index" title="Technologies" :item="item" />
  </div>
</template>
<script>
import TechChip from './TechChip.vue';

export default {
  components: { TechChip },
  props: {
    /**
     * The title of the chips
     */
    title: {
      type: String,
      default: '',
    },
    /**
     * The items to be displayed in the chips
     */
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
