<template>
  <v-container fluid class="ma-auto pa-7">
    <v-row justify="center">
      <v-col sm="12" md="6">
        <v-card>
          <v-card-title>
            <h2>Mentee Interviewer Evaluation</h2>
          </v-card-title>
          <v-card-text>
            Please take a minute to evaluate the mentee you mentored. This is important to continue the mentorship
            process and to increase interviewers availability.
          </v-card-text>
          <v-divider />
          <v-card-text>
            This evaluation is for the
            <strong data-test-id="interview-type-evaluation">{{ interviewData.interviewTypeName }}</strong> interview
            with candidate
            <strong data-test-id="candidate-name-evaluation">{{ interviewData.candidateData.name }}</strong>
          </v-card-text>
          <v-divider />
          <v-card-text>
            The following email will be used to validate the mentee's evaluation:
            <strong data-test-id="mentor-email-evaluation">{{ userInformation.email }}</strong>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col sm="12" md="6">
        <v-card>
          <v-overlay absolute :value="isLoading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-text>
            <ValidationObserver ref="observer">
              <v-row>
                <v-col cols="12" sm="6">
                  <ValidationProvider v-slot="{ errors }" name="Email" rules="required|email">
                    <v-text-field
                      v-model="formData.mentee_email"
                      label="Mentee's Email"
                      :error-messages="errors"
                      data-test-id="mentee-email-evaluation"
                      @paste="onEmailChange"
                      @keydown.space.prevent
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="6">
                  <ValidationProvider v-slot="{ errors }" name="Attendance" rules="required">
                    <v-select
                      v-model="formData.attendance"
                      label="Mentee's Attendance"
                      :items="attendance_options"
                      item-text="text"
                      item-value="value"
                      data-test-id="mentee-attendance-evaluation"
                      :error-messages="errors"
                      @change="(value) => onAttendanceChange(value)"
                    ></v-select>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <ValidationProvider v-slot="{ errors }" name="Role" rules="required">
                    <v-select
                      v-model="formData.mentee_role"
                      label="Mentee's Role"
                      :items="role_options"
                      item-text="text"
                      item-value="value"
                      :error-messages="errors"
                      data-test-id="mentee-role-evaluation"
                      :disabled="!formData.attendance"
                      @change="(value) => onRoleChange(value)"
                    ></v-select>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="6">
                  <ValidationProvider v-slot="{ errors }" name="Evaluation" rules="required">
                    <v-select
                      v-model="formData.mentee_evaluation"
                      value="1"
                      label="Mentee's Evaluation"
                      :items="evaluation_options"
                      item-text="text"
                      item-value="value"
                      data-test-id="mentee-evaluation"
                      :error-messages="errors"
                      :disabled="!formData.attendance || formData.mentee_role == 2"
                    ></v-select>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea v-model="formData.comments" label="Comments" counter="500"></v-textarea>
                </v-col>
              </v-row>
            </ValidationObserver>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" data-test-id="save-button" :disabled="isLoading" @click="onSubmit()">
              Submit
            </v-btn>
            <v-snackbar v-model="snackbar" :timeout="5000" data-test-id="snackbar">
              {{ snackbarText }}
              <template v-slot:action="{ attrs }">
                <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
                  Close
                </v-btn>
              </template>
            </v-snackbar>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogSubmit" max-width="500px">
      <template>
        <v-card data-test-id="modal-graduate-mentee">
          <v-card-title>
            Confirm Action
          </v-card-title>
          <v-card-text>
            You are about to graduate a mentee, are you sure to continue?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="submit">
              Yes
            </v-btn>
            <v-btn color="primary" text @click="dialogSubmit = false">
              No
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import menteeEvaluationService from '../services/menteeEvaluation';

export default {
  components: { ValidationProvider, ValidationObserver },
  data() {
    return {
      snackbar: false,
      dialogSubmit: false,
      snackbarText: '',
      isLoading: false,
      attendance_options: [
        { value: false, text: 'No' },
        { value: true, text: 'Yes' },
      ],
      role_options: [
        { value: 2, text: 'Shadowed Interviewer' },
        { value: 3, text: 'Lead Interviewer' },
      ],
      evaluation_options: [
        { value: 2, text: 'Take another session' },
        { value: 3, text: 'Graduate mentee' },
      ],
      interviewData: {
        interviewTypeName: null,
        candidateData: {
          name: null,
        },
      },
      formData: {
        interview_id: this.$route.params.id,
        mentee_email: null,
        attendance: null,
        mentee_role: null,
        mentee_evaluation: null,
        comments: null,
      },
    };
  },
  computed: {
    ...mapGetters('user', { userInformation: 'getUserInformation' }),
  },
  async mounted() {
    menteeEvaluationService.getInterviewData(this.$route.params.id).then((response) => {
      this.interviewData = response;
      this.formData.mentee_email = response.menteeData.email;
    });
  },
  methods: {
    onEmailChange(event) {
      event.preventDefault();
      const paste = (event.clipboardData || window.clipboardData).getData('text').trim();
      this.formData.mentee_email = paste;
    },
    onAttendanceChange(value) {
      if (!value) {
        this.formData.mentee_role = 1;
        this.formData.mentee_evaluation = 1;
      } else {
        this.formData.mentee_role = null;
        this.formData.mentee_evaluation = null;
      }
    },
    onRoleChange(value) {
      if (value === 2) {
        this.formData.mentee_evaluation = 2;
      } else {
        this.formData.mentee_evaluation = null;
      }
    },
    async onSubmit() {
      if (this.formData.mentee_evaluation === 3) {
        this.dialogSubmit = true;
      } else {
        await this.submit();
      }
    },
    clearForm() {
      this.formData = {
        ...this.formData,
        mentee_email: null,
        attendance: null,
        mentee_role: null,
        mentee_evaluation: null,
        comments: null,
      };
      this.$refs.observer.reset();
    },
    async submit() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.isLoading = true;
        this.dialogSubmit = false;
        await menteeEvaluationService.putMenteeEvaluation(this.formData).then((response) => {
          if (response.mentee_id) {
            this.snackbarText = 'Evaluation submitted successfully';
            this.snackbar = true;
            this.clearForm();
          } else {
            this.snackbarText = response.data.message;
            this.snackbar = true;
          }
        });
      } else {
        this.snackbarText = 'Please fill out all required fields';
        this.snackbar = true;
      }
      this.isLoading = false;
    },
  },
};
</script>
