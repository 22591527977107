const SET_AUTH = (state, data) => {
  state.authData = data;
};

const SET_SCOPES_TOKEN = (state, data) => {
  state.authData.scopesToken = data;
};

export default {
  SET_AUTH,
  SET_SCOPES_TOKEN,
};
