import nock from 'nock';
import flushPromises from 'flush-promises';
import { LOCAL_STORAGE_AUTH_KEYS } from '@/constants';

const getAPIRequestInterceptorWithoutAuthorization = () => nock(process.env.VUE_APP_BACKEND_ENDPOINT);

const JWT = 'token.jwt';

const mockJWTInLocalStorage = () => {
  localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.accessToken, JWT);
};

const getAPIRequestInterceptorWithAuthorization = () => {
  mockJWTInLocalStorage();

  return nock(process.env.VUE_APP_BACKEND_ENDPOINT, {
    reqheaders: {
      authorization: `${JWT}`,
    },
  });
};

const waitForAPIRequest = async () => {
  await flushPromises();
  console.log('');
  await flushPromises();
  console.log('');
  await flushPromises();
  console.log('');
  await flushPromises();
  console.log('');
  await flushPromises();
  console.log('');
  await flushPromises();
  console.log('');
  await flushPromises();
  console.log('');
  await flushPromises();
  console.log('');
  await flushPromises();
  console.log('');
  await flushPromises();
};

const waitForAPIRequestNext = async () => {
  await flushPromises();
};

const TestingUtils = {
  getAPIRequestInterceptorWithoutAuthorization,
  getAPIRequestInterceptorWithAuthorization,
  waitForAPIRequest,
  waitForAPIRequestNext,
};

export default TestingUtils;
