/* eslint-disable camelcase */
/* eslint-disable max-classes-per-file */
import APIService from '@/services/API';
import Shared from '@/services/shared';

const getInterviewData = async (interview_id) => {
  try {
    const request = new Shared.GetInterviewServiceRequest(interview_id);
    const service = new Shared.GetInterviewService(request);
    return await service.do();
  } catch (e) {
    console.error(e);
    return [];
  }
};

const PutMenteeEvaluationServiceRequest = function constructor({
  interviewId,
  menteeEmail,
  attendance,
  menteeRole,
  menteeEvaluation,
  comments,
}) {
  this.interview_id = interviewId;
  this.mentee_email = menteeEmail;
  this.has_attended = attendance;
  this.mentee_role = menteeRole;
  this.evaluation = menteeEvaluation;
  this.comments = comments;
};

/* eslint-disable camelcase */
const PutMenteeEvaluationServiceResponse = function constructor({
  mentee_id,
  evaluated_interview_type,
  has_graduated,
}) {
  this.mentee_id = mentee_id;
  this.evaluated_interview_type = evaluated_interview_type;
  this.has_graduated = has_graduated;
};
/* eslint-enable camelcase */

class PutMenteeEvaluationService extends APIService {
  constructor(putMenteeEvaluationServiceRequest) {
    super();
    this.serviceURL = '/mentees/evaluation';
    this.request = putMenteeEvaluationServiceRequest;
  }

  async do() {
    this.setAuthorizationHeaders();
    this.setScopesHeaders();
    try {
      const response = await this.createHttpRequest().put(this.serviceURL, this.request);
      return new PutMenteeEvaluationServiceResponse(response.data);
    } catch (response) {
      return response;
    }
  }
}

const putMenteeEvaluation = async (formData) => {
  try {
    const request = new PutMenteeEvaluationServiceRequest({
      interviewId: formData.interview_id,
      menteeEmail: formData.mentee_email,
      attendance: formData.attendance,
      menteeRole: formData.mentee_role,
      menteeEvaluation: formData.mentee_evaluation,
      comments: formData.comments,
    });
    const service = new PutMenteeEvaluationService(request);
    return await service.do();
  } catch (e) {
    console.error(e);
    return [];
  }
};

export default {
  getInterviewData,
  putMenteeEvaluation,
};
