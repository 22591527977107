import storeResourceFactory from '@store/libs/storeResourceFactory';
import footerActions from './actions';
import getters from './getters';

const footerStore = storeResourceFactory('content', { version: undefined, year: undefined, text: undefined });

const state = { ...footerStore.state };

const actions = {
  initializeFooterContent: footerStore.request(footerActions.initializeFooterContent),
};

const mutations = { ...footerStore.mutations };

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
