import { createLocalVue, mount } from '@vue/test-utils';
import Vuex from 'vuex';
import Vuetify from 'vuetify';
import cloneDeep from 'lodash.clonedeep';
import dashboard from '@store/modules/dashboard';
import Dashboard from '@/pages/Dashboard.vue';
import HttpStatusCodes from '@/services/HttpStatusCodes';
import { COMPLETED_STATUS, SCHEDULED_STATUS, getStatusesResponse } from '@/constants/tests/shared.mock';
import { setupServer } from 'msw/node';
import { rest } from 'msw';
import TestingUtils from '../../../tests/TestingUtils';
import { getInterviewsResponse } from './mocks/dashboard.mock';

describe('Dashboard', () => {
  const localVue = createLocalVue();
  localVue.use(Vuex);

  const getStore = () => {
    return new Vuex.Store({
      modules: {
        dashboard: cloneDeep(dashboard),
      },
    });
  };

  const vuetify = new Vuetify();

  const build = ({ store = getStore() } = {}) => {
    const wrapper = mount(Dashboard, {
      store,
      vuetify,
      localVue,
    });

    return {
      statusesFilters: () => wrapper.findAll('[data-test-id="status-checkbox"]'),
      interviewsRows: () => wrapper.findAll('[data-test-id="interviews-table"] tbody tr'),
      wrapper,
    };
  };

  const getInterviewsFromGetInterviewsResponseFilteredByStatusIds = (statusIds) => {
    return getInterviewsResponse.filter((elem) => statusIds.includes(elem.status_id));
  };

  const getQueryParamsFromInterviewStatuses = (statusIds) => {
    return statusIds ? statusIds.map((status) => `status[]=${status}`).join('&') : '';
  };

  const server = setupServer();

  const makeGetInterviewStatusesRequestInterceptor = () => {
    server.use(
      rest.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/interview-statuses`, (req, res, ctx) => {
        return res(ctx.status(HttpStatusCodes.OK), ctx.json(getStatusesResponse));
      })
    );
  };

  const makeGetInterviewsRequestInterceptor = (statusIds) => {
    const scheduledInterviews = getInterviewsFromGetInterviewsResponseFilteredByStatusIds(statusIds);
    const queryParams = getQueryParamsFromInterviewStatuses(statusIds);
    server.use(
      rest.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/interviews`, (req, res, ctx) => {
        req.url.searchParams.set('status', queryParams);
        return res(ctx.status(HttpStatusCodes.OK), ctx.json(scheduledInterviews));
      })
    );
  };

  const waitForGetInterviewStatusesAPIRequest = async () => {
    await TestingUtils.waitForAPIRequestNext();
  };

  const waitForGetInterviewersAPIRequest = async () => {
    await TestingUtils.waitForAPIRequestNext();
  };

  it('renders statuses fetched from status endpoint', async () => {
    const unhandledRequests = [];
    const statusIds = [SCHEDULED_STATUS];
    makeGetInterviewStatusesRequestInterceptor();
    makeGetInterviewsRequestInterceptor(statusIds);

    server.listen({
      onUnhandledRequest(req) {
        unhandledRequests.push({
          method: req.method,
          url: req.url.href,
        });
        console.error(unhandledRequests.slice(-1));
      },
    });

    const { statusesFilters } = build();

    await waitForGetInterviewStatusesAPIRequest();
    await waitForGetInterviewersAPIRequest();
    expect(unhandledRequests).toHaveLength(0);
    await localVue.nextTick();

    expect(statusesFilters()).toHaveLength(getStatusesResponse.length);
    for (let index = 0; index < getStatusesResponse.length; index += 1) {
      expect(statusesFilters().at(index).html()).toContain(getStatusesResponse[index].name);
    }
  });

  // eslint-disable-next-line max-len
  it('renders scheduled interviews gotten from GET /interviews endpoint filtered by scheduled status by default', async () => {
    const unhandledRequests = [];
    const statusIds = [SCHEDULED_STATUS];
    const scheduledInterviews = getInterviewsFromGetInterviewsResponseFilteredByStatusIds(statusIds);
    makeGetInterviewStatusesRequestInterceptor();
    makeGetInterviewsRequestInterceptor(statusIds);

    server.listen({
      onUnhandledRequest(req) {
        unhandledRequests.push({
          method: req.method,
          url: req.url.href,
        });
        console.error(unhandledRequests.slice(-1));
      },
    });

    const { interviewsRows } = build();

    await waitForGetInterviewStatusesAPIRequest();
    await waitForGetInterviewersAPIRequest();
    expect(unhandledRequests).toHaveLength(0);
    await localVue.nextTick();

    await localVue.nextTick();

    expect(interviewsRows()).toHaveLength(scheduledInterviews.length);
    for (let index = 0; index < scheduledInterviews.length; index += 1) {
      const technologies = scheduledInterviews[index].technologies
        .map((item) => {
          return item.name;
        })
        .join(', ');
      expect(interviewsRows().at(index).html()).toContain(scheduledInterviews[index].interviewer_data.name);
      expect(interviewsRows().at(index).html()).toContain(scheduledInterviews[index].interviewer_data.email);
      expect(interviewsRows().at(index).html()).toContain(scheduledInterviews[index].discipline);
      expect(interviewsRows().at(index).html()).toContain(technologies);
      expect(interviewsRows().at(index).html()).toContain(scheduledInterviews[index].type);
      expect(interviewsRows().at(index).html()).toContain(scheduledInterviews[index].status);
    }
  });

  it('renders interviews when user checks a filter', async () => {
    const unhandledRequests = [];
    const scheduledStatusIds = [SCHEDULED_STATUS];
    const scheduledAndCompletedStatusIds = [SCHEDULED_STATUS, COMPLETED_STATUS];
    const scheduledAndCompletedInterviews = getInterviewsFromGetInterviewsResponseFilteredByStatusIds(
      scheduledAndCompletedStatusIds
    );

    makeGetInterviewStatusesRequestInterceptor();
    makeGetInterviewsRequestInterceptor(scheduledStatusIds);
    makeGetInterviewsRequestInterceptor(scheduledAndCompletedStatusIds);

    server.listen({
      onUnhandledRequest(req) {
        unhandledRequests.push({
          method: req.method,
          url: req.url.href,
        });
        console.error(unhandledRequests.slice(-1));
      },
    });

    const { statusesFilters, interviewsRows } = build();

    await waitForGetInterviewStatusesAPIRequest();
    await waitForGetInterviewersAPIRequest();
    expect(unhandledRequests).toHaveLength(0);

    expect(statusesFilters()).toHaveLength(getStatusesResponse.length);

    const checkbox = statusesFilters().at(1);
    checkbox.setChecked(true);
    await localVue.nextTick();

    await waitForGetInterviewersAPIRequest();

    expect(checkbox.element.checked).toBe(true);

    expect(interviewsRows()).toHaveLength(scheduledAndCompletedInterviews.length);
    scheduledAndCompletedInterviews.forEach((interview, index) => {
      expect(interviewsRows().at(index).html()).toContain(interview.status);
    });
  });
  server.resetHandlers();
  server.close();
});
