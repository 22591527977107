<template>
  <v-btn
    :color="color"
    elevation="6"
    :href="href"
    :disabled="disabled"
    :class="classes"
    :outlined="outlined"
    @click="handleAction"
    >{{ text }}</v-btn
  >
</template>

<script>
export default {
  props: {
    text: { type: String, default: 'BtnPrimary' },
    actionName: { type: String, default: 'action-name' },
    href: { type: String, required: true },
    color: { type: String, default: 'blue darken-2' },
    disabled: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: Array,
      default: () => [],
    },
    hasTooltip: {
      type: Boolean,
      default: false,
    },
    tooltipText: {
      type: String,
      default: 'Please fill all the fields.',
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleAction() {
      this.$emit(this.actionName);
    },
  },
};
</script>
