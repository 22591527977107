<template>
  <v-menu offset-y :close-on-content-click="!multiple">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="{ ...attrs, ...$attrs }" :class="classes" v-on="{ ...on, ...$listeners }">
        {{ label }}
        <v-icon right size="25"> mdi-chevron-down </v-icon>
      </v-btn>
    </template>
    <v-list>
      <template v-if="!multiple">
        <v-list-item v-for="item in items" :key="item[itemValue]" @click="itemClick(item)">
          <v-list-item-content>
            <v-list-item-title>{{ item[itemText] }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-else-if="multiple && multiList">
        <v-list-item
          v-for="(item, i) in items"
          :key="item[itemValue] + '-' + i"
          :value="item[itemValue]"
          :class="item['itemChildren'] ? 'd-block px-0 w-100' : ''"
        >
          <template v-slot:default="{ active }">
            <v-list-group v-if="item['itemChildren']">
              <template v-slot:activator>
                <v-list-item-action class="mr-3">
                  <v-checkbox
                    v-model="model"
                    :input-value="active"
                    :value="item[itemValue]"
                    @change="sendModel(item)"
                  ></v-checkbox>
                </v-list-item-action>
                <v-list-item-title class="font-weight-bold">{{ item[itemText] }}</v-list-item-title>
              </template>

              <v-list-item
                v-for="(itemChild, index) in item['itemChildren']"
                :key="itemChild[itemValue] + '-' + index"
                :value="itemChild[itemValue]"
                class="ml-3"
              >
                <template v-slot:default="{ on, attrs }">
                  <v-list-item-action class="mr-3">
                    <v-checkbox
                      v-model="modelChild"
                      v-bind="attrs"
                      :value="itemChild[itemValue]"
                      :input-value="active"
                      v-on="on"
                      @change="childChange(item, itemChild[itemValue])"
                    >
                      <template v-slot:label>
                        <span class="checkbox-label">{{ itemChild[itemText] }}</span>
                      </template>
                    </v-checkbox>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </v-list-group>
          </template>
        </v-list-item>
      </template>
      <template v-else>
        <v-list-item-group v-model="innerValue" multiple>
          <v-list-item v-for="item in items" :key="item[itemValue]" :value="item[itemValue]">
            <template v-slot:default="{ active }">
              <v-list-item-action class="mr-3">
                <v-checkbox :input-value="active" :true-value="item"></v-checkbox>
              </v-list-item-action>
              <v-list-item-title>{{ item[itemText] }}</v-list-item-title>
            </template>
          </v-list-item>
        </v-list-item-group>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    /**
     * Value set by the parent component.
     * @ignore
     */
    value: {
      type: null,
      default: null,
    },
    /**
     * The button's label.
     */
    label: {
      type: String,
      default: '',
    },
    /**
     * The text to display for each item
     */
    itemText: {
      type: String,
      default: 'text',
    },
    /**
     * The value to use for each item when using multiple selection.
     */
    itemValue: {
      type: String,
      default: 'value',
    },
    /**
     * The items to display in the dropdown.
     */
    items: {
      type: Array,
      default: () => [],
    },
    /**
     * Allow multiple selections. The v-model prop must be set.
     */
    multiple: {
      type: Boolean,
      default: false,
    },
    multiList: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    model: [],
    modelChild: [],
  }),
  computed: {
    innerValue: {
      get() {
        return Array.isArray(this.value) ? this.value : [];
      },
      set(value) {
        /**
         * Emit the input event to the parent component.
         */
        this.$emit('on-change', value);
        this.$emit('multipleSelect', value);
      },
    },
  },
  methods: {
    sendModel(item) {
      if (this.model.includes(item.value)) {
        item.itemChildren.forEach((child) => {
          const index = this.modelChild.indexOf(child.value);
          if (index === -1) {
            this.modelChild.push(child.value);
          }
        });
      } else {
        item.itemChildren.forEach((child) => {
          const index = this.modelChild.indexOf(child.value);
          index > -1 && this.modelChild.splice(index, 1);
        });
      }
      this.$emit('on-change', this.model, this.modelChild);
    },
    childChange(parent, childId) {
      const parentIndex = this.model.indexOf(parent.value);
      if (!this.modelChild.includes(childId) && parentIndex > -1) {
        this.model.splice(parentIndex, 1);
      } else {
        const allItemsChecked = parent.itemChildren.every((child) => {
          return this.modelChild.includes(child.value);
        });
        allItemsChecked && this.model.push(parent.value);
      }
      this.$emit('on-change', this.model, this.modelChild);
    },
    itemClick(item) {
      /**
       * Emitted when an item from a single select dropdown is clicked.
       */
      this.$emit('singleSelect', item);
    },
  },
};
</script>
<style scoped>
.v-btn[aria-expanded='true'] .v-icon {
  transform: rotate(-180deg);
}
.checkbox-label {
  margin-left: 5px;
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0;
}
</style>
