// eslint-disable-next-line max-classes-per-file
import APIService from '@/services/API';
import Shared from '@/services/shared';

const CreateCandidateApplicationServiceRequest = function constructor({
  name,
  lastName,
  email,
  jobName,
  greenhouseApplicationId,
  greenhouseCandidateId,
  disciplineId,
}) {
  this.name = name;
  this.last_name = lastName;
  this.email = email;
  this.job_name = jobName;
  this.greenhouse_candidate_id = greenhouseCandidateId;
  this.greenhouse_application_id = greenhouseApplicationId;
  this.discipline_id = disciplineId;
};

/* eslint-disable camelcase */
const CreateCandidateApplicationResponse = function constructor({ candidate_id, application_id }) {
  this.candidateId = candidate_id;
  this.applicationId = application_id;
};
/* eslint-enable camelcase */

class CreateCandidateApplicationService extends APIService {
  constructor(scheduleInterviewServiceRequest) {
    super();
    this.request = scheduleInterviewServiceRequest;
    this.serviceURL = '/candidates';
  }

  async do() {
    this.setAuthorizationHeaders();
    this.setScopesHeaders();
    try {
      const response = await this.createHttpRequest().post(this.serviceURL, this.request);
      return new CreateCandidateApplicationResponse(response.data);
    } catch ({ response }) {
      throw new Error(`${response.status} | ${response.data.message}`);
    }
  }
}

// eslint-disable-next-line max-len
const GetInterviewersInterviewSlotsServiceRequest = function constructor({
  interviewersEmails,
  startDatetime,
  endDatetime = null,
}) {
  this.interviewers_emails = interviewersEmails;
  this.start_datetime = startDatetime;
  this.end_datetime = endDatetime;
};

/* eslint-disable camelcase */
const InterviewsSlotsResponse = function constructor({ start_datetime, end_datetime }) {
  this.startDatetime = start_datetime;
  this.endDatetime = end_datetime;
};
/* eslint-enable camelcase */

/* eslint-disable camelcase */
const GetInterviewersInterviewSlotsServiceResponse = function constructor({ interviewer_email, slots }) {
  this.interviewerEmail = interviewer_email;
  this.slots = slots;
};
/* eslint-enable camelcase */

class GetInterviewersInterviewSlotsService extends APIService {
  constructor(getInterviewersInterviewSlotsServiceRequest) {
    super();
    this.serviceURL = 'interviewers-interviews-slots';
    this.request = getInterviewersInterviewSlotsServiceRequest;
  }

  async do() {
    this.setAuthorizationHeaders();
    this.setScopesHeaders();
    try {
      const response = await this.createHttpRequest().get(this.serviceURL, { params: this.request });
      return response.data.map((responseData) => {
        const slots = responseData.slots.map((slot) => new InterviewsSlotsResponse(slot));
        // eslint-disable-next-line max-len
        return new GetInterviewersInterviewSlotsServiceResponse({
          interviewer_email: responseData.interviewer_email,
          slots,
        });
      });
    } catch (response) {
      throw new Error(`${response.status} | ${response.data.message}`);
    }
  }
}

const ScheduleInterviewServiceRequest = function constructor({
  greenhouseLink,
  startDatetime,
  endDatetime,
  interviewTypeId,
  candidateId,
  interviewerId,
  disciplineId,
  menteeId,
  technologyId,
  expectedLevelId,
  additionalInformation,
  meetingType,
  applicationId,
}) {
  this.greenhouse_link = greenhouseLink;
  this.start_datetime = startDatetime;
  this.end_datetime = endDatetime;
  this.interview_type_id = interviewTypeId;
  this.candidate_id = candidateId;
  this.interviewer_id = interviewerId;
  this.discipline_id = disciplineId;
  this.mentee_id = menteeId;
  this.technology_id = technologyId;
  this.expected_level_id = expectedLevelId;
  this.additional_information = additionalInformation;
  this.meeting_type = meetingType;
  this.application_id = applicationId;
};

/* eslint-disable camelcase */
const ScheduleInterviewServiceResponse = function constructor({ interview_id }) {
  this.interviewId = interview_id;
};
/* eslint-enable camelcase */

class ScheduleInterviewService extends APIService {
  constructor(scheduleInterviewServiceRequest) {
    super();
    this.request = scheduleInterviewServiceRequest;
    this.serviceURL = '/interviews';
  }

  async do() {
    this.setAuthorizationHeaders();
    this.setScopesHeaders();
    try {
      const response = await this.createHttpRequest().post(this.serviceURL, this.request);

      return new ScheduleInterviewServiceResponse(response.data);
    } catch (error) {
      return error.response.data.message;
    }
  }
}
/* eslint-disable camelcase */
const GetCandidateInfoServiceRequest = function constructor({ candidateId, applicationId }) {
  this.greenhouse_candidate_id = candidateId;
  this.application_id = applicationId;
};
/* eslint-disable camelcase */
const GetCandidateInfoServiceResponse = function constructor({
  first_name,
  last_name,
  email,
  selected_application,
  job_name,
}) {
  this.firstName = first_name;
  this.lastName = last_name;
  this.email = email;
  this.discipline = selected_application;
  this.jobName = job_name;
};
class GetCandidateInfoService extends APIService {
  constructor(getCandidateInfoServiceRequest) {
    super();
    this.request = getCandidateInfoServiceRequest;
    // eslint-disable-next-line max-len
    this.serviceURL = `/greenhouse-candidates/${this.request.greenhouse_candidate_id}/applications/${this.request.application_id}`;
  }

  async do() {
    this.setAuthorizationHeaders();
    this.setScopesHeaders();
    try {
      const response = await this.createHttpRequest().get(this.serviceURL, this.request);
      return new GetCandidateInfoServiceResponse(response.data);
    } catch (error) {
      throw error.response;
    }
  }
}

export default {
  ...Shared,
  CreateCandidateApplicationServiceRequest,
  CreateCandidateApplicationService,
  ScheduleInterviewServiceRequest,
  ScheduleInterviewService,
  GetInterviewersInterviewSlotsServiceRequest,
  GetInterviewersInterviewSlotsService,
  GetCandidateInfoServiceRequest,
  GetCandidateInfoService,
};
