import VueRouter from 'vue-router';
import Vue from 'vue';
import Routes from '@/router/routes';
import { LAST_PATH_KEY } from '@/constants';
import { areRolesEmpty, isUserAllowed } from '@/permissions/permissions';
import { getUserRoleHomepage } from '@/router/pages';
import store from '../../store';

Vue.use(VueRouter);
const router = new VueRouter({ mode: 'history', routes: Routes.getRoutes() });

const parseJwt = (token) => {
  try {
    return JSON.parse(Buffer.from(token.split('.')[1], 'base64'));
  } catch (e) {
    return null;
  }
};

const isTokenExpired = (token) => {
  return !token ? false : parseJwt(token).exp < new Date().getTime() / 1000;
};

const isUserWithRolesVisitingAUserRegistrationRoute = (userRoles, to) => {
  return !areRolesEmpty(userRoles) && to.meta.userRegistration;
};

export const navigationGuard = async ({ to, next, injectedStore }) => {
  const { accessToken } = injectedStore.getters['auth/getAuthData'];
  const userRoles = injectedStore.getters['auth/getScopes'];
  const pageAllowedRoles = to.meta.allowedRoles;
  const lastRoutePath = localStorage.getItem(LAST_PATH_KEY);
  let nextPath;

  if (to.query.code) {
    try {
      await injectedStore.dispatch('auth/initializeAuthentication', { code: to.query.code });
      injectedStore.dispatch('navigation/initializeNavigationContent');
      localStorage.removeItem(LAST_PATH_KEY);
      nextPath = lastRoutePath || '/dashboard';
    } catch (error) {
      nextPath = '/login';
    }
  } else if (to.meta.requiresAuth) {
    if (!accessToken) {
      localStorage.setItem(LAST_PATH_KEY, to.fullPath);
      nextPath = '/login';
    } else if (isTokenExpired(accessToken)) {
      try {
        await injectedStore.dispatch('auth/doRefreshToken');
      } catch (error) {
        nextPath = '/login';
      }
    } else if (
      !isUserAllowed({
        allowedRoles: pageAllowedRoles,
        userRoles,
      }) ||
      isUserWithRolesVisitingAUserRegistrationRoute(userRoles, to)
    ) {
      next(getUserRoleHomepage(userRoles));
    }
  }

  if (nextPath !== undefined) {
    next(nextPath);
  } else {
    next();
  }
};

router.beforeEach(async (to, _from, next) => {
  await navigationGuard({ to, next, injectedStore: store });
});

export default router;
