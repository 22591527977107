var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"app":"","clipped-left":"","color":"#00a7e5"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-img',{staticClass:"mr-6",attrs:{"alt":"logo-wizeline","src":require('../../assets/wordmark_white.svg'),"width":"185","max-width":"300","max-height":"64","contain":"","aspect-ratio":"1"},on:{"click":_vm.goToMain}}),_c('v-toolbar-title',{staticClass:"white--text text-subtitle-1"},[_vm._v(_vm._s(_vm.title))])],1),_c('v-spacer'),(_vm.userInformation.name)?_c('div',{staticClass:"d-flex mr-1 mr-sm-5"},[(!_vm.$vuetify.breakpoint.xsOnly)?_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"mr-5",attrs:{"size":"38px"}},[_c('v-img',{attrs:{"data-test-id":"user-image","src":_vm.userInformation.picture,"alt":"user-icon"}})],1),_c('div',{staticClass:"mr-1 font-weight-medium white--text",attrs:{"data-test-id":"user-name"}},[_vm._v(" "+_vm._s(_vm.userInformation.name)+" ")]),(_vm.scopes ? _vm.scopes.includes('interviewer') : false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"white","to":"/interviewer-settings"}},on),[_c('v-icon',[_vm._v("mdi-account-cog")])],1)]}}],null,false,485097496)},[_c('span',[_vm._v(" Interviewer Settings ")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"white","to":_vm.cognitoUrl},on:{"click":_vm.logout}},on),[_c('v-icon',[_vm._v("mdi-logout")])],1)]}}],null,false,53628767)},[_c('span',[_vm._v(" Log Out ")])])],1):_c('v-menu',{attrs:{"max-width":"325"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,673892800)},[_c('v-list',{attrs:{"close-on-content-click":false}},[_c('v-list-item',[_c('v-list-item-avatar',{staticClass:"mr-3",attrs:{"size":"60px"}},[_c('img',{attrs:{"data-test-id":"user-image","src":_vm.userInformation.picture,"alt":"user-icon"}})]),_c('v-list-item-content',[_c('v-list-item-title',{attrs:{"data-test-id":"user-name"}},[_vm._v(" "+_vm._s(_vm.userInformation.name)+" ")]),_c('v-list-item-subtitle',{attrs:{"data-test-id":"user-email"}},[_vm._v(" "+_vm._s(_vm.userInformation.email)+" ")])],1)],1),(_vm.scopes ? _vm.scopes.includes('interviewer') : false)?[_c('v-divider'),_c('v-list-item',{attrs:{"to":"/interviewer-settings"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-cog")])],1),_c('v-list-item-title',[_vm._v("Interviewer Settings")])],1)]:_vm._e(),_c('v-divider'),_c('v-list-item',{attrs:{"to":_vm.cognitoUrl},on:{"click":_vm.logout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout")])],1),_c('v-list-item-title',[_vm._v("Logout")])],1)],2)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }