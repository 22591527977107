// eslint-disable-next-line max-classes-per-file
import APIService from './API';
import Shared from './shared';
import Dashboard from './dashboard';

const ApplicationsServiceRequest = function constructor({ status, disciplines }) {
  this.status = status;
  this.discipline_id = disciplines;
};

/* eslint-disable camelcase */
const ApplicationsServiceResponse = function constructor({
  id,
  name,
  email,
  greenhouse_application_id,
  greenhouse_candidate_id,
  interviews,
  discipline,
  stage,
  job_name,
}) {
  this.id = id;
  this.name = name;
  this.email = email;
  this.greenhouseApplicationId = greenhouse_application_id;
  this.greenhouseCandidateId = greenhouse_candidate_id;
  this.interviews = interviews;
  this.discipline = discipline;
  this.stage = stage;
  this.jobName = job_name;
  this.searchContent = [
    name,
    email,
    discipline.name,
    interviews.map((value) => value.interviewer_data.name),
    interviews.map((value) => value.type),
    stage,
  ];
};
/* eslint-enable camelcase */

class GetApplicationsService extends APIService {
  constructor(applicationsServiceRequest) {
    super();
    this.serviceURL = '/application';
    this.request = applicationsServiceRequest;
  }

  async do() {
    this.setAuthorizationHeaders();
    this.setScopesHeaders();
    try {
      const response = await this.createHttpRequest().get(this.serviceURL, { params: this.request });
      return response.data.map((responseData) => new ApplicationsServiceResponse(responseData));
    } catch (response) {
      throw new Error(`Response | ${response}`);
    }
  }
}

const getApplications = async ({ status, disciplines }) => {
  try {
    const request = new ApplicationsServiceRequest({
      status,
      disciplines,
    });
    const service = new GetApplicationsService(request);
    return await service.do();
  } catch (error) {
    console.error(error);
    return [];
  }
};

const getDisciplines = async () => {
  try {
    const request = new Shared.GetDisciplinesServiceRequest();
    const service = new Shared.GetDisciplinesService(request);
    return await service.do();
  } catch (e) {
    console.error(e);
    return [];
  }
};

const cancelInterviewRequest = async (interviewId) => {
  try {
    const request = new Dashboard.CancelInterviewServiceRequest(interviewId);
    const service = new Dashboard.CancelInterviewService(request);
    return service.do();
  } catch (error) {
    console.error(error);
    return [];
  }
};
const getMenteesForInterview = async (interviewTypeId, disciplineId) => {
  try {
    const request = new Shared.SearchMenteesServiceRequest({
      interviewTypeId,
      disciplineId,
    });
    const service = new Shared.SearchMenteesService(request);
    return service.do();
  } catch (error) {
    console.error(error);
    return [];
  }
};

const PutAddMenteeToInterviewServiceRequest = function constructor({ interviewId, menteeId }) {
  this.interview_id = interviewId;
  this.mentee_id = menteeId;
};
class PutAddMenteeToInterviewService extends APIService {
  constructor(putAddMenteeToInterviewRequest) {
    super();
    this.serviceURL = '/interviews/add-mentee';
    this.request = putAddMenteeToInterviewRequest;
  }

  async do() {
    this.setAuthorizationHeaders();
    this.setScopesHeaders();
    try {
      return await this.createHttpRequest().put(this.serviceURL, this.request);
    } catch (response) {
      throw new Error(response);
    }
  }
}
const putAddMenteeToInterview = async (interviewId, menteeId) => {
  try {
    const request = new PutAddMenteeToInterviewServiceRequest({
      interviewId,
      menteeId,
    });
    const service = new PutAddMenteeToInterviewService(request);
    return await service.do();
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export {
  getApplications,
  getDisciplines,
  cancelInterviewRequest,
  ApplicationsServiceRequest,
  GetApplicationsService,
  getMenteesForInterview,
  putAddMenteeToInterview,
};
