import APIService from '@/services/API';

const GetAccessTokenServiceRequest = function constructor({ code }) {
  this.code = code;
};
/* eslint-disable camelcase */
const GetAccessTokenServiceResponse = function constructor({ access_token, refresh_token, expires_in, scopes_token }) {
  this.accessToken = access_token;
  this.refreshToken = refresh_token;
  this.expiresIn = expires_in;
  this.scopesToken = scopes_token;
};

class GetAccessTokenService extends APIService {
  constructor(getAccessTokenRequest) {
    super();
    this.serviceURL = 'auth_token';
    this.request = getAccessTokenRequest;
  }

  async do() {
    try {
      const response = await this.createHttpRequest().post(this.serviceURL, this.request);
      return new GetAccessTokenServiceResponse(response.data);
    } catch ({ response }) {
      throw new Error(`${response.status} | ${response.data.message}`);
    }
  }
}

export { GetAccessTokenService, GetAccessTokenServiceResponse, GetAccessTokenServiceRequest };
