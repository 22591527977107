<template>
  <v-autocomplete
    :value="value"
    :items="items"
    :return-object="returnObject"
    :item-text="itemText"
    :item-value="itemValue"
    :label="label"
    :disabled="disabled"
    :error="error"
    :error-messages="errorMessages"
    clearable
    @input="handleInput"
  />
</template>

<script>
export default {
  props: {
    items: { type: Array, default: () => [] },
    returnObject: { type: Boolean, default: true },
    isDisabled: { type: Boolean, default: false },
    label: { type: String, default: '' },
    itemText: { type: String, default: '' },
    itemValue: { type: String, default: 'id' },
    value: { type: [String, Object], default: () => {} },
    error: { type: Boolean, default: false },
    errorMessages: { type: String, default: null },
    clearable: { type: Boolean, default: true },
  },
  computed: {
    disabled() {
      return this.isDisabled;
    },
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },
  },
};
</script>
