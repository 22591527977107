<template>
  <v-data-table
    :headers="headers"
    :items="candidates"
    :loading="loadingApplications"
    loading-text="Loading completed applications... Please wait"
    fixed-header
    v-bind="$attrs"
    data-test-id="completed-table"
    v-on="$listeners"
  >
    <template v-for="(_, slot) in $slots" v-slot:[slot]>
      <!-- @slot Slots passed to vuetify component. -->
      <slot :name="slot" />
    </template>
    <template v-slot:[`item.interviewers`]="{ item }">
      <v-list color="transparent">
        <v-list-item
          v-for="interview in item.interviews"
          :key="interview.interview_id"
          class="pl-0 my-n1 py-1"
          :style="{ minHeight: '30px' }"
        >
          <span>{{ interview.interviewer_data.name }}</span>
        </v-list-item>
      </v-list>
    </template>
    <template v-slot:[`item.stage`]="{ item }">
      <v-chip
        label
        outlined
        :color="stageColor(item.stage)"
        text-color="black"
        :class="'ma-1 bg-' + stageColor(item.stage)"
        small
      >
        <b>
          {{ item.stage.toLocaleUpperCase() }}
        </b>
      </v-chip>
    </template>
    <template v-slot:[`item.interviews`]="{ item }">
      <interview-tag v-if="item.interviews.length" :items="item.interviews" @edit="(id) => edit(id)" />
      <label v-else data-test-id="no-interviews-label">No interviews scheduled</label>
    </template>
  </v-data-table>
</template>

<script>
import moment from 'moment';
import InterviewTag from '../InterviewTag/InterviewTag.vue';

export default {
  components: {
    InterviewTag,
  },
  inheritAttrs: false,
  props: {
    /**
     * List of candidates
     */
    candidates: {
      type: Array,
      required: true,
    },
    loadingApplications: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    headers: [
      { text: 'Name', value: 'name', width: '20%' },
      { text: 'Discipline', value: 'discipline.name', width: '15%' },
      { text: 'Interviewer(s)', value: 'interviewers', width: '20%' },
      { text: 'Scheduled Interview(s)', value: 'interviews', width: '35%' },
      { text: 'Stage', value: 'stage', width: '10%' },
    ],
  }),
  methods: {
    formattedDate(date) {
      return moment(date).format('dddd, MMMM DD, YYYY');
    },
    stageColor(stage) {
      if (stage === 'offer') {
        return 'green';
      }
      if (stage === 'rejected') {
        return 'red';
      }
      return 'yellow';
    },
  },
};
</script>

<style scoped>
::v-deep .v-data-footer {
  margin-right: 0px !important;
}

::v-deep tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

::v-deep tbody tr .v-chip.v-chip--outlined {
  border-width: 2px;
}

::v-deep tbody tr .v-chip.v-chip--outlined.bg-green {
  background-color: rgba(75, 175, 80, 0.25) !important;
}

::v-deep tbody tr .v-chip.v-chip--outlined.bg-yellow {
  background-color: rgba(255, 234, 60, 0.25) !important;
}

::v-deep tbody tr .v-chip.v-chip--outlined.bg-red {
  background-color: rgba(244, 69, 56, 0.25) !important;
}
</style>
