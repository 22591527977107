const getCandidatesResponse = {
  pending: [
    {
      id: 1,
      name: 'Enrique',
      lastName: 'De La Fuente',
      greenhouseApplicationId: '218013523',
      greenhouseCandidateId: '198613227',
      email: 'enrique.delafuente@wizeline.com',
      interviews: [],
      onSiteSince: '2021-10-29',
      discipline: {
        name: 'Software Engineering',
        id: 1,
        description: null,
        slug: 'swe',
      },
    },
    {
      id: 2,
      name: 'Leslie',
      lastName: 'Figueroa',
      greenhouseApplicationId: '218013523',
      greenhouseCandidateId: '198613227',
      email: 'leslie.figueroa@wizeline.com',
      interviews: [],
      onSiteSince: '2022-11-01',
      discipline: {
        name: 'Software Engineering',
        id: 1,
        description: null,
        slug: 'swe',
      },
    },
    {
      id: 3,
      name: 'Noé',
      lastName: 'Lopez',
      greenhouseApplicationId: '218013523',
      greenhouseCandidateId: '198613227',
      email: 'noe.lopez@wizeline.com',
      interviews: [],
      onSiteSince: '2022-11-01',
      discipline: {
        name: 'Software Engineering',
        id: 1,
        description: null,
        slug: 'swe',
      },
    },
    {
      id: 4,
      name: 'John',
      lastName: 'Smith',
      greenhouseApplicationId: '218013523',
      greenhouseCandidateId: '198613227',
      email: 'john.smith@wizeline.com',
      interviews: [],
      onSiteSince: '2022-11-01',
      discipline: {
        name: 'Software Engineering',
        id: 1,
        description: null,
        slug: 'swe',
      },
    },
    {
      id: 5,
      name: 'Juan',
      lastName: 'Perez',
      greenhouseApplicationId: '218013523',
      greenhouseCandidateId: '198613227',
      email: 'juan.perez@wizeline.com',
      interviews: [],
      onSiteSince: '2022-11-01',
      discipline: {
        name: 'Quality Assurance Engineering',
        id: 4,
        description: null,
        slug: 'qa',
      },
    },
  ],
  scheduled: [
    {
      discipline: { id: 3, name: 'Data Engineering' },
      email: 'test4@gmail.com',
      greenhouse_application_id: 218013523,
      greenhouse_candidate_id: 198613227,
      id: 1,
      interviews: [
        {
          greenhouse_link:
            'https://wizeline.greenhouse.io/guides/25173105/people/198613227/interview?application_id=218013523',
          interview_date: { start: '2023-03-20T19:00:00', end: '2023-03-20T20:00:00' },
          interview_id: 1,
          interviewer_data: { id: 1, email: 'nicolas.herrera@team.wizeline.com', name: 'Nicolás Herrera' },
          status: 'Completed',
          status_description: null,
          status_id: 2,
          technologies: [],
          type: 'Pair Programming',
          type_id: 3,
        },
      ],
      name: 'Juan Pablo Lopez Rodriguez',
      stage: 'on-site',
    },
    {
      discipline: { id: 3, name: 'Quality Assurance Engineering' },
      email: 'test3@gmail.com',
      greenhouse_application_id: 218013523,
      greenhouse_candidate_id: 198613227,
      id: 2,
      interviews: [
        {
          greenhouse_link:
            'https://wizeline.greenhouse.io/guides/25173105/people/198613227/interview?application_id=218013523',
          interview_date: { start: '2023-03-20T19:00:00', end: '2023-03-20T20:00:00' },
          interview_id: 2,
          interviewer_data: { id: 1, email: 'nicolas.herrera@team.wizeline.com', name: 'Nicolás Herrera' },
          status: 'Completed',
          status_description: null,
          status_id: 2,
          technologies: [],
          type: 'Pair Programming',
          type_id: 3,
        },
        {
          greenhouse_link:
            'https://wizeline.greenhouse.io/guides/25173105/people/198613227/interview?application_id=218013523',
          interview_date: { start: '2023-03-20T19:00:00', end: '2023-03-20T20:00:00' },
          interview_id: 3,
          interviewer_data: { id: 1, email: 'nicolas.herrera@team.wizeline.com', name: 'Nicolás Herrera' },
          status: 'Scheduled',
          status_description: null,
          status_id: 1,
          technologies: [],
          type: 'Test Automation Assessment',
          type_id: 3,
        },
      ],
      name: 'Test3 Test3',
      stage: 'on-site',
    },
  ],
  completed: [
    {
      discipline: { id: 3, name: 'Data Engineering' },
      email: 'test4@gmail.com',
      greenhouse_application_id: 218013523,
      greenhouse_candidate_id: 198613227,
      id: 1,
      interviews: [
        {
          greenhouse_link:
            'https://wizeline.greenhouse.io/guides/25173105/people/198613227/interview?application_id=218013523',
          interview_date: { start: '2023-03-20T19:00:00', end: '2023-03-20T20:00:00' },
          interview_id: 1,
          interviewer_data: { id: 1, email: 'nicolas.herrera@team.wizeline.com', name: 'Nicolás Herrera' },
          status: 'Completed',
          status_description: null,
          status_id: 2,
          technologies: [],
          type: 'Pair Programming',
          type_id: 3,
        },
      ],
      name: 'Juan Pablo Lopez Rodriguez',
      stage: 'offer',
    },
    {
      discipline: { id: 3, name: 'Software Engineering' },
      email: 'test@gmail.com',
      greenhouse_application_id: 218013523,
      greenhouse_candidate_id: 198613227,
      id: 2,
      interviews: [
        {
          greenhouse_link:
            'https://wizeline.greenhouse.io/guides/25173105/people/198613227/interview?application_id=218013523',
          interview_date: { start: '2023-03-20T19:00:00', end: '2023-03-20T20:00:00' },
          interview_id: 2,
          interviewer_data: { id: 1, email: 'nicolas.herrera@team.wizeline.com', name: 'Nicolás Herrera' },
          status: 'Completed',
          status_description: null,
          status_id: 2,
          technologies: [],
          type: 'Pair Programming',
          type_id: 3,
        },
      ],
      name: 'Test Test',
      stage: 'rejected',
    },
  ],
};

export { getCandidatesResponse };
