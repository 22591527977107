import storeResourceFactory from '@store/libs/storeResourceFactory';
import userActions from './actions';
import getters from './getters';

const userInformationStore = storeResourceFactory('information', {
  name: undefined,
  email: undefined,
  picture: undefined,
});

const state = { ...userInformationStore.state };

const actions = {
  initializeUserInformation: userInformationStore.request(userActions.initUserInformation),
};

const mutations = { ...userInformationStore.mutations };

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
