<template>
  <v-container fluid class="ma-auto pa-7">
    <v-row>
      <v-col sm="12" md="8" lg="6" class="d-flex">
        <h1>User Settings</h1>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col>
        <v-card :loading="isLoading">
          <v-overlay absolute :value="false">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-text>
            <v-row>
              <v-col>
                <h4 class="text-h5">
                  <v-icon color="blue" size="30px">mdi-account-circle</v-icon>
                  Basic Information
                </h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Email"
                  :value="settings.email"
                  :disabled="isLoading"
                  outlined
                  filled
                  readonly
                  hide-details
                  data-test-id="email"
                  dense
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="settings.level.id"
                  :items="levels"
                  item-text="name"
                  item-value="id"
                  outlined
                  label="Select your level"
                  hide-details
                  data-test-id="interviewer-level"
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col class="mt-3">
                <h4 class="text-h5">
                  <v-icon color="blue" size="30px">mdi-table-account</v-icon>
                  My Interviews
                </h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <MyInterviewsSection :interviews="interviews" :is-loading="isLoading" />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col class="mt-3">
                <h4 class="text-h5">
                  <v-icon color="blue" size="30px">mdi-chart-line</v-icon>
                  Insights
                </h4>
              </v-col>
            </v-row>
            <insights-section :cards="cards"></insights-section>
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col class="mt-3">
                <h4 class="text-h5">
                  <v-icon color="blue" size="30px">mdi-cog</v-icon>
                  Interviewer Settings
                </h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card outlined flat class="px-5">
                  <base-radio-group
                    v-model="settings.availability"
                    label="Availability"
                    rules="required"
                    :disabled="isLoading"
                    :options="options.availability"
                  />
                </v-card>
              </v-col>
              <v-col v-if="settings.discipline.id == 1">
                <v-autocomplete
                  v-model="settings.technologies"
                  :items="technologies"
                  item-text="name"
                  chips
                  attach
                  clearable
                  label="Select your technologies"
                  multiple
                  solo
                  return-object
                  data-test-id="interviewer-technologies"
                >
                  <template v-slot:selection="{ attrs, item, select, selected }">
                    <tech-chip
                      :item="item.name"
                      :selected="selected"
                      :method="remove"
                      v-bind="attrs"
                      :close="true"
                      :select="select"
                      :attrs="attrs"
                    >
                    </tech-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="d-flex justify-center">
            <v-btn color="primary" :disabled="isLoading" data-test-id="save-button" @click="saveSettings">
              Save
            </v-btn>
            <v-snackbar v-model="snackbar" :timeout="3000" data-test-id="snackbar">
              {{ snackbarText }}
              <template v-slot:action="{ attrs }">
                <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
                  Close
                </v-btn>
              </template>
            </v-snackbar>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BaseRadioGroup from '@/components/BaseRadioGroup/BaseRadioGroup.vue';
import TechChip from '@/components/Interviewers/TechChips/TechChip.vue';
import MyInterviewsSection from '../components/UserSettings/MyInterviewsSection/MyInterviewsSection.vue';

import { getUserSettings, putUserSettings, getTechnologies, getLevelsData } from '../services/userSettings';
import InsightsSection from '../components/UserSettings/InsightsSection.vue';
import { formatTwoDatesAsTimeRange } from '../helpers/index';

export default {
  components: { BaseRadioGroup, TechChip, InsightsSection, MyInterviewsSection },
  data() {
    return {
      isLoading: false,
      snackbar: false,
      snackbarText: '',
      settings: {
        email: '',
        availability: '',
        level: {},
        discipline: [{ id: '', name: '' }],
        technologies: [{ id: '', name: '' }],
        graduatedInterviewTypes: [],
        shadowingInterviewTypes: [],
        leadingInterviewTypes: [],
        currentYearCompletedInterviews: 0,
        lastYearCompletedInterviews: 0,
      },
      technologies: [{ id: '', name: '' }],
      levels: [],
      options: {
        availability: [
          {
            text: 'Low',
            value: 'low',
            hint: '1 Interview per week',
          },
          {
            text: 'Normal',
            value: 'normal',
            hint: '2 Interviews per week',
          },
          {
            text: 'High',
            value: 'high',
            hint: '4 Interviews per week',
          },
          {
            text: 'GT Turbo',
            value: 'gt-turbo',
            hint: 'No limit',
          },
        ],
      },
      cards: [],
      interviews: {
        scheduled: [],
        completed: [],
        canceled: [],
      },
    };
  },
  mounted() {
    this.isLoading = true;
    getUserSettings().then((response) => {
      this.isLoading = false;
      this.settings = response;
      this.interviews = response.interviews
        ? response.interviews.reduce(
            (acc, item) => {
              const {
                status: { name },
              } = item;
              if (!name) return acc;
              const loweredName = name.toLowerCase();
              item.formattedDate = formatTwoDatesAsTimeRange(item.interview_date.start, item.interview_date.end);

              switch (loweredName) {
                case 'scheduled':
                  acc.scheduled.push(item);
                  break;
                case 'completed':
                  acc.completed.push(item);
                  break;
                case 'canceled':
                  acc.canceled.push(item);
                  break;
                default:
                  break;
              }

              return acc;
            },
            { scheduled: [], completed: [], canceled: [] }
          )
        : {
            scheduled: [],
            completed: [],
            canceled: [],
          };
      this.cards = [
        {
          title: 'Statistics',
          subtitle: 'Total number of interviews you attended during the past year and the current one',
          icon: 'mdi-chart-bar',
          interviewTypes: [
            {
              interview_type_name: 'Interviews completed this year',
              number_of_interviews: response.currentYearCompletedInterviews,
            },
            {
              interview_type_name: 'Interviews completed last year',
              number_of_interviews: response.lastYearCompletedInterviews,
            },
          ],
          slug: 'statistics-card',
        },
        {
          title: 'Graduated',
          subtitle: `Interview types you're expert and youre able to conduct the interview`,
          icon: 'mdi-school-outline',
          interviewTypes: response.graduatedInterviewTypes,
          slug: 'graduated-card',
          notInterviewTypes: `Currently you're not graduated in any interview type`,
        },
        {
          title: 'Mentee/Leading',
          subtitle: 'Role assigned to the mentee or interviewer that is conducting the interview',
          icon: 'mdi-account-multiple-outline',
          interviewTypes: response.leadingInterviewTypes,
          slug: 'leading-card',
          notInterviewTypes: `Currently you're not leading interviews as a mentee yet`,
        },
        {
          title: 'Mentee/Shadowing',
          subtitle: 'Role assigned to the mentee or interviewer that is only observing the interview',
          icon: 'mdi-incognito',
          interviewTypes: response.shadowingInterviewTypes,
          slug: 'shadowing-card',
          notInterviewTypes: `Currently you're not shadowing interviews as a mentee yet`,
        },
      ];
    });
    getTechnologies().then((response) => {
      this.technologies = response;
    });
    getLevelsData().then((response) => {
      this.levels = response;
    });
  },
  methods: {
    async saveSettings() {
      this.isLoading = true;
      await putUserSettings(this.settings)
        .then(() => {
          this.showSnackbar('Settings saved successfully');
        })
        .catch(() => {
          this.showSnackbar('Error saving settings');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    remove(item) {
      const index = this.settings.technologies.map((tech) => tech.name).indexOf(item);
      this.settings.technologies.splice(index, 1);
    },
    showSnackbar(message) {
      this.snackbarText = message;
      this.snackbar = true;
    },
  },
};
</script>
<style scoped>
.interviews-subtext {
  font-size: 1.06rem;
}
</style>
