/* eslint-disable max-classes-per-file */
import Dashboard from './dashboard';
import APIService from './API';
import Shared from './shared';

const GetInterviewerSlotsHoursServiceRequest = function constructor({ interviewerId }) {
  this.interviewer_id = interviewerId;
};

/* eslint-disable camelcase */
const GetInterviewerSlotsHoursResponse = function constructor({ next_week_slots_hours, current_week_slots_hours }) {
  this.nextWeekSlotsHours = next_week_slots_hours;
  this.currentWeekSlotsHours = current_week_slots_hours;
};
/* eslint-enable camelcase */

class GetInterviewerSlotsHoursService extends APIService {
  constructor(getInterviewerSlotsHoursServiceRequest) {
    super();
    this.request = getInterviewerSlotsHoursServiceRequest;
    this.serviceURL = `interviewers/${this.request.interviewer_id}/slots-hours`;
  }

  async do() {
    this.setAuthorizationHeaders();
    this.setScopesHeaders();
    try {
      const response = await this.createHttpRequest().get(this.serviceURL);
      return new GetInterviewerSlotsHoursResponse(response.data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

const PutGraduateMenteeServiceRequest = function constructor({ interviewerId, interviewTypeId }) {
  this.interviewer_id = interviewerId;
  this.interview_type_id = interviewTypeId;
};

/* eslint-disable camelcase */
const PutGraduateMenteeResponse = function constructor({ interviewer_id, interview_type_name, has_graduated }) {
  this.interviewerId = interviewer_id;
  this.interviewTypeName = interview_type_name;
  this.hasGraduated = has_graduated;
};

class PutGraduateMenteeService extends APIService {
  constructor(putGraduateMenteeServiceRequest) {
    super();
    this.request = putGraduateMenteeServiceRequest;
    this.serviceURL = '/graduate-mentee';
  }

  async do() {
    this.setAuthorizationHeaders();
    this.setScopesHeaders();
    try {
      const response = await this.createHttpRequest().put(this.serviceURL, this.request);
      return new PutGraduateMenteeResponse(response.data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

const getInterviewerSlotsHours = async (interviewerId) => {
  try {
    const request = new GetInterviewerSlotsHoursServiceRequest({
      interviewerId,
    });
    const service = new GetInterviewerSlotsHoursService(request);
    return service.do();
  } catch (error) {
    console.error(error);
    return [];
  }
};

const getInterviewers = async ({ regions, status, disciplines, technologies, interviewTypes }) => {
  try {
    const request = new Dashboard.GetInterviewersServiceRequest({
      regions,
      disciplines,
      technologies,
      graduated: status.includes('graduated'),
      leading: status.includes('leading'),
      shadowing: status.includes('shadowing'),
      interviewTypes: interviewTypes?.general,
      interviewTypesGraduated: interviewTypes?.graduated,
      interviewTypesLeading: interviewTypes?.leading,
      interviewTypesShadowing: interviewTypes?.shadowing,
    });
    const service = new Dashboard.GetInterviewersService(request);
    return await service.do();
  } catch (error) {
    console.error(error);
    return [];
  }
};
const getRegions = async () => {
  try {
    const request = new Shared.GetCitiesServiceRequest();
    const service = new Shared.GetCitiesService(request);
    return await service.do();
  } catch (e) {
    console.error(e);
    return [];
  }
};
const getDisciplines = async () => {
  try {
    const request = new Shared.GetDisciplinesServiceRequest();
    const service = new Shared.GetDisciplinesService(request);
    return await service.do();
  } catch (e) {
    console.error(e);
    return [];
  }
};

const getInterviewTypes = async (id) => {
  try {
    const request = new Shared.GetInterviewTypesServiceRequest({ disciplineId: id });
    const service = new Shared.GetInterviewTypesService(request);
    return await service.do();
  } catch (e) {
    console.error(e);
    return [];
  }
};
const getTechnologies = async () => {
  try {
    const request = new Shared.GetTechnologiesServiceRequest();
    const service = new Shared.GetTechnologiesService(request);
    return await service.do();
  } catch (e) {
    console.error(e);
    return [];
  }
};

const graduateMentee = async (interviewerId, interviewTypeId) => {
  try {
    const request = new PutGraduateMenteeServiceRequest({
      interviewerId,
      interviewTypeId,
    });
    const service = new PutGraduateMenteeService(request);
    return service.do();
  } catch (e) {
    console.error(e);
    return [];
  }
};

export {
  getInterviewerSlotsHours,
  getInterviewers,
  getRegions,
  getDisciplines,
  getInterviewTypes,
  getTechnologies,
  graduateMentee,
};
