<template>
  <v-menu
    ref="menu"
    v-model="menu"
    transition="scale-transition"
    offset-y
    min-width="290px"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <validation-provider
        v-slot="{ errors }"
        :name="$attrs.name || $attrs.label"
        :rules="rules"
        v-bind="validatorProps"
      >
        <v-text-field
          :value="innerValue"
          :class="{ 'label-required': required }"
          :error-messages="errors"
          placeholder="Select a time"
          readonly
          v-bind="{ ...attrs, ...$attrs }"
          v-on="{ ...on, ...$listeners }"
          @click:append="on.click"
        >
          <template v-for="(_, slot) in $slots" v-slot:[slot]>
            <!-- @slot Slots passed to vuetify component. -->
            <slot :name="slot" />
          </template>
        </v-text-field>
      </validation-provider>
    </template>
    <v-time-picker
      v-if="!digital"
      v-model="innerValue"
      ampm-in-title
      :format="format"
      v-bind="timePickerProps"
      @click:minute="$refs.menu.save(innerValue)"
    />
    <digital-time-picker v-else v-model="innerValue" :format="format" :minute-step="minuteStep" />
  </v-menu>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import DigitalTimePicker from '../DigitalTimePicker/DigitalTimePicker.vue';

export default {
  components: {
    ValidationProvider,
    DigitalTimePicker,
  },
  inheritAttrs: false,
  props: {
    /**
     * Value set by the parent component.
     * @ignore
     */
    value: {
      type: null,
      default: null,
    },
    /**
     * Rules for the input from vee-validate.
     * @see https://vee-validate.logaretm.com/v2/guide/rules.html
     */
    rules: {
      type: [String, Object],
      default: '',
    },
    /**
     * Props for the validation provider.
     * @see https://vee-validate.logaretm.com/v2/guide/components/validation-provider.html#props
     */
    validatorProps: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Date format used by the time picker.
     */
    format: {
      type: String,
      default: '24hr',
      validator(value) {
        return ['ampm', '24hr'].includes(value);
      },
    },
    /**
     * Shows a digital clock with dropdowns instead of the analog clock.
     */
    digital: {
      type: Boolean,
      default: false,
    },
    /**
     * Used for the digital clock, sets the interval between minutes options.
     */
    minuteStep: {
      type: Number,
      default: 1,
      validator: (value) => {
        return value >= 1 && value <= 60;
      },
    },
    /**
     * Props passed to the v-time-picker component (analog clock).
     */
    timePickerProps: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(value) {
        /**
         * Emit the input event to the parent component.
         */
        this.$emit('input', value);
      },
    },
    required() {
      if (this.rules) {
        if (typeof this.rules === 'string') {
          return this.rules.includes('required');
        }
        return this.rules.required;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.label-required::v-deep label::after {
  content: ' *';
  color: #d32f2f;
}
</style>
