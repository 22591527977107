/* eslint-disable max-classes-per-file */
import APIService from '@/services/API';
import Shared from '@/services/shared';

const getLevelsData = async () => {
  try {
    const request = new Shared.GetLevelsServiceRequest();
    const service = new Shared.GetLevelsService(request);
    return await service.do();
  } catch (e) {
    console.error(e);
    return [];
  }
};

const getDisciplinesData = async () => {
  try {
    const request = new Shared.GetDisciplinesServiceRequest();
    const service = new Shared.GetDisciplinesService(request);
    return await service.do();
  } catch (e) {
    console.error(e);
    return [];
  }
};

const getCitiesData = async () => {
  try {
    const request = new Shared.GetCitiesServiceRequest();
    const service = new Shared.GetCitiesService(request);
    return await service.do();
  } catch (e) {
    console.error(e);
    return [];
  }
};

/* eslint-disable camelcase */
const PostTAServiceRequest = function constructor({
  name,
  last_name,
  bamboo_employee_id,
  assigned_disciplines_ids,
  join_date,
  level_id,
  city_id,
}) {
  this.name = name;
  this.last_name = last_name;
  this.bamboo_employee_id = bamboo_employee_id;
  this.assigned_disciplines_ids = assigned_disciplines_ids;
  this.join_date = new Date(join_date).toISOString();
  this.level_id = level_id;
  this.city_id = city_id;
};

const PostTAServiceResponse = function constructor({ talent_acquisition_id, scopes_token }) {
  this.talent_acquisition_id = talent_acquisition_id;
  this.scopes_token = scopes_token;
};
/* eslint-enable camelcase */

class PostTAService extends APIService {
  constructor(postTAServiceRequest) {
    super();
    this.serviceURL = '/talent-acquisition';
    this.request = postTAServiceRequest;
  }

  async do() {
    this.setAuthorizationHeaders();
    try {
      const response = await this.createHttpRequest().post(this.serviceURL, this.request);
      return new PostTAServiceResponse(response.data);
    } catch (error) {
      throw error.response;
    }
  }
}

const postTA = async (formData) => {
  try {
    const request = new PostTAServiceRequest({
      name: formData.name,
      last_name: formData.last_name,
      bamboo_employee_id: formData.bamboo_employee_id,
      level_id: formData.level_id,
      assigned_disciplines_ids: formData.assigned_disciplines_ids,
      join_date: formData.join_date,
      city_id: formData.city_id,
    });
    const service = new PostTAService(request);
    return await service.do();
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default {
  getLevelsData,
  getDisciplinesData,
  getCitiesData,
  postTA,
};
