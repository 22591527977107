import storeResourceFactory from '@store/libs/storeResourceFactory';
import { initNavigationContent } from '@store/modules/navigation/actions';
import getters from './getters';

const navigationContentStore = storeResourceFactory('content', [
  {
    name: undefined,
    icon: undefined,
    link: undefined,
  },
]);

const state = { ...navigationContentStore.state };

const actions = {
  initializeNavigationContent: navigationContentStore.request(initNavigationContent),
};

const mutations = { ...navigationContentStore.mutations };

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
