import axios from 'axios';
import { LOCAL_STORAGE_AUTH_KEYS } from '../constants/index';

const AUTH_TOKEN = localStorage.getItem(LOCAL_STORAGE_AUTH_KEYS.accessToken);

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_ENDPOINT,
});

// Set the AUTH_TOKEN for all axios requests.
axiosInstance.interceptors.request.use((config) => {
  config.headers.Authorization = AUTH_TOKEN;
  return config;
});

export default { axiosInstance };
