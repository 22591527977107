import get from 'lodash.get';

const getAvailableInterviewers = (state) => {
  const availableInterviewers = get(state, 'availableInterviewers');
  return availableInterviewers;
};

const getMentees = (state) => {
  const mentees = get(state, 'mentees');
  return mentees;
};

const getDisciplines = (state) => {
  const disciplines = get(state, 'disciplines');
  return disciplines;
};

const getInterviewTypes = (state) => {
  const interviewTypes = get(state, 'interviewTypes');
  return interviewTypes;
};

const getTechnologies = (state) => {
  const technoogies = get(state, 'technologies');
  return technoogies;
};

const getLevels = (state) => {
  const levels = get(state, 'levels');
  return levels;
};

export default {
  getAvailableInterviewers,
  getMentees,
  getDisciplines,
  getInterviewTypes,
  getTechnologies,
  getLevels,
};
