const ROLES = {
  admin: 'admin',
  talentAcquisition: 'talent-acquisition',
  interviewer: 'interviewer',
  candidate: 'candidate',
};

const areRolesEmpty = (roles) => {
  return !roles || roles.length === 0;
};

const isAccessibleForAnyRole = (allowedRoles) => {
  return areRolesEmpty(allowedRoles);
};

const isAnAdminUsed = (userRoles) => {
  if (areRolesEmpty(userRoles)) {
    return false;
  }

  return userRoles.includes(ROLES.admin);
};

const isUserAllowed = ({ allowedRoles, userRoles }) => {
  if (isAccessibleForAnyRole(allowedRoles)) {
    return true;
  }

  if (areRolesEmpty(userRoles)) {
    return false;
  }

  if (isAnAdminUsed(userRoles)) {
    return true;
  }

  return allowedRoles.some((role) => userRoles.includes(role));
};
export { ROLES, areRolesEmpty, isUserAllowed };
