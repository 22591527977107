import { LOCAL_STORAGE_AUTH_KEYS } from '@/constants';
import { GetAccessTokenService, GetAccessTokenServiceRequest } from '@/services/authentication/GetAccessTokenService';
import {
  RefreshAccessTokenService,
  RefreshAccessTokenServiceRequest,
} from '@/services/authentication/RefreshTokenService';

const deleteLocalStorage = () => {
  localStorage.removeItem(LOCAL_STORAGE_AUTH_KEYS.accessToken);
  localStorage.removeItem(LOCAL_STORAGE_AUTH_KEYS.refreshToken);
  localStorage.removeItem(LOCAL_STORAGE_AUTH_KEYS.expiresIn);
  localStorage.removeItem(LOCAL_STORAGE_AUTH_KEYS.scopesToken);
};

const initAuth = async (state, payload) => {
  const request = new GetAccessTokenServiceRequest({ code: payload.code });
  const service = new GetAccessTokenService(request);

  const response = await service.do();
  localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.accessToken, response.accessToken);
  localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.refreshToken, response.refreshToken);
  localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.expiresIn, response.expiresIn);
  localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.scopesToken, response.scopesToken);

  return {
    accessToken: response.accessToken,
    refreshToken: response.refreshToken,
    expiresIn: response.expiresIn,
    scopesToken: response.scopesToken,
  };
};

const userLogout = async () => {
  try {
    deleteLocalStorage();
    return {
      accessToken: undefined,
      refreshToken: undefined,
      expiresIn: undefined,
      scopesToken: undefined,
    };
  } catch (error) {
    console.error('Error while trying to logout.', error);
  }
};

const doRefreshToken = async ({ state }) => {
  const request = new RefreshAccessTokenServiceRequest({ refreshToken: state.authData.refreshToken });
  const service = new RefreshAccessTokenService(request);

  try {
    const response = await service.do();

    localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.accessToken, response.accessToken);
    localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.expiresIn, response.expiresIn);
    localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.scopesToken, response.scopesToken);

    return {
      ...state.authData,
      accessToken: response.accessToken,
      expiresIn: response.expiresIn,
      scopesToken: response.scopesToken,
    };
  } catch (error) {
    deleteLocalStorage();
    throw error;
  }
};

export default { initAuth, doRefreshToken, userLogout };
