import {
  cancelledInterviewStatusId,
  completedInterviewStatusId,
  scheduledInterviewStatusId,
  INTERVIEW_STATUS_COLORS,
} from '@store/modules/dashboard/constants';

const SET_INTERVIEW_STATUS = (state, { id, status }) => {
  const index = state.interviews.findIndex((interview) => interview.id === id);
  switch (status) {
    case scheduledInterviewStatusId:
      state.interviews[index].status = 'Scheduled';
      state.interviews[index].statusId = scheduledInterviewStatusId;
      state.interviews[index].statusColor = INTERVIEW_STATUS_COLORS[scheduledInterviewStatusId];
      break;
    case completedInterviewStatusId:
      state.interviews[index].status = 'Completed';
      state.interviews[index].statusId = completedInterviewStatusId;
      state.interviews[index].statusColor = INTERVIEW_STATUS_COLORS[completedInterviewStatusId];
      break;
    case cancelledInterviewStatusId:
      state.interviews[index].status = 'Canceled';
      state.interviews[index].statusId = cancelledInterviewStatusId;
      state.interviews[index].statusColor = INTERVIEW_STATUS_COLORS[cancelledInterviewStatusId];
      break;
    default:
      break;
  }

  if (!state.statuses.selected.includes(status)) {
    state.interviews = state.interviews.filter((interview) => interview.id !== id);
  }
};

const SET_SELECTED_STATUSES = (state, { statuses }) => {
  state.statuses.selected = statuses;
};

const SHOW_SNACKBAR = (state, text) => {
  state.snackbar.text = text;
  state.snackbar.show = true;
};

export default {
  SET_INTERVIEW_STATUS,
  SET_SELECTED_STATUSES,
  SHOW_SNACKBAR,
};
