<template>
  <v-data-table
    :headers="headers"
    :items="interviews"
    :items-per-page="5"
    class="elevation-1"
    v-bind="$attrs"
    data-test-id="my-interviews-table"
    v-on="$listeners"
  >
  </v-data-table>
</template>

<script>
export default {
  props: {
    interviews: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: 'Candidate', value: 'candidate_name', width: '20%' },
        { text: 'Date', value: 'formattedDate', width: '20%' },
        { text: 'Type', value: 'type.name', width: '20%' },
        { text: 'Interviewer', value: 'interviewer.email', width: '20%' },
        { text: 'Mentee', value: 'mentee.email', width: '20%' },
      ],
    };
  },
  methods: {},
};
</script>
<style scoped></style>
