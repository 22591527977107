import get from 'lodash.get';
import SharedGetters from '@store/modules/shared/getters';

const getInitialFormData = (state) => {
  return get(state, 'originalInterview');
};

const getFormData = (state) => {
  return get(state, 'interview');
};

const getDisciplineHasTechnologies = (state) => {
  const { disciplineHasTechnologies } = get(state, 'interview');
  return disciplineHasTechnologies;
};

export default {
  ...SharedGetters,
  getInitialFormData,
  getFormData,
  getDisciplineHasTechnologies,
};
