const technologies = {
  JavaScript: {
    icon: 'mdi-language-javascript',
    color: '#e9d33b',
  },
  TypeScript: {
    icon: 'mdi-language-typescript',
    color: '#3967b4',
  },
  Angular: {
    icon: 'mdi-angular',
    color: '#be2c2e',
  },
  'Vue.js': {
    icon: 'mdi-vuejs',
    color: '#5ca77a',
  },
  React: {
    icon: 'mdi-react',
    color: '#52c6f2',
  },
  'React Native': {
    icon: 'mdi-react',
    color: '#71c9ee',
  },
  Node: {
    icon: 'mdi-nodejs',
    color: '#68915c',
  },
  'C#': {
    icon: 'mdi-language-csharp',
    color: '#824984',
  },
  '.Net': {
    icon: 'mdi-dot-net',
    color: '#402ac0',
  },
  Ruby: {
    icon: 'mdi-language-ruby',
    color: '#ba2f18',
  },
  'Ruby on Rails': {
    icon: 'mdi-language-ruby-on-rails',
    color: '#ad2814',
  },
  Roku: {
    icon: 'mdi-plus',
    color: '#583684',
  },
  PHP: {
    icon: 'mdi-language-php',
    color: '#7b83ad',
  },
  Python: {
    icon: 'mdi-language-python',
    color: '#385e88',
  },
  Go: {
    icon: 'mdi-language-go',
    color: '#3f99c6',
  },
  Java: {
    icon: 'mdi-language-java',
    color: '#cc6920',
  },
  Kotlin: {
    icon: 'mdi-language-kotlin',
    color: '#5868ce',
  },
  AWS: {
    icon: 'mdi-aws',
    color: '#e79028',
  },
  GCP: {
    icon: 'mdi-google-cloud',
    color: '#4574e3',
  },
  Docker: {
    icon: 'mdi-docker',
    color: '#3d85dd',
  },
  Kubernetes: {
    icon: 'mdi-kubernetes',
    color: '#365dd3',
  },
  Terraform: {
    icon: 'mdi-terraform',
    color: '#4d3bd6',
  },
  Linux: {
    icon: 'mdi-linux',
    color: '#1e1c1d',
  },
};

export default technologies;
