const START_LOADING = (state) => {
  state.interviewersLoading = true;
};

const STOP_LOADING = (state) => {
  state.interviewersLoading = false;
};

const SET_SELECTED_DISCIPLINES = (state, { disciplines }) => {
  state.disciplines.selectedDisciplines = disciplines;
};
export default {
  SET_SELECTED_DISCIPLINES,
  START_LOADING,
  STOP_LOADING,
};
