const MENTEE = {
  id: 1,
  name: 'Mentee Test',
  email: 'mentee@email.com',
};

const TECH_JS = { id: 3, name: 'JavaScript' };
const DISCIPLINE_SWE = { id: 1, name: 'Software Engineering' };
const DISCIPLINE_SRE = { id: 2, name: 'Site Reliability Engineering' };
const INTERVIEW_TYPE_TA = { id: 1, name: 'Tech Assessment', slug: 'tech-assessment', duration: 60 };

const SCHEDULED_STATUS = 1;
const COMPLETED_STATUS = 2;
const CANCELED_STATUS = 3;

const getStatusesResponse = [
  { id: SCHEDULED_STATUS, name: 'Scheduled', slug: 'scheduled' },
  { id: COMPLETED_STATUS, name: 'Completed', slug: 'completed' },
  { id: CANCELED_STATUS, name: 'Canceled', slug: 'canceled' },
];

const getLevelsSuccessResponse = [
  { id: 1, name: 'Level I' },
  { id: 2, name: 'Level II' },
  { id: 3, name: 'Level III' },
  { id: 4, name: 'Level IV' },
  { id: 5, name: 'Level V' },
  { id: 6, name: 'Level VI' },
];

const getDisciplinesSuccessResponse = [
  { name: 'Software Engineering', id: 1, description: null, slug: 'swe' },
  { name: 'Site Reliability Engineering', id: 2, description: null, slug: 'sre' },
];

const getRegionsSuccessResponse = [
  {
    id: 1,
    name: 'Mexico City',
    country_name: 'Mexico',
  },
  {
    id: 2,
    name: 'Guadalajara',
    country_name: 'Mexico',
  },
  {
    id: 3,
    name: 'Queretaro',
    country_name: 'Mexico',
  },
  {
    id: 4,
    name: 'Remote',
    country_name: 'Mexico',
  },
  {
    id: 5,
    name: 'Barcelona',
    country_name: 'Spain',
  },
];

const getTechnologiesSuccessResponse = [
  { id: 1, name: 'Java' },
  { id: 2, name: 'NodeJs' },
  { id: 3, name: 'JavaScript' },
  { id: 4, name: 'Vue.js' },
  { id: 5, name: 'React' },
  { id: 6, name: 'TypeScript' },
  { id: 7, name: 'Angular' },
];

const getInterviewTypesSuccessResponse = [
  { id: 1, name: 'Tech Assessment', description: 'Tech Assessment Description', slug: 'tech-assessment', duration: 60 },
  { id: 2, name: 'Design', description: 'Design Description', slug: 'design', duration: 60 },
  {
    id: 3,
    name: 'Pair Programing',
    description: 'Pair Programming Description',
    slug: 'pair-programming',
    duration: 60,
  },
];

const getInterviewTypesSRESuccessResponse = [
  { id: 4, name: 'DevOps Mentorship', description: 'DevOps M Description', slug: 'devops', duration: 60 },
  { id: 5, name: 'Design & Architecture', description: 'Design Description', slug: 'design-arch', duration: 60 },
];

const getMenteesServiceResponse = [
  {
    id: MENTEE.id,
    email: MENTEE.email,
  },
  {
    id: 2,
    email: 'mentee2@wizeline.com',
  },
];

const getCountries = {
  mexico: 'mex',
  spain: 'esp',
  colombia: 'col',
  usa: 'usa',
  emea: 'XXX',
  apac: 'XXX',
};

export {
  MENTEE,
  SCHEDULED_STATUS,
  COMPLETED_STATUS,
  CANCELED_STATUS,
  DISCIPLINE_SWE,
  DISCIPLINE_SRE,
  INTERVIEW_TYPE_TA,
  TECH_JS,
  getStatusesResponse,
  getLevelsSuccessResponse,
  getDisciplinesSuccessResponse,
  getRegionsSuccessResponse,
  getTechnologiesSuccessResponse,
  getInterviewTypesSuccessResponse,
  getInterviewTypesSRESuccessResponse,
  getMenteesServiceResponse,
  getCountries,
};
