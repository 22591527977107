<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>When do you want to schedule it?</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday"> Today </v-btn>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-btn fab text small color="grey darken-2" data-test-id="next-week-button" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="340">
          <v-row>
            <v-col>
              <v-sheet height="400">
                <v-calendar
                  ref="calendar"
                  v-model="focus"
                  :events="events"
                  color="primary"
                  :type="type"
                  :event-color="getEventColor"
                  :weekdays="weekdays"
                  data-test-id="calendar-wrapper"
                  @click:date="viewDay"
                  @click:more="viewDay"
                  @click:event="setInterviewSelectedSlot"
                  @click:time="selectIntervalTimer"
                ></v-calendar>
              </v-sheet>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Moment from 'moment';

export default {
  data: () => ({
    type: 'week',
    typeToLabel: {
      month: 'Month',
      week: 'Week',
      day: 'Day',
    },
    weekdays: [1, 2, 3, 4, 5, 6],
  }),
  computed: {
    ...mapGetters('scheduleInterview', {
      events: 'getAvailability',
      rangeDate: 'getFormDataRange',
      calendarFocus: 'getCalendarFocus',
      selectedSlot: 'getSelectedSlot',
    }),
    focus: {
      get() {
        return this.calendarFocus;
      },
      set(value) {
        this.setCalendarFocus(value);
      },
    },
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  methods: {
    ...mapActions('scheduleInterview', {
      setSelectedSlot: 'setSelectedSlot',
      setCalendarFocus: 'setCalendarFocus',
      searchAvailableMentee: 'searchAvailableMentee',
      addEmptyCalendarSlot: 'addEmptyCalendarSlot',
    }),
    viewDay({ date }) {
      this.focus = date;
      this.type = 'day';
    },
    getEventColor(event) {
      return event.isSelected ? '#B71C1C' : event.color;
    },
    setToday() {
      this.focus = '';
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    setInterviewSelectedSlot(selectedEvent) {
      this.setSelectedSlot(selectedEvent);
      this.searchAvailableMentee();
    },
    selectIntervalTimer(event) {
      const eventMoment = Moment(`${event.date} ${event.time}`);
      const customEvent = { ...event, eventMoment };
      if (this.selectedSlot?.email) {
        const isBetweenSelectedSlot = eventMoment.isBetween(this.selectedSlot.start, this.selectedSlot.end);
        if (!isBetweenSelectedSlot) {
          this.addEmptyCalendarSlot(customEvent);
          this.searchAvailableMentee();
        }
      } else {
        this.addEmptyCalendarSlot(customEvent);
        this.searchAvailableMentee();
      }
    },
  },
};
</script>
<style scoped>
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  background-color: #1867c0;
  color: #ffffff;
  border: 1px solid #1867c0;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
  left: 4px;
  margin-right: 8px;
  position: relative;
}

.my-event.with-time {
  position: absolute;
  right: 4px;
  margin-right: 0px;
}
</style>
